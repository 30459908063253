// import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Button, Dropdown, UncontrolledAlert } from 'reactstrap';
import { useLocation/* , Link  */ } from 'react-router-dom';

// i18n
import { withTranslation } from 'react-i18next';
import axiosApi from '../../helpers/apiResquests';

// Import menuDropdown
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import LocalesSeleccion from '../CommonForBoth/TopbarDropdown/LocalesSeleccion';
// import megamenuImg from '../../assets/images/megamenu-img.png';

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from '../../store/actions';

const store = require('store');

const Header = React.memo(props => {
  const { t } = props;
  // const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  // const [socialDrp, setsocialDrp] = useState(false);
  const [locales, setLocales] = useState();
  const [divisiones, setDivisiones] = useState();
  const [local, setLocal] = useState();
  const [division, setDivision] = useState();
  const [menuColapsado, setMenuColapsado] = useState(store.get('menu') ? store.get('menu') : false);
  // eliminar cuando se elimine la barra de aviso del nuevo front
  const [avisoNuevoFront, setAvisoNuevoFront] = useState(store.get('aviso') !== undefined ? store.get('aviso') : true);

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // const { location } = props;
  const location = useLocation();

  // eliminar cuando se elimine la barra de aviso del nuevo front
  if (!avisoNuevoFront) {
    setTimeout(() => {
      document.getElementById('contedor-principal').className = 'main-content';
    }, 150);
  }

  /* function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */
  /* !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  } */
  const cargaDatos = async () => {
    try {
      const response = await axiosApi.get('/api/localesDivisiones');
      const result = response.data;
      setLocales(result.locales);
      setDivisiones(result.divisiones);
      setLocal(result.localSelected);
      setDivision(result.divisionSelected);
      store.set('funcionalidades', result.funcionalidades);
      store.set('adm', result.adm);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargaDatos();
    const { body } = document;
    if (menuColapsado) {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }, [location.pathname]);

  function tToggle() {
    const { body } = document;
    store.set('menu', !menuColapsado);
    body.classList.toggle('vertical-collpsed');
    body.classList.toggle('sidebar-enable');
    setMenuColapsado(!menuColapsado);
  }

  // eliminar cuando se elimine la barra de aviso del nuevo front
  function onDismiss(e) {
    if (e.target.className === 'btn-close') {
      document.getElementById('contedor-principal').className = 'main-content';
      setAvisoNuevoFront(false);
      store.set('aviso', false);
    }
  }

  return (
    <>
      <header id="page-topbar">
        <UncontrolledAlert
          hidden={!avisoNuevoFront}
          color="warning"
          className="mb-0"
          role="alert"
          onClick={e => onDismiss(e)}
          style={{ width: '100%' }}
        >
          {t('aviso_web')}
        </UncontrolledAlert>
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <Dropdown
              className="dropdown-mega d-lg-block ms-2"
              isOpen={megaMenu}
              toggle={() => {
                setmegaMenu(!megaMenu);
              }}
            >
              <LocalesSeleccion
                local={local}
                division={division}
                locales={locales}
                divisiones={divisiones}
              />
            </Dropdown>
          </div>
          <div className="d-flex">
            <Button
              type="button"
              color="link"
              style={{ color: '#555b6d' }}
              onClick={() => {
                // eslint-disable-next-line no-alert
                if (window.confirm(t('Do you want to open the help center?')) === true) {
                  window.open('https://helpcenter.journeytok.com/portal/es/home', '_blank', 'noreferrer');
                }
              }}
            >
              <i className="bx bx-help-circle font-size-22" />
            </Button>

            {false && <LanguageDropdown />}
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </>
  );
});

Header.propTypes = {
  // changeSidebarType: PropTypes.func,
  // leftMenu: PropTypes.any,
  // leftSideBarType: PropTypes.any,
  // showRightSidebar: PropTypes.any,
  // showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  // toggleLeftmenu: PropTypes.func
  // location: PropTypes.object,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
})(withTranslation()(Header));
