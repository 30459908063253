import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// import images
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';

import axiosApi from '../../helpers/apiResquests';

// import images
import profile from '../../assets/images/profile-img.png';
import logoImg from '../../assets/images/logotipo_journeytok.png';

const RecuperarPassword = props => {
  const { t } = props;
  const [mensajeError, setMensajeError] = useState('');
  const history = useHistory();
  // meta title
  document.title = t('Journeytok dashboard');

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('VALID_EMAIL')).required(t('Please enter your email'))
    }),
    onSubmit: async (values) => {
      const { email } = values;
      try {
        const response = await axiosApi.post('/api/password/remind', { email: email.trim() });
        const { data } = response;
        const { ok, mensaje } = data;
        if (ok) {
          setMensajeError('');
          validation.setFieldValue('email', '');
          history.push({
            pathname: '/email-check',
            state: { email }
          });
        } else if (data.error) {
          setMensajeError(mensaje);
          throw Error(mensaje);
        }
      } catch (error) {
        console.log(error);
      }
    }
  });

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t('Reset password')}</h5>
                        <p>{t('Reset your Journeytok password.')}</p>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="auth-logo">
                      <a href="https://journeytok.com/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logoImg} alt="" className="rounded-circle" height="34" />
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="p-2">
                    <div className="alert alert-info text-center mb-4" role="alert">
                      {' '}
                      {t('Enter your email and instructions will be sent to you!')}
                      {' '}
                    </div>
                    {mensajeError !== '' && (
                      <div className="alert alert-danger text-center mb-4" role="alert">
                        {' '}
                        {mensajeError}
                        {' '}
                      </div>
                    )}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={t('Enter email')}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            !!(validation.touched.email && validation.errors.email)
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="text-end">
                        <button className="btn btn-primary w-md" type="submit">
                          {t('Reset password')}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t('Remember it ?')}
                  {' '}
                  <Link to="/login" className="fw-medium text-primary">
                    {' '}
                    {t('Sign in')}
                  </Link>
                  {' '}
                </p>
                <p>
                  ©
                  {' '}
                  {new Date().getFullYear()}
                  {' '}
                  Journeytok.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

RecuperarPassword.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(RecuperarPassword);
