import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardTitle, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';
import moment from 'moment';

// import BarChart from '../Charts/BarChart';
import BarChartApex from '../Charts/BarChartApex';
import ColumnChart from '../Charts/ColumnChart';
import Pie from '../Charts/Pie';
import SplineArea from '../Charts/SplineArea';
import LineApexChart from '../Charts/LineApexChart';

import axiosApi from '../../helpers/apiResquests';
import DateRangePicker from '../../components/Common/DateRangePicker';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

const Estadisticas = props => {
  const queryParameters = new URLSearchParams(window.location.search);
  const showChart = parseInt(queryParameters.get('show'), 10);
  const canalesDataOriginal = [
    {
      name: 'Whatsapp',
      data: []
    },
    {
      name: 'Email',
      data: []
    },
    {
      name: 'Webchat',
      data: []
    }
  ];
  const { t } = props;
  const lastYear = new Date();
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  lastYear.setMonth(lastYear.getMonth() + 1);
  const [startDate, setStartDate] = useState(lastYear.toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [idLocal, setIdLocal] = useState(null);
  const [estadosData, setEstadosData] = useState([]);
  const [mesesData, setMesesData] = useState({ categories: [], data: [] });
  const [propietariosData, setPropietariosData] = useState({ categories: [], data: [] });
  const [canalesData, setCanalesData] = useState(canalesDataOriginal);
  const [columnCategories, setColumnCategories] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [agentesFilter, setAgentesFilter] = useState(null);
  const [departamentos, setDepartamentos] = useState([]);
  const [sinActividadData, setSinActividadData] = useState([]);
  const [ganadasPerdidasData, setGanadasPerdidasData] = useState({
    series: [
      { name: t('GANADA'), values: [], color: '', sum: 0 },
      { name: t('PERDIDA'), values: [], color: '', sum: 0 }
    ],
    date: []
  });
  const [tiempoMedioRespuesta, setTiempoMedioRespuesta] = useState({ data: [], categories: [] });
  const [tiempoMedioRespuestaPorAgente, setTiempoMedioRespuestaPorAgente] = useState([]);

  const colores = ['#52D726', '#FFEC00', '#FF7300', '#FF0000', '#007ED6', '#7CDDDD', '#556ee6'];

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargaEstadisticas = async () => {
    try {
      let parametros = '';
      if (agentesFilter && agentesFilter !== '0') {
        parametros = `&agente=${agentesFilter}`;
      }
      let url = `/mc/conversations/estadisticas?idLocal=${idLocal}`;
      if (startDate) {
        url += `&startDate=${startDate}`;
      }
      if (endDate) {
        url += `&endDate=${endDate}`;
      }
      if (parametros !== '') {
        url += parametros;
      }
      const response = await axiosApi.get(url);
      const { data } = response;
      const {
        conversacionesPorEstados,
        conversacionesPorMeses,
        conversacionesPorCanal,
        propietarios,
        conversacionesSinActividad,
        conversacionesGanadasPerdidas,
        conversacionesTiempoRespuesta
      } = data;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t("The data can't be fetched"), 'error');
        return;
      }

      let initialDate = moment(startDate);
      const monthsDiff = Math.round(moment(endDate).diff(initialDate, 'months', true)) + 1;

      const conversacionesMesesParaGrafica = {};
      conversacionesMesesParaGrafica.categories = [];
      conversacionesMesesParaGrafica.data = [];
      for (let i = 0; i < monthsDiff; i++) {
        const month = initialDate.format('MMMM');
        const year = initialDate.format('YY');
        conversacionesMesesParaGrafica.categories.push(t(month) + year);
        if (conversacionesPorMeses[month.toLocaleLowerCase() + year]) {
          conversacionesMesesParaGrafica.data.push(
            parseInt(conversacionesPorMeses[month.toLocaleLowerCase() + year], 10)
          );
        } else {
          conversacionesMesesParaGrafica.data.push(0);
        }
        initialDate.add(1, 'month');
      }
      setMesesData(conversacionesMesesParaGrafica);

      const conversacionesPropietariosParaGrafica = {};
      conversacionesPropietariosParaGrafica.categories = [];
      conversacionesPropietariosParaGrafica.data = [];
      Object.keys(propietarios).forEach(element => {
        const parsedAginado = element.split('_');
        let category = '';
        if (parsedAginado[0] === 'departamento') {
          category = departamentos.find(d => d.id_deptos_equipo ===
            parseInt(parsedAginado[1], 10))?.descripcion;
        } else if (parsedAginado[0] === 'agente') {
          category = agentes.find(a => a.id === parsedAginado[1])?.nombre;
        }
        if (!category) {
          category = '';
        }

        conversacionesPropietariosParaGrafica.categories.push(category);
        conversacionesPropietariosParaGrafica.data.push(propietarios[element]);
      });

      setPropietariosData(conversacionesPropietariosParaGrafica);

      initialDate = moment(startDate);

      const canalesParaGrafica = structuredClone(canalesDataOriginal);
      const categoriesParaGrafica = [];
      for (let i = 0; i < monthsDiff; i++) {
        const month = initialDate.format('MMMM');
        const year = initialDate.format('YY');
        categoriesParaGrafica.push(t(month) + year);
        if (conversacionesPorCanal[month.toLocaleLowerCase() + year]?.Whatsapp) {
          canalesParaGrafica[0].data.push(
            conversacionesPorCanal[month.toLocaleLowerCase() + year].Whatsapp
          );
        } else {
          canalesParaGrafica[0].data.push(0);
        }
        if (conversacionesPorCanal[month.toLocaleLowerCase() + year]?.Email) {
          canalesParaGrafica[1].data.push(
            conversacionesPorCanal[month.toLocaleLowerCase() + year].Email
          );
        } else {
          canalesParaGrafica[1].data.push(0);
        }
        if (conversacionesPorCanal[month.toLocaleLowerCase() + year]?.chat) {
          canalesParaGrafica[2].data.push(
            conversacionesPorCanal[month.toLocaleLowerCase() + year].chat
          );
        } else {
          canalesParaGrafica[2].data.push(0);
        }
        initialDate.add(1, 'month');
      }

      setCanalesData(canalesParaGrafica);
      setColumnCategories(categoriesParaGrafica);

      initialDate = moment(startDate);

      const ganadasPerdidasParaGrafica = {
        series: [
          { name: t('GANADA'), values: [], color: '#23C552', sum: 0 },
          { name: t('PERDIDA'), values: [], color: '#F84F31', sum: 0 }
        ],
        date: []
      };
      for (let i = 0; i < monthsDiff; i++) {
        const month = initialDate.format('MMMM');
        const year = initialDate.format('YY');
        ganadasPerdidasParaGrafica.date.push(t(month) + year);
        if (conversacionesGanadasPerdidas[month.toLocaleLowerCase() + year]?.GANADA) {
          ganadasPerdidasParaGrafica.series[0].values.push(
            conversacionesGanadasPerdidas[month.toLocaleLowerCase() + year].GANADA
          );
          ganadasPerdidasParaGrafica.series[0].sum +=
            conversacionesGanadasPerdidas[month.toLocaleLowerCase() + year].GANADA;
        } else {
          ganadasPerdidasParaGrafica.series[0].values.push(0);
        }
        if (conversacionesGanadasPerdidas[month.toLocaleLowerCase() + year]?.PERDIDA) {
          ganadasPerdidasParaGrafica.series[1].values.push(
            conversacionesGanadasPerdidas[month.toLocaleLowerCase() + year].PERDIDA
          );
          ganadasPerdidasParaGrafica.series[1].sum +=
            conversacionesGanadasPerdidas[month.toLocaleLowerCase() + year].PERDIDA;
        } else {
          ganadasPerdidasParaGrafica.series[1].values.push(0);
        }
        initialDate.add(1, 'month');
      }

      setGanadasPerdidasData(ganadasPerdidasParaGrafica);

      const estadosDataParaGrafica = [];
      Object.keys(conversacionesPorEstados).forEach((element, index) => {
        estadosDataParaGrafica.push({
          name: t(element),
          value: conversacionesPorEstados[element],
          color: colores[index % colores.length]
        });
      });
      setEstadosData(estadosDataParaGrafica);

      const sinActividadDataParaGrafica = [];
      Object.keys(conversacionesSinActividad).forEach((element, index) => {
        sinActividadDataParaGrafica.push({
          name: t(element),
          value: conversacionesSinActividad[element],
          color: colores[index % colores.length]
        });
      });
      setSinActividadData(sinActividadDataParaGrafica);

      initialDate = moment(startDate);

      const tiempoMedioRespuestaParaGrafica = {};
      const tiempoMedioRespuestaParaGraficaPorAgente = {};
      tiempoMedioRespuestaParaGrafica.categories = [];
      tiempoMedioRespuestaParaGrafica.data = [];
      for (let i = 0; i < monthsDiff; i++) {
        const month = initialDate.format('MMMM');
        const year = initialDate.format('YY');
        tiempoMedioRespuestaParaGrafica.categories.push(t(month) + year);
        // tramos los datos para la gráfica como datos agregados
        if (conversacionesTiempoRespuesta[month.toLocaleLowerCase() + year]) {
          const tiempos = [];
          Object.keys(conversacionesTiempoRespuesta[month.toLocaleLowerCase() + year]).forEach(
            element => {
              tiempos.push(
                conversacionesTiempoRespuesta[month.toLocaleLowerCase() + year][element]
              );
            }
          );
          tiempoMedioRespuestaParaGrafica.data.push(
            parseInt(tiempos.reduce((a, b) => a + b) / tiempos.length / 1000 / 60, 10)
          );
        } else {
          tiempoMedioRespuestaParaGrafica.data.push(0);
        }
        // tramos los datos para la gráfica separados por agentes
        agentes.forEach(agente => {
          if (agentesFilter && agentesFilter !== '0') {
            return;
          }
          if (!tiempoMedioRespuestaParaGraficaPorAgente[agente.id]) {
            tiempoMedioRespuestaParaGraficaPorAgente[agente.id] = [];
          }
          if (conversacionesTiempoRespuesta[month.toLocaleLowerCase() + year]) {
            if (conversacionesTiempoRespuesta[month.toLocaleLowerCase() + year][agente.id]) {
              tiempoMedioRespuestaParaGraficaPorAgente[agente.id].push(
                parseInt(
                  conversacionesTiempoRespuesta[month.toLocaleLowerCase() + year][agente.id] /
                    1000 /
                    60,
                  10
                )
              );
            } else {
              tiempoMedioRespuestaParaGraficaPorAgente[agente.id].push(0);
            }
          } else {
            tiempoMedioRespuestaParaGraficaPorAgente[agente.id].push(0);
          }
        });
        initialDate.add(1, 'month');
      }
      const tiemposPorAgente = [];
      agentes.forEach(agente => {
        if (tiempoMedioRespuestaParaGraficaPorAgente[agente.id]) {
          tiemposPorAgente.push({
            name: agente.nombre,
            data: tiempoMedioRespuestaParaGraficaPorAgente[agente.id]
          });
        }
      });
      setTiempoMedioRespuestaPorAgente(tiemposPorAgente);
      setTiempoMedioRespuesta(tiempoMedioRespuestaParaGrafica);
    } catch (error) {
      console.log(error);
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const cargaDatos = async () => {
    try {
      const whoami = await axiosApi.get('/mc/whoami');
      const { data } = whoami;
      setIdLocal(data.idLocal.toString());
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const cargaConfig = async () => {
    try {
      const response = await axiosApi.get(`/mc/companies/config?id=${idLocal}`);
      const { data } = response;
      const {
        error,
        agentes: agentesAux,
        departamentosEquipo
      } = data;
      if (error) {
        showToast(t('Error getting data'), t("The data can't be fetched"), 'error');
        return;
      }
      setAgentes(agentesAux);
      setDepartamentos(departamentosEquipo);
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  useEffect(() => {
    if (idLocal) {
      cargaConfig();
    }
  }, [idLocal]);

  useEffect(() => {
    if (idLocal) {
      cargaEstadisticas();
    }
  }, [agentes, departamentos, agentesFilter, startDate, endDate]);

  const setDates = (event) => {
    setStartDate(event[0]?.toISOString());
    setEndDate(event[1]?.toISOString());
  };

  const filtrar = () => {
    // loadData();
  };

  document.title = `${t('Statistics')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={t('Statistics')}
            breadcrumbItems={[{ title: t('Messaging Center') }, { title: t('Statistics') }]}
          />
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            setDates={setDates}
            filtrar={filtrar}
          />
          <Row>
            <Col className="col-12">
              <Row>
                <Col className="col-2 mb-2">
                  <select
                    className="form-select"
                    id="horizontal-Assigned-Input"
                    defaultValue
                    onChange={event => {
                      setAgentesFilter(event.target.value);
                    }}
                  >
                    <option value={0}>
                      {t('All')}
                    </option>
                    {agentes.map(agente => {
                      return (
                        <option key={`filtro_agente${agente.id}`} value={agente.id}>
                          {agente.nombre}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <CardTitle>{t('New conversations')}</CardTitle>
                      <div id="doughnut-chart" className="e-chart">
                        <BarChartApex categories={mesesData.categories} data={mesesData.data} horizontal={false} seriesName={t('New conversations')} color="#34c38f" toolbar />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <CardTitle>{t('Channel')}</CardTitle>
                      <div id="doughnut-chart" className="e-chart">
                        <ColumnChart categories={columnCategories} series={canalesData} title={t('New')} toolbar />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <CardTitle>{t('Statuses')}</CardTitle>
                      <div id="pie-chart" className="e-chart">
                        <Pie
                          title={t('Status')}
                          data={estadosData}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <CardTitle>{t('Average response time')}</CardTitle>
                      <div id="pie-chart" className="e-chart">
                        <LineApexChart
                          series={[{
                            name: t('Average response time (minutes)'),
                            data: tiempoMedioRespuesta.data
                          }]}
                          categories={tiempoMedioRespuesta.categories}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <CardTitle>{t('Owners')}</CardTitle>
                      <div id="pie-chart" className="e-chart">
                        <BarChartApex categories={propietariosData.categories} data={propietariosData.data} horizontal seriesName={t('Conversations')} color="#34c38f" />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <CardTitle>{t('Without activity')}</CardTitle>
                      <div id="pie-chart" className="e-chart">
                        <Pie
                          title={t('Without activity')}
                          data={sinActividadData}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <CardTitle>{t('Average response time by agent')}</CardTitle>
                      <div id="pie-chart" className="e-chart">
                        <LineApexChart
                          series={tiempoMedioRespuestaPorAgente}
                          categories={tiempoMedioRespuesta.categories}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {showChart === 1 && (
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <CardTitle>{t('Wons vs losts')}</CardTitle>
                        <div id="doughnut-chart" className="e-chart">
                          <SplineArea
                            data={ganadasPerdidasData.series}
                            dateArray={ganadasPerdidasData.date}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

Estadisticas.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Estadisticas);
