import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import 'assets/scss/estilos.scss';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Modal,
  Form,
  Input,
  FormFeedback
} from 'reactstrap';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import toastr from 'toastr';
import axiosApi from '../../../helpers/apiResquests';

import 'toastr/build/toastr.min.css';
// Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const Alerta = props => {
  const { idAlerta } = useParams();
  let correoInicial;
  if (idAlerta === 'crear') {
    correoInicial = [{ email: '' }];
  } else {
    correoInicial = [];
  }
  const [emails, setEmails] = useState(1);
  const [redirect, setRedirect] = useState(false);

  const [modalStandard, setModalStandard] = useState(false);
  const [cambiosPendientes, setCambiosPendientes] = useState(false);

  const { t } = props;
  const history = useHistory();

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function togStandard() {
    setModalStandard(!modalStandard);
    removeBodyCss();
  }

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  // Form validation
  const validationMessagingCenterReport = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: '',
      activa: false,
      descripcion: '',
      diaMes: '1',
      diaSemana: 'Mon',
      tiempo: 1,
      filtro: 'puntuacion_excelente',
      correosReceptores: correoInicial,
      momento: 'minutos'
    },
    validationSchema: Yup.object().shape({
      descripcion: Yup.string().required('This value is required'),
      tiempo: Yup.number().min(1, 'This value must be greater than 0').required(),
      correosReceptores: Yup.array().of(
        Yup.object()
          .shape({
            email: Yup.string().email('Enter a valid email').required('Email is required')
          })
          .required()
      )
    }),
    onSubmit: async () => {
      let receptores = [];
      const {
        correosReceptores,
        descripcion,
        momento,
        activa,
        id,
        tiempo,
      } = validationMessagingCenterReport.values;
      correosReceptores.forEach(correo => {
        if (correo.valido) {
          receptores = [...receptores, correo.email];
        }
      });
      const datosAlerta = {
        activa,
        id_alertas: id,
        descripcion,
        momento,
        receptores: [...new Set(receptores)],
        tiempo
      };
      try {
        const response = await axiosApi.post('/api/alerta/messagingcenter', datosAlerta);
        const { data } = response;
        if (data.err) {
          throw Error('Error');
        }
        const title = t('Report saved');
        const message = t('The report has been modified');
        showToast(title, message, 'success');
        setCambiosPendientes(false);
        validationMessagingCenterReport.setFieldValue('id', data.id_alertas);
      } catch (error) {
        console.log(error);
        const title = t('Error saving report');
        const message = t('The report has not been modified');
        showToast(title, message, 'error');
      }
    }
  });

  function handleAddRowNested() {
    const { correosReceptores } =
      validationMessagingCenterReport.values;
    const item = { email: '' };
    validationMessagingCenterReport.setFieldValue('correosReceptores', [...correosReceptores, item]);
    setEmails(emails + 1);
  }

  function handleRemoveRowNested(e, idx) {
    const { correosReceptores } =
    validationMessagingCenterReport.values;
    const rowsAux = correosReceptores;
    if (emails > 1) {
      document.getElementById(`nested${idx}`).style.display = 'none';
      rowsAux[idx].valido = false;
      rowsAux[idx].email = 'no@email.com';
      validationMessagingCenterReport.setFieldValue('correosReceptores', rowsAux);
      setEmails(emails - 1);
    }
  }

  function cancelar() {
    if (cambiosPendientes) {
      togStandard();
    } else {
      history.goBack();
    }
  }

  const cargaDatos = async () => {
    if (idAlerta !== 'crear') {
      try {
        const response = await axiosApi.get(`/api/alertas/messagingcenter/${idAlerta}`);
        const { data } = response;
        const alerta = data;
        validationMessagingCenterReport.setFieldValue('id', alerta.id_alertas);
        validationMessagingCenterReport.setFieldValue('activa', alerta.activa === 1);
        validationMessagingCenterReport.setFieldValue('descripcion', alerta.descripcion);
        validationMessagingCenterReport.setFieldValue('periodo', alerta.periodo);
        if (alerta.periodo % 1440 === 0) {
          validationMessagingCenterReport.setFieldValue('tiempo', alerta.periodo / 1440);
          validationMessagingCenterReport.setFieldValue('momento', 'dias');
        } else if (alerta.periodo % 60 === 0) {
          validationMessagingCenterReport.setFieldValue('tiempo', alerta.periodo / 60);
          validationMessagingCenterReport.setFieldValue('momento', 'horas');
        } else {
          validationMessagingCenterReport.setFieldValue('tiempo', alerta.periodo);
          validationMessagingCenterReport.setFieldValue('momento', 'minutos');
        }
        if (alerta.correo_receptor && alerta.correo_receptor !== '') {
          const correos = [];
          JSON.parse(JSON.stringify(alerta.correo_receptor)).forEach((correo) => {
            setEmails(emails + 1);
            correos.push({ email: correo, valido: true });
          });
          validationMessagingCenterReport.setFieldValue('correosReceptores', correos);
        } else {
          const item = { email: '' };
          const { correosReceptores } =
          validationMessagingCenterReport.values;
          validationMessagingCenterReport.setFieldValue('correosReceptores', [...correosReceptores, item]);
        }
      } catch (e) {
        setRedirect(true);
      }
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  document.title = `${t('Report data')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      {redirect && <Redirect to="/alertas/messagingcenter" />}
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={{ size: 8, offset: 2 }}>
              <Breadcrumbs
                title={t('Settings')}
                breadcrumbItem={t('Messaging Center reports')}
                back
              />
            </Col>
          </Row>
          <Row>
            <Col lg={{ size: 8, offset: 2 }}>
              <Card>
                <CardBody>
                  {!validationMessagingCenterReport.values.id && (
                    <CardTitle className="mb-4">{t('New report')}</CardTitle>
                  )}
                  {validationMessagingCenterReport.values.id && (
                    <CardTitle className="mb-4">{t('Edit report')}</CardTitle>
                  )}

                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validationMessagingCenterReport.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="row mb-4">
                      <Col sm={8}>
                        <Input
                          name="id"
                          type="hidden"
                          className="form-control"
                          id="horizontal-id-Input"
                          value={validationMessagingCenterReport.values.id || ''}
                        />
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('Enable')}
                      </Label>
                      <Col sm={1} className="form-check-label">
                        <div className="form-check form-switch form-switch-md">
                          <input
                            checked={validationMessagingCenterReport.values.activa || false}
                            type="checkbox"
                            className="form-control form-check-input"
                            id="customSwitchsizemd"
                            onChange={event => {
                              if (!cambiosPendientes) {
                                setCambiosPendientes(true);
                              }
                              validationMessagingCenterReport.setFieldValue(
                                'activa',
                                event.target.checked
                              );
                            }}
                          />
                        </div>
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-lastname-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('Description')}
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          name="descipcion"
                          className="form-control"
                          id="horizontal-lastname-Input"
                          onChange={event => {
                            if (!cambiosPendientes) {
                              setCambiosPendientes(true);
                            }
                            validationMessagingCenterReport.setFieldValue(
                              'descripcion',
                              event.target.value
                            );
                          }}
                          onBlur={validationMessagingCenterReport.handleBlur}
                          value={validationMessagingCenterReport.values.descripcion || ''}
                          invalid={
                            !!(
                              validationMessagingCenterReport.touched.descripcion &&
                              validationMessagingCenterReport.errors.descripcion
                            )
                          }
                        />
                        {validationMessagingCenterReport.touched.descripcion &&
                        validationMessagingCenterReport.errors.descripcion ? (
                          <FormFeedback type="invalid">
                            {validationMessagingCenterReport.errors.descripcion}
                          </FormFeedback>
                          ) : null}
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label htmlFor="horizontal-momento-Input" className="col-sm-4 col-form-label">
                        {t('After')}
                      </Label>
                      <Col sm={4}>
                        <Input
                          type="number"
                          name="tiempo"
                          step="1"
                          className="form-control"
                          id="horizontal-tiempo-Input"
                          onChange={event => {
                            if (!cambiosPendientes) {
                              setCambiosPendientes(true);
                            }
                            validationMessagingCenterReport.setFieldValue(
                              'tiempo',
                              Number(event.target.value)
                            );
                          }}
                          onBlur={validationMessagingCenterReport.handleBlur}
                          value={validationMessagingCenterReport.values.tiempo}
                          invalid={
                            !!(
                              validationMessagingCenterReport.touched.tiempo &&
                              validationMessagingCenterReport.errors.tiempo
                            )
                          }
                        />
                        {validationMessagingCenterReport.touched.tiempo &&
                        validationMessagingCenterReport.errors.tiempo ? (
                          <FormFeedback type="invalid">
                            {validationMessagingCenterReport.errors.tiempo}
                          </FormFeedback>
                          ) : null}
                      </Col>
                      <Col sm={4}>
                        <select
                          value={validationMessagingCenterReport.values.momento}
                          className="form-select"
                          id="horizontal-momento-Input"
                          onChange={e => {
                            if (!cambiosPendientes) {
                              setCambiosPendientes(true);
                            }
                            validationMessagingCenterReport.setFieldValue(
                              'momento',
                              e.target.value
                            );
                          }}
                        >
                          <option value="minutos">{t('Minutes')}</option>
                          <option value="horas">{t('Hours')}</option>
                          <option value="dias">{t('Days')}</option>
                        </select>
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label className="col-sm-4 col-form-label">{t('Recipients')}</Label>
                      <Col sm={8}>
                        <table style={{ width: '100%' }}>
                          <tbody>
                            {validationMessagingCenterReport.values.correosReceptores.map(
                              (item, idx) => (
                                <tr id={`nested${idx}`} key={idx}>
                                  <td>
                                    <Row>
                                      <Col md="10">
                                        <Input
                                          name={`email${idx}`}
                                          type="email"
                                          className="inner form-control"
                                          placeholder={t('Recipient')}
                                          value={item.email}
                                          onChange={event => {
                                            if (!cambiosPendientes) {
                                              setCambiosPendientes(true);
                                            }
                                            const { correosReceptores } =
                                              validationMessagingCenterReport.values;
                                            validationMessagingCenterReport.setFieldValue(
                                              'correosReceptores',
                                              correosReceptores.map((value, j) => {
                                                if (idx === j) {
                                                  value = {
                                                    email: event.target.value,
                                                    valido: true
                                                  };
                                                }
                                                return value;
                                              })
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col md="1">
                                        <Button
                                          onClick={e => {
                                            handleRemoveRowNested(e, idx);
                                          }}
                                          color="link"
                                          className="btn-block inner"
                                          style={{ width: '100%' }}
                                        >
                                          <i
                                            style={{ fontSize: '20px', color: 'lightslategrey' }}
                                            className="bx bx-trash"
                                          />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        <Button
                          onClick={() => {
                            handleAddRowNested();
                          }}
                          color="link"
                          className="mt-1"
                        >
                          {t('Add email')}
                        </Button>
                      </Col>
                    </div>
                    <div className="row justify-content-end">
                      <Col sm={8}>
                        <div>
                          <Button type="submit" color="success" className="w-md">
                            {t('Save')}
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              cancelar();
                            }}
                            color="link"
                            className="w-md"
                          >
                            {t('Cancel')}
                          </Button>
                        </div>
                      </Col>
                      <Modal
                        isOpen={modalStandard}
                        toggle={() => {
                          togStandard();
                        }}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                            {t('Are you sure?')}
                          </h5>
                          <button
                            type="button"
                            onClick={() => {
                              setModalStandard(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <p>{t('If you click continue, unsaved changes will be lost.')}</p>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                              togStandard();
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                          >
                            {t('Close')}
                          </button>
                          <button
                            onClick={() => {
                              history.goBack();
                            }}
                            type="button"
                            className="btn btn-primary "
                          >
                            {t('Continue')}
                          </button>
                        </div>
                      </Modal>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Alerta.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Alerta);
