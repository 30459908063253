import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// Charts
import BarChartApex from 'pages/Charts/BarChartApex';
import Pie from '../Charts/Pie';

import axiosApi from '../../helpers/apiResquests';
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import DateRangePicker from '../../components/Common/DateRangePicker';

const PanelVisitante = props => {
  const { t } = props;
  const [dataEdad, setDataEdad] = useState([]);
  const [dataOrigen, setDataOrigen] = useState([]);
  const [dataCalidad, setDataCalidad] = useState([]);
  const [dataAcceso, setDataAcceso] = useState([]);
  const [dataIdioma, setDataIdioma] = useState([]);
  const [arrayIdioma, setArrayIdioma] = useState([]);
  const [dataNacionalidad, setDataNacionalidad] = useState([]);
  const [arrayNacionalidad, setArrayNacionalidad] = useState([]);
  const [totalDatos, setTotalDatos] = useState(0);
  const [totalCargado, setTotalCargado] = useState(0);
  const [porComercial, setPorComercial] = useState(0);
  const [totalComercial, setTotalComercial] = useState(0);
  const coloresEdad = ['#6F6456', '#CB7E94', '#A1CFDD', '#B7D968', '#FF9C00', '#E94B30', '#8a3ab9', '#f74c98'];
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  const [startDate, setStartDate] = useState(currentDate.toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());

  const setDates = (event) => {
    setStartDate(event[0]?.toISOString());
    setEndDate(event[1]?.toISOString());
  };

  function preparaDatosParaPie(datos, etiquetas, colores) {
    const preparedData = [];
    for (let i = 0; i < datos.length; i++) {
      const datoPreparado = { name: etiquetas[i], value: datos[i], color: colores[i] };
      preparedData.push(datoPreparado);
    }
    return preparedData;
  }

  const cargaDatos = async (extra = '') => {
    try {
      const response = await axiosApi.get(`/api/locales/panelVisitante${extra}`);
      const data = response.data.datos;
      const datosEdad = preparaDatosParaPie(data.dataEdades, [t('No data'), '< 18', '18-24', '25-34', '35-44', '45-54', '55-64', '> 64'], coloresEdad);
      setDataEdad(datosEdad);
      const datosOrigen = preparaDatosParaPie(data.dataOrigen, ['PMS', 'CSV', 'wifi'], ['#3B5998', '#dd4b39', '#1da1f2']);
      setDataOrigen(datosOrigen);
      const datosCalidad = preparaDatosParaPie(
        data.dataCalidadEmail,
        [t('email_verificados'), t('email_validos'), t('email_erroneos'), t('email_no_validados')],
        ['#0A6522', '#7CFC00', '#B90E0A', '#311465']
      );
      setDataCalidad(datosCalidad);
      const datosAcceso = preparaDatosParaPie(
        data.dataTipoLogin,
        data.labelsDinamicosTipoLogin,
        ['#f4c20d', '#4267B2', '#1da1f2', '#8a3ab9', '#2f81a9', '#db7a26', '#5cb85c', '#5c055c', '#f74c98', '#8a50F0', '', '#64DD17', '#f0cc1b', '#64B5F6', '#5D4037']
      );
      setDataAcceso(datosAcceso);
      setDataIdioma(data.dataIdiomas);
      setArrayIdioma(data.labelsDinamicosIdiomas);
      setDataNacionalidad(data.dataNacionalidades);
      setArrayNacionalidad(data.labelsDinamicosNacionalidades);
      setTotalCargado(data.totalvisitantesFiltered);
      setTotalDatos(data.totalvisitantesAbsoluto);
      setPorComercial(data.aceptaComercial.porcentajeAceptaComercialFiltered);
      setTotalComercial(data.aceptaComercial.porcentajeAceptaComercialTotal);
    } catch (error) {
      // console.log(error);
    }
  };

  const filtrar = () => {
    const startArray = startDate.split('T')[0].split('-');
    const endArray = endDate.split('T')[0].split('-');
    const startString = `${startArray[2]}-${startArray[1]}-${startArray[0]}`;
    const endString = `${endArray[2]}-${endArray[1]}-${endArray[0]}`;
    const extra = `/${startString}/${endString}`;
    cargaDatos(extra);
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  document.title = `${t('Database')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Database')} breadcrumbItems={[{ title: 'Smart Wifi & Analytics' }, { title: t('Database') }]} />
          <Row style={{ display: 'flex', justifyContent: 'end', marginTop: '1%', marginBottom: '2%' }}>
            <Col>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setDates={setDates}
                filtrar={filtrar}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <Card>
                <CardBody className="mini-stats-wid">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        {t('Total ammount of customer')}
                      </p>
                      <h3>
                        {totalDatos}
                      </h3>
                    </div>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary" style={{ marginTop: '4%' }}>
                      <span className="avatar-title">
                        <i className="bx bx-data font-size-24" />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody className="mini-stats-wid">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        {t('Loaded customer data')}
                      </p>
                      <h3>
                        {totalCargado}
                      </h3>
                    </div>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary" style={{ marginTop: '4%' }}>
                      <span className="avatar-title">
                        <i className="bx bx-id-card font-size-24" />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody className="mini-stats-wid">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        {t('Total customer that accepted commercial')}
                      </p>
                      <h3>
                        {`${totalComercial}%`}
                      </h3>
                    </div>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary" style={{ marginTop: '4%' }}>
                      <span className="avatar-title">
                        <i className="bx bx-badge-check font-size-24" />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody className="mini-stats-wid">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        {t('Loaded customer that accept commercial')}
                      </p>
                      <h3>
                        {`${porComercial}%`}
                      </h3>
                    </div>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary" style={{ marginTop: '4%' }}>
                      <span className="avatar-title">
                        <i className="bx bx-badge-check font-size-24" />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ paddingLeft: '1%', paddingRight: '1%' }}>
            <Col>
              <Card style={{ padding: '1%' }}>
                <CardBody>
                  <CardTitle><h5>{t('Origin')}</h5></CardTitle>
                  <Pie
                    title={t('Origin')}
                    data={dataOrigen}
                    toolbar
                  />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{ padding: '1%' }}>
                <CardBody>
                  <CardTitle><h5>{t('Wifi access')}</h5></CardTitle>
                  <Pie
                    title={t('Wifi access')}
                    data={dataAcceso}
                    toolbar
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ paddingLeft: '1%', paddingRight: '1%' }}>
            <Col>
              <Card style={{ padding: '1%' }}>
                <CardBody>
                  <CardTitle><h5>{t('Language')}</h5></CardTitle>
                  <BarChartApex
                    title={t('Language')}
                    data={dataIdioma}
                    categories={arrayIdioma}
                    horizontal
                    toolbar
                  />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{ padding: '1%' }}>
                <CardBody>
                  <CardTitle><h5>{t('Nationality')}</h5></CardTitle>
                  <BarChartApex
                    title={t('Nationality')}
                    data={dataNacionalidad}
                    categories={arrayNacionalidad}
                    horizontal
                    toolbar
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ padding: '1%' }}>
                <CardBody>
                  <CardTitle><h5>{t('Age')}</h5></CardTitle>
                  <Pie
                    title={t('Age')}
                    data={dataEdad}
                    toolbar
                  />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{ padding: '1%' }}>
                <CardBody>
                  <CardTitle><h5>{t('Email quality')}</h5></CardTitle>
                  <Pie
                    title={t('Email quality')}
                    data={dataCalidad}
                    toolbar
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

PanelVisitante.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(PanelVisitante);
