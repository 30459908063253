import React, { useState } from 'react';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Link } from 'react-router-dom';

import { Row, Col, CardBody, Card, Container, Form, Label, Input, FormFeedback } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import axiosApi from '../../helpers/apiResquests';

// import images
import profileImg from '../../assets/images/profile-img.png';
import logoImg from '../../assets/images/logotipo_journeytok.png';

const CambiarPasswordNueva = props => {
  const { t } = props;
  const [mensajeError, setMensajeError] = useState('');
  // meta title
  document.title = t('Journeytok dashboard');

  // form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t('Please enter your password'))
        .min(8)
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\d\w\W].*$/, t('PASSWORD_NOT_VALID')),
      passwordConfirmation: Yup.string()
        .required(t('Please enter your password'))
        .when('password', {
          is: val => !!(val && val.length > 0),
          then: Yup.string().oneOf([Yup.ref('password')], t('PASSWORD_NOT_MATCH'))
        })
    }),
    onSubmit: async (values) => {
      const { password, passwordConfirmation } = values;
      try {
        const response = await axiosApi.post(
          '/api/password/change',
          {
            nueva_pass: password,
            nueva_pass_confirmation: passwordConfirmation
          }
        );
        const { data } = response;
        const { ok, tipoError } = data;
        if (ok) {
          window.location.href = `${process.env.REACT_APP_HOST_PANEL}/estadisticas/graficas`;
        } else if (tipoError) {
          if (tipoError === 'PASSWORD_DIFERENTE') {
            setMensajeError(t('The new password must be different from the old password'));
          } else if (tipoError === 'PASSWORD_ERROR') {
            setMensajeError(t('PASSWORD_NOT_VALID'));
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  });
  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t('Password change')}</h5>
                        <p>{t('Set your new password')}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">{t('Password')}</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder={t('Enter Password')}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={
                            !!(validation.touched.password && validation.errors.password)
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{t('Confirm password')}</Label>
                        <Input
                          name="passwordConfirmation"
                          type="password"
                          placeholder={t('Enter Password')}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.passwordConfirmation || ''}
                          invalid={
                            !!(validation.touched.passwordConfirmation &&
                              validation.errors.passwordConfirmation)
                          }
                        />
                        {validation.touched.passwordConfirmation &&
                          validation.errors.passwordConfirmation ? (
                            <FormFeedback type="invalid">{validation.errors.passwordConfirmation}</FormFeedback>
                          ) : null}
                      </div>

                      {mensajeError !== '' && (
                        <div className="alert alert-danger text-center mb-4" role="alert">
                          {' '}
                          {mensajeError}
                          {' '}
                        </div>
                      )}

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          {t('Send')}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  ©
                  {' '}
                  {new Date().getFullYear()}
                  {' '}
                  Journeytok
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

CambiarPasswordNueva.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(CambiarPasswordNueva);
