import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  Button,
  Form,
  Input,
  FormFeedback,
  CardText
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import _ from 'lodash';

import { formatQuery } from 'react-querybuilder';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import FiltrosSegmentos from '../../components/Common/FiltrosSegmentos';
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

const DEFAULT_QUERY = {
  combinator: 'or',
  rules: [{
    combinator: 'and',
    rules: [{
      field: '~',
      operator: '=',
      value: '',
      valueSource: 'value'
    }] }] };

const Segmentos = props => {
  const { t } = props;
  const [segmentosData, setSegmentosData] = useState([]);
  const [modalNuevoSegmento, setModalNuevoSegmento] = useState(false);
  const [modalCambioObjeto, setModalCambioObjeto] = useState(false);
  const [query, setQuery] = useState(DEFAULT_QUERY);
  const [segmentoBorrar, setSegmentoBorrar] = useState(null);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [valorObjeto, setValorObjeto] = useState(null);

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargaSegmentos = async () => {
    try {
      const response = await axiosApi.get('/api/segmentos');
      const { data } = response;
      const { segmentos, error } = data;
      if (error) {
        showToast(t('Error getting data'), t("The data can't be fetched"), 'error');
        return;
      }
      setSegmentosData(segmentos);
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
      setSegmentosData([]);
    }
  };

  useEffect(() => {
    cargaSegmentos();
  }, []);

  const rowStyle = () => {
    return {};
  };

  // Form validation
  const validationSegmento = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nombre: '',
      segmento: '',
      objeto: ''
    },
    validationSchema: Yup.object().shape({
      nombre: Yup.string().required(t('VALUE_REQUIRED')),
      segmento: Yup.string().required(t('VALUE_REQUIRED')).test(
        'is_not_empty_segment',
        t('VALUE_REQUIRED'),
        (value) => {
          if (!value) return false;
          const esq = JSON.parse(value);
          if (esq.rules.length === 0) return false;
          return esq.rules.every(r => {
            if (r.rules === undefined || r.rules.length === 0) return false;
            if (r.rules) {
              return r.rules.every(r2 => {
                if (r2.field in ['reservas_pagado', 'reservas_checkin_estado_nuevo', 'reservas_checkin_estado_cliente', 'reservas_pagado_clientes']) {
                  return true;
                }
                const arrayOperadorSinValor = ['today', 'last_week', 'this_month', 'last_30_days', 'this_year', 'last_year', 'verified', 'valid', 'unvalidated', 'erroneous', 'blackList', 'empty', 'notEmpty'];
                if (arrayOperadorSinValor.includes(r2.operator)) return true;
                if (r2.value === '' || r2.value.length === 0) return false;
                return true;
              });
            }
            return true;
          });
        }
      ),
      objeto: Yup.string().required(t('VALUE_REQUIRED')).oneOf(
        [
          'CLIENTES',
          'RESERVAS'
        ],
        t('VALUE_REQUIRED')
      ),
    }),
    onSubmit: async () => {
      const { id, nombre, segmento, objeto } = validationSegmento.values;
      const datosNuevoSegmento = {
        id,
        nombre,
        segmento,
        objeto
      };
      try {
        const response = await axiosApi.post('/api/segmentos/nuevo', datosNuevoSegmento);
        const { data } = response;
        const { guardado, motivo } = data;
        if (guardado) {
          const title = t('Segment saved');
          let message = '';
          if (id !== '' && id) message = t('The segment has been modified');
          else message = t('The segment has been created');
          showToast(title, message, 'success');
          setModalNuevoSegmento(false);
          setQuery(DEFAULT_QUERY);
          validationSegmento.setFieldValue('id', '');
          validationSegmento.setFieldValue('segmento', '');
          validationSegmento.setFieldValue('nombre', '');
          validationSegmento.setFieldValue('objeto', '');
          const segmentosAux = structuredClone(segmentosData);
          const obj = JSON.parse(localStorage.getItem('userName'));
          const nombreUsuario = `${obj.name} ${obj.lastname}`;
          validationSegmento.resetForm();
          if (id !== '' && id) {
            const index = segmentosAux.findIndex(s => s.id_filtros_predefinidos === id);
            if (index >= 0) {
              segmentosAux[index].nombre = nombre;
              segmentosAux[index].vistas = JSON.stringify([objeto]);
              segmentosAux[index].segmento = _.cloneDeep(segmento);
            }
          } else {
            segmentosAux.push({
              nombre,
              nombreCompleto: nombreUsuario,
              vistas: JSON.stringify([objeto]),
              id_filtros_predefinidos: guardado,
              segmento: _.cloneDeep(segmento)
            });
          }
          setSegmentosData(segmentosAux);
        } else if (motivo === 'FILTRO_EXISTENTE') {
          const title = t('Error saving segment');
          const message = t('The segment already exists');
          showToast(title, message, 'error');
        } else if (motivo === 'FILTRO_NO_PERTENECE') {
          const title = t('Error saving segment');
          const message = t('The segment does not belongs to you');
          showToast(title, message, 'error');
        } else {
          const title = t('Error saving segment');
          const message = t('The segment has not been modified');
          showToast(title, message, 'error');
        }
      } catch (error) {
        console.log(error);
        const title = t('Error saving segment');
        const message = t('The segment has not been modified');
        showToast(title, message, 'error');
      }
    }
  });

  const eliminar = async id => {
    setModalEliminar(false);
    const idSegmento = {
      id
    };
    try {
      const response = await axiosApi.post('/api/segmentos/borrar', idSegmento);
      const { data } = response;
      const { borrado, motivo } = data;
      setSegmentoBorrar(null);
      if (!borrado) {
        if (motivo === 'FILTRO_NO_PERTENECE') {
          const title = t('Error deleting segment');
          const message = t('The segment does not belongs to you');
          showToast(title, message, 'error');
          return;
        }
        const title = t('Error deleting segment');
        const message = t('The segment has not been deleted');
        showToast(title, message, 'error');
        return;
      }
      const semgmentosAux = segmentosData.filter(item => {
        return item.id_filtros_predefinidos !== id;
      });
      setSegmentosData(semgmentosAux);
      const title = t('Segment deleted');
      const message = t('The segment has been deleted');
      showToast(title, message, 'success');
    } catch (e) {
      const title = t('Error deleting segment');
      const message = t('The segment has not been deleted');
      showToast(title, message, 'error');
    }
  };

  const editarSegmento = (id) => {
    const index = segmentosData.findIndex(s => s.id_filtros_predefinidos === id);
    const json = JSON.parse(segmentosData[index].segmento);
    validationSegmento.setFieldValue('id', segmentosData[index].id_filtros_predefinidos);
    validationSegmento.setFieldValue('segmento', json);
    validationSegmento.setFieldValue('nombre', segmentosData[index].nombre);
    if (segmentosData[index].vistas) {
      validationSegmento.setFieldValue('objeto', JSON.parse(segmentosData[index].vistas)[0]);
    }
    setQuery(json);
    setModalNuevoSegmento(true);
  };

  const eliminarSemento = (event, id) => {
    event.preventDefault();
    setSegmentoBorrar(id);
    setModalEliminar(true);
  };

  useEffect(() => {
    const segmentoJSON = formatQuery(query, 'json');
    validationSegmento.setFieldValue('segmento', segmentoJSON);
  }, [query]);

  const columns = [
    {
      dataField: 'nombre',
      text: t('Segment name'),
      sort: true,
      align: 'left',
      headerAlign: 'left'
    },
    {
      dataField: 'nombreCompleto',
      text: t('Owner'),
      headerStyle: { maxWidth: '10vw' },
      style: { maxWidth: '10vw', height: '100%', wordWrap: 'break-word', whiteSpace: 'pre-line' },
      sort: true
    },
    {
      dataField: 'vistas',
      text: t('Object'),
      headerStyle: { maxWidth: '10vw' },
      style: { maxWidth: '10vw', height: '100%', wordWrap: 'break-word', whiteSpace: 'pre-line' },
      sort: true,
      formatter: (value) => {
        return JSON.parse(value);
      }
    },
    {
      dataField: 'id_filtros_predefinidos',
      text: '',
      sort: true,
      formatter: (value) => {
        return (
          <UncontrolledDropdown direction="left">
            <DropdownToggle tag="a" caret>
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu
              className="lista-opciones"
              style={{ top: '0px !important' }}
            >
              <DropdownItem disabled>{t('Show filters')}</DropdownItem>
              <DropdownItem onClick={() => { editarSegmento(value); }}>{t('Edit')}</DropdownItem>
              <DropdownItem onClick={(event) => { eliminarSemento(event, value); }}>{t('Delete')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: segmentosData.length,
    sizePerPageRenderer,
    custom: true,
    showTotal: true
  };

  document.title = `${t('Segments')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={t('Segments')}
            breadcrumbItems={[{ title: t('Settings') }, { title: t('Segments') }]}
          />

          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }}>
                  <Button
                    color="primary"
                    className="mt-1"
                    onClick={() => {
                      setModalNuevoSegmento(true);
                    }}
                  >
                    {t('New segment')}
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-3">
                    <Row />
                  </CardSubtitle>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id_filtros_predefinidos"
                    columns={columns}
                    data={segmentosData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id_filtros_predefinidos"
                        columns={columns}
                        data={segmentosData}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id_filtros_predefinidos"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    rowStyle={rowStyle}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Segments')}</span>
                  <br />
                  <br />
                  {t('Segments info text')}
                  <br />
                </CardText>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={modalNuevoSegmento}
            toggle={() => {
              setModalNuevoSegmento(!modalNuevoSegmento);
              validationSegmento.resetForm();
              setQuery(DEFAULT_QUERY);
            }}
          >
            <Form
              id="form-add-user"
              onSubmit={e => {
                e.preventDefault();
                validationSegmento.handleSubmit();
                return false;
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {t('New segment')}
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setModalNuevoSegmento(false);
                    validationSegmento.setFieldValue('id', '');
                    validationSegmento.setFieldValue('segmento', '');
                    validationSegmento.setFieldValue('nombre', '');
                    validationSegmento.setFieldValue('objeto', '');
                    setQuery(DEFAULT_QUERY);
                  }}
                  className="btn-close"
                />
              </div>
              <div className="modal-body">
                <Row className="mb-3">
                  <Input
                    name="id"
                    type="hidden"
                    className="form-control"
                    id="horizontal-id-Input"
                    value={validationSegmento.values.id || ''}
                  />
                  <Col sm={4}>
                    <label htmlFor="new-segment-name" className="col-form-label">
                      {t('Segment name')}
                      :
                    </label>
                  </Col>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="nombre"
                      className="form-control"
                      id="new-segment-name"
                      onChange={event => {
                        validationSegmento.setFieldValue('nombre', event.target.value);
                      }}
                      onBlur={validationSegmento.handleBlur}
                      value={validationSegmento.values.nombre || ''}
                      invalid={
                        !!(
                          validationSegmento.touched.nombre &&
                          validationSegmento.errors.nombre
                        )
                      }
                    />
                    {validationSegmento.touched.nombre &&
                    validationSegmento.errors.nombre ? (
                      <FormFeedback type="invalid">
                        {validationSegmento.errors.nombre}
                      </FormFeedback>
                      ) : null}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={4}>
                    <label htmlFor="new-segment-object" className="col-form-label">
                      {t('Object')}
                      :
                    </label>
                  </Col>
                  <Col sm={4}>
                    <select
                      className={(
                        validationSegmento.touched.objeto &&
                        validationSegmento.errors.objeto
                      ) ? 'form-select is-invalid' : 'form-select'}
                      name="objeto"
                      id="new-segment-object"
                      onBlur={validationSegmento.handleBlur}
                      onChange={event => {
                        const json = JSON.parse(
                          validationSegmento.values.segmento || DEFAULT_QUERY
                        );
                        if (
                          validationSegmento.values.objeto === '' ||
                          (json.rules.length === 1 &&
                            json.rules[0]?.rules?.length === 1 &&
                            json.rules[0]?.rules[0]?.field === '~')
                        ) {
                          validationSegmento.setFieldValue('objeto', event.target.value);
                        } else {
                          setModalCambioObjeto(true);
                          setValorObjeto(event.target.value);
                        }
                      }}
                      value={validationSegmento.values.objeto || ''}
                    >
                      <option disabled value="">
                        {t('Select an option')}
                      </option>
                      <option value="RESERVAS">
                        {t('Bookings')}
                      </option>
                      <option value="CLIENTES">
                        {t('Customers')}
                      </option>
                    </select>
                    {validationSegmento.touched.objeto &&
                    validationSegmento.errors.objeto ? (
                      <FormFeedback type="invalid">
                        {validationSegmento.errors.objeto}
                      </FormFeedback>
                      ) : null}
                  </Col>
                </Row>
                <FiltrosSegmentos
                  query={query}
                  setQuery={setQuery}
                  objeto={validationSegmento.values.objeto}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setModalNuevoSegmento(false);
                    validationSegmento.setFieldValue('id', '');
                    validationSegmento.setFieldValue('segmento', '');
                    validationSegmento.setFieldValue('nombre', '');
                    validationSegmento.setFieldValue('objeto', '');
                    setQuery(DEFAULT_QUERY);
                    validationSegmento.resetForm();
                  }}
                >
                  {t('Cancel')}
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    console.log(validationSegmento.errors);
                  }}
                  disabled={validationSegmento.errors.segmento}
                >
                  {t('Save')}
                </button>
              </div>
            </Form>
          </Modal>
          <Modal
            size="xs"
            backdrop="static"
            isOpen={modalCambioObjeto}
            toggle={() => {
              setModalCambioObjeto(!modalCambioObjeto);
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t('Object')}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalNuevoSegmento(false);
                }}
                className="btn-close"
              />
            </div>
            <div className="modal-body">
              {t('By changing the object you will lose the selected filters')}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setModalCambioObjeto(false);
                }}
              >
                {t('Cancel')}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  validationSegmento.setFieldValue('objeto', valorObjeto);
                  setQuery(DEFAULT_QUERY);
                  setModalCambioObjeto(false);
                }}
              >
                {t('Accept')}
              </button>
            </div>
          </Modal>
          <Modal
            title={t('Are you sure?')}
            confirmBtnBsStyle="danger"
            isOpen={modalEliminar}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {t('Delete segment')}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalEliminar(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{t('Accion no reversible')}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="link"
                onClick={() => {
                  setModalEliminar(false);
                }}
                data-dismiss="modal"
              >
                {t('Cancel')}
              </Button>
              <Button
                onClick={() => {
                  eliminar(segmentoBorrar);
                  setModalEliminar(false);
                }}
                style={{ backgroundColor: '#dc3545', borderColor: '#dc3545' }}
              >
                {t('Delete')}
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

Segmentos.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Segmentos);
