import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  Badge,
  Input,
  Row,
  UncontrolledTooltip
} from 'reactstrap';

const NodoChatbot = props => {
  const { data, outputs, inputs } = props;
  const {
    id,
    mensaje,
    t,
    editar,
    closeFunction,
    respuestas,
    inicio,
    final,
  } = data;

  const posStart = t('Start') === 'Start' ? '-8.2%' : '-8.7%';
  const posFin = t('End') !== 'Fin' ? '-7.3%' : '-6.4%';

  return (
    <Card
      style={{
        display: 'inline-block',
        border: '1px',
        borderStyle: '',
        minWidth: '100px',
        maxWidth: '350px',
        width: '100%',
        minHeight: '200px',
        position: 'absolute',
      }}
    >
      <CardHeader>
        {!inicio && (
          React.cloneElement(outputs[0], { style: {
            width: '18px',
            height: '18px',
            position: 'absolute',
            left: '-5.2%',
            justifyContent: 'flex-end',
            border: '1px',
            borderColor: 'initial',
            borderStyle: 'solid',
          } })
        )}
        {inicio && !final && (
          <Badge color="success" style={{ display: 'flex', position: 'absolute', left: posStart, top: '11px', transform: 'rotate(270deg)', fontSize: '12px' }}>
            {t('Start')}
          </Badge>
        )}
        {inicio && final && (
          <Badge color="success" style={{ display: 'flex', position: 'absolute', left: posFin, top: '5px', transform: 'rotate(270deg)', fontSize: '12px' }}>
            {t('End')}
          </Badge>
        )}
        <i className="bx bx-edit font-size-20 noDraggable" onClick={() => editar(id)} style={{ cursor: 'pointer' }} />
        <Badge pill color="success" className="font-size-14" style={{ marginLeft: '5%', width: '80%' }}>
          {t('Mensaje')}
        </Badge>
        {!inicio && (
        <Input
          type="button"
          className="close noDraggable"
          onClick={() => closeFunction(id)}
        />
        )}
        {inicio && !final && (
        <>
          <i className="fas fa-info-circle font-size-20" id="tooltipInicio" style={{ marginLeft: '2%' }} />
          <UncontrolledTooltip target="tooltipInicio">{t('tooltip nodo inicio')}</UncontrolledTooltip>
        </>
        )}
        {inicio && final && (
        <>
          <i className="fas fa-info-circle font-size-20" id="tooltipFin" style={{ marginLeft: '2%' }} />
          <UncontrolledTooltip target="tooltipFin">{t('tooltip nodo fin')}</UncontrolledTooltip>
        </>
        )}
      </CardHeader>
      <CardBody>
        <Row style={{ display: 'flex', justifyContent: 'center', border: '1px', borderStyle: 'solid', borderRadius: '5px', borderColor: 'black', padding: '5px', textAlign: 'center' }}>
          {mensaje}
        </Row>
        {respuestas.map((respuesta, index) => {
          return (
            <Row key={respuesta.id} style={{ display: 'flex', justifyContent: 'center', border: '1px', borderRadius: '5px', borderStyle: 'solid', borderColor: 'black', marginTop: '4%' }}>
              {respuesta.texto}
              <i
                className="bx bx-circle font-size-18"
                style={{ display: 'flex', position: 'absolute', justifyContent: 'flex-end', cursor: 'pointer' }}
              >
                {React.cloneElement(inputs[index], { style: { width: '18px', height: '18px', position: 'absolute', justifyContent: 'flex-end' } })}
              </i>
            </Row>
          );
        })}
      </CardBody>
    </Card>
  );
};

NodoChatbot.propTypes = {
  id: PropTypes.number,
  mensaje: PropTypes.string,
  t: PropTypes.any,
  editar: PropTypes.func,
  closeFunction: PropTypes.any,
  respuestas: PropTypes.array,
  inicio: PropTypes.bool,
  final: PropTypes.bool,
  data: PropTypes.array,
  outputs: PropTypes.any,
  inputs: PropTypes.any
};

export default NodoChatbot;
