import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import axiosApi from '../../../helpers/apiResquests';

const LocalesSeleccion = React.memo(props => {
  const {
    success,
    locales,
    divisiones,
    local,
    division
  } = props;
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [localesBusqueda, setLocalesBusqueda] =
    useState(locales);
  const [divisionesBusqueda, setDivisionesBusqueda] =
    useState(divisiones);

  const [, setusername] = useState('Admin');
  const [busqueda, setBusqueda] = useState('');

  useEffect(() => {
    if (locales) {
      setLocalesBusqueda(
        locales.filter(
          localAux => localAux.denominacion.toLowerCase().includes(busqueda) ||
            localAux.id_locales.toString().indexOf(busqueda) > -1
        )
      );
    }
    if (divisiones) {
      setDivisionesBusqueda(
        divisiones.filter(
          divisionAux => divisionAux['nombre-div'].toLowerCase().includes(busqueda) ||
          divisionAux.locales.some(
            localAux => localAux.denominacion.toLowerCase().includes(busqueda) ||
            localAux.id_locales.toString().indexOf(busqueda) > -1
          )
        )
      );
    }
  }, [busqueda, locales, divisiones]);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === 'fake' ||
        process.env.REACT_APP_DEFAULTAUTH === 'jwt'
      ) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.username);
      }
    }
  }, [success]);

  async function cambiarDivision(id) {
    const idDivision = { id_division: id };
    const response = await axiosApi.post('/api/divisiones/cambiar', idDivision);
    if (response.data.result === 'ok') {
      if (response.data.cambio) {
        const url = `${process.env.REACT_APP_HOST_PANEL}/estadisticas/graficas-div`;
        const newWindow = window.open(url, '_self');
        if (newWindow) newWindow.opener = null;
      } else {
        window.location.reload();
      }
    }
  }

  async function cambiarLocal(id) {
    const idLocal = { id_local: id };
    const response = await axiosApi.post('/api/locales/cambiar', idLocal);
    if (response.data.result === 'ok') {
      if (response.data.cambio) {
        const url = `${process.env.REACT_APP_HOST_PANEL}/`;
        const newWindow = window.open(url, '_self');
        if (newWindow) newWindow.opener = null;
      } else {
        window.location.reload();
      }
    }
  }

  function useDetectarSiPulsacionDentro(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (menu) {
            setMenu(false);
            setBusqueda('');
          }
        } else if (!menu) {
          setBusqueda('');
          setMenu(true);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, menu]);
  }

  const wrapperRef = useRef(null);
  useDetectarSiPulsacionDentro(wrapperRef);

  return (
    <>
      <div ref={wrapperRef}>
        <Dropdown isOpen={menu} className="d-inline-block" toggle={() => {}}>
          <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button">
            {menu && (
              <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={busqueda}
                    placeholder="Search..."
                    onChange={event => {
                      setBusqueda(event.target.value.toLowerCase());
                    }}
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>
            )}
            {!menu && local && local.denominacion && (
              <>
                <i className="bx bx-home" />
                {' '}
                {local.denominacion}
                {' '}
                {local.idLocal}
              </>
            )}
            {!menu && division && division.denominacion && (
              <>
                <i className="bx bx-sitemap" />
                {' '}
                {division.denominacion}
              </>
            )}
            {!menu && <i className="mdi mdi-chevron-down d-xl-inline-block" />}
          </DropdownToggle>
          <DropdownMenu className="listado-locales">
            {localesBusqueda &&
              localesBusqueda.map(localAux => (
                <DropdownItem
                  key={['loc', localAux.id_locales].join()}
                  tag="button"
                  onClick={() => {
                    cambiarLocal(localAux.id_locales);
                  }}
                >
                  <i className="bx bx-home" />
                  {' '}
                  {localAux.denominacion}
                  {' '}
                  {localAux.id_locales}
                </DropdownItem>
              ))}
            {divisionesBusqueda &&
              divisionesBusqueda.map(divisionAux => (
                <div
                  key={['div', divisionAux.id_divisiones].join()}
                  tag="div"
                  className="listado-division"
                >
                  <Button
                    color="link"
                    className="dropdown-item boton-division"
                    onClick={() => {
                      cambiarDivision(divisionAux.id_divisiones);
                    }}
                  >
                    <i className="bx bx-sitemap" />
                    {' '}
                    {divisionAux['nombre-div']}
                  </Button>
                  {divisionAux.locales.map(
                    localAux => (localAux.denominacion.toLowerCase().includes(busqueda) ||
                        localAux.id_locales.toString().indexOf(busqueda) > -1) && (
                        <DropdownItem
                          key={['loc', localAux.id_locales].join()}
                          onClick={() => {
                            cambiarLocal(localAux.id_locales);
                          }}
                          tag="button"
                        >
                          <i className="bx bx-home" />
                          {' '}
                          {localAux.denominacion}
                          {' '}
                          {localAux.id_locales}
                        </DropdownItem>
                    )
                  )}
                </div>
              ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
});

LocalesSeleccion.propTypes = {
  success: PropTypes.any,
  locales: PropTypes.any,
  divisiones: PropTypes.any,
  local: PropTypes.any,
  division: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default connect(mapStatetoProps, {})(withTranslation()(LocalesSeleccion));
