import React from 'react';
import { Redirect } from 'react-router-dom';

// Inner Authentication
import Login from '../pages/AuthenticationInner/Login';
import RecuperarPassword from '../pages/AuthenticationInner/RecuperarPassword';
import CompruebaEmail from '../pages/AuthenticationInner/CompruebaEmail';
import CambiarPassword from '../pages/AuthenticationInner/CambiarPassword';
import CambiarPasswordNueva from '../pages/AuthenticationInner/CambiarPasswordNueva';

/* // Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';

// Inner Authentication
import Login1 from '../pages/AuthenticationInner/Login';

// Pages
import Pages404 from '../pages/Utility/pages-404';
import Pages500 from '../pages/Utility/pages-500'; */

// Perfil Usuario
import PerfilUsuario from '../pages/PerfilUsuario/index';

// alertas
import AlertasFeedback from '../pages/Alertas/Feedback/index';
import AlertasCheckin from '../pages/Alertas/Checkin/index';
import AlertasSistemas from '../pages/Alertas/Sistemas/index';
import AlertasMessagingCenter from '../pages/Alertas/MessagingCenter/index';
import AlertaFeedback from '../pages/Alertas/Feedback/alertas';
import AlertaCheckin from '../pages/Alertas/Checkin/alertas';
import AlertaSistemas from '../pages/Alertas/Sistemas/alertas';
import AlertaMessagingCenter from '../pages/Alertas/MessagingCenter/alertas';

// reputacion
import InvitacionesReview from '../pages/Reputacion/index';
import Review360 from '../pages/Reputacion/review360';

// Locales
import ListadoClientes from '../pages/Locales/ListadoClientes';
import ListadoReservas from '../pages/Locales/ListadoReservas';
import ListadoCheckin from '../pages/Listado/ListadoCheckin';

// Messaging Center
import Inbox from '../pages/MessagingCenter/inbox';
import ReglasAsignacion from '../pages/MessagingCenter/reglasAsignacion';
import Tareas from '../pages/MessagingCenter/tareas';
import Pipeline from '../pages/MessagingCenter/pipeline';
import Estadisticas from '../pages/MessagingCenter/estadisticas';
import RespuestasRapidas from '../pages/MessagingCenter/respuestasRapidas';
import ListadoClientesMessaging from '../pages/MessagingCenter/ListadoClientesMessaging';
import DiagramaChatbot from '../pages/MessagingCenter/diagramaChatbot';
// Whatsapp
import Automatizacion from '../pages/Whatsapp/Automatizacion';
import Plantillas from '../pages/Whatsapp/Plantillas';
import RegistroEnvios from '../pages/Whatsapp/RegistroEnvios';
import Costes from '../pages/Whatsapp/Costes';
import NuevaPlantilla from '../pages/Whatsapp/nuevaPlantilla';

// Administracion
import Miembros from '../pages/Administracion/Miembros';
import Miembros2 from '../pages/Administracion/Miembros2';
import LogAcceso from '../pages/Administracion/LogAcceso';
import Miembro from '../pages/Administracion/CrearMiembros';
// Estadísticas
import EstadisticasGeneral from '../pages/Estadisticas/Estadisticas';
// Locales
import ListadoDuplicados from '../pages/Locales/ListadoDuplicados';
// Panel visitantes
import PanelVisitantes from '../pages/Listado/PanelVisitantes';

// Segmentos
import Segmentos from '../pages/Segmentos/segmentos';

const userRoutes = [
  // Perfil Usuario
  { path: '/perfil-usuario', component: PerfilUsuario },

  // alertas
  { path: '/alertas/feedback', component: AlertasFeedback },
  { path: '/alertas/feedback/:idAlerta', component: AlertaFeedback },
  { path: '/alertas/checkin', component: AlertasCheckin },
  { path: '/alertas/checkin/:idAlerta', component: AlertaCheckin },
  { path: '/alertas/sistemas', component: AlertasSistemas },
  { path: '/alertas/sistemas/:idAlerta', component: AlertaSistemas },
  { path: '/alertas/messagingcenter', component: AlertasMessagingCenter },
  { path: '/alertas/messagingcenter/:idAlerta', component: AlertaMessagingCenter },

  // review 360
  { path: '/reputacion/invitaciones', component: InvitacionesReview },
  { path: '/reputacion/review360', component: Review360 },

  // Messaging Center
  { path: '/messagingcenter/inbox', component: Inbox },
  { path: '/messagingcenter/reglas', component: ReglasAsignacion },
  { path: '/messagingcenter/tareas', component: Tareas },
  { path: '/messagingcenter/pipeline', component: Pipeline },
  { path: '/messagingcenter/estadisticas', component: Estadisticas },
  { path: '/messagingcenter/respuestasRapidas', component: RespuestasRapidas },
  { path: '/messagingcenter/listadoClientes', component: ListadoClientesMessaging },
  // whatsapp
  { path: '/whatsapp/automatizacion', component: Automatizacion },
  { path: '/whatsapp/plantillas', component: Plantillas },
  { path: '/whatsapp/logEnvios/', component: RegistroEnvios },
  { path: '/whatsapp/costes/', component: Costes },
  { path: '/whatsapp/plantillas/nuevaPlantilla/', component: NuevaPlantilla },
  // administracion
  { path: '/administracion/miembros', component: Miembros },
  { path: '/administracion/miembros2', component: Miembros2 },
  { path: '/administracion/logacceso', component: LogAcceso },
  { path: '/administracion/miembros/:idMiembro', component: Miembro },
  // locales
  { path: '/listado/clientes', component: ListadoClientes },
  { path: '/locales/duplicados', component: ListadoDuplicados },
  { path: '/locales/reservas', component: ListadoReservas },
  { path: '/listado/checkin', component: ListadoCheckin },
  // estadisticas
  { path: '/estadisticas', component: EstadisticasGeneral },
  { path: '/panelVisitantes', component: PanelVisitantes },
  { path: '/messagingcenter/chatbotDiagrama', component: DiagramaChatbot },

  // segmentos
  { path: '/segmentos', component: Segmentos },
  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/perfil-usuario" /> }
];

const authRoutes = [
  // Login
  { path: '/login', component: Login },
  // Recuperación contraseña
  { path: '/password-recovery', component: RecuperarPassword },
  // Pagina de comprueba email
  { path: '/email-check', component: CompruebaEmail },
  // Pagina de nueva contraseña
  { path: '/password-reset/:reset', component: CambiarPassword },
  { path: '/password-change', component: CambiarPasswordNueva }
];

export { userRoutes, authRoutes };
