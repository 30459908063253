import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// import SweetAlert
import SweetAlert from 'react-bootstrap-sweetalert';

const Aviso = props => {
  const {
    t,
    successDlg,
    errorDlg,
    onSuccessDlgChange,
    onErrorDlgChange,
    alertaCambiar,
    cambiarEstadoAlerta
  } = props;

  return (
    <>
      {successDlg ? (
        <SweetAlert
          success
          title={t('Report enable')}
          onConfirm={() => {
            cambiarEstadoAlerta(alertaCambiar);
            onSuccessDlgChange(false);
          }}
        >
          {t('The report has been enable')}
        </SweetAlert>
      ) : null}

      {errorDlg ? (
        <SweetAlert
          error
          title={t('Report disabled')}
          onConfirm={() => {
            cambiarEstadoAlerta(alertaCambiar);
            onErrorDlgChange(false);
          }}
        >
          {t('The report has been disable')}
        </SweetAlert>
      ) : null}
    </>
  );
};

Aviso.propTypes = {
  t: PropTypes.any,
  successDlg: PropTypes.bool,
  errorDlg: PropTypes.bool,
  onSuccessDlgChange: PropTypes.func,
  onErrorDlgChange: PropTypes.func,
  cambiarEstadoAlerta: PropTypes.func,
  alertaCambiar: PropTypes.number
};

export default withTranslation()(Aviso);
