import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  UncontrolledTooltip,
  Button,
  Form,
  FormGroup,
  Label,
  Input
  /* CardText */
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

// iconos-redes-sociales
import accesoDirecto from '../../assets/images/iconos-redes-sociales/icono-acceso-directo.png';
import iconoMail from '../../assets/images/iconos-redes-sociales/icono-mail.png';
import iconoAuto from '../../assets/images/iconos-redes-sociales/icono-auto.png';
import iconoCHN from '../../assets/images/iconos-redes-sociales/icono-chn.png';
import iconoCSV from '../../assets/images/iconos-redes-sociales/icono-csv.png';
import iconoFB from '../../assets/images/iconos-redes-sociales/icono-facebook.png';
import iconoGPlus from '../../assets/images/iconos-redes-sociales/icono-gplus.png';
import iconoTwitter from '../../assets/images/iconos-redes-sociales/icono-twitter.png';
import iconoHuesped from '../../assets/images/iconos-redes-sociales/icono-huesped.svg';
import iconoInstagram from '../../assets/images/iconos-redes-sociales/icono-instagram.png';
import iconoLinkedin from '../../assets/images/iconos-redes-sociales/icono-linkedin.png';
import iconoMC from '../../assets/images/iconos-redes-sociales/icono-mc.png';
import iconoPMS from '../../assets/images/iconos-redes-sociales/icono-pms.png';
import iconoTlf from '../../assets/images/iconos-redes-sociales/icono-telefono.svg';
import iconoVK from '../../assets/images/iconos-redes-sociales/icono-vk.png';
import iconoWhatsapp from '../../assets/images/iconos-redes-sociales/icono-whatsapp.png';
import iconoUserPass from '../../assets/images/iconos-redes-sociales/icono-user-lock.svg';
import iconoManual from '../../assets/images/iconos-redes-sociales/icono-manual.png';
import iconoEvento from '../../assets/images/iconos-redes-sociales/icono-event.svg';
import iconoAcceso from '../../assets/images/iconos-redes-sociales/icono-acceso.svg';

const ListadoClientesMessaging = props => {
  const { t } = props;

  const [visitantes, setVisitantes] = useState([]);
  const [visitantesVisibles, setVisitantesVisibles] = useState([]);
  const [start, setStart] = useState(0);
  const [len, setLen] = useState(50);
  const [size, setSize] = useState(10);
  const [totalAcciones, setTotalAcciones] = useState(60);
  const [pagina, setPagina] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [modalExportar, setModalExportar] = useState(false);

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  function iconoVia(viaVisita, id) {
    let tipoLoginArchivoIcono = '';
    let extra = ('');
    if (viaVisita) {
      if (viaVisita === 'Facebook') {
        tipoLoginArchivoIcono = iconoFB;
      } else if (viaVisita === 'Google') {
        tipoLoginArchivoIcono = iconoGPlus;
      } else if (viaVisita === 'Twitter') {
        tipoLoginArchivoIcono = iconoTwitter;
      } else if (viaVisita === 'Linkedin') {
        tipoLoginArchivoIcono = iconoLinkedin;
      } else if (viaVisita === 'VK') {
        tipoLoginArchivoIcono = iconoVK;
      } else if (viaVisita === 'Instagram') {
        tipoLoginArchivoIcono = iconoInstagram;
      } else if (viaVisita === 'Boton-Directo') {
        tipoLoginArchivoIcono = accesoDirecto;
      } else if (viaVisita === 'Formulario') {
        tipoLoginArchivoIcono = iconoMail;
      } else if (viaVisita === 'Telefono') {
        tipoLoginArchivoIcono = iconoTlf;
      } else if (viaVisita.indexOf('CSV') > -1) {
        tipoLoginArchivoIcono = iconoCSV;
      } else if (viaVisita.indexOf('PMS') > -1) {
        tipoLoginArchivoIcono = iconoPMS;
      } else if (viaVisita.indexOf('CHECKIN') > -1) {
        tipoLoginArchivoIcono = iconoCHN;
      } else if (viaVisita.indexOf('MC') > -1) {
        tipoLoginArchivoIcono = iconoMC;
      } else if (viaVisita.indexOf('Whatsapp') > -1) {
        tipoLoginArchivoIcono = iconoWhatsapp;
      } else if (viaVisita === 'Auto') {
        tipoLoginArchivoIcono = iconoAuto;
      } else if (viaVisita.indexOf('AUTO') > -1) {
        tipoLoginArchivoIcono = iconoAuto;
      } else if (viaVisita === 'HUESPED-PMS') {
        tipoLoginArchivoIcono = iconoHuesped;
      } else if (viaVisita.indexOf('USUARIO') > -1) {
        tipoLoginArchivoIcono = iconoUserPass;
        extra = viaVisita.split(':')[1];
      } else if (viaVisita.indexOf('MANUAL') > -1) {
        tipoLoginArchivoIcono = iconoManual;
      } else if (viaVisita.indexOf('evento') > -1) {
        tipoLoginArchivoIcono = iconoEvento;
      } else if (viaVisita.indexOf('ACCESO') > -1) {
        tipoLoginArchivoIcono = iconoAcceso;
      } else {
        return '-';
      }
    } else {
      return '-';
    }

    return (
      <>
        <img className="rounded" alt="" style={{ width: '30px', height: '30px' }} src={tipoLoginArchivoIcono} id={`viaVisita${id}`} />
        {extra && (
        <UncontrolledTooltip placement="top" target={`viaVisita${id}`}>
          {extra}
        </UncontrolledTooltip>
        )}
      </>
    );
  }
  const cargaDatos = async (inicio, longitud, minIndex = 0) => {
    try {
      const response = await axiosApi.get(`/api/locales/listadoClientes/${inicio}/${longitud}`);
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      let i = 1;
      const datos = data.data.map(camp => {
        const fecha = camp[1] ? camp[1].split(' ') : '';
        const emailTlf = camp[4] ? camp[4].split('<br>') : ['', ''];
        const entry = {
          id: i,
          timestamp: fecha ? `${fecha[0]} ${fecha[fecha.length - 1]}` : '',
          nombre: camp[10],
          genero: camp[5],
          origen: camp[11],
          mail: emailTlf[0] ? emailTlf[0] : '',
          edad: camp[6],
          idioma: camp[7],
          ultima: camp[8],
          tlf: emailTlf[1] ? emailTlf[1] : '',
          ficha: camp[12],
        };
        i += 1;
        return entry;
      });
      console.log(data);
      setVisitantes(datos);
      const copyDatos = datos.slice(0);
      setVisitantesVisibles(copyDatos.slice(minIndex, minIndex + longitud / 5));
      setIsLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  const cargaCantidadTotal = async () => {
    try {
      const response = await axiosApi.get('/api/locales/totalListadoClientes');
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setTotalAcciones(data.total);
      cargaDatos(start, len);
    } catch (error) {
      // error
    }
  };

  /* const exportar = async () => {
    try {
      const response = await axiosApi.get('/api/exportar/listadoClientes/');
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
      }
    } catch (error) {
      // console.log(error);
    }
  }; */

  useEffect(() => {
    cargaCantidadTotal();
  }, []);

  const columns = [
    {
      dataField: 'timestamp',
      text: t('Registration'),
      sort: true,
    },
    {
      dataField: 'origen',
      text: t('Origin'),
      sort: true,
      formatter: (value, row) => {
        return iconoVia(value, row.id);
      }
    },
    {
      dataField: 'nombre',
      text: t('Name'),
      sort: true,
      formatter: (value, row) => {
        return <a href={`${process.env.REACT_APP_HOST_PANEL}/listado/ficha_visitante/${row.ficha}`} target="_blank" rel="noreferrer">{value}</a>;
      }
    },
    {
      dataField: 'mail',
      text: `${t('Email')}/${t('Phone')}`,
      sort: true,
      formatter: (value, row) => {
        return (
          <>
            {value}
            <br />
            {row.tlf}
          </>
        );
      }
    },
    {
      dataField: 'genero',
      text: t('Gender'),
      sort: true
    },
    {
      dataField: 'edad',
      text: t('Age'),
      sort: true
    },
    {
      dataField: 'idioma',
      text: t('Language'),
      sort: true
    },
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              setIsLoading(true);
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
              setLen(5 * option.page);
              setSize(option.page);
              cargaDatos(0, 5 * option.page);
              setPagina(1);
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const handlePageChange = (page) => {
    if (Math.floor((page - 1) / 5) * len !== start) {
      setIsLoading(true);
      const minIndex = Math.floor((page - 1) / 5) * len;
      const indiceArray = Math.floor(((page - 1) % 5) * size);
      cargaDatos(minIndex, len, indiceArray);
      setStart(minIndex);
    }
    setPagina(page);
  };

  const pageOptions = {
    sizePerPage: size,
    page: pagina,
    totalSize: totalAcciones,
    sizePerPageRenderer,
    custom: true,
    showTotal: true,
    onPageChange: (page) => handlePageChange(page)
  };

  const handleTableChange = (type, { page }) => {
    if (type === 'pagination') {
      const datos = visitantes.slice(0);
      const minIndex = ((page - 1) % 5) * size;
      setVisitantesVisibles(datos.slice(minIndex, minIndex + size));
    }
  };

  const NoDataIndication = () => (
    <div className="spinner">
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
    </div>
  );

  document.title = `${t('Visits list')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={`${t('Customers list')}`} breadcrumbItems={[{ title: 'Messaging Center' }, { title: t('Customers list') }]} />
          <Row style={{ marginBottom: '0.5%' }}>
            <Col>
              <Button color="link" onClick={() => setModalExportar(true)}>
                <i />
                {t('Export')}
              </Button>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                color="success"
              >
                {t('Add customer')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }} />
              </Row>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={visitantesVisibles}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={visitantesVisibles}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    remote={{ pagination: true }}
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    onTableChange={handleTableChange}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={() => <NoDataIndication />}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
            <Modal
              isOpen={isLoading}
              centered
            >
              <div
                className="modal-header"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <h5 className="modal-title mt-0" id="myModalLabel">
                  {t('Loading data')}
                </h5>
              </div>
            </Modal>
          </Row>
          <Row>
            <Card body className="puntos">
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <Row style={{ margin: '5px', marginTop: '20px' }}>
                  {t('TextoPieVisitantes')}
                </Row>
                <Row style={{ paddingTop: '2%' }}>
                  <Col className="auto">
                    <Row>
                      <Col className="xs-12" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoMail} />
                      </Col>
                      <Col className="xs-12" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('Form')}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="auto">
                    <Row>
                      <Col className="auto" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoTlf} />
                      </Col>
                      <Col className="auto" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('Phone')}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="auto">
                    <Row>
                      <Col className="auto" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={accesoDirecto} />
                      </Col>
                      <Col className="auto" style={{ textAlign: 'left' }}>
                        {t('Direct access')}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="auto" style={{ marginRight: '10%' }}>
                    <Row>
                      <Col className="auto" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoAuto} />
                      </Col>
                      <Col className="auto " style={{ textAlign: 'left' }}>
                        {t('Automatic')}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '2%' }}>
                  <Col className="xs-4">
                    <Row>
                      <Col className="xs-8" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoWhatsapp} />
                      </Col>
                      <Col className="xs-8" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('Whatsapp')}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="xs-4">
                    <Row>
                      <Col className="xs-8" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoFB} />
                      </Col>
                      <Col className="xs-8" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('Facebook')}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="xs-4">
                    <Row>
                      <Col className="xs-8" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoTwitter} />
                      </Col>
                      <Col className="xs-8" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('Twitter')}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="xs-4" style={{ marginRight: '10%' }}>
                    <Row>
                      <Col className="xs-8" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoHuesped} />
                      </Col>
                      <Col className="xs-8" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('Checking')}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '3%' }}>
                  <Col className="xs-4">
                    <Row>
                      <Col className="xs-8" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoGPlus} />
                      </Col>
                      <Col className="xs-8" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('Google Plus')}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="xs-4">
                    <Row>
                      <Col className="xs-8" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoLinkedin} />
                      </Col>
                      <Col className="xs-8" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('Linkedin')}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="xs-4">
                    <Row>
                      <Col className="xs-8" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoUserPass} />
                      </Col>
                      <Col className="xs-8" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('User and password')}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="xs-4" style={{ marginRight: '10%' }}>
                    <Row>
                      <Col className="xs-8" style={{ textAlign: 'right' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoAcceso} />
                      </Col>
                      <Col className="xs-8" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('Access code')}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '3%' }}>
                  <Col className="col-2" style={{ alignItems: 'left', paddingLeft: '6.5%' }}>
                    <Row>
                      <Col className="xs-8" style={{ textAlign: 'right', marginTop: '12%' }}>
                        <img className="rounded" title="" alt="" style={{ width: '40px', height: '40px' }} src={iconoEvento} />
                      </Col>
                      <Col className="xs-8" style={{ textAlign: 'left', padding: '10px' }}>
                        {t('Event code')}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
              </div>
            </Card>
          </Row>
          <Modal
            isOpen={modalExportar}
            toggle={() => {
              setModalExportar(false);
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                Exportando datos
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalExportar(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                Elija el formato con el cual quiere descargar sus datos:
              </div>
              <div style={{ marginTop: '3%' }}>
                <Form className="mb-0">
                  <FormGroup>
                    <FormGroup className="mb-0" check>
                      <Input type="radio" name="radio1" defaultChecked />
                      {'  '}
                      <Label>CSV</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input type="radio" name="radio1" />
                      {'  '}
                      <Label>Excel</Label>
                    </FormGroup>
                  </FormGroup>
                </Form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalExportar(false);
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                {t('Close')}
              </button>
              <button
                type="button"
                className="btn btn-primary "
              >
                {t('Exportar')}
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

ListadoClientesMessaging.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(ListadoClientesMessaging);
