import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Form, Row, Input, Label, FormFeedback } from 'reactstrap';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import axiosApi from '../../helpers/apiResquests';
// import images
import logoJourneytok from '../../assets/images/logo_journeytok_azul.png';
import CarouselPage from './CarouselPage';

const Login = props => {
  const { t } = props;
  const history = useHistory();
  const [mensajeError, setMensajeError] = useState('');
  const [passwordShow, setPasswordShow] = useState(false);

  // meta title
  document.title = t('Journeytok dashboard');

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('VALID_EMAIL')).required(t('Please enter your email')),
      password: Yup.string().required(t('Please enter your password'))
    }),
    onSubmit: async (values) => {
      const { email, password } = values;
      try {
        const response = await axiosApi.post('/api/login', { email: email.trim(), password });
        const { data } = response;
        const { login, tipoError, dias } = data;
        if (data.err) {
          throw Error(data.mensaje);
        }
        if (login) {
          setMensajeError('');
          if (!tipoError) {
            window.location.href = `${process.env.REACT_APP_HOST_PANEL}/estadisticas/graficas`;
          } else if (tipoError === 'PRIMERA_PASSWORD') {
            history.push('/password-change');
          } else if (tipoError === 'CADUCIDAD_PROXIMA') {
            window.location.href = `${process.env.REACT_APP_HOST_PANEL}/api/welcome/${dias}`;
          }
        } else if (!tipoError) {
          setMensajeError(t('Wrong user or password'));
        } else if (tipoError === 'CADUCADA') {
          setMensajeError(t('Your password has expired and must be changed. Please click in forgot password to change it'));
        } else {
          setMensajeError(t('Wrong user or password'));
        }
      } catch (error) {
        console.log(error);
      }
    }
  });
  return (
    <>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <a href="https://journeytok.com/" target="_blank" className="d-block auth-logo" rel="noreferrer">
                        <img src={logoJourneytok} alt="" height="46" className="logo-dark-element" />
                      </a>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">{t('Welcome Back !')}</h5>
                        <p className="text-muted">{t('Sign in to continue to Journeytok dashboard.')}</p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">{t('Email')}</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder={t('Enter email')}
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ''}
                              invalid={
                                !!(validation.touched.email && validation.errors.email)
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link to="/password-recovery" className="text-muted">
                                {t('Forgot password?')}
                              </Link>
                            </div>
                            <Label className="form-label">{t('Password')}</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ''}
                                type={passwordShow ? 'text' : 'password'}
                                placeholder={t('Enter Password')}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  !!(validation.touched.password && validation.errors.password)
                                }
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light"
                                type="button"
                                id="password-addon"
                              >
                                <i className="mdi mdi-eye-outline" />
                              </button>
                              {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label className="form-check-label" htmlFor="auth-remember-check">
                              {t('Remember me')}
                            </label>
                          </div>

                          {mensajeError !== '' && (
                            <div className="alert alert-danger text-center mb-4" role="alert">
                              {' '}
                              {mensajeError}
                              {' '}
                            </div>
                          )}

                          <div className="mt-3 d-grid">
                            <button className="btn btn-primary btn-block" type="submit">
                              {t('Log in')}
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        ©
                        {' '}
                        {new Date().getFullYear()}
                        {' '}
                        Journeytok.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Login.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Login);
