export const GET_INBOX_MAILS = 'GET_INBOX_MAILS';
export const GET_INBOX_MAILS_SUCCESS = 'GET_INBOX_MAILS_SUCCESS';
export const GET_INBOX_MAILS_FAIL = 'GET_INBOX_MAILS_FAIL';

export const ADD_NEW_INBOX_MAIL = 'ADD_NEW_INBOX_MAIL';
export const ADD_INBOX_MAIL_SUCCESS = 'ADD_INBOX_MAIL_SUCCESS';
export const ADD_INBOX_MAIL_FAIL = 'ADD_INBOX_MAIL_FAIL';

export const DELETE_INBOX_MAIL = 'DELETE_INBOX_MAIL';
export const DELETE_INBOX_MAIL_SUCCESS = 'DELETE_INBOX_MAIL_SUCCESS';
export const DELETE_INBOX_MAIL_FAIL = 'DELETE_INBOX_MAIL_FAIL';

// starred mail
export const GET_STARRED_MAILS = 'GET_STARRED_MAILS';
export const GET_STARRED_MAILS_SUCCESS = 'GET_STARRED_MAILS_SUCCESS';
export const GET_STARRED_MAILS_FAIL = 'GET_STARRED_MAILS_FAIL';

// important mail
export const GET_IMPORTANT_MAILS = 'GET_IMPORTANT_MAILS';
export const GET_IMPORTANT_MAILS_SUCCESS = 'GET_IMPORTANT_MAILS_SUCCESS';
export const GET_IMPORTANT_MAILS_FAIL = 'GET_IMPORTANT_MAILS_FAIL';

// sent mail
export const GET_SENT_MAILS = 'GET_SENT_MAILS';
export const GET_SENT_MAILS_SUCCESS = 'GET_SENT_MAILS_SUCCESS';
export const GET_SENT_MAILS_FAIL = 'GET_SENT_MAILS_FAIL';

// draft mail
export const GET_DRAFT_MAILS = 'GET_DRAFT_MAILS';
export const GET_DRAFT_MAILS_SUCCESS = 'GET_DRAFT_MAILS_SUCCESS';
export const GET_DRAFT_MAILS_FAIL = 'GET_DRAFT_MAILS_FAIL';

// trash mail
export const GET_TRASH_MAILS = 'GET_TRASH_MAILS';
export const GET_TRASH_MAILS_SUCCESS = 'GET_TRASH_MAILS_SUCCESS';
export const GET_TRASH_MAILS_FAIL = 'GET_TRASH_MAILS_FAIL';
