import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  Button,
  Popover,
  PopoverHeader,
  Input,
  PopoverBody,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';
import _ from 'lodash';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';
import listaTraducida from '../../common/traducirListaIdiomas';

const RespuestasRapidas = props => {
  const { t } = props;
  const [idLocal, setIdLocal] = useState(null);
  const refIdLocal = useRef();
  const [modalNuevaRespuesta, setModalNuevaRespuesta] = useState(false);
  const [filter, setFilter] = useState('0');
  const [categorias, setCategorias] = useState([]);
  const [respuestasRapidasFiltered, setRespuestasRapidasFiltered] = useState([]);
  const [respuestasRapidas, setRespuestasRapidas] = useState([]);
  const [nuevaCategoria, setNuevaCategoria] = useState('0');
  const [categoria, setCategoria] = useState('');
  const [titulo, setTitulo] = useState('');
  const [contenido, setContenido] = useState({});
  const [editar, setEditar] = useState(false);
  const [filterIdioma, setFilterIdioma] = useState('-1');
  const [mostrarAnadirIdioma, setMostrarAnadirIdioma] = useState(false);
  const [listaFiltrada, setListaFiltrada] = useState(listaTraducida);
  const listaElegibles = useState(listaTraducida)[0];
  const [idiomas, setIdiomas] = useState({});
  const [idiomasRR, setIdiomasRR] = useState([]);
  const [respuestaEliminar, setRespuestaEliminar] = useState('');
  const [respuestaEliminadaCorrectamente, setRespuestaEliminadaCorrectamente] = useState(false);
  const [confirmacionEliminada, setConfirmacionEliminada] = useState(false);
  const [tituloConfirmacionEliminada, setTituloConfirmacionEliminada] = useState('');
  const [mensajeConfirmacion, setMensajeConfirmacion] = useState();
  const [idiomaTituloCambio, setIdiomaTituloCambio] = useState([]);

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargarRespuestasRapidas = async () => {
    try {
      const response = await axiosApi.get(`/mc/companies/respuestasRapidas?id=${refIdLocal.current}`);
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      const arrayRespuestas = [];
      Object.keys(data.respuestasRapidas).forEach(r => {
        arrayRespuestas.push(...data.respuestasRapidas[r]);
      });
      const nuevosIdiomas = {};
      arrayRespuestas.forEach((r, index) => {
        r.id = index;
        if (typeof r.contenido !== 'string') {
          Object.keys(r.contenido).forEach(c => {
            if (!nuevosIdiomas[c]) {
              const nuevoIdioma = _.pick(listaTraducida, c);
              if (Object.keys(nuevoIdioma).length !== 0) nuevosIdiomas[c] = nuevoIdioma[c];
            }
            if (!r.idiomaActivo) r.idiomaActivo = c;
          });
        }
      });
      setIdiomas(nuevosIdiomas);
      setRespuestasRapidas(arrayRespuestas);
    } catch (error) {
      console.log(error);
    }
  };

  const cargaDatos = async () => {
    try {
      const whoami = await axiosApi.get('/mc/whoami');
      const { data } = whoami;
      setIdLocal(data.idLocal.toString());
      refIdLocal.current = data.idLocal.toString();
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const guardaRespuesta = async () => {
    const index = respuestasRapidas.findIndex(r => r.titulo === titulo);
    if (index !== -1 && !editar) {
      showToast(t('Error adding quick reply'), t('The quick reply already exists'), 'error');
      return;
    }
    let categoriaRespuesta;
    if (nuevaCategoria === '0') {
      categoriaRespuesta = categoria;
    } else {
      categoriaRespuesta = nuevaCategoria;
    }
    const tituloSinEspacios = structuredClone(titulo.trim().replace(/\s\s+/g, ' ').replace(/ /g, '_'));
    const categoriaSinEspacios = structuredClone(categoriaRespuesta.trim().replace(/\s\s+/g, ' ').replace(/ /g, '_'));
    const copiaContenido = { ...contenido };
    Object.keys(copiaContenido).forEach(c => {
      copiaContenido[c] = copiaContenido[c].trim();
    });
    const contenidoSinEspacios = structuredClone(copiaContenido);
    const response = await axiosApi.post('/mc/companies/respuestasRapidas/nueva', {
      titulo: tituloSinEspacios,
      contenido: contenidoSinEspacios,
      categoria: categoriaSinEspacios,
      idLocal,
      editar
    });
    const { error } = response;
    if (error) {
      if (editar) {
        showToast(t('Error editing quick reply'), t("The quick reply can't be edited"), 'error');
        return;
      }
      showToast(t('Error adding quick reply'), t("The quick reply can't be created"), 'error');
      return;
    }
    if (nuevaCategoria === '0') {
      const auxCategorias = structuredClone(categorias);
      auxCategorias.push(categoria);
      setCategorias(auxCategorias);
    }
    const auxRespuestas = structuredClone(respuestasRapidas);
    if (editar) {
      auxRespuestas[index].titulo = tituloSinEspacios;
      auxRespuestas[index].categoria = categoriaSinEspacios;
      auxRespuestas[index].contenido = contenidoSinEspacios;
      showToast(t('Quick reply saved'), t('The quick reply has been updated'), 'success');
    } else {
      auxRespuestas.push({
        titulo: tituloSinEspacios,
        contenido: contenidoSinEspacios,
        categoria: categoriaSinEspacios
      });
      showToast(t('Quick reply saved'), t('The quick reply has been added'), 'success');
    }
    setTitulo('');
    setContenido({});
    setIdiomasRR([]);
    setCategoria('');
    setNuevaCategoria('0');
    setModalNuevaRespuesta(false);
    const nuevosIdiomas = {};
    auxRespuestas.forEach((r) => {
      Object.keys(r.contenido).forEach(c => {
        if (!nuevosIdiomas[c]) {
          const nuevoIdioma = _.pick(listaTraducida, c);
          if (Object.keys(nuevoIdioma).length !== 0) nuevosIdiomas[c] = nuevoIdioma[c];
        }
        if (!r.idiomaActivo) r.idiomaActivo = c;
      });
    });
    setRespuestasRapidas(auxRespuestas);
    setIdiomas(nuevosIdiomas);
  };

  const eliminarRespuestaRapida = async (value) => {
    try {
      const response = await axiosApi.post('/mc/companies/respuestasRapidas/delete', {
        titulo: value,
        idLocal
      });
      const { error } = response;
      if (error || response.status === 500) {
        throw new Error();
      }
      setConfirmacionEliminada(true);
      if (nuevaCategoria === '0') {
        const auxCategorias = structuredClone(categorias);
        auxCategorias.push(categoria);
        setCategorias(auxCategorias);
      }
      setRespuestaEliminadaCorrectamente(true);
      setTituloConfirmacionEliminada(t('Deleted'));
      setMensajeConfirmacion(t('The quick reply has been deleted'));
      const auxRespuestas = structuredClone(respuestasRapidas);
      const indexEliminar = auxRespuestas.findIndex(
        r => r.titulo === value
      );
      if (indexEliminar !== -1) {
        auxRespuestas.splice(indexEliminar, 1);
      }
      setRespuestasRapidas(auxRespuestas);
      setRespuestaEliminar('');
      showToast(t('Quick reply deleted'), t('The quick reply has been deleted'), 'success');
    } catch (e) {
      setConfirmacionEliminada(true);
      setRespuestaEliminar('');
      showToast(t('Error deleting quick reply'), t("The quick reply can't be deleted"), 'error');
      setRespuestaEliminadaCorrectamente(false);
      setTituloConfirmacionEliminada(t('Error'));
      setMensajeConfirmacion(t("The quick reply can't be deleted"));
    }
  };

  const editarRespuestaRapida = async (value) => {
    const indexEditar = respuestasRapidas.findIndex(
      r => r.titulo === value
    );
    setTitulo(respuestasRapidas[indexEditar].titulo);
    setNuevaCategoria(respuestasRapidas[indexEditar].categoria);
    setContenido(respuestasRapidas[indexEditar].contenido);
    setIdiomasRR(Object.keys(respuestasRapidas[indexEditar].contenido));
    setModalNuevaRespuesta(true);
    setEditar(true);
  };

  const traducirRespuestaRapida = async (value, destino) => {
    try {
      const response = await axiosApi.post('/mc/traducir', {
        mensaje: value,
        idioma: destino
      });
      const { data } = response;
      if (data.message === 'ok') {
        const traduccion = data.data;
        const copiaContenido = { ...contenido };
        copiaContenido[destino] = traduccion;
        setContenido(copiaContenido);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const handleOutsideClick = (e) => {
    if (!e.target.className.includes('añadirIdiomaClassFiltro')) {
      setMostrarAnadirIdioma(false);
    }
  };

  useEffect(() => {
    cargaDatos();
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (idLocal) {
      cargarRespuestasRapidas();
    }
  }, [idLocal]);

  useEffect(() => {
    const arrayCategorias = [];
    respuestasRapidas.forEach(r => {
      arrayCategorias.push(r.categoria);
    });
    setCategorias([...new Set(arrayCategorias)]);
  }, [respuestasRapidas]);

  useEffect(() => {
    if (respuestasRapidas.length > 0) {
      let filteredData = _.cloneDeep(respuestasRapidas);
      if (filter !== '0') {
        filteredData = filteredData.filter(
          r => r.categoria === filter
        );
      }
      if (filterIdioma !== '-1') {
        filteredData = filteredData.filter(
          r => r.contenido[filterIdioma]
        );
      }
      setRespuestasRapidasFiltered(filteredData);
    }
  }, [filter, respuestasRapidas, filterIdioma]);

  async function eliminarIdiomaRespuestaRapida(value) {
    const indexEditar = respuestasRapidas.findIndex(
      r => r.titulo === value
    );
    let nuevoContenido = respuestasRapidas[indexEditar].contenido;
    nuevoContenido = _.omit(nuevoContenido, filterIdioma);

    const tituloSinEspacios = structuredClone(respuestasRapidas[indexEditar].titulo.trim().replace(/\s\s+/g, ' ').replace(/ /g, '_'));
    const categoriaSinEspacios = structuredClone(respuestasRapidas[indexEditar].categoria.trim().replace(/\s\s+/g, ' ').replace(/ /g, '_'));
    Object.keys(nuevoContenido).forEach(c => {
      nuevoContenido[c] = nuevoContenido[c].trim();
    });
    const contenidoSinEspacios = structuredClone(nuevoContenido);
    const response = await axiosApi.post('/mc/companies/respuestasRapidas/nueva', {
      titulo: tituloSinEspacios,
      contenido: contenidoSinEspacios,
      categoria: categoriaSinEspacios,
      idLocal,
      editar: true
    });
    const { error } = response;
    if (error) {
      if (editar) {
        showToast(t('Error editing quick reply'), t("The quick reply can't be edited"), 'error');
        return;
      }
      showToast(t('Error deleting quick reply'), t("The quick reply can't be deleted"), 'error');
      return;
    }
    const auxRespuestas = structuredClone(respuestasRapidas);
    auxRespuestas[indexEditar].titulo = tituloSinEspacios;
    auxRespuestas[indexEditar].categoria = categoriaSinEspacios;
    auxRespuestas[indexEditar].contenido = contenidoSinEspacios;
    showToast(t('Quick reply deleted'), t('The quick reply has been deleted'), 'success');
    setRespuestasRapidas(auxRespuestas);
    const nuevosIdiomas = {};
    auxRespuestas.forEach((r) => {
      Object.keys(r.contenido).forEach(c => {
        if (!nuevosIdiomas[c]) {
          const nuevoIdioma = _.pick(listaTraducida, c);
          if (Object.keys(nuevoIdioma).length !== 0) nuevosIdiomas[c] = nuevoIdioma[c];
        }
      });
    });
    if (!nuevosIdiomas[filterIdioma]) setFilterIdioma('-1');
    setIdiomas(nuevosIdiomas);
    setConfirmacionEliminada(true);
    setRespuestaEliminadaCorrectamente(true);
    setTituloConfirmacionEliminada(t('Deleted'));
    setMensajeConfirmacion(t('The quick reply has been deleted'));
    setRespuestaEliminar('');
  }

  const cambiarIdiomaActivo = (value, idioma) => {
    setIdiomaTituloCambio([value, idioma]);
  };

  useEffect(() => {
    if (idiomaTituloCambio.length > 0) {
      const indexEditar = respuestasRapidas.findIndex(
        r => r.titulo === idiomaTituloCambio[0]
      );
      const nuevasRespuestas = _.cloneDeep(respuestasRapidas);
      nuevasRespuestas[indexEditar].idiomaActivo = idiomaTituloCambio[1];
      setRespuestasRapidas(nuevasRespuestas);
    }
  }, [idiomaTituloCambio]);

  const columns = [
    {
      dataField: 'categoria',
      text: t('Category'),
      sort: true,
      align: 'left',
      headerAlign: 'center',
      headerStyle: { maxWidth: '15%' },
      style: { maxWidth: '15%',
        height: '100%',
        width: '15%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' }
    },
    {
      dataField: 'titulo',
      text: t('Title'),
      headerStyle: { maxWidth: '15%' },
      style: { maxWidth: '15%',
        height: '100%',
        width: '15%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      sort: true,
      align: 'left',
      headerAlign: 'center'
    },
    {
      dataField: 'contenido',
      text: t('Content'),
      headerStyle: { maxWidth: '50%' },
      style: { maxWidth: '50%',
        height: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      sort: true,
      align: 'left',
      headerAlign: 'center',
      formatExtraData: filterIdioma,
      formatter: (cell, row, rowIndex, extra) => {
        if (extra === '-1' && typeof cell !== 'string') {
          return (
            <Row>
              {cell[row.idiomaActivo]}
            </Row>
          );
        }
        return cell[extra];
      }
    },
    {
      dataField: 'idiomaActivo',
      text: t('Language'),
      headerStyle: { maxWidth: '20%' },
      style: {
        maxWidth: '20%',
        width: '20%',
        height: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line'
      },
      sort: true,
      align: 'left',
      headerAlign: 'center',
      hidden: (filterIdioma !== '-1'),
      formatExtraData: filterIdioma,
      formatter: (cell, row, rowIndex, extra) => {
        if (extra === '-1' && typeof row.contenido !== 'string') {
          const listaIdiomas = Object.keys(row.contenido);
          return (
            <Col>
              {listaIdiomas.map((i) => (
                <span key={i}>
                  <span
                    style={
                      cell === i ? {
                        border: '1px',
                        borderStyle: 'solid',
                        padding: '3px',
                        cursor: 'pointer',
                        backgroundColor: '#778899',
                        color: 'white',
                        borderColor: '#778899',
                        borderRadius: '5px'
                      } : {
                        border: '1px', borderStyle: 'solid', padding: '3px', cursor: 'pointer', borderColor: '#778899', borderRadius: '5px'
                      }
                    }
                    id={`${i}_${rowIndex}`}
                    onClick={() => cambiarIdiomaActivo(row.titulo, i)}
                  >
                    <UncontrolledTooltip target={`${i}_${rowIndex}`}>
                      {listaTraducida[i]}
                    </UncontrolledTooltip>
                    {i.toUpperCase()}
                  </span>
                  {'  '}
                </span>
              ))}
            </Col>
          );
        }
        return '';
      }
    },
    {
      dataField: 'id',
      text: t('Options'),
      sort: false,
      align: 'center',
      headerAlign: 'center',
      headerStyle: { maxWidth: '5%' },
      style: { maxWidth: '5%',
        height: '100%',
        wordWrap: 'break-word',
        width: '5%',
        whiteSpace: 'pre-line'
      },
      formatter: (value, row) => {
        return (
          <UncontrolledDropdown
            direction="left"
          >
            <DropdownToggle tag="a" caret>
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu
              className="lista-opciones"
            >
              <DropdownItem onClick={() => editarRespuestaRapida(row.titulo)}>{t('Edit')}</DropdownItem>
              <DropdownItem onClick={() => setRespuestaEliminar(row.titulo)}>{t('Remove')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  function anadirIdioma(nuevoIdioma) {
    const mensaje = Object.values(contenido)[0];
    const lang = _.pick(listaTraducida, nuevoIdioma);
    const nuevosIdiomasRR = { ...idiomasRR };
    nuevosIdiomasRR[nuevoIdioma] = lang[nuevoIdioma];
    setIdiomasRR(nuevosIdiomasRR);
    const nuevosIdiomas = { ...idiomasRR };
    if (!nuevosIdiomas[nuevoIdioma]) {
      nuevosIdiomas[nuevoIdioma] = lang[nuevoIdioma];
    }
    if (mensaje) traducirRespuestaRapida(mensaje, nuevoIdioma);
    else setContenido({ ...contenido, [nuevoIdioma]: '' });
  }

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: respuestasRapidasFiltered.length,
    sizePerPageRenderer,
    custom: true,
    showTotal: true
  };

  function filtrarListaIdiomas(value) {
    let filtrar = { ...listaTraducida };
    if (value === '') {
      filtrar = _.pickBy(filtrar, (i, k) => {
        return !contenido[k];
      });
    } else {
      filtrar = _.pickBy(filtrar, (i, k) => {
        return i.toLowerCase().includes(value) && !contenido[k];
      });
    }
    setListaFiltrada(filtrar);
  }

  function editarContenido(clave, mensaje) {
    const copiaContenido = { ...contenido };
    copiaContenido[clave] = mensaje;
    setContenido(copiaContenido);
  }

  function eliminarIdioma(clave) {
    let copiaContenido = { ...contenido };
    copiaContenido = _.omit(copiaContenido, clave);
    setContenido(copiaContenido);
  }

  document.title = `${t('Quick replies')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={t('Quick replies')}
            breadcrumbItems={[{ title: t('Messaging Center') }, { title: t('Quick replies') }]}
          />

          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }}>
                  <Button
                    color="primary"
                    className="mt-1"
                    onClick={() => {
                      setModalNuevaRespuesta(true);
                    }}
                  >
                    {t('New quick reply')}
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-3">
                    <Row>
                      <Col className="col-2">
                        <select
                          className="form-select"
                          id="horizontal-Assigned-Input"
                          defaultValue={0}
                          onChange={event => {
                            setFilter(event.target.value);
                          }}
                        >
                          <option value={0}>{t('All categories')}</option>
                          {categorias.map(c => {
                            return (
                              <option key={`cateroria${c}`} value={c}>
                                {c}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                      <Col>
                        <Nav className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto">
                          <NavItem>
                            <NavLink
                              className={filterIdioma === '-1' ? 'active' : ''}
                              onClick={() => setFilterIdioma('-1')}
                            >
                              {t('All languages')}
                            </NavLink>
                          </NavItem>
                          {Object.keys(idiomas).map(idioma => {
                            return (
                              <NavItem key={idioma}>
                                <NavLink
                                  className={filterIdioma === idioma ? 'active' : ''}
                                  onClick={() => setFilterIdioma(idioma)}
                                >
                                  {t(idiomas[idioma])}
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </Nav>
                      </Col>
                    </Row>
                  </CardSubtitle>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="titulo"
                    columns={columns}
                    data={respuestasRapidasFiltered}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="titulo"
                        columns={columns}
                        data={respuestasRapidasFiltered}
                        search
                        columnToggle
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="titulo"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
            {/* <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Whatsapp automation info title')}</span>
                  <br />
                  {t('Whatsapp automation info text')}
                  <br />
                  {/* <a target="_blank" href="http://helpcenter.wifreezone.com/es/articles/5849058-campsWhatsapp-de-feedback" rel="noreferrer">{t('Read more')}</a> */
            /* }
                </CardText>
              </Card>
            </Col> */}
          </Row>
          <Row style={{ marginTop: '5%' }}>
            <Card body className="puntos">
              <div style={{ whiteSpace: 'break-spaces' }}>
                <Row style={{ margin: '5px' }}>
                  <p><strong>{t('Titulo descripcion respuestas rapidas')}</strong></p>
                  <p>
                    <span>{t('Descripcion respuestas rapidas')}</span>
                    <a
                      href="https://helpcenter.journeytok.com/portal/es/kb/articles/respuestas-r%C3%A1pidas"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('Quick replies')}
                    </a>
                  </p>
                </Row>
              </div>
            </Card>
          </Row>
          <Modal
            isOpen={modalNuevaRespuesta}
            toggle={() => {
              setModalNuevaRespuesta(!modalNuevaRespuesta);
              setEditar(false);
              setMostrarAnadirIdioma(false);
              setTitulo('');
              setContenido('');
              setCategoria('');
              setNuevaCategoria('0');
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t('New quick reply')}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalNuevaRespuesta(false);
                  setEditar(false);
                }}
                className="btn-close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="new-quick-reply-title" className="col-form-label">
                    {t('Title')}
                    :
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="new-quick-reply-title"
                    value={titulo}
                    disabled={editar}
                    onChange={event => {
                      setTitulo(event.target.value.toLowerCase());
                    }}
                  />
                  <div className="mb-3">
                    <label htmlFor="new-quick-reply-category" className="col-form-label">
                      {t('All categories')}
                      :
                    </label>
                    <select
                      className="form-select"
                      id="horizontal-Assigned-Input"
                      onChange={event => {
                        setNuevaCategoria(event.target.value.toLowerCase());
                      }}
                    >
                      <option value={0}>{t('New category')}</option>
                      {categorias.map(c => {
                        return (
                          <option
                            key={`cateroria${c}`}
                            value={c}
                            selected={c === nuevaCategoria}
                          >
                            {c}
                          </option>
                        );
                      })}
                    </select>
                    {nuevaCategoria && nuevaCategoria === '0' && (
                      <div className="mt-2">
                        <input
                          type="text"
                          className="form-control"
                          id="new-quick-reply-category"
                          value={categoria}
                          onChange={event => {
                            setCategoria(event.target.value.toLowerCase());
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {Object.keys(contenido).length > 0 && Object.keys(contenido).map(c => {
                    return (
                      <div key={c}>
                        <label htmlFor="new-quick-reply-content" className="col-form-label">
                          {`${t('Content')} (${c.toUpperCase()})`}
                          {':   '}
                          <i className="bx bx-trash font-size-16" onClick={() => eliminarIdioma(c)} />
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="new-quick-reply-content"
                          rows={4}
                          value={contenido[c]}
                          onChange={event => {
                            editarContenido(c, event.target.value);
                          }}
                        />
                      </div>
                    );
                  })}
                  <Button
                    color="link"
                    onClick={() => {
                      setMostrarAnadirIdioma(!mostrarAnadirIdioma);
                      setListaFiltrada(listaElegibles);
                    }}
                    id="anadirIdioma"
                    className="añadirIdiomaClassFiltro"
                  >
                    {t('Add content')}
                  </Button>
                  <Popover isOpen={mostrarAnadirIdioma} target="anadirIdioma" className="añadirIdiomaClassFiltro">
                    <PopoverHeader className="añadirIdiomaClassFiltro">
                      <Input
                        type="text"
                        className="añadirIdiomaClassFiltro"
                        placeholder={t('Language')}
                        onChange={(e) => filtrarListaIdiomas(e.target.value.toLowerCase())}
                      />
                    </PopoverHeader>
                    <PopoverBody style={{ maxHeight: '200px', overflow: 'scroll' }} className="añadirIdiomaClassFiltro">
                      {listaFiltrada && Object.keys(listaFiltrada).map((i, index) => {
                        return (
                          <div
                            key={i}
                            className="añadirIdiomaClassFiltro"
                            onClick={() => {
                              anadirIdioma(i);
                              setMostrarAnadirIdioma(false);
                            }}
                            style={
                              index === Object.keys(listaFiltrada).length - 1 ?
                                { fontSize: '14px' } :
                                { marginBottom: '5%', fontSize: '14px' }
                            }
                          >
                            {listaFiltrada[i]}
                          </div>
                        );
                      })}
                    </PopoverBody>
                  </Popover>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setTitulo('');
                  setContenido('');
                  setCategoria('');
                  setNuevaCategoria('0');
                  setModalNuevaRespuesta(false);
                  setEditar(false);
                  setMostrarAnadirIdioma(false);
                }}
              >
                {t('Cancel')}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  guardaRespuesta();
                  setEditar(false);
                  setMostrarAnadirIdioma(false);
                }}
              >
                {t('Save')}
              </button>
            </div>
          </Modal>
          <SweetAlert
            warning
            showCancel
            confirmBtnText={t('Confirm')}
            cancelBtnText={t('Cancel')}
            title={t('Are you sure?')}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              if (filterIdioma === '-1') eliminarRespuestaRapida(respuestaEliminar);
              else eliminarIdiomaRespuestaRapida(respuestaEliminar);
            }}
            onCancel={() => {
              setRespuestaEliminar('');
              setConfirmacionEliminada(true);
              setRespuestaEliminadaCorrectamente(false);
              setTituloConfirmacionEliminada(t('Cancelled'));
              setMensajeConfirmacion(t("The quick reply can't be deleted"));
            }}
            show={respuestaEliminar !== ''}
          >
            {filterIdioma === '-1' && t('You will delete all languages associated with this reply!')}
            {filterIdioma !== '-1' && t('You will delete the selected language only!', { Idioma: listaTraducida[filterIdioma] })}
          </SweetAlert>
          <SweetAlert
            success={respuestaEliminadaCorrectamente}
            error={!respuestaEliminadaCorrectamente}
            title={tituloConfirmacionEliminada}
            onConfirm={() => setConfirmacionEliminada(false)}
            show={confirmacionEliminada}
          >
            {mensajeConfirmacion}
          </SweetAlert>
        </div>
      </div>
    </>
  );
};

RespuestasRapidas.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(RespuestasRapidas);
