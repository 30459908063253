import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import 'assets/scss/estilos.scss';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Modal,
  InputGroup,
  Form,
  Input,
  FormFeedback
} from 'reactstrap';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Import Flatepicker
// import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import axiosApi from '../../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const Alerta = props => {
  const { idAlerta } = useParams();
  let correoInicial;
  if (idAlerta === 'crear') {
    correoInicial = [{ email: '' }];
  } else {
    correoInicial = [];
  }
  const [emails, setEmails] = useState(1);
  const [sizeMomento, setSizeMomento] = useState(8);
  const [sizeDiaSemana, setSizeDiaSemana] = useState(0);
  const [sizeHora, setSizeHora] = useState(0);
  const [sizeDiaMes, setSizeDiaMes] = useState(0);
  const [redirect, setRedirect] = useState(false);

  const [modalStandard, setModalStandard] = useState(false);
  const [cambiosPendientes, setCambiosPendientes] = useState(false);

  const diasMes = Array.from({ length: 27 }, (_, i) => i + 1);
  const { t } = props;
  const history = useHistory();

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function togStandard() {
    setModalStandard(!modalStandard);
    removeBodyCss();
  }

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }
  // Form validation
  const validationCheckinReport = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: '',
      activa: false,
      descripcion: '',
      diaMes: '1',
      diaSemana: 'Mon',
      frecuencia: 'al_momento',
      correosReceptores: correoInicial,
      momento: new Date('Mon, 20 Jan 2020 11:00:00 UTC +1')
    },
    validationSchema: Yup.object().shape({
      descripcion: Yup.string().required('This value is required'),
      frecuencia: Yup.string().required('This value is required'),
      correosReceptores: Yup.array().of(
        Yup.object()
          .shape({
            email: Yup.string().email('Enter a valid email').required('Email is required')
          })
          .required()
      )
    }),
    onSubmit: async () => {
      let receptores = [];
      const { correosReceptores, descripcion, momento, activa, id, frecuencia, diaSemana, diaMes } =
        validationCheckinReport.values;
      correosReceptores.forEach(correo => {
        if (correo.valido) {
          receptores = [...receptores, correo.email];
        }
      });
      const datosAlerta = {
        activa,
        id_alertas: id,
        descripcion,
        frecuencia,
        receptores: [...new Set(receptores)],
        momento: momento?.getHours(),
        diaSemana,
        diaMes
      };
      try {
        const response = await axiosApi.post('/api/alerta/checkin', datosAlerta);
        const { data } = response;
        if (data.err) {
          throw Error('Error');
        }
        const title = t('Report saved');
        const message = t('The report has been modified');
        showToast(title, message, 'success');
        setCambiosPendientes(false);
        validationCheckinReport.setFieldValue('id', data.id_alertas);
      } catch (error) {
        console.log(error);
        const title = t('Error saving report');
        const message = t('The report has not been modified');
        showToast(title, message, 'error');
      }
    }
  });

  function handleAddRowNested() {
    const { correosReceptores } =
      validationCheckinReport.values;
    const item = { email: '' };
    validationCheckinReport.setFieldValue('correosReceptores', [...correosReceptores, item]);
    setEmails(emails + 1);
  }

  function handleRemoveRowNested(e, idx) {
    const { correosReceptores } =
    validationCheckinReport.values;
    const rowsAux = correosReceptores;
    if (emails > 1) {
      document.getElementById(`nested${idx}`).style.display = 'none';
      rowsAux[idx].valido = false;
      rowsAux[idx].email = 'no@email.com';
      validationCheckinReport.setFieldValue('correosReceptores', rowsAux);
      setEmails(emails - 1);
    }
  }

  const onChangeMomento = value => {
    switch (value) {
      case 'al_momento':
        setSizeDiaSemana(0);
        setSizeHora(0);
        setSizeMomento(8);
        setSizeDiaMes(0);
        break;
      case 'diario':
        setSizeDiaSemana(0);
        setSizeHora(4);
        setSizeMomento(4);
        setSizeDiaMes(0);
        break;
      case 'semanal':
        setSizeDiaSemana(2);
        setSizeHora(3);
        setSizeMomento(3);
        setSizeDiaMes(0);
        break;
      case 'mensual':
        setSizeDiaSemana(0);
        setSizeHora(3);
        setSizeMomento(3);
        setSizeDiaMes(2);
        break;
      default:
    }
    validationCheckinReport.setFieldValue('frecuencia', value);
  };

  function cancelar() {
    if (cambiosPendientes) {
      togStandard();
    } else {
      history.goBack();
    }
  }

  const cargaDatos = async () => {
    if (idAlerta !== 'crear') {
      try {
        const response = await axiosApi.get(`/api/alertas/checkin/${idAlerta}`);
        const { data } = response;
        const alerta = data;
        validationCheckinReport.setFieldValue('id', alerta.id_alertas);
        validationCheckinReport.setFieldValue('activa', alerta.activa === 1);
        validationCheckinReport.setFieldValue('descripcion', alerta.descripcion);
        validationCheckinReport.setFieldValue('periodo', alerta.periodo);
        if (alerta.hora) {
          validationCheckinReport.setFieldValue('momento', (new Date(`Mon, 20 Jan 2020 ${alerta.hora}:00:00 UTC +1`)));
        }
        if (alerta.diaMes) {
          validationCheckinReport.setFieldValue('diaMes', alerta.periodo);
        }
        if (alerta.diaSemana) {
          validationCheckinReport.setFieldValue('diaSemana', alerta.periodo);
        }
        if (alerta.correo_receptor && alerta.correo_receptor !== '') {
          const correos = [];
          JSON.parse(JSON.stringify(alerta.correo_receptor)).forEach((correo) => {
            setEmails(emails + 1);
            correos.push({ email: correo, valido: true });
          });
          validationCheckinReport.setFieldValue('correosReceptores', correos);
        } else {
          const item = { email: '' };
          const { correosReceptores } =
          validationCheckinReport.values;
          validationCheckinReport.setFieldValue('correosReceptores', [...correosReceptores, item]);
        }
      } catch (e) {
        setRedirect(true);
      }
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  document.title = `${t('Report data')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      {redirect && <Redirect to="/alertas/checkin" />}
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={{ size: 8, offset: 2 }}>
              <Breadcrumbs title={t('Settings')} breadcrumbItem={t('Checkin reports')} back />
            </Col>
          </Row>
          <Row>
            <Col lg={{ size: 8, offset: 2 }}>
              <Card>
                <CardBody>
                  {!validationCheckinReport.values.id && <CardTitle className="mb-4">{t('New report')}</CardTitle>}
                  {validationCheckinReport.values.id && <CardTitle className="mb-4">{t('Edit report')}</CardTitle>}

                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validationCheckinReport.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="row mb-4">
                      <Col sm={8}>
                        <Input
                          name="id"
                          type="hidden"
                          className="form-control"
                          id="horizontal-id-Input"
                          value={validationCheckinReport.values.id || ''}
                        />
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('Enable')}
                      </Label>
                      <Col sm={1} className="form-check-label">
                        <div className="form-check form-switch form-switch-md">
                          <Input
                            checked={validationCheckinReport.values.activa || false}
                            type="checkbox"
                            className="form-control form-check-input"
                            id="customSwitchsizemd"
                            onChange={event => {
                              if (!cambiosPendientes) {
                                setCambiosPendientes(true);
                              }
                              validationCheckinReport.setFieldValue('activa', event.target.checked);
                            }}
                          />
                        </div>
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-lastname-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('Description')}
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          name="descripcion"
                          className="form-control"
                          id="horizontal-lastname-Input"
                          onChange={event => {
                            if (!cambiosPendientes) {
                              setCambiosPendientes(true);
                            }
                            validationCheckinReport.setFieldValue(
                              'descripcion',
                              event.target.value
                            );
                          }}
                          onBlur={validationCheckinReport.handleBlur}
                          value={validationCheckinReport.values.descripcion || ''}
                          invalid={
                            !!(
                              validationCheckinReport.touched.descripcion &&
                              validationCheckinReport.errors.descripcion
                            )
                          }
                        />
                        {validationCheckinReport.touched.descripcion &&
                        validationCheckinReport.errors.descripcion ? (
                          <FormFeedback type="invalid">
                            {validationCheckinReport.errors.descripcion}
                          </FormFeedback>
                          ) : null}
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-frecuencia-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('Receive')}
                      </Label>
                      <Col sm={sizeMomento}>
                        <select
                          value={validationCheckinReport.values.frecuencia || 'al_momento'}
                          className="form-select"
                          id="horizontal-frecuencia-Input"
                          onChange={e => {
                            if (!cambiosPendientes) {
                              setCambiosPendientes(true);
                            }
                            onChangeMomento(e.target.value);
                          }}
                        >
                          <option value="al_momento">{t('At the moment')}</option>
                          <option value="diario">{t('Daily')}</option>
                          <option value="semanal">{t('Weekly')}</option>
                          <option value="mensual">{t('Monthly')}</option>
                        </select>
                        {validationCheckinReport.touched.frecuencia &&
                        validationCheckinReport.errors.frecuencia ? (
                          <FormFeedback type="invalid">
                            {validationCheckinReport.errors.frecuencia}
                          </FormFeedback>
                          ) : null}
                      </Col>
                      <Col hidden={sizeDiaMes === 0} sm={sizeDiaMes}>
                        <select
                          value={validationCheckinReport.values.diaMes || 'al_momento'}
                          className="form-select"
                          id="horizontal-dia-mes-Input"
                          onChange={event => {
                            if (!cambiosPendientes) {
                              setCambiosPendientes(true);
                            }
                            validationCheckinReport.setFieldValue('diaMes', event.target.value);
                          }}
                        >
                          {diasMes.map((value, i) => (
                            <option key={i} value={value}>
                              {value}
                            </option>
                          ))}
                          <option value="ultimo_dia">{t('Last day of the month')}</option>
                        </select>
                      </Col>
                      <Col hidden={sizeDiaSemana === 0} sm={sizeDiaSemana}>
                        <select
                          value={validationCheckinReport.values.diaSemana || 'Mon'}
                          className="form-select"
                          id="horizontal-dia-semana-Input"
                          onChange={event => {
                            if (!cambiosPendientes) {
                              setCambiosPendientes(true);
                            }
                            validationCheckinReport.setFieldValue('diaSemana', event.target.value);
                          }}
                        >
                          <option value="Mon">{t('Mon')}</option>
                          <option value="Tue">{t('Tue')}</option>
                          <option value="Wed">{t('Wed')}</option>
                          <option value="Thu">{t('Thu')}</option>
                          <option value="Fri">{t('Fri')}</option>
                          <option value="Sat">{t('Sat')}</option>
                          <option value="Sun">{t('Sun')}</option>
                        </select>
                      </Col>
                      <Col hidden={sizeHora === 0} sm={sizeHora}>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Select time"
                            value={validationCheckinReport.values.momento}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: 'H:i',
                              time_24hr: true,
                              minuteIncrement: 60,
                              defaultHour: 11
                            }}
                            onReady={() => {
                              document.getElementsByClassName(
                                'numInput flatpickr-minute'
                              )[0].disabled = 'true';
                            }}
                            onChange={event => {
                              if (!cambiosPendientes) {
                                setCambiosPendientes(true);
                              }
                              validationCheckinReport.setFieldValue('momento', new Date(event));
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-clock-outline" />
                            </span>
                          </div>
                        </InputGroup>
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label className="col-sm-4 col-form-label">{t('Recipients')}</Label>
                      <Col sm={8}>
                        <table style={{ width: '100%' }}>
                          <tbody>
                            {validationCheckinReport.values.correosReceptores.map((item, idx) => (
                              <tr id={`nested${idx}`} key={idx}>
                                <td>
                                  <Row>
                                    <Col md="10">
                                      <Input
                                        name={`email${idx}`}
                                        type="email"
                                        className="inner form-control"
                                        placeholder={t('Recipient')}
                                        value={item.email}
                                        onChange={event => {
                                          if (!cambiosPendientes) {
                                            setCambiosPendientes(true);
                                          }
                                          const { correosReceptores } =
                                            validationCheckinReport.values;
                                          validationCheckinReport.setFieldValue(
                                            'correosReceptores',
                                            correosReceptores.map((value, j) => {
                                              if (idx === j) {
                                                value = { email: event.target.value, valido: true };
                                              }
                                              return value;
                                            })
                                          );
                                        }}
                                        onBlur={validationCheckinReport.handleBlur}
                                        invalid={
                                          !!(
                                            validationCheckinReport.touched.correosReceptores &&
                                            validationCheckinReport.errors.correosReceptores
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col md="1">
                                      <Button
                                        onClick={e => {
                                          handleRemoveRowNested(e, idx);
                                        }}
                                        color="link"
                                        className="btn-block inner"
                                        style={{ width: '100%' }}
                                      >
                                        <i
                                          style={{ fontSize: '20px', color: 'lightslategrey' }}
                                          className="bx bx-trash"
                                        />
                                      </Button>
                                    </Col>
                                  </Row>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <Button
                          onClick={() => {
                            handleAddRowNested();
                          }}
                          color="link"
                          className="mt-1"
                        >
                          {t('Add email')}
                        </Button>
                      </Col>
                    </div>
                    <div className="row justify-content-end">
                      <Col sm={8}>
                        <div>
                          <Button type="submit" color="success" className="w-md">
                            {t('Save')}
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              cancelar();
                            }}
                            color="link"
                            className="w-md"
                          >
                            {t('Cancel')}
                          </Button>
                        </div>
                      </Col>
                      <Modal
                        isOpen={modalStandard}
                        toggle={() => {
                          togStandard();
                        }}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                            {t('Are you sure?')}
                          </h5>
                          <button
                            type="button"
                            onClick={() => {
                              setModalStandard(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <p>{t('If you click continue, unsaved changes will be lost.')}</p>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                              togStandard();
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                          >
                            {t('Close')}
                          </button>
                          <button
                            onClick={() => {
                              history.goBack();
                            }}
                            type="button"
                            className="btn btn-primary "
                          >
                            {t('Continue')}
                          </button>
                        </div>
                      </Modal>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Alerta.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Alerta);
