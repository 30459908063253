import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Col } from 'reactstrap';
import wifreezone from '../../assets/images/wifreezone.png';
import messagingCenter from '../../assets/images/messaging-center.png';
import crm from '../../assets/images/crm.png';
import checkin from '../../assets/images/check-in.png';

const CarouselPage = () => {
  return (
    <>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overflay" />
            <div className="d-flex h-100 flex-column">
              <div className="p-4 mt-auto mb-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="text-center">
                      <h3 className="mb-3">
                        <span className="text-primary">Journeytok</span>
                      </h3>
                      <div dir="ltr">
                        <Carousel
                          className="owl-carousel owl-theme auth-review-carousel slider_css"
                          id="auth-review-carousel"
                          showThumbs={false}
                          autoPlay
                          infiniteLoop
                        >
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <h5 className="mb-3">
                                  <span className="text-primary">CRM & Automated Guest Journey</span>
                                </h5>
                                <img src={crm} alt="" className="mb-4" style={{ maxHeight: '50vh', maxWidth: '50vh' }} />
                                <p className="font-size-14 mt-4 mb-2">
                                  El CRM hotelero más potente y a la vez sencillo.
                                  Centraliza toda la información de tus clientes
                                  una única base de datos que normalizará de forma
                                  automática la información para que tengas los datos
                                  importantes. Automatiza la comunicación con el huésped,
                                  en cualquier etapa del viaje, y por su canal favorito.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <h5 className="mb-3">
                                  <span className="text-primary">Wifreezone</span>
                                </h5>
                                <img src={wifreezone} className="mb-4" alt="" style={{ maxHeight: '50vh', maxWidth: '50vh' }} />
                                <p className="font-size-14 mt-4 mb-2">
                                  Usa el wifi de clientes para generar una base de datos de
                                  calidad que te permita fidelizar a tu huésped e incentivar
                                  la venta directa, evitando comisiones.
                                  Permite la captación del correo electrónico
                                  y número de teléfono de todos
                                  los huéspedes, no sólo de quien reserva.
                                  Podrás segmentar por clientes alojados,
                                  visitas y adaptar el wifi para los eventos.
                                  El mejor portal cautivo especializado en hoteles.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="pb-5 pt-3">
                                <h5 className="mb-3">
                                  <span className="text-primary">Checkin online</span>
                                </h5>
                                <img src={checkin} alt="" className="mb-4" style={{ maxHeight: '50vh', maxWidth: '50vh' }} />
                                <p className="font-size-14 mt-4 mb-2">
                                  Tus huéspedes realizarán el proceso de
                                  check-in por sí mismos, usando sus dispositivos y
                                  desde cualquier lugar. Tendrás todos los datos volcados
                                  a tu PMS de forma automática, ahorrando tiempo a
                                  tus cliente y descargando la recepción.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="pb-3 pt-3">
                                <h5 className="mb-3">
                                  <span className="text-primary">Messaging Center</span>
                                </h5>
                                <img src={messagingCenter} alt="" className="mb-2" style={{ maxHeight: '35vh', maxWidth: '35vh' }} />
                                <p className="font-size-14 mt-4 mb-2">
                                  Establece un proceso de comunicación bidireccional
                                  con tu cliente, omnicanal y con todas las conversaciones
                                  centralizadas en una única interfaz que podrán utilizar
                                  los distintos departamentos del hotel. Utiliza el canal
                                  que prefieren tus clientes (whatsapp, email, webchat…).
                                </p>
                              </div>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};
export default CarouselPage;
