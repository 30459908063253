import React, { useCallback, useState, useEffect, useRef, useReducer } from 'react';
import {
  Row,
  Col,
  Button,
  Offcanvas,
  Input,
  Label,
  Modal,
  Card,
  Form,
  Nav,
  NavLink,
  NavItem,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledTooltip,
  Tooltip
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Prompt } from 'react-router-dom/cjs/react-router-dom';
import { createPicker } from 'picmo';

import Diagram, { useSchema } from 'beautiful-react-diagrams';
import 'assets/scss/diagrama.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';
import NodoChatbot from './NodoChatbot';
import listaTraducida from '../../common/traducirListaIdiomas';

const DiagramaChatbot = props => {
  const { t } = props;
  const [ultimoId, setUltimoID] = useState(2);
  const [nodoEditar, setNodoEditar] = useState();
  const [nodoEditable, setNodoEditable] = useState(null);
  const [modalNuevaRespuesta, setModalNuevaRespuesta] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [respuestasRapidas, setRespuestasRapidas] = useState([]);
  const [nuevaCategoria, setNuevaCategoria] = useState('0');
  const [categoria, setCategoria] = useState('');
  const [titulo, setTitulo] = useState('');
  const [contenido, setContenido] = useState({});
  const [idLocal, setIdLocal] = useState(null);
  const [nodoEliminar, setNodoEliminar] = useState(-1);
  const [guardadoLinks, setGuardadoLinks] = useState([]);
  const [update, forceUpdate] = useReducer(x => (x + 1) % 5, 0);
  const zoneRef = useRef(null);
  const [esquema, { onChange, addNode, removeNode }] = useSchema();
  const [waitRefresh, setWaitRefresh] = useState(false);
  const [radioCheck, setRadioCheck] = useState(0);
  const [rrSeleccionada, setRRSeleccionada] = useState('');
  const [modalTranslate, setModalTranslate] = useState(false);
  const [idiomaActivo, setIdiomaActivo] = useState('es');
  const [idiomas, setIdiomas] = useState({});
  const [idiomaOrigen, setIdiomaOrigen] = useState('es');
  const [idiomaDestino, setIdiomaDestino] = useState('es');
  const [diagramaTextoOrigen, setDiagramaTextoOrigen] = useState({});
  const idDiagrama = 0;
  const [diagramaTextoNuevo, setDiagramaTextoNuevo] = useState({});
  const [nombreH, setNombreH] = useState('');
  const [mostrarAnadirIdioma, setMostrarAnadirIdioma] = useState(false);
  const [listaFiltrada, setListaFiltrada] = useState(listaTraducida);
  const [listaElegibles, setListaElegibles] = useState(listaTraducida);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalCancelar, setModalCancelar] = useState(false);
  const [idiomasRR, setIdiomasRR] = useState(listaTraducida);
  const [mostrarAnadirIdiomaRR, setMostrarAnadirIdiomaRR] = useState(false);
  const [listaElegiblesRR, setListaFiltradaRR] = useState(listaTraducida);
  const [mostrarPrueba, setMostrarPrueba] = useState(false);
  const [menuEmojis, setMenuEmojis] = useState('');
  const [urlRedirect, setURLRedirect] = useState('');
  const [timeoutRedirect, setTimeoutRedirect] = useState(0);

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargarRespuestasRapidas = async () => {
    try {
      const response = await axiosApi.get(`/mc/companies/respuestasRapidas?id=${idLocal}`);
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      const arrayRespuestas = [];
      Object.keys(data.respuestasRapidas).forEach(r => {
        arrayRespuestas.push(...data.respuestasRapidas[r]);
      });
      arrayRespuestas.forEach((r, index) => { r.id = index; });
      setRespuestasRapidas(arrayRespuestas);
      setRRSeleccionada(arrayRespuestas[0].titulo);
    } catch (error) {
      // console.log(error);
    }
  };

  function editarRespuesta(id, value) {
    const nuevasRespuestas = [...nodoEditable.data.respuestas];
    const index = nuevasRespuestas.findIndex(r => r.id === id);
    nuevasRespuestas[index].texto = value;
    const nuevoProps = { ...nodoEditable.data };
    nuevoProps.respuestas = nuevasRespuestas;
    setNodoEditable({ ...nodoEditable, data: nuevoProps });
  }

  function editarMensaje(value, radio = 0, url = '', timeout = 0) {
    const nuevoProps = { ...nodoEditable.data };
    let nuevosInputs = [...nodoEditable.inputs];
    nuevoProps.mensaje = radio === 0 ? value : nuevoProps.mensaje;
    nuevoProps.rr = radio === 1 ? value : '';
    nuevoProps.contactar = radio === 2;
    nuevoProps.reiniciar = radio === 3;
    nuevoProps.redirect = radio === 4;
    nuevoProps.url = url;
    nuevoProps.timeoutRedirect = timeout;
    if (radio !== 0) {
      nuevosInputs = [];
      nuevoProps.respuestas = [];
    }
    setNodoEditable({ ...nodoEditable, data: nuevoProps, inputs: nuevosInputs });
  }

  function anadirRespuesta() {
    const nuevoProp = { ...nodoEditable.data };
    const nuevoInput = [...nodoEditable.inputs];
    if (nuevoProp.respuestas.length === 0) {
      nuevoProp.respuestas.push({ id: 0, texto: '' });
    } else {
      nuevoProp.respuestas.push({ id: (nuevoProp.respuestas[nuevoProp.respuestas.length - 1].id) + 1, texto: '' });
    }
    if (!nuevoInput) {
      nuevoInput.push({ id: `nodo${nodoEditable.data.id}-0`, alignment: 'right' });
    } else {
      nuevoInput.push({
        id: `nodo${nodoEditable.data.id}-${nuevoProp.respuestas[nuevoProp.respuestas.length - 1].id}`,
        alignment: 'right'
      });
    }
    setNodoEditable({ ...nodoEditable, inputs: nuevoInput, data: nuevoProp });
  }

  function eliminarRespuesta(id) {
    const nuevoProp = { ...nodoEditable.data };
    let nuevoInput = [...nodoEditable.inputs];
    nuevoProp.respuestas = nuevoProp.respuestas.filter((respuesta) => respuesta.id !== id);
    nuevoInput = nuevoInput.filter((port) => port.id !== `nodo${nuevoProp.id}-${id}`);
    setNodoEditable({ ...nodoEditable, data: nuevoProp, inputs: nuevoInput });
    setMenuEmojis('');
  }

  function mostrarEditar(nodoOriginal) {
    if (nodoOriginal) {
      console.log(nodoOriginal);
      if (nodoOriginal.data.reiniciar) {
        setRadioCheck(3);
      } else if (nodoOriginal.data.contactar) {
        setRadioCheck(2);
      } else if (nodoOriginal.data.redirect) {
        setRadioCheck(4);
        setURLRedirect(nodoOriginal.data.url);
        setTimeoutRedirect(nodoOriginal.data.timeoutRedirect);
      } else if (nodoOriginal.data.rr) {
        setRadioCheck(1);
        setRRSeleccionada(nodoOriginal.data.rr);
      } else {
        setRadioCheck(0);
      }
      setNodoEditable(_.cloneDeep(nodoOriginal));
    }
  }

  const eliminarNodo = useCallback((id) => {
    setNodoEliminar(id);
  });

  const editarNodo = useCallback((id) => {
    setNodoEditar(id);
  });

  const guardaRespuesta = async () => {
    const index = respuestasRapidas.findIndex(r => r.titulo === titulo);
    if (index !== -1) {
      showToast(t('Error adding quick reply'), t('The quick reply already exists'), 'error');
      return;
    }
    let categoriaRespuesta;
    if (nuevaCategoria === '0') {
      categoriaRespuesta = categoria;
    } else {
      categoriaRespuesta = nuevaCategoria;
    }
    const tituloSinEspacios = structuredClone(titulo.trim().replace(/\s\s+/g, ' ').replace(/ /g, '_'));
    const categoriaSinEspacios = structuredClone(categoriaRespuesta.trim().replace(/\s\s+/g, ' ').replace(/ /g, '_'));
    const copiaContenido = { ...contenido };
    Object.keys(copiaContenido).forEach(c => {
      copiaContenido[c] = copiaContenido[c].trim();
    });
    const contenidoSinEspacios = structuredClone(copiaContenido);
    const response = await axiosApi.post('/mc/companies/respuestasRapidas/nueva', {
      titulo: tituloSinEspacios,
      contenido: contenidoSinEspacios,
      categoria: categoriaSinEspacios,
      idLocal,
    });
    const { error } = response;
    if (error) {
      showToast(t('Error adding quick reply'), t("The quick reply can't be created"), 'error');
      return;
    }
    if (nuevaCategoria === '0') {
      const auxCategorias = structuredClone(categorias);
      auxCategorias.push(categoria);
      setCategorias(auxCategorias);
    }
    const auxRespuestas = structuredClone(respuestasRapidas);
    auxRespuestas.push({
      titulo: tituloSinEspacios.replace(' ', '_'),
      contenido: contenidoSinEspacios,
      categoria: categoriaSinEspacios
    });
    showToast(t('Quick reply saved'), t('The quick reply has been added'), 'success');
    setRespuestasRapidas(auxRespuestas);
    setTitulo('');
    setContenido('');
    setCategoria('');
    setNuevaCategoria('0');
    setModalNuevaRespuesta(false);
    setRRSeleccionada(titulo);
    editarMensaje(titulo, 1);
  };

  const guardarDiagrama = async () => {
    try {
      const diagramaGuardar = { idDiagrama };
      Object.keys(diagramaTextoNuevo).forEach(k => {
        diagramaGuardar[k] = diagramaTextoNuevo[k];
      });
      diagramaGuardar[idiomaActivo] = _.cloneDeep(esquema);
      const nuevoTexto = _.cloneDeep(diagramaTextoNuevo);
      nuevoTexto[idiomaActivo] = _.cloneDeep(esquema);
      // Esto será controlado despues en el panel que se cree para manejar multiples diagramas
      if (!('activo' in diagramaGuardar)) diagramaGuardar.activo = true;
      const response = await axiosApi.post('/mc/companies/guardarDiagramaChat', {
        diagrama: diagramaGuardar,
        idLocal,
        idDiagrama
      });
      const { error } = response;
      if (error) {
        showToast(t('Error saving diagram'), t("The diagram can't be saved"), 'error');
        return;
      }
      setDiagramaTextoOrigen(nuevoTexto);
      setDiagramaTextoNuevo(nuevoTexto);
      showToast(t('Diagram saved'), t('The diagram has been saved'), 'success');
    } catch (error) {
      // error
    }
  };

  function generarEsquema(diagramaText) {
    const copiaNodos = [...esquema.nodes];
    copiaNodos.forEach(n => {
      removeNode(n);
    });
    let lastID = 2;
    if (!diagramaText) {
      const finMensajes = [{ id: 0, texto: t('Yes') }, { id: 1, texto: t('No') }];
      const nodoInicio = {
        id: 'inicio',
        render: NodoChatbot,
        data: { id: 0, mensaje: t('Welcome to hotel. What can we do to help you?', { hotel: nombreH }), t, editar: editarNodo, closeFunction: eliminarNodo, respuestas: [], inicio: true, reiniciar: false, contactar: false },
        coordinates: [50, 0],
        className: 'nodoChatbot',
        inputs: []
      };
      const nodoFin = {
        id: 'fin',
        render: NodoChatbot,
        data: { id: 1, mensaje: t('Have we solve your problem?'), t, editar: editarNodo, closeFunction: eliminarNodo, respuestas: finMensajes, inicio: true, final: true, reiniciar: false, contactar: false },
        coordinates: [400, 0],
        className: 'nodoChatbot',
        inputs: [{ id: 'nodo1-0', alignment: 'right' }, { id: 'nodo1-1', alignment: 'right' }]
      };
      addNode(nodoInicio);
      addNode(nodoFin);
      forceUpdate();
    } else {
      diagramaText.nodes.forEach(nodo => {
        if (nodo.id !== 'fin' && nodo.id !== 'inicio') {
          if (parseInt(nodo.id, 10) >= lastID) lastID = parseInt(nodo.id, 10) + 1;
        }
        const node = {
          id: nodo.id,
          render: NodoChatbot,
          coordinates: nodo.coordinates,
          className: 'nodoChatbot',
          inputs: nodo.inputs,
          outputs: nodo.outputs,
          data: {
            id: nodo.data.id,
            mensaje: nodo.data.mensaje,
            t,
            editar: editarNodo,
            closeFunction: eliminarNodo,
            respuestas: nodo.data.respuestas,
            inicio: nodo.data.inicio || false,
            final: nodo.data.final || false,
            contactar: nodo.data.contactar || false,
            reiniciar: nodo.data.reiniciar || false,
            redirect: nodo.data.redirect || false,
            url: nodo.data.url || '',
            timeoutRedirect: nodo.data.timeoutRedirect || 0,
            rr: nodo.data.rr || ''
          }
        };
        addNode(node);
      });
      setGuardadoLinks(diagramaText.links);
    }
    setUltimoID(lastID);
    forceUpdate();
  }

  const cargaDatos = async () => {
    try {
      const whoami = await axiosApi.get('/mc/whoami');
      const { data } = whoami;
      setIdLocal(data.idLocal);
      const diagrama = await axiosApi.get(`/mc/companies/cargarDiagramaChat?id=${data.idLocal}&diagramaID=${idDiagrama}`);
      if (diagrama.data.error || Object.keys(diagrama.data.diagrama).length === 0) {
        throw new Error();
      }
      const diagramaText = diagrama.data.diagrama;
      setDiagramaTextoOrigen(_.cloneDeep(diagramaText));
      setDiagramaTextoNuevo(_.cloneDeep(diagramaText));
      generarEsquema(diagramaText[idiomaActivo]);
      const keys = Object.keys(diagramaText);
      setIdiomas(_.pick(listaTraducida, keys));
      setListaElegibles(_.omit(listaTraducida, keys));
      forceUpdate();
    } catch (error) {
      let nombreHotel = '';
      try {
        const nombre = await axiosApi.get('/api/getNombreLocalSelected');
        nombreHotel = nombre.data.nombre;
      } catch (error2) {
        // console.log(error2);
        nombreHotel = '';
      }
      setNombreH(nombreHotel);
      const finMensajes = [{ id: 0, texto: t('Yes') }, { id: 1, texto: t('No') }];
      const nodoInicio = {
        id: 'inicio',
        render: NodoChatbot,
        data: { id: 0, mensaje: t('Welcome to hotel. What can we do to help you?', { hotel: nombreHotel }), t, editar: editarNodo, closeFunction: eliminarNodo, respuestas: [], inicio: true, reiniciar: false, contactar: false },
        coordinates: [50, 0],
        className: 'nodoChatbot',
        inputs: []
      };
      const nodoFin = {
        id: 'fin',
        render: NodoChatbot,
        data: { id: 1, mensaje: t('Have we solve your problem?'), t, editar: editarNodo, closeFunction: eliminarNodo, respuestas: finMensajes, inicio: true, final: true, reiniciar: false, contactar: false },
        coordinates: [400, 0],
        className: 'nodoChatbot',
        inputs: [{ id: 'nodo1-0', alignment: 'right' }, { id: 'nodo1-1', alignment: 'right' }]
      };
      addNode(nodoInicio);
      addNode(nodoFin);
      const nuevoIdiomas = {};
      nuevoIdiomas[localStorage.getItem('I18N_LANGUAGE')] = listaTraducida[localStorage.getItem('I18N_LANGUAGE')];
      setIdiomas(nuevoIdiomas);
      const nuevoTexto = {};
      nuevoTexto[localStorage.getItem('I18N_LANGUAGE')] = esquema;
      nuevoTexto.idDiagrama = 0;
      nuevoTexto.activo = true;
      setDiagramaTextoNuevo(nuevoTexto);
      setDiagramaTextoOrigen(nuevoTexto);
      forceUpdate();
    }
  };

  const handleOutsideClick = (e) => {
    if (!e.target.className.includes('añadirIdiomaClassFiltro')) {
      setMostrarAnadirIdioma(false);
    }
  };

  const sonIgualesDiagramas = () => {
    return _.isEqual(diagramaTextoNuevo, diagramaTextoOrigen);
  };

  const handleBeforeUnload = (e) => {
    if (!sonIgualesDiagramas()) {
      e.preventDefault();
      e.returnValue = t('You have unsaved changes, are you sure you want to leave?');
    }
  };

  useEffect(() => {
    cargaDatos();
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [diagramaTextoNuevo, diagramaTextoOrigen]);

  useEffect(() => {
    mostrarEditar([...esquema.nodes.filter((nodo) => nodo.data.id === nodoEditar)][0]);
  }, [nodoEditar]);

  const anadirNodo = (respuesta = false) => {
    let title = t('Write a quick reply first');
    if (respuestasRapidas.length > 0) title = respuestasRapidas[0].titulo;
    const nuevoNodo = {
      id: `${ultimoId}`,
      render: NodoChatbot,
      data: {
        id: ultimoId,
        mensaje: respuesta ? title : '',
        t,
        editar: editarNodo,
        closeFunction: eliminarNodo,
        respuestas: [],
        reiniciar: false,
        contactar: false
      },
      coordinates: [(((ultimoId * 100) % 4) + 50), 300],
      className: 'nodoChatbot',
      outputs: [{ id: `nodo${ultimoId}`, alignment: 'left' }],
      inputs: []
    };
    setNodoEditable(nuevoNodo);
    setRadioCheck(0);
    if (respuestasRapidas.length > 0) setRRSeleccionada(respuestasRapidas[0].titulo);
    forceUpdate();
  };

  function actualizarNodo() {
    const nodoToRemove = esquema.nodes.find(nodo => nodo.data.id === nodoEditar);
    let { links } = { ...esquema };
    if (nodoToRemove) {
      removeNode(nodoToRemove);
      if (radioCheck !== 0) {
        links = links.filter(
          (link) => link.input.split('-')[0] !== `nodo${nodoEditar}`
        );
        esquema.links = links;
      }
    } else setUltimoID(ultimoId + 1);
    setGuardadoLinks(links);
    const nodoEditado = {
      id: nodoEditable.id,
      data: nodoEditable.data,
      className: 'nodoChatbot',
      render: NodoChatbot,
      coordinates: nodoEditable.coordinates,
      inputs: nodoEditable.inputs,
      outputs: nodoEditable.outputs
    };
    console.log(nodoEditado);
    addNode(nodoEditado);
    const nuevoTextoDiagrama = _.cloneDeep(diagramaTextoNuevo);
    const index = nuevoTextoDiagrama[idiomaActivo].nodes.findIndex(n => n.data.id === nodoEditar);
    if (index > -1) nuevoTextoDiagrama[idiomaActivo].nodes[index].data = nodoEditable.data;
    else nuevoTextoDiagrama[idiomaActivo].nodes.push(_.cloneDeep(nodoEditable));
    setDiagramaTextoNuevo(nuevoTextoDiagrama);
    forceUpdate();
  }

  useEffect(() => {
    if (idLocal) {
      cargarRespuestasRapidas();
    }
  }, [idLocal]);

  useEffect(() => {
    if (nodoEliminar >= 0) {
      const nodoToRemove = esquema.nodes.find(nodo => nodo.data.id === nodoEliminar);
      removeNode(nodoToRemove);
      const nuevoTextoDiagrama = _.cloneDeep(diagramaTextoNuevo);
      nuevoTextoDiagrama[idiomaActivo] = esquema;
      setDiagramaTextoNuevo(nuevoTextoDiagrama);
      setNodoEliminar(-1);
      forceUpdate();
    }
  }, [nodoEliminar]);

  useEffect(() => {
    if (guardadoLinks.length > 0) {
      esquema.links = guardadoLinks;
      setGuardadoLinks([]);
      forceUpdate();
    }
  }, [guardadoLinks]);

  useEffect(() => {
    const originalLen = esquema.links.length;
    esquema.links = esquema.links.filter(
      (link) => /^nodo[0-9]+-[0-9]+$/.test(link.input) &&
      /^nodo[0-9]+$/.test(link.output) &&
      link.input.split('-')[0] !== link.output
    );
    if (originalLen !== esquema.links.length) {
      forceUpdate();
    }
  }, [esquema.links]);

  useEffect(() => {
    const arrayCategorias = [];
    respuestasRapidas.forEach(r => {
      arrayCategorias.push(r.categoria);
    });
    setCategorias([...new Set(arrayCategorias)]);
  }, [respuestasRapidas]);

  function cancelar() {
    generarEsquema(diagramaTextoOrigen[idiomaActivo]);
    setDiagramaTextoNuevo(diagramaTextoOrigen);
    const keys = Object.keys(diagramaTextoOrigen);
    const nuevoIdiomas = _.pick(listaTraducida, keys);
    setIdiomas(nuevoIdiomas);
    forceUpdate();
  }

  function cambiarIdiomaActivo(nuevoIdioma) {
    if (nuevoIdioma !== idiomaActivo) {
      const nuevoTextoDiagrama = { ...diagramaTextoNuevo };
      nuevoTextoDiagrama[idiomaActivo] = _.cloneDeep(esquema);
      setDiagramaTextoNuevo(nuevoTextoDiagrama);
      if (diagramaTextoNuevo) generarEsquema(diagramaTextoNuevo[nuevoIdioma]);
      setIdiomaActivo(nuevoIdioma);
      forceUpdate();
    }
  }

  async function traducir(origen, destino) {
    const copiaDiagrama = _.cloneDeep(diagramaTextoNuevo[origen]);
    const promesas = copiaDiagrama.nodes.map(async n => {
      if (origen !== destino) {
        n.data.respuestas.forEach(async r => {
          try {
            const response = await axiosApi.post('/mc/traducir', {
              mensaje: r.texto,
              idioma: destino
            });
            const { data } = response;
            if (data.message === 'ok') {
              const traduccion = data.data;
              r.texto = traduccion;
            }
          } catch (e) {
            // console.log(e);
          }
        });
        try {
          const response = await axiosApi.post('/mc/traducir', {
            mensaje: n.data.mensaje,
            idioma: destino
          });
          const { data } = response;
          if (data.message === 'ok') {
            const traduccion = data.data;
            n.data.mensaje = traduccion;
          }
        } catch (e) {
          // console.log(e);
        }
      }
      return n;
    });
    await Promise.all(promesas);
    const nuevoTexto = _.cloneDeep(diagramaTextoNuevo);
    nuevoTexto[destino] = copiaDiagrama;
    setDiagramaTextoNuevo(nuevoTexto);
    setModalTranslate(false);
    setIdiomaActivo(destino);
    generarEsquema(copiaDiagrama);
  }

  function onDragEnd(result) {
    if (result.source.index !== result.destination.index) {
      const nuevoRespuestas = [...nodoEditable.data.respuestas];
      const [removed] = nuevoRespuestas.splice(result.source.index, 1);
      nuevoRespuestas.splice(result.destination.index, 0, removed);
      const nuevoInput = [...nodoEditable.inputs];
      const [moved] = nuevoInput.splice(result.source.index, 1);
      nuevoInput.splice(result.destination.index, 0, moved);

      const nuevoData = { ...nodoEditable.data };
      nuevoData.respuestas = nuevoRespuestas;
      setNodoEditable({ ...nodoEditable, data: nuevoData, inputs: nuevoInput });
    }
  }

  function filtrarListaIdiomas(value) {
    let filtrar = { ...listaTraducida };
    if (value === '') {
      filtrar = _.pickBy(filtrar, (i, k) => {
        return !idiomas[k];
      });
    } else {
      filtrar = _.pickBy(filtrar, (i, k) => {
        return i.toLowerCase().includes(value) && !idiomas[k];
      });
    }
    setListaFiltrada(filtrar);
  }

  function eliminarDiagrama() {
    let nuevoTexto = _.cloneDeep(diagramaTextoNuevo);
    nuevoTexto = _.omit(nuevoTexto, idiomaActivo);
    setDiagramaTextoNuevo(nuevoTexto);
    const keys = Object.keys(nuevoTexto);
    const nuevoIdiomas = _.pick(listaTraducida, keys);
    const nuevoElegibles = _.omit(listaTraducida, keys);
    setIdiomas(nuevoIdiomas);
    setIdiomaActivo(Object.keys(nuevoIdiomas)[0]);
    setListaElegibles(nuevoElegibles);
    generarEsquema(nuevoTexto[Object.keys(nuevoIdiomas)[0]]);
  }

  function anadirIdioma(nuevoIdioma) {
    const lang = _.pick(listaTraducida, nuevoIdioma);
    const nuevosIdiomas = { ...idiomas };
    nuevosIdiomas[nuevoIdioma] = lang[nuevoIdioma];
    const langElegibles = _.omit(listaElegibles, nuevoIdioma);
    setListaElegibles(langElegibles);
    setIdiomas(nuevosIdiomas);
    traducir(idiomaActivo, nuevoIdioma);
  }

  const traducirRespuestaRapida = async (value, destino) => {
    try {
      const response = await axiosApi.post('/mc/traducir', {
        mensaje: value,
        idioma: destino
      });
      const { data } = response;
      if (data.message === 'ok') {
        const traduccion = data.data;
        const copiaContenido = { ...contenido };
        copiaContenido[destino] = traduccion;
        setContenido(copiaContenido);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  function anadirIdiomaRR(nuevoIdioma) {
    const mensaje = Object.values(contenido)[0];
    const lang = _.pick(listaTraducida, nuevoIdioma);
    const nuevosIdiomasRR = { ...idiomasRR };
    nuevosIdiomasRR[nuevoIdioma] = lang[nuevoIdioma];
    setIdiomasRR(nuevosIdiomasRR);
    const nuevosIdiomas = { ...idiomasRR };
    if (!nuevosIdiomas[nuevoIdioma]) {
      nuevosIdiomas[nuevoIdioma] = lang[nuevoIdioma];
    }
    if (mensaje) traducirRespuestaRapida(mensaje, nuevoIdioma);
    else setContenido({ ...contenido, [nuevoIdioma]: '' });
  }

  function eliminarIdioma(clave) {
    let copiaContenido = { ...contenido };
    copiaContenido = _.omit(copiaContenido, clave);
    setContenido(copiaContenido);
  }

  function editarContenido(clave, mensaje) {
    const copiaContenido = { ...contenido };
    copiaContenido[clave] = mensaje;
    setContenido(copiaContenido);
  }

  function filtrarListaIdiomasRR(value) {
    let filtrar = { ...listaTraducida };
    if (value === '') {
      filtrar = _.pickBy(filtrar, (i, k) => {
        return !contenido[k];
      });
    } else {
      filtrar = _.pickBy(filtrar, (i, k) => {
        return i.toLowerCase().includes(value) && !contenido[k];
      });
    }
    setListaFiltradaRR(filtrar);
  }

  useEffect(
    () => {
      const rootElement = document.querySelector('#pickerContainer');
      if (rootElement) {
        const picker = createPicker({
          rootElement,
          categories: [
            'smileys-emotion',
            'people-body',
            'animals-nature',
            'food-drink',
            'travel-places',
            'activities',
            'objects',
            'symbols',
          ]
        });
        // The picker emits an event when an emoji is selected.
        // Do with it as you will!
        const objetivo = menuEmojis.split('Emojitooltip')[1];
        picker.addEventListener('emoji:select', event => {
          let texto = '';
          if (objetivo === 'Mensaje') {
            texto = `${nodoEditable.data.mensaje}${event.emoji}`;
            editarMensaje(texto);
          } else {
            const obj = parseInt(objetivo, 10);
            const index = nodoEditable.data.respuestas.findIndex(r => r.id === obj);
            texto = `${nodoEditable.data.respuestas[index].texto}${event.emoji}`;
            editarRespuesta(obj, texto);
          }
          setMenuEmojis('');
        });
      }
    }, [menuEmojis]
  );

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={t('Chatbot diagrams')}
          breadcrumbItems={[{ title: t('Messaging Center') }, { title: t('Chatbot diagrams') }]}
        />
        <Row style={{ minHeight: '720px', maxHeight: '720px' }}>
          <Col style={{ marginLeft: '1%', maxWidth: '92%', width: '92%' }}>
            <Nav className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto" style={{ marginBottom: '1%' }}>
              {Object.keys(idiomas).map(idioma => {
                return (
                  <NavItem key={idioma}>
                    <NavLink
                      className={idiomaActivo === idioma ? 'active' : ''}
                      onClick={() => cambiarIdiomaActivo(idioma)}
                    >
                      {t(idiomas[idioma])}
                    </NavLink>
                  </NavItem>
                );
              })}
              <NavItem id="añadir">
                <NavLink>
                  <i
                    className="bx bx-plus font-size-18"
                    id="anadirIdioma"
                    onClick={() => {
                      setMostrarAnadirIdioma(!mostrarAnadirIdioma);
                      setListaFiltrada(listaElegibles);
                    }}
                  />
                  <Popover isOpen={mostrarAnadirIdioma} target="anadirIdioma" className="añadirIdiomaClassFiltro">
                    <PopoverHeader className="añadirIdiomaClassFiltro">
                      <Input
                        type="text"
                        placeholder={t('Language')}
                        onChange={(e) => filtrarListaIdiomas(e.target.value.toLowerCase())}
                        className="añadirIdiomaClassFiltro"
                      />
                    </PopoverHeader>
                    <PopoverBody style={{ maxHeight: '200px', overflow: 'scroll' }} className="añadirIdiomaClassFiltro">
                      {listaFiltrada && Object.keys(listaFiltrada).map((i, index) => {
                        return (
                          <div
                            key={i}
                            onClick={() => {
                              anadirIdioma(i);
                              setMostrarAnadirIdioma(false);
                            }}
                            className="añadirIdiomaClassFiltro"
                            style={
                              index === Object.keys(listaFiltrada).length - 1 ?
                                { fontSize: '14px' } :
                                { marginBottom: '5%', fontSize: '14px' }
                            }
                          >
                            {listaFiltrada[i]}
                          </div>
                        );
                      })}
                    </PopoverBody>
                  </Popover>
                </NavLink>
              </NavItem>
            </Nav>
            <div
              style={{
                overflowX: 'scroll',
                overflowY: 'scroll',
                height: '100%',
                width: '100%',
              }}
              ref={zoneRef}
              onMouseUp={(event) => {
                if (event.target.className === '') {
                  forceUpdate();
                }
              }}
              onScroll={() => {
                if (!waitRefresh) {
                  setWaitRefresh(true);
                  setTimeout(() => {
                    forceUpdate();
                    setWaitRefresh(false);
                  }, 300);
                }
              }}
            >
              <Diagram
                schema={esquema}
                onChange={onChange}
                key={update}
                style={{
                  width: '200%',
                  height: '200%'
                }}
              />
            </div>
          </Col>
          <Col xs="1" style={{ marginLeft: '3%', marginRight: '2%', marginTop: '4%' }}>
            <Row style={{ marginBottom: '9%' }}>
              <Button
                onClick={() => anadirNodo()}
                color="success"
              >
                {t('Add node')}
              </Button>
            </Row>
            <Row style={{ marginBottom: '9%' }}>
              <Button
                onClick={() => setModalTranslate(true)}
                color="success"
              >
                {t('Translate')}
              </Button>
            </Row>
            <Row style={{ marginBottom: '9%' }}>
              <Button onClick={() => setMostrarPrueba(!mostrarPrueba)} color="success">
                {t('Previsualize chatbot')}
              </Button>
            </Row>
            <Row style={{ marginBottom: '8%', width: '100%', position: 'absolute', bottom: '12%' }}>
              <Button
                onClick={() => guardarDiagrama()}
                color="primary"
              >
                {t('Save')}
              </Button>
            </Row>
            <Row style={{ width: '100%', marginBottom: '4%', position: 'absolute', bottom: '6%' }}>
              <Button onClick={() => setModalCancelar(true)}>
                {t('Cancel')}
              </Button>
            </Row>
            <Row style={{ width: '100%', position: 'absolute', bottom: '0px' }}>
              <Button onClick={() => setModalEliminar(true)} color="danger" disabled={Object.keys(diagramaTextoNuevo).length < 4}>
                {t('Delete')}
              </Button>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '5%' }}>
          <Card body className="puntos">
            <div style={{ whiteSpace: 'break-spaces' }}>
              <Row style={{ margin: '5px' }}>
                <p>{t('Descripcion diagrama chatbot')}</p>
              </Row>
            </div>
          </Card>
        </Row>
        {mostrarPrueba && (
          <div
            style={{
              position: 'fixed',
              bottom: '15px',
              right: '15px',
              zIndex: '10000000000',
              boxShadow: '0px 0px 3px 3px lightgray',
              borderRadius: '20px'
            }}
          >
            <button
              type="button"
              onClick={() => {
                setMostrarPrueba(false);
              }}
              style={{
                background: 'none',
                border: '0',
                fontSize: '25px',
                position: 'absolute',
                top: '-1',
                right: '2px',
                width: '1em',
                height: '1em',
                zIndex: '2'
              }}
            >
              <span aria-hidden="true" style={{ color: 'black' }}>&times;</span>
            </button>
            <iframe
              src={`${process.env.REACT_APP_WEBCHAT_DOMAIN}/nuevo/chat?id=${idLocal}&region=europe`}
              title="chat"
              width="500"
              height="700"
              scrolling="no"
              style={{ overflow: 'hidden', borderRadius: '20px' }}
            />
          </div>
        )}
        <Offcanvas
          isOpen={nodoEditable !== null}
          toggle={() => {
            setNodoEditar(-1);
            setNodoEditable(null);
            setMenuEmojis('');
          }}
          direction="end"
          style={{ width: '30%', padding: '2%' }}
        >
          <div className="modal-header" style={{ marginBottom: '2%' }}>
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t('Edit node')}
            </h5>
            <button
              type="button"
              onClick={() => {
                setNodoEditar(-1);
                setNodoEditable(null);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {nodoEditable && (
              <>
                <Row>
                  <Row>
                    <Label>
                      {t('Message')}
                    </Label>
                  </Row>
                  <Row style={{ marginBottom: '4%' }}>
                    <Col>
                      <Input
                        type="textarea"
                        value={nodoEditable.data.mensaje}
                        // eslint-disable-next-line max-len
                        onChange={(event) => editarMensaje(event.target.value, 0, urlRedirect, timeoutRedirect)}
                        style={{ width: '96%' }}
                        placeholder={t('Write your text here')}
                      />
                      <i
                        className="mdi mdi-emoticon-happy-outline me-1"
                        id="EmojitooltipMensaje"
                        onClick={() => {
                          if (menuEmojis === 'EmojitooltipMensaje') setMenuEmojis('');
                          else setMenuEmojis('EmojitooltipMensaje');
                        }}
                        style={{
                          position: 'absolute',
                          top: '4px',
                          right: '0',
                          fontSize: '20px',
                          cursor: 'pointer'
                        }}
                      />
                      <Tooltip
                        placement="bottom-start"
                        target="EmojitooltipMensaje"
                        isOpen={menuEmojis === 'EmojitooltipMensaje'}
                        style={{ all: 'unset' }}
                      >
                        <div
                          style={{ display: 'block' }}
                          id="pickerContainer"
                        />
                      </Tooltip>
                      <UncontrolledTooltip placement="top" target="EmojitooltipMensaje">
                        {t('Emojis')}
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </Row>
                <Input
                  type="select"
                  onChange={(e) => {
                    setRadioCheck(parseInt(e.target.value, 10));
                    if (parseInt(e.target.value, 10) === 1) {
                      editarMensaje(respuestasRapidas[0].titulo, 1);
                    } else if (parseInt(e.target.value, 10) === 2) {
                      editarMensaje('', 2);
                    } else if (parseInt(e.target.value, 10) === 3) {
                      editarMensaje('', 3);
                    } else if (parseInt(e.target.value, 10) === 4) {
                      editarMensaje('', 4);
                    }
                  }}
                  style={{ marginBottom: '4%' }}
                  defaultValue={radioCheck}
                >
                  <option value={0}>
                    {t('Add options')}
                  </option>
                  <option value={1}>
                    {t('Send quick reply')}
                  </option>
                  <option value={2}>
                    {t('Contact with the hotel')}
                  </option>
                  <option value="3">
                    {t('Restart conversation')}
                  </option>
                  <option value="4">
                    {t('Redirect to URL')}
                  </option>
                </Input>
                {radioCheck === 0 && (
                <>
                  <DragDropContext onDragEnd={(r) => onDragEnd(r)}>
                    <div>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {nodoEditable.data.respuestas &&
                            nodoEditable.data.respuestas.map((respuesta, index) => {
                              return (
                                <Draggable
                                  draggableId={`${respuesta.id}`}
                                  key={`${respuesta.id}`}
                                  index={index}
                                >
                                  {(draggableProvided) => (
                                    <div
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      {...draggableProvided.dragHandleProps}
                                    >
                                      <Row
                                        style={{ display: 'flex', marginBottom: '4%' }}
                                      >
                                        <Col style={{ maxWidth: 'fit-content' }}>
                                          <i className="mdi mdi-dots-grid font-size-20" />
                                        </Col>
                                        <Col>
                                          <Input
                                            type="text"
                                            value={respuesta.texto}
                                            onChange={
                                              (e) => editarRespuesta(respuesta.id, e.target.value)
                                            }
                                            placeholder={t('Button text')}
                                            required
                                          />
                                        </Col>
                                        <Col style={{ display: 'flex', maxWidth: 'fit-content', marginLeft: '20px' }}>
                                          <i
                                            className="mdi mdi-emoticon-happy-outline me-1"
                                            id={`Emojitooltip${respuesta.id}`}
                                            onClick={() => {
                                              if (menuEmojis === `Emojitooltip${respuesta.id}`) setMenuEmojis('');
                                              else setMenuEmojis(`Emojitooltip${respuesta.id}`);
                                            }}
                                            style={{
                                              position: 'absolute',
                                              top: '4px',
                                              right: '25px',
                                              fontSize: '20px',
                                              cursor: 'pointer'
                                            }}
                                          />
                                          <Tooltip
                                            placement="bottom-start"
                                            target={`Emojitooltip${respuesta.id}`}
                                            isOpen={menuEmojis === `Emojitooltip${respuesta.id}`}
                                            style={{ all: 'unset' }}
                                          >
                                            <div
                                              style={{ display: 'block' }}
                                              id="pickerContainer"
                                            />
                                          </Tooltip>
                                          <UncontrolledTooltip placement="top" target={`Emojitooltip${respuesta.id}`}>
                                            {t('Emojis')}
                                          </UncontrolledTooltip>
                                          <i
                                            className="bx bx-trash"
                                            onClick={() => eliminarRespuesta(respuesta.id)}
                                            style={{
                                              color: 'red',
                                              position: 'absolute',
                                              top: '7px',
                                              right: '-8px',
                                              fontSize: '20px',
                                              cursor: 'pointer'
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </DragDropContext>
                  <Row style={{ marginTop: '4%', display: 'flex', justifyContent: 'center' }}>
                    <Button
                      color="success"
                      onClick={() => anadirRespuesta()}
                      style={{ width: '50%', display: 'flex', justifyContent: 'center' }}
                    >
                      {t('Add option')}
                    </Button>
                  </Row>
                </>
                )}
                {radioCheck === 1 && (
                  <Row>
                    {respuestasRapidas.length > 0 && (
                    <Col>
                      <Input
                        type="select"
                        onChange={(event) => {
                          setRRSeleccionada(event.target.value);
                          editarMensaje(event.target.value, 1);
                        }}
                        value={rrSeleccionada}
                      >
                        {respuestasRapidas.map(c => {
                          return (
                            <option
                              key={`respuesta${c.id}`}
                              value={c.titulo}
                            >
                              {c.titulo}
                            </option>
                          );
                        })}
                      </Input>
                    </Col>
                    )}
                    {respuestasRapidas.length === 0 && (
                    <Input
                      type="select"
                    >
                      <option>
                        {t('Write a quick reply first')}
                      </option>
                    </Input>
                    )}
                    <Row style={{ marginTop: '4%', display: 'flex', justifyContent: 'center' }}>
                      <Button
                        color="success"
                        onClick={() => setModalNuevaRespuesta(true)}
                        style={{ width: '50%', display: 'flex', justifyContent: 'center' }}
                      >
                        {t('Add new answer')}
                      </Button>
                    </Row>
                  </Row>
                )}
                {radioCheck === 4 && (
                  <>
                    <Input
                      type="text"
                      placeholder={t('Redirect to URL')}
                      onChange={(e) => {
                        setURLRedirect(e.target.value);
                        editarMensaje('', 4, e.target.value, timeoutRedirect);
                      }}
                      style={{ marginBottom: '15px' }}
                      value={urlRedirect}
                    />
                    <Input
                      type="number"
                      placeholder={t('Seconds before redirecting')}
                      onChange={(e) => {
                        setTimeoutRedirect(e.target.value);
                        editarMensaje('', 4, urlRedirect, e.target.value);
                      }}
                      value={timeoutRedirect}
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className="modal-footer" style={{ justifyContent: 'flex-start' }}>
            <button
              type="button"
              onClick={() => {
                setNodoEditar(-1);
                setNodoEditable(null);
                setMenuEmojis('');
              }}
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              {t('Close')}
            </button>
            <button
              onClick={() => {
                actualizarNodo();
                setNodoEditable(null);
                setNodoEditar(-1);
                setMenuEmojis('');
              }}
              type="button"
              className="btn btn-primary"
              style={{ marginLeft: '2%' }}
              disabled={
                nodoEditable && (
                  (radioCheck === 0 && (nodoEditable.data.respuestas.some(respuesta => {
                    return respuesta.texto === '';
                  }) || nodoEditable.data.respuestas.length === 0 || nodoEditable.data.mensaje === '')
                  ) || (radioCheck === 1 && nodoEditable.data.rr === '') ||
                  (radioCheck === 4 && (nodoEditable.data.url === ''))
                )
              }
            >
              {t('Save')}
            </button>
          </div>
        </Offcanvas>
        <Modal
          isOpen={modalNuevaRespuesta}
          toggle={() => {
            setModalNuevaRespuesta(!modalNuevaRespuesta);
            setMostrarAnadirIdioma(false);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t('New quick reply')}
            </h5>
            <button
              type="button"
              onClick={() => {
                setModalNuevaRespuesta(false);
              }}
              className="btn-close"
            />
          </div>
          <div className="modal-body">
            <form>
              <div className="mb-3">
                <label htmlFor="new-quick-reply-title" className="col-form-label">
                  {t('Title')}
                  :
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="new-quick-reply-title"
                  value={titulo}
                  onChange={event => {
                    setTitulo(event.target.value.toLowerCase());
                  }}
                />
                <div className="mb-3">
                  <label htmlFor="new-quick-reply-category" className="col-form-label">
                    {t('All categories')}
                    :
                  </label>
                  <select
                    className="form-select"
                    id="horizontal-Assigned-Input"
                    onChange={event => {
                      setNuevaCategoria(event.target.value.toLowerCase());
                    }}
                  >
                    <option value={0}>{t('New category')}</option>
                    {categorias.map(c => {
                      return (
                        <option
                          key={`categoria${c}`}
                          value={c}
                          selected={c === nuevaCategoria}
                        >
                          {c}
                        </option>
                      );
                    })}
                  </select>
                  {nuevaCategoria && nuevaCategoria === '0' && (
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control"
                        id="new-quick-reply-category"
                        value={categoria}
                        onChange={event => {
                          setCategoria(event.target.value.toLowerCase());
                        }}
                      />
                    </div>
                  )}
                </div>
                {Object.keys(contenido).length > 0 && Object.keys(contenido).map(c => {
                  return (
                    <div key={c}>
                      <label htmlFor="new-quick-reply-content" className="col-form-label">
                        {`${t('Content')} (${c.toUpperCase()})`}
                        {':   '}
                        <i className="bx bx-trash font-size-16" onClick={() => eliminarIdioma(c)} />
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="new-quick-reply-content"
                        rows={4}
                        value={contenido[c]}
                        onChange={event => {
                          editarContenido(c, event.target.value);
                        }}
                      />
                    </div>
                  );
                })}
                <Button
                  color="link"
                  onClick={() => {
                    setMostrarAnadirIdiomaRR(!mostrarAnadirIdiomaRR);
                    setListaFiltradaRR(listaTraducida);
                  }}
                  id="anadirIdiomaRR"
                >
                  {t('Add content')}
                </Button>
                <Popover isOpen={mostrarAnadirIdiomaRR} target="anadirIdiomaRR" className="añadirIdiomaClassFiltro">
                  <PopoverHeader className="añadirIdiomaClassFiltro">
                    <Input
                      type="text"
                      placeholder={t('Language')}
                      onChange={(e) => filtrarListaIdiomasRR(e.target.value.toLowerCase())}
                      className="añadirIdiomaClassFiltro"
                    />
                  </PopoverHeader>
                  <PopoverBody style={{ maxHeight: '200px', overflow: 'scroll' }} className="añadirIdiomaClassFiltro">
                    {listaElegiblesRR && Object.keys(listaElegiblesRR).map((i, index) => {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            anadirIdiomaRR(i);
                            setMostrarAnadirIdiomaRR(false);
                          }}
                          className="añadirIdiomaClassFiltro"
                          style={
                            index === Object.keys(listaFiltrada).length - 1 ?
                              { fontSize: '14px' } :
                              { marginBottom: '5%', fontSize: '14px' }
                          }
                        >
                          {listaFiltrada[i]}
                        </div>
                      );
                    })}
                  </PopoverBody>
                </Popover>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setTitulo('');
                setContenido('');
                setCategoria('');
                setNuevaCategoria('0');
                setModalNuevaRespuesta(false);
                setMostrarAnadirIdioma(false);
                setMenuEmojis('');
              }}
            >
              {t('Cancel')}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                guardaRespuesta();
                setMostrarAnadirIdioma(false);
              }}
              disabled={titulo === '' || contenido === '' || (nuevaCategoria === '0' && categoria === '')}
            >
              {t('Save')}
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={modalTranslate}
          toggle={() => {
            setModalTranslate(false);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t('Translate')}
            </h5>
            <button
              type="button"
              onClick={() => {
                setModalTranslate(false);
              }}
              className="btn-close"
            />
          </div>
          <Form>
            <div className="modal-body" style={{ display: 'flex', justifyContent: 'center' }}>
              <Row style={{ width: '100%' }}>
                <Col style={{ paddingRight: '5%' }}>
                  <Label>
                    {t('Translate from')}
                  </Label>
                  <Input type="select" onChange={(e) => setIdiomaOrigen(e.target.value)}>
                    {Object.keys(idiomas).map(idioma => {
                      return (
                        <option key={idioma} value={idioma}>
                          {t(idiomas[idioma])}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
                <Col style={{ paddingLeft: '5%' }}>
                  <Label>
                    {t('Translate to')}
                  </Label>
                  <Input type="select" onChange={(e) => setIdiomaDestino(e.target.value)}>
                    {Object.keys(idiomas).map(idioma => {
                      return (
                        <option key={idioma} value={idioma}>
                          {t(idiomas[idioma])}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setModalTranslate(false)}
              >
                {t('Cancel')}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  traducir(idiomaOrigen, idiomaDestino);
                }}
              >
                {t('Translate')}
              </button>
            </div>
          </Form>
        </Modal>
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t('Confirm')}
          cancelBtnText={t('Cancel')}
          title={t('Are you sure?')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            eliminarDiagrama();
            setModalEliminar(false);
          }}
          onCancel={() => setModalEliminar(false)}
          show={modalEliminar}
        >
          {t('Eliminar diagrama warning')}
        </SweetAlert>
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t('Confirm')}
          cancelBtnText={t('Cancel')}
          title={t('Are you sure?')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            cancelar();
            setModalCancelar(false);
          }}
          onCancel={() => setModalCancelar(false)}
          show={modalCancelar}
        >
          {t('Cancelar diagrama warning')}
        </SweetAlert>
        <Prompt when={!_.isEqual(diagramaTextoNuevo, diagramaTextoOrigen)} message={t('You have unsaved changes, are you sure you want to leave?')} />
      </div>
    </div>
  );
};

DiagramaChatbot.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(DiagramaChatbot);
