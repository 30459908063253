/* eslint-disable no-case-declarations */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  // Badge,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Modal,
  Spinner,
  Progress
} from 'reactstrap';

// import Charts
import { withTranslation } from 'react-i18next';

import axiosApi from 'helpers/apiResquests';

// Charts
import BarChartApex from 'pages/Charts/BarChartApex';
import SplineArea from '../Charts/SplineArea';
import Pie from '../Charts/Pie';

// iconos-redes-sociales
import accesoDirecto from '../../assets/images/iconos-redes-sociales/icono-acceso-directo.png';
import iconoMail from '../../assets/images/iconos-redes-sociales/icono-mail.png';
import iconoAuto from '../../assets/images/iconos-redes-sociales/icono-auto.png';
import iconoCHN from '../../assets/images/iconos-redes-sociales/icono-chn.png';
import iconoCSV from '../../assets/images/iconos-redes-sociales/icono-csv.png';
import iconoFB from '../../assets/images/iconos-redes-sociales/icono-facebook.png';
import iconoGPlus from '../../assets/images/iconos-redes-sociales/icono-gplus.png';
import iconoTwitter from '../../assets/images/iconos-redes-sociales/icono-twitter.png';
import iconoHuesped from '../../assets/images/iconos-redes-sociales/icono-huesped.svg';
import iconoInstagram from '../../assets/images/iconos-redes-sociales/icono-instagram.png';
import iconoLinkedin from '../../assets/images/iconos-redes-sociales/icono-linkedin.png';
import iconoMC from '../../assets/images/iconos-redes-sociales/icono-mc.png';
import iconoPMS from '../../assets/images/iconos-redes-sociales/icono-pms.png';
import iconoTlf from '../../assets/images/iconos-redes-sociales/icono-telefono.svg';
import iconoVK from '../../assets/images/iconos-redes-sociales/icono-vk.png';
import iconoWhatsapp from '../../assets/images/iconos-redes-sociales/icono-whatsapp.png';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import DateRangePicker from '../../components/Common/DateRangePicker';

const Estadisticas = props => {
  const opciones = {
    Number_of_wifi_connections: true,
    Number_of_registries: true,
    Last_connection: true,
    Last_feedback: true,
    Wifi_connections_vs_new_registries: true,
    Login_method: true,
    OS: true,
    Language: true,
    Nationality: true,
    Age: true,
    Gender: true,
  };

  const { t } = props;
  const [visibles, setVisibles] = useState(opciones);
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  const [startDate, setStartDate] = useState(currentDate.toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [opcionesModificadas, setOpcionesModificadas] = useState(opciones);
  const [progressBar, setProgressBar] = useState(0);
  // Estados para panel
  const [conexionesWifi, setConexionesWifi] = useState(0);
  const [conexionesWifiArray, setConexionesWifiArray] = useState([]);
  const [registros, setRegistros] = useState(0);
  const [registrosArray, setRegistrosArray] = useState([]);
  const [login, setLogin] = useState([]);
  const [os, setOS] = useState([]);
  const [language, setLanguage] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [age, setAge] = useState([]);
  const [gender, setGender] = useState([]);
  const [arrayDates, setArrayDates] = useState([]);
  const [modifyOpciones, setModifyOpciones] = useState(false);
  const [lastConnection, setLastConnection] = useState({});
  const [lastFeedback, setLastFeedback] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const coloresEdad = ['#6F6456', '#CB7E94', '#A1CFDD', '#B7D968', '#FF9C00', '#E94B30', '#8a3ab9', '#f74c98'];
  const coloresGenero = ['#5e8de4', '#f74c98', '#686868'];
  const coloresLogin = ['#3B5998', '#dd4b39', '#1da1f2', '#8a3ab9', '#2f81a9', '#db7a26', '#5cb85c', '#5c055c', '#f74c98', '#8a50F0', '#64DD17', '#f0cc1b', '#64B5F6', '#5D4037'];
  const coloresSO = ['#5e8de4', '#A1CFDD', '#ffa500', '#555555', '#5cb85c', '#D3D3D3', 'Red'];
  const idiomasString = ['Spanish', 'English', 'French', 'German', 'Others'];
  const nacionalidadString = ['Spanish', 'English', 'French', 'German', 'Others'];

  function generateDateArray() {
    const fechaInicio = new Date(startDate);
    const fechaFin = new Date(endDate);
    const dias = Math.round((fechaFin - fechaInicio) / (1000 * 60 * 60 * 24));

    const datesArray = [];
    for (let i = 0; i <= dias; i++) {
      const newDate = new Date(fechaInicio.getTime() + i * 1000 * 60 * 60 * 24);
      datesArray.push(`${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`);
    }
    setArrayDates(datesArray);
  }

  function prepareDataFromDatabaseToPie(data, colores) {
    const preparedData = [];
    for (let i = 0; i < data.length; i++) {
      const dato = data[i];
      const datoPreparado = [t(dato.label), dato.value, colores[i]];
      preparedData.push(datoPreparado);
    }
    return preparedData;
  }

  function prepareDataLanguages(idiomas) {
    const dictValores = { Spanish: 0, English: 0, French: 0, German: 0, Others: 0 };
    idiomas.forEach((idioma) => {
      switch (idioma.locale) {
        case 'es':
        case 'ES':
        case 'es_ES':
        case 'es_LA':
          dictValores.Spanish += parseInt(idioma.value, 10);
          break;
        case 'en':
        case 'en_US':
        case 'en_GB':
        case 'GB':
          dictValores.English += parseInt(idioma.value, 10);
          break;
        case 'fr':
        case 'FR':
        case 'fr_FR':
        case 'fr_CA':
          dictValores.French += parseInt(idioma.value, 10);
          break;
        case 'de_DE':
        case 'de':
        case 'DE':
          dictValores.German += parseInt(idioma.value, 10);
          break;
        default:
          dictValores.Others += parseInt(idioma.value, 10);
      }
    });

    const datos = [];

    for (let i = 0; i < 5; i++) {
      datos.push(dictValores[idiomasString[i]]);
    }
    setLanguage(datos);
  }

  function prepareDataNationalities(nacionalidades) {
    const dictValores = { Spanish: 0, English: 0, French: 0, German: 0, Others: 0 };
    nacionalidades.forEach((nacionalidad) => {
      switch (nacionalidad.nacionalidad) {
        case 'Spanish':
          dictValores.Spanish += parseInt(nacionalidad.value, 10);
          break;
        case 'English':
          dictValores.English += parseInt(nacionalidad.value, 10);
          break;
        case 'French':
          dictValores.French += parseInt(nacionalidad.value, 10);
          break;
        case 'German':
          dictValores.German += parseInt(nacionalidad.value, 10);
          break;
        default:
          dictValores.Others += parseInt(nacionalidad.value, 10);
      }
    });

    const datos = [];

    for (let i = 0; i < 5; i++) {
      datos.push(dictValores[nacionalidadString[i]]);
    }
    setNationality(datos);
  }

  function translateArray(array) {
    const translated = [];
    array.forEach(value => {
      translated.push(t(value));
    });

    return translated;
  }

  function generarSimbolo(viaVisita) {
    let tipoLoginArchivoIcono = '';

    if (viaVisita) {
      if (viaVisita === 'Facebook') {
        tipoLoginArchivoIcono = iconoFB;
      } else if (viaVisita === 'Google') {
        tipoLoginArchivoIcono = iconoGPlus;
      } else if (viaVisita === 'Twitter') {
        tipoLoginArchivoIcono = iconoTwitter;
      } else if (viaVisita === 'Linkedin') {
        tipoLoginArchivoIcono = iconoLinkedin;
      } else if (viaVisita === 'VK') {
        tipoLoginArchivoIcono = iconoVK;
      } else if (viaVisita === 'Instagram') {
        tipoLoginArchivoIcono = iconoInstagram;
      } else if (viaVisita === 'Boton-Directo') {
        tipoLoginArchivoIcono = accesoDirecto;
      } else if (viaVisita === 'Formulario') {
        tipoLoginArchivoIcono = iconoMail;
      } else if (viaVisita === 'Telefono') {
        tipoLoginArchivoIcono = iconoTlf;
      } else if (viaVisita.indexOf('CSV') > -1) {
        tipoLoginArchivoIcono = iconoCSV;
      } else if (viaVisita.indexOf('PMS') > -1) {
        tipoLoginArchivoIcono = iconoPMS;
      } else if (viaVisita.indexOf('CHECKIN') > -1) {
        tipoLoginArchivoIcono = iconoCHN;
      } else if (viaVisita.indexOf('MC') > -1) {
        tipoLoginArchivoIcono = iconoMC;
      } else if (viaVisita.indexOf('Whatsapp') > -1) {
        tipoLoginArchivoIcono = iconoWhatsapp;
      } else if (viaVisita === 'Auto') {
        tipoLoginArchivoIcono = iconoAuto;
      } else if (viaVisita.indexOf('AUTO:') > -1) {
        tipoLoginArchivoIcono = iconoAuto;
      } else if (viaVisita === 'HUESPED-PMS') {
        tipoLoginArchivoIcono = iconoHuesped;
      } else {
        return '-';
      }
    } else {
      return '-';
    }

    return (
      <img className="rounded" title={viaVisita} alt="" style={{ width: '30px', height: '30px' }} src={tipoLoginArchivoIcono} />
    );
  }

  const cargaDatos = async (indices = null) => {
    try {
      let progress = 0;
      let indicesCheck;
      let visitas;
      let incorporaciones;
      let ultimaconexion;
      let ultimofeedback;
      let dataEdad;
      let dataGenero;
      let dataLogin;
      let dataOS;
      let idiomas;
      let nacionalidades;
      if (indices === null) {
        const response = await axiosApi.get('/api/estadisticas/obtener');
        const data = JSON.parse(response.data.data);
        if (data) {
          indicesCheck = data;
        } else {
          indicesCheck = opciones;
        }
        setVisibles(indicesCheck);
        setOpcionesModificadas(indicesCheck);
      } else {
        indicesCheck = indices;
      }
      if (indicesCheck.Number_of_wifi_connections ||
        indicesCheck.Wifi_connections_vs_new_registries) {
        visitas = await axiosApi.get(`/api/estadisticas/visitas/${startDate}/${endDate}`);
      }
      progress += 10;
      setProgressBar(progress);
      if (indicesCheck.Number_of_registries || indicesCheck.Wifi_connections_vs_new_registries) {
        incorporaciones = await axiosApi.get(`/api/estadisticas/incorporaciones/${startDate}/${endDate}`);
      }
      progress += 10;
      setProgressBar(progress);
      if (indicesCheck.Last_connection) {
        ultimaconexion = await axiosApi.get('/api/estadisticas/ultimaconexion');
      }
      progress += 10;
      setProgressBar(progress);
      if (indicesCheck.Last_feedback) {
        ultimofeedback = await axiosApi.get('/api/estadisticas/ultimofeedback');
      }
      progress += 10;
      setProgressBar(progress);
      if (indicesCheck.Age || indicesCheck.Gender || indicesCheck.Login_method) {
        const data = await axiosApi.get(`/api/estadisticas/loginsexoedad/${startDate}/${endDate}`);
        dataEdad = prepareDataFromDatabaseToPie(data.data.data.dataEdad, coloresEdad);
        dataGenero = prepareDataFromDatabaseToPie(data.data.data.dataSexos, coloresGenero);
        dataLogin = prepareDataFromDatabaseToPie(data.data.data.dataTipoLogin, coloresLogin);
      }
      progress += 30;
      setProgressBar(progress);

      if (indicesCheck.OS) {
        const osData = await axiosApi.get(`/api/estadisticas/os/${startDate}/${endDate}`);
        console.log(osData.data.data);
        dataOS = prepareDataFromDatabaseToPie(osData.data.data, coloresSO);
      }
      progress += 10;
      setProgressBar(progress);

      if (indicesCheck.Language) {
        idiomas = await axiosApi.get(`/api/estadisticas/language/${startDate}/${endDate}`);
      }
      progress += 20;
      setProgressBar(progress);

      if (indicesCheck.Nationality) {
        nacionalidades = await axiosApi.get(`/api/estadisticas/nacionalidad/${startDate}/${endDate}`);
      }
      progress += 20;
      setProgressBar(progress);
      setConexionesWifi(visitas.data.visitas.totalPeriodo);
      setConexionesWifiArray(visitas.data.visitas.dataVisits);
      setRegistros(incorporaciones.data.incorporaciones.totalPeriodo);
      setRegistrosArray(incorporaciones.data.incorporaciones.dataIncorporaciones);
      setLastConnection(ultimaconexion.data.data[0]);
      setLastFeedback(ultimofeedback.data.data[0]);
      setAge(dataEdad);
      setGender(dataGenero);
      setLogin(dataLogin);
      setOS(dataOS);
      prepareDataLanguages(idiomas.data.data);
      prepareDataNationalities(nacionalidades.data.data);
    } catch (error) {
      console.log(error);
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
    setIsLoading(false);
    setProgressBar(0);
  };

  useEffect(() => {
    generateDateArray();
    cargaDatos();
  }, []);

  const filtrar = () => {
    setIsLoading(true);
    generateDateArray();
    cargaDatos(visibles);
  };

  document.title = `${t('Dashboard')} - ${t('Journeytok dashboard')}`;

  const handleRemove = (index) => {
    const copy = { ...visibles };
    copy[index] = false;
    setVisibles(copy);
    setOpcionesModificadas(copy);
  };

  const swapVisible = (index) => {
    console.log(index);
    const copy = { ...opcionesModificadas };
    copy[index] = !copy[index];
    console.log(copy);
    setOpcionesModificadas(copy);
  };

  function generatePieRow(data) {
    if (data.length > 0) {
      const rows = [];
      data.forEach((valor) => {
        const row = { name: valor[0], value: valor[1], color: valor[2] };
        rows.push(row);
      });
      return rows;
    }
    return [];
  }

  function cancel() {
    setModifyOpciones(false);
    setOpcionesModificadas(visibles);
  }

  async function saveConfig() {
    setModifyOpciones(false);
    setVisibles(opcionesModificadas);
    const response = await axiosApi.post(`/api/estadisticas/guardar/${JSON.stringify(opcionesModificadas)}`);
    if (response.data.error) {
      // error
    }
    cargaDatos();
  }

  const setDates = (event) => {
    setStartDate(event[0]?.toISOString());
    setEndDate(event[1]?.toISOString());
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t('Journeytok Dashboard')}
            breadcrumbItems={[{ title: 'Smart Wifi & Analytics' }, { title: t('Dashboard') }]}
          />
          <Row>
            <Col className="mb-2">
              <button type="button" className="bx bx-cog border-0 font-size-20" style={{ backgroundColor: '#f8f9fa' }} onClick={() => setModifyOpciones(true)} />
            </Col>
            <Col lg="6" style={{ display: 'flex', alignItems: 'right', justifyContent: 'right', marginBottom: '1%' }}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setDates={setDates}
                filtrar={filtrar}
              />
            </Col>
          </Row>
          <Row>
            { visibles.Number_of_wifi_connections && (
            <Col lg="3">
              <Card style={{ height: '85%' }}>
                <CardBody className="mini-stats-wid">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        {t('Number_of_wifi_connections')}
                      </p>
                      <h3>
                        {conexionesWifi}
                      </h3>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <button type="button" className="bx close" onClick={() => handleRemove('Number_of_wifi_connections')} />
                      </span>
                    </div>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary" style={{ marginTop: '4%' }}>
                      <span className="avatar-title">
                        <i className="bx bx-wifi font-size-24" />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
            { visibles.Number_of_registries && (
            <Col lg="3">
              <Card className="mini-stats-wid" style={{ height: '85%' }}>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        {t('Number_of_registries')}
                      </p>
                      <h3>
                        {registros}
                      </h3>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <button type="button" className="bx close" onClick={() => handleRemove('Number_of_registries')} />
                      </span>
                    </div>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary" style={{ marginTop: '4%' }}>
                      <span className="avatar-title">
                        <i className="bx bxs-user-badge font-size-24" />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
            { visibles.Last_connection && (
            <Col lg="3">
              <Card style={{ height: '85%' }}>
                <CardBody className="mini-stats-wid">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        {t('Last_connection')}
                      </p>
                      {lastConnection ? (
                        <>
                          <h5>
                            {lastConnection.fecha_hora}
                          </h5>
                          <h5>
                            {lastConnection.nombre}
                          </h5>
                          <h5>
                            {'Tipo de login: '}
                            {generarSimbolo(lastConnection.via_visita)}
                          </h5>
                        </>
                      ) : <h5>{t('No data found')}</h5>}
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <button type="button" className="bx close" onClick={() => handleRemove('Last_connection')} />
                      </span>
                    </div>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary" style={{ marginTop: '4%' }}>
                      <span className="avatar-title">
                        <i className="bx bxs-user-badge font-size-24" />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
            { visibles.Last_feedback && (
            <Col lg="3">
              <Card style={{ height: '85%' }}>
                <CardBody className="mini-stats-wid">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        {t('Last_feedback')}
                      </p>
                      <h5>
                        {lastFeedback ? lastFeedback.momento_opinion : ''}
                      </h5>
                      <h5>
                        {lastFeedback ? lastFeedback.nombre : t('No data found')}
                      </h5>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <button type="button" className="bx close" onClick={() => handleRemove('Last_feedback')} />
                      </span>
                    </div>
                    <br />
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary" style={{ marginTop: '4%' }}>
                      <span className="avatar-title">
                        <i className="bx bxs-comment-error font-size-24" />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
          </Row>
          <Row>
            { visibles.Wifi_connections_vs_new_registries && (
            <Col lg="7">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{t('Wifi_connections_vs_new_registries')}</CardTitle>
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <button type="button" className="bx close" onClick={() => (handleRemove('Wifi_connections_vs_new_registries'))} />
                    </span>
                  </div>
                  <div id="doughnut-chart" className="e-chart">
                    <SplineArea
                      dateArray={arrayDates}
                      data={[
                        { name: t('Number_of_registries'), values: registrosArray, sum: registros, color: '#00af87' },
                        { name: t('Number_of_wifi_connections'), values: conexionesWifiArray, sum: conexionesWifi, color: '#f4c20d' }
                      ]}
                      toolbar
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
            { visibles.Login_method && (
            <Col lg="5">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{t('Login_method')}</CardTitle>
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <button type="button" className="bx close" onClick={() => (handleRemove('Login_method'))} />
                    </span>
                  </div>
                  <div id="pie-chart" className="e-chart">
                    <Pie
                      title={t('Login_method')}
                      data={generatePieRow(login)}
                      toolbar
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
            { visibles.OS && (
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{t('OS')}</CardTitle>
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <button type="button" className="bx close" onClick={() => (handleRemove('OS'))} />
                    </span>
                  </div>
                  <div id="pie-chart" className="e-chart">
                    <Pie
                      title={t('OS')}
                      data={generatePieRow(os)}
                      toolbar
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
            { visibles.Language && (
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{t('Language')}</CardTitle>
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <button type="button" className="bx close" onClick={() => (handleRemove('Language'))} />
                    </span>
                  </div>
                  <div id="pie-chart" className="e-chart">
                    <BarChartApex
                      title={t('Language')}
                      data={language}
                      categories={translateArray(idiomasString)}
                      horizontal
                      toolbar
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
            { visibles.Nationality && (
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{t('Nationality')}</CardTitle>
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <button type="button" className="bx close" onClick={() => (handleRemove('Nationality'))} />
                    </span>
                  </div>
                  <div id="pie-chart" className="e-chart">
                    <BarChartApex
                      title={t('Nationalyty')}
                      data={nationality}
                      categories={translateArray(nacionalidadString)}
                      horizontal
                      toolbar
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
            { visibles.Age && (
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{t('Age')}</CardTitle>
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <button type="button" className="bx close" onClick={() => (handleRemove('Age'))} />
                    </span>
                  </div>
                  <div id="pie-chart" className="e-chart">
                    <Pie
                      title={t('Age')}
                      data={generatePieRow(age)}
                      completo
                      toolbar
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
            { visibles.Gender && (
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{t('Gender')}</CardTitle>
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <button type="button" className="bx close" onClick={() => (handleRemove('Gender'))} />
                    </span>
                  </div>
                  <div id="pie-chart" className="e-chart">
                    <Pie
                      title={t('Gender')}
                      data={generatePieRow(gender)}
                      completo
                      toolbar
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            )}
          </Row>
          <Row>
            <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Journeytok dashboard info title')}</span>
                  <br />
                  {t('Journeytok dashboard info text')}
                  <br />
                  {/* <a target="_blank" href="http://helpcenter.journeytok.com/es/articles/5849058-alertas-de-feedback" rel="noreferrer">{t('Read more')}</a> */}
                </CardText>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modifyOpciones}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel" style={{ textAlign: 'center' }}>
            {t('Modify options')}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModifyOpciones(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <br />
        <Row>
          <Col style={{ marginLeft: '15%' }}>
            <h2 style={{ marginTop: '5px', textAlign: 'center' }}>{t('Dashboard')}</h2>
          </Col>
          <Col style={{ marginRight: '15%' }}>
            <h2 style={{ marginTop: '5px', textAlign: 'center' }}>{t('Not in use')}</h2>
          </Col>
        </Row>
        <Row>
          <Col className="border" style={{ marginLeft: '15%' }}>
            <br />
            {Object.keys(opciones).map((opcion) => (
              <Row key={opcion}>
                {opcionesModificadas[opcion] && (
                <p className="font-size-16" style={{ display: 'flex', alignItems: 'center', }}>
                  <span className="border border-dark bg-light" style={{ paddingLeft: '4px', paddingRight: '4px' }}>{`${t(opcion)}`}</span>
                  <i className="bx bx-x font-size-24 border border-dark bg-light" onClick={() => swapVisible(opcion)} />
                </p>
                )}
              </Row>
            ))}
          </Col>
          <Col className="border" style={{ marginRight: '15%' }}>
            <br />
            {Object.keys(opciones).map((opcion) => (
              <Row key={opcion}>
                {!opcionesModificadas[opcion] && (
                <p className="font-size-16" style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                  <i className="bx bx-left-arrow-alt font-size-24 border border-dark bg-light" onClick={() => swapVisible(opcion)} />
                  <span className="border border-dark bg-light" style={{ paddingLeft: '4px', paddingRight: '4px' }}>{`${t(opcion)}`}</span>
                </p>
                )}
              </Row>
            ))}
          </Col>
        </Row>
        <br />
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              cancel();
            }}
          >
            {t('Cancel')}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              saveConfig(false);
            }}
          >
            {t('Save')}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isLoading}
        centered
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5%' }}>
          <h3 style={{ marginRight: '2%' }}>{t('Loading data')}</h3>
          <Spinner />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5%' }}>
          <Progress
            value={progressBar}
            color="primary"
            style={{ width: '75%' }}
            animated
          />
        </div>
      </Modal>
    </>
  );
};

Estadisticas.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Estadisticas);
