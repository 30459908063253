import i18n from 'i18next';

import listaPaises from './listaPaises.json';

function traducirListaPaises() {
  const nuevaLista = {};
  Object.keys(listaPaises).forEach(clave => {
    nuevaLista[clave] = i18n.t(listaPaises[clave]);
  });
  return nuevaLista;
}

const listaTraducidaPaises = traducirListaPaises();

export default listaTraducidaPaises;
