import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './dashboard.scss';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  FormFeedback,
  Input
} from 'reactstrap';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import axiosApi from '../../helpers/apiResquests';
// i18n
import i18n from '../../i18n';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const PerfilUsuario = props => {
  const { t } = props;

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  // Form validation
  const validationUserData = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: '',
      nombre: '',
      apellido: '',
      email: '',
      telefono: '',
      idioma: '',
    },
    validationSchema: Yup.object().shape({
      nombre: Yup.string().required('This value is required'),
      apellido: Yup.string().required('This value is required'),
      idioma: Yup.string().required('This value is required'),
      email: Yup.string().email('Must be a valid Email').max(255).required('Email is required'),
      telefono: Yup.string().matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Enter a valid phone number!'
      ).required('Please Enter Your Number')
    }),
    onSubmit: async (values) => {
      const { idioma } = values;
      try {
        i18n.changeLanguage(idioma);
        localStorage.setItem('I18N_LANGUAGE', idioma);
        const response = await axiosApi.post('/api/userdata', values);
        const { data } = response;
        if (data.err) {
          throw Error('Error');
        }
        const title = t('User data saved');
        const message = t('Your personal data have been modified');
        showToast(title, message, 'success');
      } catch (error) {
        console.log(error);
        const title = t('Error saving user data');
        const message = t('Your personal data have not been modified');
        showToast(title, message, 'error');
      }
    }
  });
  const validationPassword = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: '',
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required(t('VALUE_REQUIRED')),
      password: Yup.string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\d\w\W].*$/,
          'Enter a valid password!'
        )
        .required(t('PASSWORD_NOT_VALID')),
      passwordConfirmation: Yup.string().when('password', {
        is: val => !!(val && val.length > 0),
        then: Yup.string().oneOf([Yup.ref('password')], t('PASSWORD_NOT_MATCH'))
      })
    }),
    onSubmit: async values => {
      const { id } = validationUserData.values;
      try {
        const response = await axiosApi.post('/api/changepassword', { ...{ id }, ...values });
        const { data } = response;
        if (data.err) {
          throw Error(data.mensaje);
        }
        const title = t('User data saved');
        const message = t('Your password have been modified');
        showToast(title, message, 'success');
      } catch (error) {
        console.log(error);
        const title = t('Error saving user data');
        let message = t('Your password have not been modified');
        if (error.message) {
          message = t(error.message);
        }
        showToast(title, message, 'error');
      }
    }
  });

  const cargaDatos = async () => {
    try {
      const response = await axiosApi.get('/api/userdata');
      const { data } = response;
      const usuario = data;
      validationUserData.setFieldValue('id', usuario.id);
      validationUserData.setFieldValue('nombre', usuario.nombre);
      validationUserData.setFieldValue('apellido', usuario.apellido);
      validationUserData.setFieldValue('email', usuario.email);
      validationUserData.setFieldValue('telefono', usuario.telefono);
      validationUserData.setFieldValue('idioma', usuario.idioma);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  document.title = `${t('Edit user profile')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={{ size: 6, offset: 3 }}>
              <Breadcrumbs title={t('Journeytok')} breadcrumbItem={t('User profile')} />
            </Col>
          </Row>
          <Row>
            <Col lg={{ size: 6, offset: 3 }}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{t('User data')}</CardTitle>

                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validationUserData.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="row mb-4">
                      <Col sm={8}>
                        <Input
                          name="id"
                          type="hidden"
                          className="form-control"
                          id="horizontal-id-Input"
                          value={validationUserData.values.id || ''}
                        />
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('First name')}
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          name="nombre"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          onChange={validationUserData.handleChange}
                          onBlur={validationUserData.handleBlur}
                          value={validationUserData.values.nombre || ''}
                          invalid={
                            !!(
                              validationUserData.touched.nombre && validationUserData.errors.nombre
                            )
                          }
                        />
                        {validationUserData.touched.nombre && validationUserData.errors.nombre ? (
                          <FormFeedback type="invalid">
                            {validationUserData.errors.nombre}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-lastname-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('Last name')}
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          name="apellido"
                          className="form-control"
                          id="horizontal-lastname-Input"
                          onChange={validationUserData.handleChange}
                          onBlur={validationUserData.handleBlur}
                          value={validationUserData.values.apellido || ''}
                          invalid={
                            !!(
                              validationUserData.touched.apellido &&
                              validationUserData.errors.apellido
                            )
                          }
                        />
                        {validationUserData.touched.apellido &&
                        validationUserData.errors.apellido ? (
                          <FormFeedback type="invalid">
                            {validationUserData.errors.apellido}
                          </FormFeedback>
                          ) : null}
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label htmlFor="horizontal-email-Input" className="col-sm-4 col-form-label">
                        {t('Email')}
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="email"
                          name="email"
                          className="form-control"
                          id="horizontal-email-Input"
                          onChange={validationUserData.handleChange}
                          onBlur={validationUserData.handleBlur}
                          value={validationUserData.values.email || ''}
                          invalid={
                            !!(validationUserData.touched.email && validationUserData.errors.email)
                          }
                        />
                        {validationUserData.touched.email && validationUserData.errors.email ? (
                          <FormFeedback type="invalid">
                            {validationUserData.errors.email}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label htmlFor="horizontal-phone-Input" className="col-sm-4 col-form-label">
                        {t('Phone')}
                      </Label>
                      <Col sm={8}>
                        <Input
                          name="telefono"
                          type="number"
                          className="form-control"
                          id="horizontal-phone-Input"
                          onChange={validationUserData.handleChange}
                          onBlur={validationUserData.handleBlur}
                          value={validationUserData.values.telefono || ''}
                          invalid={
                            !!(
                              validationUserData.touched.telefono &&
                              validationUserData.errors.telefono
                            )
                          }
                        />
                        {validationUserData.touched.telefono &&
                        validationUserData.errors.telefono ? (
                          <FormFeedback type="invalid">
                            {validationUserData.errors.telefono}
                          </FormFeedback>
                          ) : null}
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-language-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('Language')}
                      </Label>
                      <Col sm={8}>
                        <select
                          className="form-select"
                          id="horizontal-language-Input"
                          onChange={event => {
                            validationUserData.setFieldValue(
                              'idioma',
                              event.target.value
                            );
                          }}
                          value={validationUserData.values.idioma || 'es'}
                        >
                          <option value="en">
                            {'EN '}
                            {t('English')}
                          </option>
                          <option value="es">
                            {'ES '}
                            {t('Spanish')}
                          </option>
                          <option value="pt">
                            {'PT '}
                            {t('Portuguese')}
                          </option>
                        </select>
                        {validationUserData.touched.idioma && validationUserData.errors.idioma ? (
                          <FormFeedback type="invalid">
                            {validationUserData.errors.idioma}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div>

                    <div className="row justify-content-end">
                      <Col sm={8}>
                        <div>
                          <Button type="submit" color="success" className="w-md">
                            {t('Save')}
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end row  */}

          <Row>
            <Col lg={{ size: 6, offset: 3 }}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{t('Change password')}</CardTitle>

                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validationPassword.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-current-password-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('Current password')}
                      </Label>
                      <Col sm={8}>
                        <Input
                          name="oldPassword"
                          type="password"
                          className="form-control"
                          id="horizontal-current-password-Input"
                          onChange={validationPassword.handleChange}
                          onBlur={validationPassword.handleBlur}
                          value={validationPassword.values.oldPassword || ''}
                          invalid={
                            !!(
                              validationPassword.touched.oldPassword &&
                              validationPassword.errors.oldPassword
                            )
                          }
                        />
                        {validationPassword.touched.oldPassword &&
                        validationPassword.errors.oldPassword ? (
                          <FormFeedback type="invalid">
                            {validationPassword.errors.oldPassword}
                          </FormFeedback>
                          ) : null}
                      </Col>
                    </div>

                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-password-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('New password')}
                      </Label>
                      <Col sm={8}>
                        <Input
                          name="password"
                          type="password"
                          className="form-control"
                          id="horizontal-password-Input"
                          onChange={validationPassword.handleChange}
                          onBlur={validationPassword.handleBlur}
                          value={validationPassword.values.password || ''}
                          invalid={
                            !!(
                              validationPassword.touched.password &&
                              validationPassword.errors.password
                            )
                          }
                        />
                        {validationPassword.touched.password &&
                        validationPassword.errors.password ? (
                          <FormFeedback type="invalid">
                            {validationPassword.errors.password}
                          </FormFeedback>
                          ) : null}
                      </Col>
                    </div>

                    <div className="row mb-4">
                      <Label
                        htmlFor="horizontal-password-confirmation-Input"
                        className="col-sm-4 col-form-label"
                      >
                        {t('Confirm password')}
                      </Label>
                      <Col sm={8}>
                        <Input
                          name="passwordConfirmation"
                          type="password"
                          className="form-control"
                          id="horizontal-password-confirmation-Input"
                          onChange={validationPassword.handleChange}
                          onBlur={validationPassword.handleBlur}
                          value={validationPassword.values.passwordConfirmation || ''}
                          invalid={
                            !!(
                              validationPassword.touched.passwordConfirmation &&
                              validationPassword.errors.passwordConfirmation
                            )
                          }
                        />
                        {validationPassword.touched.passwordConfirmation &&
                        validationPassword.errors.passwordConfirmation ? (
                          <FormFeedback type="invalid">
                            {validationPassword.errors.passwordConfirmation}
                          </FormFeedback>
                          ) : null}
                      </Col>
                    </div>

                    <div className="row justify-content-end">
                      <Col sm={8}>
                        <div>
                          <Button type="submit" color="success" className="w-md">
                            {t('Save password')}
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </>
  );
};

PerfilUsuario.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(PerfilUsuario);
