import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
  UncontrolledTooltip,
  Modal,
  Input,
  FormFeedback
  /* CardText */
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';
import { utils, writeFileXLSX } from 'xlsx';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import es from 'react-phone-input-2/lang/es.json';
import pt from 'react-phone-input-2/lang/pt.json';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactSelect from 'react-select';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import moment from 'moment';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

import currencySymbol from '../../common/currencySymbol.json';
import CabeceraListas from '../../components/Common/CabeceraListas';

const store = require('store');
const eventPlugin = require('store/plugins/events');

store.addPlugin(eventPlugin);

const ListadoReservas = props => {
  const { t } = props;

  const [reservas, setReservas] = useState([]);
  const [reservasVisibles, setReservasVisibles] = useState([]);
  const [start, setStart] = useState(0);
  const [len, setLen] = useState(50);
  const [size, setSize] = useState(10);
  const [totalReservas, setTotalReservas] = useState(0);
  const [pagina, setPagina] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [filtros, setFiltros] = useState({});
  const [filtroActivo, setFiltroActivo] = useState('');
  const [busquedaManual, setBusquedaManual] = useState('');
  const [cargaInicial, setCargaInicial] = useState(true);
  const [modalInstantaneo, setModalInstantaneo] = useState(false);
  const [telefonoSinPrefijo, setTelefonoSinPrefijo] = useState();
  const [nombrePlantilla, setNombrePlantilla] = useState({});
  const [idiomaPlantilla, setIdiomaPlantilla] = useState({});
  const [camposEnvioUnico, setCamposEnvioUnico] = useState([]);
  const [expandirEnvio, setExpandirEnvio] = useState(false);
  const [camposEnviar, setCamposEnviar] = useState([]);
  const [buscaReserva, setBuscaReserva] = useState([]);
  const [idReservaInvalida, setIdReservaInvalida] = useState(false);
  const [campoRequerido, setCampoRequerido] = useState([]);
  const [idUsuarioEnviar, setIdUsuarioEnvia] = useState();
  const [idiomaTlf, setIdiomaTlf] = useState();
  const [modalErrorInstantaneo, setModalErrorInstantaneo] = useState(false);
  const [plantillas, setPlantillas] = useState({});
  const [componentesPlantilla, setComponentesPlantilla] = useState();
  const [dicNombreIdiomasPlantilla, setDicNombreIdiomasPlantilla] = useState({});
  const [opcionesPlantillas, setOpcionesPlantillas] = useState({});
  const [opcionesIdiomas, setOpcionesIdiomas] = useState({});
  const [funcionalidades, setFuncionalidades] = useState(store.get('funcionalidades'));
  const [idReservaEnviar, setIdReservaEnviar] = useState('');
  const [tlfEnviar, setTlfEnviar] = useState('');

  store.watch('funcionalidades', () => {
    setFuncionalidades(store.get('funcionalidades'));
  });

  function htmlEntities(str) {
    return String(str).replaceAll('&ntilde;', 'ñ')
      .replaceAll('&Ntilde;', 'Ñ')
      .replaceAll('&amp;', '&')
      .replaceAll('&ntilde;', 'ñ')
      .replaceAll('&Agrave;', 'À')
      .replaceAll('&Aacute;', 'Á')
      .replaceAll('&Acirc;', 'Â')
      .replaceAll('&Atilde;', 'Ã')
      .replaceAll('&Auml;', 'Ä')
      .replaceAll('&Aring;', 'Å')
      .replaceAll('&AElig;', 'Æ')
      .replaceAll('&Ccedil;', 'Ç')
      .replaceAll('&Egrave;', 'È')
      .replaceAll('&Eacute;', 'É')
      .replaceAll('&Ecirc;', 'Ê')
      .replaceAll('&Euml;', 'Ë')
      .replaceAll('&Igrave;', 'Ì')
      .replaceAll('&Iacute;', 'Í')
      .replaceAll('&Icirc;', 'Î')
      .replaceAll('&Iuml;', 'Ï')
      .replaceAll('&ETH;', 'Ð')
      .replaceAll('&Ograve;', 'Ò')
      .replaceAll('&Oacute;', 'Ó')
      .replaceAll('&Ocirc;', 'Ô')
      .replaceAll('&Otilde;', 'Õ')
      .replaceAll('&Ouml;', 'Ö')
      .replaceAll('&Oslash;', 'Ø')
      .replaceAll('&Ugrave;', 'Ù')
      .replaceAll('&Uacute;', 'Ú')
      .replaceAll('&Ucirc;', 'Û')
      .replaceAll('&Uuml;', 'Ü')
      .replaceAll('&Yacute;', 'Ý')
      .replaceAll('&THORN;', 'Þ')
      .replaceAll('&szlig;', 'ß')
      .replaceAll('&agrave;', 'à')
      .replaceAll('&aacute;', 'á')
      .replaceAll('&acirc;', 'â')
      .replaceAll('&atilde;', 'ã')
      .replaceAll('&auml;', 'ä')
      .replaceAll('&aring;', 'å')
      .replaceAll('&aelig;', 'æ')
      .replaceAll('&ccedil;', 'ç')
      .replaceAll('&egrave;', 'è')
      .replaceAll('&eacute;', 'é')
      .replaceAll('&ecirc;', 'ê')
      .replaceAll('&euml;', 'ë')
      .replaceAll('&igrave;', 'ì')
      .replaceAll('&iacute;', 'í')
      .replaceAll('&icirc;', 'î')
      .replaceAll('&iuml;', 'ï')
      .replaceAll('&eth;', 'ð')
      .replaceAll('&ograve;', 'ò')
      .replaceAll('&oacute;', 'ó')
      .replaceAll('&ocirc;', 'ô')
      .replaceAll('&otilde;', 'õ')
      .replaceAll('&ouml;', 'ö')
      .replaceAll('&oslash;', 'ø')
      .replaceAll('&ugrave;', 'ù')
      .replaceAll('&uacute;', 'ú')
      .replaceAll('&ucirc;', 'û')
      .replaceAll('&uuml;', 'ü')
      .replaceAll('&yacute;', 'ý')
      .replaceAll('&thorn;', 'þ')
      .replaceAll('&yuml;', 'ÿ');
  }

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function togInstantaneo() {
    setModalInstantaneo(!modalInstantaneo);
    removeBodyCss();
  }

  const cargaPlaceholderCamp = async (camp, idioma) => {
    try {
      const datos = await axiosApi.post('/api/campwhatsapp/getPlaceholderCamp', { camp, idioma });
      const variablesPlantillas = JSON.parse(
        datos.data.datos[0].estructura_placeholder_template_whatsapp
      );
      const campos = Object.keys(variablesPlantillas).map(v => {
        if (v.includes('[[neocheckin_userlang]]')) return 'enlace_checkin';
        return v;
      });
      const nuevosCamposEnviar = Array(Object.keys(variablesPlantillas).length).fill('');
      if (variablesPlantillas.id_pms_show) {
        setBuscaReserva(true);
        const index = campos.indexOf('id_pms_show');
        nuevosCamposEnviar[index] = idReservaEnviar;
      } else setBuscaReserva(false);
      setTelefonoSinPrefijo(tlfEnviar);
      setCamposEnviar(nuevosCamposEnviar);
      setCamposEnvioUnico(campos);
      setCampoRequerido(Array(campos.length).fill(false));
      setModalInstantaneo(true);
    } catch (e) {
      // console.log(e);
      setModalErrorInstantaneo(true);
      setNombrePlantilla({});
      setIdiomaPlantilla({});
    }
  };

  const enviarTemplate = async (name, components, language) => {
    const datos = {
      name,
      components,
      language,
      phone: telefonoSinPrefijo,
      parametros: camposEnviar,
      id_usuarios: idUsuarioEnviar || ''
    };
    try {
      const response = await axiosApi.post('/api/whatsapp/unico', datos);
      const { data } = response;
      const { enviado } = data;
      if (enviado) {
        showToast(t('Whatsapp unique'), t('The unique message has been sent successfully'), 'success');
      } else {
        showToast(t('Whatsapp unique'), t('The unique message has not been sent'), 'error');
      }
      setIdUsuarioEnvia();
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const buscarDatosCliente = async () => {
    let idReserva = false;
    try {
      const telefono = telefonoSinPrefijo || '';
      const index = camposEnvioUnico.findIndex(i => i === 'id_pms_show');
      let res = '';
      if (index !== -1) {
        res = camposEnviar[index];
        idReserva = true;
      }
      const response = await axiosApi.post('/api/campwhatsapp/getDatosPlaceholder', { telefono, res });
      const datos = response.data.datos[0];
      const copiaCampos = [...camposEnviar];
      camposEnvioUnico.forEach((c, i) => {
        let valor = '';
        switch (c) {
          case 'first_name':
          case 'username':
            valor = datos.nombre;
            break;
          case 'fecha_inicio':
          case 'checkin_date':
            valor = datos.fecha_inicio;
            break;
          case 'fecha_fin':
          case 'checkout_date':
            valor = datos.fecha_fin;
            break;
          case 'adultos':
          case 'adults':
            valor = datos.adultos;
            break;
          case 'menores':
          case 'children':
            valor = datos.menores;
            break;
          case 'room_type':
          case 'tipo':
            break;
          case 'payment_link':
            valor = `${process.env.REACT_APP_WEBCHAT_DOMAIN}/nuevo/pago?pay=${datos.enlace}&bookin=${datos.id_reservas}`;
            break;
          case 'checkin_link':
            valor = datos.enlace_checkin;
            break;
          default:
            valor = copiaCampos[i];
            break;
        }
        copiaCampos[i] = valor;
      });
      if (datos.telefono) {
        setTelefonoSinPrefijo(datos.telefono);
      }
      setIdUsuarioEnvia(datos.id_usuarios);
      setCamposEnviar(copiaCampos);
      setIdReservaInvalida(false);
    } catch (e) {
      // console.log(e);
      setIdReservaInvalida(true);
      idReserva = false;
    } finally {
      if (!buscaReserva || idReserva) setExpandirEnvio(true);
    }
  };

  useEffect(() => {
    if (nombrePlantilla.value !== '' && idiomaPlantilla.value !== '' && nombrePlantilla.value && idiomaPlantilla.value) {
      cargaPlaceholderCamp(nombrePlantilla.value, idiomaPlantilla.value);
      const p = plantillas.filter(
        temp => temp.name === nombrePlantilla.value && temp.language === idiomaPlantilla.value
      )[0];
      setComponentesPlantilla(p.components);
    } else if (nombrePlantilla.value && nombrePlantilla.value !== '') {
      const nuevosIdiomas = dicNombreIdiomasPlantilla[nombrePlantilla.value].split(',').map(i => {
        return { label: i, value: i };
      });
      setOpcionesIdiomas(nuevosIdiomas);
    }
  }, [nombrePlantilla, idiomaPlantilla]);

  const cargaPlantillas = async () => {
    try {
      const response = await axiosApi.get('/api/whatsapp/plantillas');
      let { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      if (data) {
        data = data.filter(e => {
          return !(e.name.startsWith('sample_') || e.name === 'hello_world');
        });
        const nombrePlantillas = {};
        data.forEach(template => {
          if (nombrePlantillas[template.name]) nombrePlantillas[template.name] += `,${template.language}`;
          else nombrePlantillas[template.name] = template.language;
        });
        setDicNombreIdiomasPlantilla(nombrePlantillas);
        const opSelPlant = Object.keys(nombrePlantillas).map(template => {
          return { value: template, label: template };
        });
        setOpcionesPlantillas(opSelPlant);
      }
      setPlantillas(data);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const cargaDatos = async (inicio, longitud, minIndex = 0, busqueda = '') => {
    try {
      const response = await axiosApi.post('/api/locales/reservas', { inicio, longitud, filtroActivo, busqueda });
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setReservas(data.datos);
      const copyDatos = data.datos.slice(0);
      setReservasVisibles(copyDatos.slice(minIndex, minIndex + longitud / 5));
      setIsLoading(false);
    } catch (error) {
      showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
      setReservas([]);
      // console.log(error);
    }
  };

  const cargaCantidadTotal = async (busqueda = '') => {
    try {
      const response = await axiosApi.post('/api/locales/totalReservas', { filtroActivo, busqueda });
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setTotalReservas(data.total[0].total);
      cargaDatos(start, len, 0, busqueda);
    } catch (error) {
      showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
      setTotalReservas(0);
    }
  };

  const cargaFiltros = async () => {
    try {
      const response = await axiosApi.get('/api/locales/filtrosreservas');
      setFiltros(response.data.filtros);
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    cargaFiltros();
    cargaCantidadTotal();
    setCargaInicial(false);
    cargaPlantillas();
    switch (localStorage.getItem('I18N_LANGUAGE')) {
      case 'es':
        setIdiomaTlf(es);
        break;
      case 'pt':
        setIdiomaTlf(pt);
        break;
      default:
        setIdiomaTlf('');
    }
  }, []);

  useEffect(() => {
    if (!cargaInicial) {
      setStart(0);
      setPagina(1);
      setIsLoading(true);
      cargaCantidadTotal();
    }
  }, [filtroActivo]);

  const columns = [
    {
      dataField: 'estado',
      text: t('Status'),
      sort: false,
      style: {
        width: '7%'
      },
      formatExtraData: () => isLoading,
      formatter: (value, r, i, loading) => {
        let tipoPildora = '';
        switch (value) {
          case 'Reserved':
          case 'Checked-Out':
          case 'In-house':
            tipoPildora = 'success';
            break;
          case 'Cancelled':
            tipoPildora = 'danger';
            break;
          default:
            tipoPildora = 'warning';
            break;
        }
        if (loading()) return '';
        return (
          <Badge color={tipoPildora} pill style={{ width: '100%', fontSize: '11px' }}>
            {t(value)}
          </Badge>
        );
      }
    },
    {
      dataField: 'fecha_reserva',
      text: t('Reservation date'),
      sort: false,
      style: {
        width: '7%'
      },
      formatter: (value) => {
        if (value) {
          return moment(value).format('DD/MM/YYYY');
        }
        return '';
      }
    },
    {
      dataField: 'id_pms_show',
      text: t('Reservation id'),
      sort: false,
      headerStyle: { width: '10vw' },
      style: {
        width: '10vw',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line'
      },
      formatter: (value, row) => {
        if (value) {
          return value;
        }
        if (row.id_pms) {
          return row.id_pms;
        }
        return '';
      }
    },
    {
      dataField: 'nombre',
      text: `${t('Owner')}`,
      sort: false,
      headerStyle: { maxWidth: '10vw' },
      style: { maxWidth: '10vw',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      formatExtraData: () => isLoading,
      formatter: (value, row, i, loading) => {
        return (
          <span>
            <span style={{ wordWrap: 'breakWord' }}>
              {!loading() && (
                <a
                  href={`${process.env.REACT_APP_HOST_PANEL}/listado/ficha_visitante/${row.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {htmlEntities(value)}
                </a>
              )}
              {loading() && htmlEntities(value)}
            </span>
            {htmlEntities(value) && row.email && <br />}
            <span style={{ wordWrap: 'breakWord' }}>{row.email}</span>
            {((row.email && row.telefono) ||
              (!row.email && htmlEntities(value) && row.telefono)) && <br />}
            <span style={{ wordWrap: 'breakWord' }}>{row.telefono}</span>
          </span>
        );
      }
    },
    {
      dataField: 'via_reserva',
      text: t('Channel'),
      headerStyle: {
        width: '7%',
      },
      style: {
        width: '7%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line'
      },

      formatter: (value) => {
        return (
          <span>
            {value}
          </span>
        );
      },
      sort: false
    },
    {
      dataField: 'fecha_inicio',
      text: t('Check in'),
      style: {
        width: '8%'
      },
      formatter: (value) => {
        if (value) {
          return moment(value).format('DD/MM/YYYY');
        }
        return '';
      },
      sort: false
    },
    {
      dataField: 'fecha_fin',
      text: t('Nights'),
      style: {
        width: '5%'
      },
      formatter: (value, row) => {
        return (
          parseInt((new Date(value).getTime() - new Date(row.fecha_inicio).getTime()) /
          (1000 * 60 * 60 * 24), 10)
        );
      },
      sort: false,
      headerFormatter: (column) => {
        return (
          <span id="columnaNoches">
            <i className="bx bx-moon font-size-22" />
            <UncontrolledTooltip target="columnaNoches">
              {t(column.text)}
            </UncontrolledTooltip>
          </span>
        );
      }
    },
    {
      dataField: 'num_hab_reservadas',
      text: t('Rooms'),
      style: {
        width: '6%'
      },
      formatter: (value) => {
        if (value) {
          return value;
        }
        return '-';
      },
      sort: false,
      headerFormatter: (column) => {
        return (
          <span id="columnaHabitaciones">
            <i className="bx bx-home-alt font-size-18" />
            <UncontrolledTooltip target="columnaHabitaciones">
              {t(column.text)}
            </UncontrolledTooltip>
          </span>
        );
      }
    },
    {
      dataField: 'adultos',
      text: t('Guests'),
      style: {
        width: '6%'
      },
      formatter: (value, row) => {
        return (parseInt(value, 10) + parseInt(row.menores, 10));
      },
      sort: false,
      headerFormatter: (column) => {
        return (
          <span id="columnaHuespedes">
            <i className="bx bx-user font-size-18" />
            <UncontrolledTooltip target="columnaHuespedes">
              {t(column.text)}
            </UncontrolledTooltip>
          </span>
        );
      }
    },
    {
      dataField: 'tarifas',
      text: t('Total cost'),
      style: {
        width: '7%'
      },
      sort: false,
      formatter: (value) => {
        const parsedJson = JSON.parse(value);
        if (parsedJson && parsedJson.totalGlobal) {
          let precio;
          try {
            precio = parseFloat(parseFloat(parsedJson.totalGlobal.AmountAfterTax).toFixed(2));
          } catch (error) {
            precio = parsedJson.totalGlobal.AmountAfterTax;
          }
          if (currencySymbol[parsedJson.totalGlobal.CurrencyCode]) {
            return `${precio} ${currencySymbol[parsedJson.totalGlobal.CurrencyCode]}`;
          }
          return precio;
        }
        return '-';
      },
      headerFormatter: (column) => {
        return (
          <span id="columnaCoste">
            <i className="bx bx-dollar-circle font-size-22" />
            <UncontrolledTooltip target="columnaCoste">
              {t(column.text)}
            </UncontrolledTooltip>
          </span>
        );
      }
    },
    {
      dataField: 'pagado',
      text: t('Paid'),
      style: {
        width: '5%'
      },
      sort: false,
      formatter: (value, row) => {
        const parsedJson = JSON.parse(row.tarifas);
        if (parsedJson && parsedJson.totalGlobal && parsedJson.totalGlobal.deposit) {
          if (currencySymbol[parsedJson.totalGlobal.CurrencyCode]) {
            return `${parsedJson.totalGlobal.deposit} ${currencySymbol[parsedJson.totalGlobal.CurrencyCode]}`;
          }
          return parsedJson.totalGlobal.deposit;
        }
        return '-';
      },
      headerFormatter: (column) => {
        return (
          <span id="columnaPagado">
            <i className="bx bx-receipt font-size-22" />
            <UncontrolledTooltip target="columnaPagado">
              {t(column.text)}
            </UncontrolledTooltip>
          </span>
        );
      }
    },
    {
      dataField: 'id_usuarios',
      text: '',
      style: {
        width: '5%'
      },
      formatExtraData: () => [isLoading, funcionalidades],
      formatter: (value, r, i, loading) => {
        const func = loading()[1];
        if (loading()[0]) return '';
        return (
          <UncontrolledDropdown
            direction="left"
          >
            <DropdownToggle tag="a" caret>
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu
              className="lista-opciones"
            >
              <DropdownItem>
                {!func?.chatzone?.chatzone && (
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/nuevo/messagingcenter/inbox?abrir=${value}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: 'initial', display: 'flex', width: '100%' }}
                  >
                    {t('Contactar')}
                  </a>
                )}
                {func?.chatzone?.chatzone && (
                  <>
                    <span
                      style={{ color: '#74788d', backgroundColor: 'transparent', cursor: 'default' }}
                      id="dropdownContactar"
                    >
                      {t('Contactar')}
                    </span>
                    <UncontrolledTooltip target="dropdownContactar">
                      {t('Funcionalidad deshabilitada tooltip')}
                    </UncontrolledTooltip>
                  </>
                )}
              </DropdownItem>
              <DropdownItem
                id="dropdownPlantillaWhatsapp"
                onClick={() => {
                  if (!func?.whatsapp?.plantillas) {
                    setModalInstantaneo(true);
                    const idReservaAux = r.id_pms_show || r.id_pms || '';
                    setIdReservaEnviar(idReservaAux);
                    setTlfEnviar(r.telefono);
                  }
                }}
                style={func?.whatsapp?.plantillas ? { color: '#74788d', backgroundColor: 'transparent', cursor: 'default' } : {}}
              >
                {t('Send whatsapp template')}
              </DropdownItem>
              {func?.whatsapp?.plantillas && (
                <UncontrolledTooltip target="dropdownPlantillaWhatsapp">
                  {t('Funcionalidad deshabilitada tooltip')}
                </UncontrolledTooltip>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id_reservas',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              setIsLoading(true);
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
              setLen(5 * option.page);
              setSize(option.page);
              cargaDatos(0, 5 * option.page);
              setPagina(1);
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const handlePageChange = (page) => {
    if (Math.floor((page - 1) / 5) * len !== start) {
      setIsLoading(true);
      const minIndex = Math.floor((page - 1) / 5) * len;
      const indiceArray = Math.floor(((page - 1) % 5) * size);
      cargaDatos(minIndex, len, indiceArray);
      setStart(minIndex);
    }
    setPagina(page);
  };

  const pageOptions = {
    sizePerPage: size,
    page: pagina,
    totalSize: totalReservas,
    sizePerPageRenderer,
    custom: true,
    showTotal: true,
    onPageChange: (page) => handlePageChange(page)
  };

  const handleTableChange = (type, { page }) => {
    if (type === 'pagination') {
      const datos = reservas.slice(0);
      const minIndex = ((page - 1) % 5) * size;
      setReservasVisibles(datos.slice(minIndex, minIndex + size));
    }
  };

  const NoDataIndication = () => (
    <div className="spinner">
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
    </div>
  );

  const handleCambioBusqueda = (value) => {
    setBusquedaManual(value);
  };

  useEffect(() => {
    if (!cargaInicial) {
      setStart(0);
      setPagina(1);
      const delayed = setTimeout(() => {
        setIsLoading(true);
        cargaCantidadTotal(busquedaManual);
      }, 1000);
      return () => clearTimeout(delayed);
    }
    return undefined;
  }, [busquedaManual]);

  const handleExportar = async (destino) => {
    try {
      const result = await axiosApi.post('/api/locales/reservas/exportar', { filtroActivo, busqueda: busquedaManual });
      const campos = [t('Status'), t('Reservation ID'), t('Reservation date'), t('Owner'), t('Email'), t('Phone'), t('Channel'), t('Check in'), t('Check out'), t('Rooms'), `${t('Adults')}/${t('Children')}`, t('Total cost'), t('Paid')];
      const { datos } = result.data;
      datos.forEach(d => {
        d[0] = t(d[0]);
      });
      const fichero = [campos, ...datos];
      if (destino === 'csv') {
        let f = fichero.map(r => {
          r = r.map(v => {
            if (!v) return '-';
            return v;
          });
          return r.join(',');
        });
        f = f.join('\n');
        const blob = new Blob(['\ufeff', f], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${t('Reservations')}.csv`;
        document.body.appendChild(a);
        a.click();

        // Limpia el enlace y la URL del blob.
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        const libro = utils.book_new();
        const worksheet = utils.aoa_to_sheet(fichero);
        utils.book_append_sheet(libro, worksheet);
        writeFileXLSX(libro, `${t('Reservations')}.xlsx`);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  document.title = `${t('Reservations list')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={`${t('Reservations list')}`} breadcrumbItems={[{ title: t('Bookings') }, { title: t('Reservations list') }]} />
          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }} />
              </Row>
              <Card>
                <CardBody>
                  <CabeceraListas
                    filtros={filtros}
                    modificarFiltroActivo={setFiltroActivo}
                    textoMostrarTodos="All reservations"
                    total={totalReservas}
                    vista="Reservations"
                    handleChange={handleCambioBusqueda}
                    funcionExportar={handleExportar}
                    isLoading={isLoading}
                  />
                  <hr style={{ borderColor: '#495057', borderTop: '2px solid', marginLeft: '-20px', marginRight: '-20px' }} />
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id_reservas"
                    columns={columns}
                    data={reservasVisibles}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id_reservas"
                        columns={columns}
                        data={reservasVisibles}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    remote={{ pagination: true }}
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    onTableChange={handleTableChange}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={() => <NoDataIndication />}
                                    rowClasses={isLoading ? 'placeholder-dinamic' : ''}
                                    rowStyle={isLoading ? { display: 'table-row' } : {}}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card body className="puntos">
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <Row style={{ margin: '5px', marginTop: '20px' }}>
                  <p><strong>{t('Reservations list')}</strong></p>
                  <p>{t('TextoPieReservas')}</p>
                </Row>
              </div>
            </Card>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={modalInstantaneo}
        toggle={() => {
          togInstantaneo();
          setExpandirEnvio(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t('Unique Whatsapp send')}
          </h5>
          <button
            type="button"
            onClick={() => {
              togInstantaneo();
              setIdiomaPlantilla('');
              setTelefonoSinPrefijo('');
              setCamposEnviar([]);
              setCamposEnvioUnico([]);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row style={{ marginBottom: '15px' }}>
            <Col sm={3} xs={3}>
              {t('Template')}
            </Col>
            <Col>
              <ReactSelect
                onChange={setNombrePlantilla}
                options={opcionesPlantillas}
                placeholder={t('Select')}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col sm={3} xs={3}>
              {t('Language')}
            </Col>
            <Col>
              <ReactSelect
                onChange={setIdiomaPlantilla}
                options={opcionesIdiomas}
                placeholder={t('Select')}
              />
            </Col>
          </Row>
          {!buscaReserva && (
            <Row style={{ marginBottom: '15px' }}>
              <Col
                sm={3}
                xs={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '5vh'
                }}
              >
                {t('Send to')}
              </Col>

              <Col
                sm={6}
                xs={6}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '5vh'
                }}
              >
                <PhoneInput
                  onChange={setTelefonoSinPrefijo}
                  value={telefonoSinPrefijo}
                  enableSearch
                  country={localStorage.getItem('I18N_LANGUAGE')}
                  localization={idiomaTlf}
                  defaultValue={tlfEnviar}
                />
              </Col>
            </Row>
          )}
          {buscaReserva && (
            <Row style={{ marginBottom: '15px' }}>
              <Col
                sm={3}
                xs={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('Booking ID')}
              </Col>
              <Col>
                <Input
                  onChange={(e) => {
                    const index = camposEnvioUnico.indexOf('id_pms_show');
                    setIdReservaInvalida(false);
                    setExpandirEnvio(false);
                    let copiaCampos = [...camposEnviar];
                    copiaCampos = copiaCampos.map((v, i) => {
                      if (i !== index) return '';
                      return e.target.value;
                    });
                    setCamposEnviar(copiaCampos);
                  }}
                  id="idReserva"
                  invalid={idReservaInvalida}
                  defaultValue={idReservaEnviar}
                />
                {idReservaInvalida && (
                  <FormFeedback
                    target="idReserva"
                  >
                    {t('Invalid reservation')}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          )}
          {expandirEnvio && buscaReserva && (
            <>
              <Row style={{ marginBottom: '15px' }}>
                <Col
                  sm={3}
                  xs={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '5vh'
                  }}
                >
                  {t('Send to')}
                </Col>
                <Col
                  sm={6}
                  xs={6}
                  style={{
                    height: '5vh'
                  }}
                >
                  <PhoneInput
                    onChange={setTelefonoSinPrefijo}
                    value={telefonoSinPrefijo}
                    enableSearch
                    country={localStorage.getItem('I18N_LANGUAGE')}
                  />
                </Col>
              </Row>
            </>
          )}
          {expandirEnvio && (
            <Row style={{ marginBottom: '15px' }}>
              <strong>
                {t('Las variables de la plantilla serán sustituidas con esta información:')}
              </strong>
            </Row>
          )}
          {expandirEnvio && camposEnvioUnico.map((c, i) => {
            if (c !== 'id_pms_show') {
              return (
                <Row key={i} style={{ marginBottom: '15px' }}>
                  <Col sm={3} xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                    {t(c)}
                  </Col>
                  <Col>
                    <Input
                      value={camposEnviar[i]}
                      onChange={(e) => {
                        const temp = [...camposEnviar];
                        temp[i] = e.target.value;
                        setCamposEnviar(temp);
                        const nuevosRequeridos = [...campoRequerido];
                        nuevosRequeridos[i] = false;
                        setCampoRequerido(nuevosRequeridos);
                      }}
                      invalid={campoRequerido[i]}
                    />
                    {campoRequerido[i] && (
                      <FormFeedback>
                        {t('This value is required')}
                      </FormFeedback>
                    )}
                  </Col>
                </Row>
              );
            }
            return '';
          })}
        </div>
        <div className="modal-footer">
          {!expandirEnvio && (
            <button
              type="button"
              onClick={() => {
                togInstantaneo();
                setExpandirEnvio(false);
                setIdiomaPlantilla('');
                setTelefonoSinPrefijo('');
                setCamposEnviar([]);
                setCamposEnvioUnico([]);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              {t('Close')}
            </button>
          )}
          {expandirEnvio && (
            <button
              type="button"
              onClick={() => {
                setExpandirEnvio(false);
                let temp = [...camposEnviar];
                const index = camposEnvioUnico.indexOf('id_pms_show');
                if (index !== -1) setTelefonoSinPrefijo('34');
                temp = temp.map((v, i) => {
                  if (i !== index) return '';
                  return v;
                });
                setCamposEnviar(temp);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              {t('Return')}
            </button>
          )}
          <button
            onClick={() => {
              if (nombrePlantilla.value !== '' && camposEnviar.filter(c => c === '').length === 0 && telefonoSinPrefijo) {
                enviarTemplate(
                  nombrePlantilla.value,
                  componentesPlantilla,
                  idiomaPlantilla.value
                );
                setNombrePlantilla({});
                setIdiomaPlantilla({});
                setExpandirEnvio(false);
                togInstantaneo();
                setIdiomaPlantilla('');
                setTelefonoSinPrefijo('');
                setCamposEnviar([]);
                setCamposEnvioUnico([]);
              } else if (!expandirEnvio) {
                buscarDatosCliente();
              } else {
                const nuevosRequeridos = camposEnviar.map(v => {
                  return !v;
                });
                setCampoRequerido(nuevosRequeridos);
              }
            }}
            type="button"
            className="btn btn-primary"
          >
            {camposEnviar.filter(c => c === '').length === 0 ? t('Send') : t('Next')}
          </button>
        </div>
      </Modal>
      <SweetAlert
        show={modalErrorInstantaneo}
        title={t('Error')}
        warning
        confirmBtnText={t('Ok').toUpperCase()}
        confirmBtnBsStyle="success"
        onConfirm={() => setModalErrorInstantaneo(false)}
      >
        {t('Error loading template')}
      </SweetAlert>
    </>
  );
};

ListadoReservas.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(ListadoReservas);
