import i18n from 'i18n';

export const validator = (r) => !!r.value;

const fields =
[
  {
    label: i18n.t('Customer'),
    options: [
      {
        name: 'cliente_nombre',
        label: i18n.t('First name'),
        objetos: ['RESERVAS', 'CLIENTES'],
        optgroup: 'Cliente',
        type: 'string',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: 'beginsWith', label: i18n.t('empieza por') },
          { name: 'doesNotBeginWith', label: i18n.t('no empieza por') },
          { name: 'contains', label: i18n.t('contiene') },
          { name: 'doesNotContain', label: i18n.t('no contiene') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') }
        ],
        validator,
      },
      {
        name: 'cliente_apellidos',
        label: i18n.t('Last name'),
        objetos: ['RESERVAS', 'CLIENTES'],
        optgroup: 'Cliente',
        type: 'string',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: 'beginsWith', label: i18n.t('empieza por') },
          { name: 'doesNotBeginWith', label: i18n.t('no empieza por') },
          { name: 'contains', label: i18n.t('contiene') },
          { name: 'doesNotContain', label: i18n.t('no contiene') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') }
        ],
        validator,
      },
      {
        name: 'cliente_email',
        label: i18n.t('Email'),
        objetos: ['RESERVAS', 'CLIENTES'],
        optgroup: 'Cliente',
        type: 'string',
        validator,
        operators: [
          { name: 'beginsWith', label: i18n.t('empieza por') },
          { name: 'endsWith', label: i18n.t('termina por') },
          { name: 'contains', label: i18n.t('contiene') },
          { name: 'doesNotContain', label: i18n.t('no contiene') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') },
          { name: 'verified', label: i18n.t('verificado') },
          { name: 'valid', label: i18n.t('no verificado') },
          { name: 'unvalidated', label: i18n.t('sin validar') },
          { name: 'erroneous', label: i18n.t('erroneo') },
          { name: 'blackList', label: i18n.t('lista negra') }
        ]
      },
      {
        name: 'cliente_telefono',
        label: i18n.t('Phone'),
        objetos: ['RESERVAS', 'CLIENTES'],
        optgroup: 'Cliente',
        type: 'string',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: 'beginsWith', label: i18n.t('empieza por') },
          { name: 'doesNotBeginWith', label: i18n.t('no empieza por') },
          { name: 'contains', label: i18n.t('contiene') },
          { name: 'doesNotContain', label: i18n.t('no contiene') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') }
        ],
        validator,
      },
      {
        name: 'cliente_edad',
        label: i18n.t('Age'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        type: 'number',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') }
        ]
      },
      {
        name: 'cliente_genero',
        label: i18n.t('Gender'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        datatype: 'select',
        valorSwitchOpciones: 2,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'cliente_idioma',
        label: i18n.t('Language'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        datatype: 'select',
        valorSwitchOpciones: 3,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'cliente_nacionalidad',
        label: i18n.t('Nationality'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        datatype: 'select',
        valorSwitchOpciones: 4,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'cliente_ciudad',
        label: i18n.t('City'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        type: 'string',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: 'beginsWith', label: i18n.t('empieza por') },
          { name: 'doesNotBeginWith', label: i18n.t('no empieza por') },
          { name: 'contains', label: i18n.t('contiene') },
          { name: 'doesNotContain', label: i18n.t('no contiene') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') }
        ],
        validator,
      },
      {
        name: 'cliente_gdpr',
        label: i18n.t('Accepts commercial'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        datatype: 'select',
        valorSwitchOpciones: 12,
        operators: [
          { name: '=', label: i18n.t('es(ser)') }
        ]
      },
      {
        name: 'cliente_conexion_wifi',
        label: i18n.t('Fecha conexión WiFi'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        datatype: 'date',
        operators: [
          { name: 'between', label: i18n.t('entre') },
          { name: 'after', label: i18n.t('despues de') },
          { name: 'before', label: i18n.t('antes de') },
          { name: 'today', label: i18n.t('hoy') },
          { name: 'last_week', label: i18n.t('ultima semana') },
          { name: 'this_month', label: i18n.t('este mes') },
          { name: 'last_30_days', label: i18n.t('ultimos 30 dias') },
          { name: 'this_year', label: i18n.t('este año') },
          { name: 'last_year', label: i18n.t('último año') }
        ]
      },
      {
        name: 'cliente_at_least_x_loggin',
        label: i18n.t('Número de conexiones WiFi'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        type: 'integer',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') }
        ]
      },
      {
        name: 'cliente_registro_fecha',
        label: i18n.t('Fecha de registro'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        datatype: 'date',
        operators: [
          { name: 'between', label: i18n.t('entre') },
          { name: 'after', label: i18n.t('despues de') },
          { name: 'before', label: i18n.t('antes de') },
          { name: 'today', label: i18n.t('hoy') },
          { name: 'last_week', label: i18n.t('ultima semana') },
          { name: 'this_month', label: i18n.t('este mes') },
          { name: 'last_30_days', label: i18n.t('ultimos 30 dias') },
          { name: 'this_year', label: i18n.t('este año') },
          { name: 'last_year', label: i18n.t('último año') }
        ]
      },
      {
        name: 'cliente_metodo_registro',
        label: i18n.t('Método de registro'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        datatype: 'select',
        valorSwitchOpciones: 5,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'estancias_alojado',
        label: i18n.t('Está alojado'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        datatype: 'select',
        valorSwitchOpciones: 13,
        operators: [
          { name: '=', label: i18n.t('es(ser)') }
        ]
      },
      {
        name: 'estancias_num_estancias',
        label: i18n.t('Nº estancias'),
        objetos: ['CLIENTES'],
        optgroup: 'Cliente',
        type: 'number',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') }
        ]
      },
      {
        name: 'etiquetas',
        label: i18n.t('Tags'),
        optgroup: 'Visita',
        datatype: 'select',
        valorSwitchOpciones: 16,
        objetos: ['CLIENTES'],
        operators: [
          { name: 'inRegex', label: i18n.t('es(ser)') },
          { name: 'notInRegex', label: i18n.t('no es') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') },
        ]
      }
    ]
  },
  {
    label: i18n.t('Dispositivo'),
    options: [
      {
        name: 'dispositivo_mac',
        label: i18n.t('Direccion MAC'),
        optgroup: 'Visita',
        type: 'string',
        validator,
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') }
        ]
      },
      {
        name: 'dispositivo_os',
        label: i18n.t('OS'),
        optgroup: 'Visita',
        datatype: 'select',
        valorSwitchOpciones: 6,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
    ]
  },
  {
    label: i18n.t('Estancias'),
    options: [
      {
        name: 'estancias_fecha',
        label: i18n.t('Fecha inicio de estancia'),
        objetos: ['CLIENTES'],
        optgroup: 'Estancia',
        datatype: 'date',
        operators: [
          { name: 'between', label: i18n.t('entre') },
          { name: 'after', label: i18n.t('despues de') },
          { name: 'before', label: i18n.t('antes de') },
          { name: 'today', label: i18n.t('hoy') },
          { name: 'last_week', label: i18n.t('ultima semana') },
          { name: 'this_month', label: i18n.t('este mes') },
          { name: 'last_30_days', label: i18n.t('ultimos 30 dias') },
          { name: 'this_year', label: i18n.t('este año') },
          { name: 'last_year', label: i18n.t('último año') }
        ]
      },
      {
        name: 'estancias_grupo_habitaciones',
        label: i18n.t('Grupo habitaciones'),
        objetos: ['CLIENTES'],
        optgroup: 'Estancia',
        datatype: 'select',
        valorSwitchOpciones: 11,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'estancias_habitacion',
        label: i18n.t('Nº de habitaciones'),
        objetos: ['CLIENTES'],
        optgroup: 'Estancia',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'estancias_noches',
        label: i18n.t('Nº de noches (por estancia)'),
        objetos: ['CLIENTES'],
        optgroup: 'Estancia',
        type: 'string',
        validator,
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'estancias_tipo',
        label: i18n.t('Tipo habitacion'),
        objetos: ['CLIENTES'],
        optgroup: 'Estancia',
        type: 'string',
        validator,
        operators: [
          { name: 'beginsWith', label: i18n.t('empieza por') },
          { name: 'endsWith', label: i18n.t('termina por') },
          { name: 'contains', label: i18n.t('contiene') },
          { name: 'doesNotContain', label: i18n.t('no contiene') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') },
          { name: 'verified', label: i18n.t('verificado') },
          { name: 'notVerified', label: i18n.t('no verificado') },
          { name: 'notValidated', label: i18n.t('sin validar') },
          { name: 'error', label: i18n.t('erroneo') },
          { name: 'blackList', label: i18n.t('lista negra') }
        ]
      }
    ]
  },
  {
    label: i18n.t('Reserva'),
    options: [
      {
        name: 'reservas_fecha_nuevo',
        label: i18n.t('Fecha de reserva'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        datatype: 'date',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: 'after', label: i18n.t('despues de') },
          { name: 'before', label: i18n.t('antes de') },
          { name: 'today', label: i18n.t('hoy') },
          { name: 'last_week', label: i18n.t('ultima semana') },
          { name: 'this_month', label: i18n.t('este mes') },
          { name: 'last_30_days', label: i18n.t('ultimos 30 dias') },
          { name: 'this_year', label: i18n.t('este año') },
          { name: 'last_year', label: i18n.t('último año') },
          { name: 'between', label: i18n.t('entre') }
        ]
      },
      {
        name: 'reservas_checkin_nuevo',
        label: i18n.t('Fecha de checkin'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        datatype: 'date',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: 'after', label: i18n.t('despues de') },
          { name: 'before', label: i18n.t('antes de') },
          { name: 'today', label: i18n.t('hoy') },
          { name: 'last_week', label: i18n.t('ultima semana') },
          { name: 'this_month', label: i18n.t('este mes') },
          { name: 'last_30_days', label: i18n.t('ultimos 30 dias') },
          { name: 'this_year', label: i18n.t('este año') },
          { name: 'last_year', label: i18n.t('último año') },
          { name: 'between', label: i18n.t('entre') }
        ]
      },
      {
        name: 'reservas_checkout_nuevo',
        label: i18n.t('Fecha de checkout'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        datatype: 'date',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: 'after', label: i18n.t('despues de') },
          { name: 'before', label: i18n.t('antes de') },
          { name: 'today', label: i18n.t('hoy') },
          { name: 'last_week', label: i18n.t('ultima semana') },
          { name: 'this_month', label: i18n.t('este mes') },
          { name: 'last_30_days', label: i18n.t('ultimos 30 dias') },
          { name: 'this_year', label: i18n.t('este año') },
          { name: 'last_year', label: i18n.t('último año') },
          { name: 'between', label: i18n.t('entre') }
        ]
      },
      {
        name: 'reservas_dia_checkin_nuevo',
        label: i18n.t('Dia de checkin'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        datatype: 'select',
        valorSwitchOpciones: 7,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'reservas_dia_checkout_nuevo',
        label: i18n.t('Dia de checkout'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        datatype: 'select',
        valorSwitchOpciones: 7,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'reservas_canal_nuevo',
        label: i18n.t('Canal de reserva'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        datatype: 'select',
        valorSwitchOpciones: 0,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'reservas_estado_nuevo',
        label: i18n.t('Reservation status'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        datatype: 'select',
        valorSwitchOpciones: 1,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'reservas_habitacion',
        label: i18n.t('Tipo habitacion'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        type: 'string',
        validator,
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: 'beginsWith', label: i18n.t('empieza por') },
          { name: 'doesNotBeginWith', label: i18n.t('no empieza por') },
          { name: 'contains', label: i18n.t('contiene') },
          { name: 'doesNotContain', label: i18n.t('no contiene') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') }
        ],
      },
      {
        name: 'reservas_ninos_nuevo',
        label: i18n.t('Children'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        datatype: 'select',
        valorSwitchOpciones: 14,
        operators: [
          { name: '=', label: i18n.t('es(ser)') }
        ]
      },
      {
        name: 'reservas_num_huespedes',
        label: i18n.t('Nº de huespedes'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        type: 'number',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'reservas_num_habitaciones',
        label: i18n.t('Nº de habitaciones'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        type: 'number',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'reservas_con_antelacion',
        label: i18n.t('Reservó con antelación (dias)'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        type: 'number',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'reserva_precio_total',
        label: i18n.t('Precio total'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        type: 'number',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'reservas_checkin_estado_nuevo',
        label: i18n.t('Checkin status'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        datatype: 'select',
        operators: [
          { name: '=', label: i18n.t('Completed') },
          { name: '!=', label: i18n.t('Pending') }
        ]
      },
      {
        name: 'reservas_pagado',
        label: i18n.t('Booking paid'),
        objetos: ['RESERVAS'],
        optgroup: 'Reserva',
        datatype: 'select',
        operators: [
          { name: '>', label: i18n.t('Not paid') },
          { name: '<=', label: i18n.t('Paid') },
          { name: 'isNull', label: i18n.t('No info') }
        ]
      },
      {
        name: 'reservas_fecha',
        label: i18n.t('Fecha de reserva'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        datatype: 'date',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: 'after', label: i18n.t('despues de') },
          { name: 'before', label: i18n.t('antes de') },
          { name: 'today', label: i18n.t('hoy') },
          { name: 'last_week', label: i18n.t('ultima semana') },
          { name: 'this_month', label: i18n.t('este mes') },
          { name: 'last_30_days', label: i18n.t('ultimos 30 dias') },
          { name: 'this_year', label: i18n.t('este año') },
          { name: 'last_year', label: i18n.t('último año') },
          { name: 'between', label: i18n.t('entre') }
        ]
      },
      {
        name: 'reservas_checkin_clientes',
        label: i18n.t('Fecha de checkin'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        datatype: 'date',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: 'after', label: i18n.t('despues de') },
          { name: 'before', label: i18n.t('antes de') },
          { name: 'today', label: i18n.t('hoy') },
          { name: 'last_week', label: i18n.t('ultima semana') },
          { name: 'this_month', label: i18n.t('este mes') },
          { name: 'last_30_days', label: i18n.t('ultimos 30 dias') },
          { name: 'this_year', label: i18n.t('este año') },
          { name: 'last_year', label: i18n.t('último año') },
          { name: 'between', label: i18n.t('entre') }
        ]
      },
      {
        name: 'reservas_checkout_clientes',
        label: i18n.t('Fecha de checkout'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        datatype: 'date',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: 'after', label: i18n.t('despues de') },
          { name: 'before', label: i18n.t('antes de') },
          { name: 'today', label: i18n.t('hoy') },
          { name: 'last_week', label: i18n.t('ultima semana') },
          { name: 'this_month', label: i18n.t('este mes') },
          { name: 'last_30_days', label: i18n.t('ultimos 30 dias') },
          { name: 'this_year', label: i18n.t('este año') },
          { name: 'last_year', label: i18n.t('último año') },
          { name: 'between', label: i18n.t('entre') }
        ]
      },
      {
        name: 'reservas_dia_checkin',
        label: i18n.t('Dia de checkin'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        datatype: 'select',
        valorSwitchOpciones: 7,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'reservas_dia_checkout',
        label: i18n.t('Dia de checkout'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        datatype: 'select',
        valorSwitchOpciones: 7,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'reservas_canal',
        label: i18n.t('Canal de reserva'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        datatype: 'select',
        valorSwitchOpciones: 0,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'reservas_estado_cliente',
        label: i18n.t('Reservation status'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        datatype: 'select',
        valorSwitchOpciones: 1,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'reservas_habitacion_clientes',
        label: i18n.t('Tipo habitacion'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        type: 'string',
        validator,
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: 'beginsWith', label: i18n.t('empieza por') },
          { name: 'doesNotBeginWith', label: i18n.t('no empieza por') },
          { name: 'contains', label: i18n.t('contiene') },
          { name: 'doesNotContain', label: i18n.t('no contiene') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') }
        ],
      },
      {
        name: 'reservas_ninos_clientes',
        label: i18n.t('Children'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        datatype: 'select',
        valorSwitchOpciones: 14,
        operators: [
          { name: '=', label: i18n.t('es(ser)') }
        ]
      },
      {
        name: 'reservas_num_huespedes_clientes',
        label: i18n.t('Nº de huespedes'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        type: 'number',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'reservas_num_habitaciones_clientes',
        label: i18n.t('Nº de habitaciones'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        type: 'number',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'reservas_con_antelacion_clientes',
        label: i18n.t('Reservó con antelación (dias)'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        type: 'number',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'reserva_precio_total_clientes',
        label: i18n.t('Precio total'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        type: 'number',
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'reservas_checkin_estado_cliente',
        label: i18n.t('Checkin status'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        datatype: 'select',
        operators: [
          { name: '=', label: i18n.t('Completed') },
          { name: '!=', label: i18n.t('Pending') }
        ]
      },
      {
        name: 'reservas_pagado_clientes',
        label: i18n.t('Booking paid'),
        objetos: ['CLIENTES'],
        optgroup: 'Reserva',
        datatype: 'select',
        operators: [
          { name: '>', label: i18n.t('Not paid') },
          { name: '<=', label: i18n.t('Paid') },
          { name: 'isNull', label: i18n.t('No info') }
        ]
      }
    ]
  },
  {
    label: i18n.t('Feedback'),
    options: [
      {
        name: 'feedback_responde_encuesta',
        label: i18n.t('Respondió a alguna encuesta'),
        objetos: ['CLIENTES'],
        optgroup: 'Feedback',
        datatype: 'select',
        valorSwitchOpciones: 15,
        operators: [
          { name: '=', label: i18n.t('es(ser)') }
        ]
      },
      {
        name: 'feedback_responde_id_encuesta',
        label: i18n.t('Respondió a la encuesta'),
        objetos: ['CLIENTES'],
        optgroup: 'Feedback',
        datatype: 'select',
        valorSwitchOpciones: 8,
        operators: [
          { name: 'in', label: i18n.t('es(ser)') },
          { name: 'notIn', label: i18n.t('no es') }
        ]
      },
      {
        name: 'feedback_responde_encuesta_canal',
        label: i18n.t('Respondió a encuesta por canal'),
        objetos: ['CLIENTES'],
        optgroup: 'Feedback',
        datatype: 'select',
        valorSwitchOpciones: 9,
        operators: [
          { name: 'in', label: i18n.t('proviene') },
          { name: 'notIn', label: i18n.t('no proviene') }
        ]
      },
      {
        name: 'feedback_responde_id_pregunta',
        label: i18n.t('Respondió a la pregunta'),
        objetos: ['CLIENTES'],
        optgroup: 'Feedback',
        datatype: 'select',
        valorSwitchOpciones: 10,
        operators: [
          { name: '=', label: i18n.t('es(ser)') }
        ]
      },
      {
        name: 'feedback_responde_puntuacion',
        label: i18n.t('Respondió con puntuacion'),
        objetos: ['CLIENTES'],
        optgroup: 'Feedback',
        type: 'string',
        validator,
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: '<', label: i18n.t('menor que') },
          { name: '<=', label: i18n.t('menor igual que') },
          { name: '>', label: i18n.t('mayor que') },
          { name: '>=', label: i18n.t('mayor igual que') },
          { name: 'between', label: i18n.t('entre') },
          { name: 'notBetween', label: i18n.t('no esta entre') },
        ]
      },
      {
        name: 'feedback_responde_texto',
        label: i18n.t('Respondió con texto'),
        objetos: ['CLIENTES'],
        optgroup: 'Feedback',
        type: 'string',
        validator,
        operators: [
          { name: '=', label: i18n.t('es igual') },
          { name: '!=', label: i18n.t('no es igual') },
          { name: 'beginsWith', label: i18n.t('empieza por') },
          { name: 'doesNotBeginWith', label: i18n.t('no empieza por') },
          { name: 'contains', label: i18n.t('contiene') },
          { name: 'doesNotContain', label: i18n.t('no contiene') },
          { name: 'empty', label: i18n.t('está vacío') },
          { name: 'notEmpty', label: i18n.t('no está vacío') }
        ]
      }
    ]
  }
];

export default fields;
