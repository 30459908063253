import PropTypes from 'prop-types';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChartApex = props => {
  const { categories, data, horizontal, seriesName, color = '#34c38f', toolbar } = props;
  const series = [
    {
      name: seriesName,
      data
    }
  ];
  const options = {
    chart: {
      stacked: true,
      toolbar: {
        show: toolbar
      }
    },
    plotOptions: {
      bar: {
        horizontal
      }
    },
    dataLabels: {
      enabled: false
    },

    colors: [color],
    grid: {
      borderColor: '#f1f1f1'
    },
    xaxis: {
      categories
    }
  };

  return <ReactApexChart options={options} series={series} type="bar" height="350" />;
};

BarChartApex.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
  horizontal: PropTypes.bool,
  seriesName: PropTypes.string,
  color: PropTypes.string,
  toolbar: PropTypes.bool
};

export default BarChartApex;
