import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState('Usuario');
  const { success, t } = props;

  useEffect(() => {
    if (localStorage.getItem('userName')) {
      const obj = JSON.parse(localStorage.getItem('userName'));
      if (obj) {
        if (obj.name) {
          setusername(obj.name);
        }
      }
      /* if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === 'fake' ||
        process.env.REACT_APP_DEFAULTAUTH === 'jwt'
      ) {
        const obj = JSON.parse(localStorage.getItem('userName'));
        if (obj) {
          if (obj.name) {
            setusername(obj.name);
          }
        }
      } */
    }
  }, [success]);

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <i className="bx bx-user" />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href={`${process.env.REACT_APP_HOST_PANEL}/informes`}>
            <i className="bx bxs-report font-size-16 align-middle me-1" />
            {t('Scheduled reports')}
          </DropdownItem>
          <DropdownItem tag="a" href="/nuevo/perfil-usuario">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {t('User profile')}
          </DropdownItem>
          <div className="dropdown-divider" />
          <DropdownItem tag="a" href={`${process.env.REACT_APP_HOST_PANEL}/auth/logout`}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t('Logout')}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default connect(mapStatetoProps, {})(withTranslation()(ProfileMenu));
