import React from 'react';
import PropTypes from 'prop-types';
import { Route/* , Redirect */ } from 'react-router-dom';

const Authmiddleware = ({
  component: Component, layout: Layout, isAuthProtected, funcionalidades, ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      /* if (isAuthProtected && !localStorage.getItem('authUser')
      && document.cookie.indexOf('laravel_session') === -1) {
        // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
      } */

      if (funcionalidades) {
        switch (props.location.pathname) {
          case '/alertas/feedback':
            if (funcionalidades?.feedback?.general) {
              // return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
              const url = `${process.env.REACT_APP_HOST_PANEL}/`;
              const newWindow = window.open(url, '_self');
              if (newWindow) newWindow.opener = null;
            }
            break;
          case '/alertas/messagingcenter':
            if (funcionalidades?.chatzone?.chatzone) {
              // return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
              const url = `${process.env.REACT_APP_HOST_PANEL}/`;
              const newWindow = window.open(url, '_self');
              if (newWindow) newWindow.opener = null;
            }
            break;
          case '/alertas/checkin':
            if (funcionalidades?.clientes?.checkin) {
              // return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
              const url = `${process.env.REACT_APP_HOST_PANEL}/`;
              const newWindow = window.open(url, '_self');
              if (newWindow) newWindow.opener = null;
            }
            break;
          default:
        }
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  funcionalidades: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
};

export default Authmiddleware;
