import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  Spinner,
  Input,
  Label,
  FormGroup
  /* UncontrolledTooltip, */
  /* CardText */
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

const LogAcceso = props => {
  const { t } = props;

  const [accionesLog, setAccionesLog] = useState([]);
  const [accionesVisibles, setAccionesVisibles] = useState([]);
  const [start, setStart] = useState(0);
  const [len, setLen] = useState(50);
  const [size, setSize] = useState(10);
  const [totalAcciones, setTotalAcciones] = useState(60);
  const [pagina, setPagina] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [filtroTabla, setFiltroTabla] = useState('');
  const [productos, setProductos] = useState([]);

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargaDatos = async (inicio, longitud, minIndex = 0, filtro = '') => {
    if (productos.length === 0) {
      try {
        const response = await axiosApi.get('/api/administracion/log/acciones');
        const { data } = response;
        const { error } = data;
        if (error) {
          showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
          return;
        }
        setProductos(data.productos);
      } catch (error) {
        console.log(error);
      }
    }
    try {
      const response = await axiosApi.get(`/api/administracion/log/${inicio}/${longitud}${filtro}`);
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      let i = 1;
      // console.log(data.data);
      const datos = data.data.map(camp => {
        const fecha = camp[1].split(' ');
        let accion;
        const nuevoLocal = camp[13] || 'Bug cambio de local en nuevo panel';
        if (camp[9] === 17) {
          accion = `Cambio de ${camp[12] || ''}: ${nuevoLocal}`;
        } else {
          accion = camp[5];
        }
        const entry = {
          id: i,
          timestamp: `${fecha[0]} ${fecha[fecha.length - 1]}`,
          establecimiento: camp[4],
          nombre: camp[10],
          accion,
          producto: camp[11],
          idAccion: camp[9]
        };
        i += 1;
        return entry;
      });
      setAccionesLog(datos);
      const copyDatos = datos.slice(0);
      setAccionesVisibles(copyDatos.slice(minIndex, minIndex + longitud / 5));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cargaCantidadTotal = async (filtro = '') => {
    try {
      const response = await axiosApi.get(`/api/administracion/loglength${filtro}`);
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setTotalAcciones(data.data);
      cargaDatos(start, len, 0, filtro);
    } catch (error) {
      // error
    }
  };

  useEffect(() => {
    cargaCantidadTotal();
  }, []);

  const columns = [
    {
      dataField: 'timestamp',
      text: t('Date'),
      sort: true,
      headerStyle: { maxWidth: '12%', width: '12%' },
      style: { maxWidth: '12%', width: '12%' }
    },
    {
      dataField: 'nombre',
      text: t('Team member'),
      sort: true,
      headerStyle: { maxWidth: '20%', width: '20%' },
      style: { maxWidth: '20%', width: '20%' }
    },
    {
      dataField: 'establecimiento',
      text: t('Establishment'),
      headerStyle: { maxWidth: '25vh' },
      style: { maxWidth: '25vh',
        height: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      sort: true,
      hidden: true
    },
    {
      dataField: 'producto',
      text: t('Product'),
      sort: true,
      headerStyle: { maxWidth: '15%', width: '15%' },
      style: { maxWidth: '15%', width: '15%' }
    },
    {
      dataField: 'accion',
      text: t('Action'),
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              setIsLoading(true);
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
              setLen(5 * option.page);
              setSize(option.page);
              cargaDatos(0, 5 * option.page, 0, filtroTabla);
              setPagina(1);
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const handlePageChange = (page) => {
    if (Math.floor((page - 1) / 5) * len !== start) {
      setIsLoading(true);
      const minIndex = Math.floor((page - 1) / 5) * len;
      const indiceArray = Math.floor(((page - 1) % 5) * size);
      cargaDatos(minIndex, len, indiceArray, filtroTabla);
      setStart(minIndex);
    }
    setPagina(page);
  };

  const pageOptions = {
    sizePerPage: size,
    page: pagina,
    totalSize: totalAcciones,
    sizePerPageRenderer,
    custom: true,
    showTotal: true,
    onPageChange: (page) => handlePageChange(page)
  };

  const handleTableChange = (type, { page }) => {
    if (type === 'pagination') {
      const datos = accionesLog.slice(0);
      const minIndex = ((page - 1) % 5) * size;
      setAccionesVisibles(datos.slice(minIndex, minIndex + size));
    }
  };

  const NoDataIndication = () => (
    <div className="spinner">
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
    </div>
  );

  document.title = `${t('Access log')} - ${t('Journeytok dashboard')}`;

  function actualizarTabla(event) {
    setPagina(1);
    if (event.target.value) cargaCantidadTotal(`/${event.target.value}`);
    else cargaCantidadTotal('');
    if (event.target.value !== '') {
      setFiltroTabla(`/${event.target.value}`);
    } else {
      setFiltroTabla('');
    }
    setIsLoading(true);
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Access log')} breadcrumbItems={[{ title: t('Management') }, { title: t('Access log') }]} />

          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }} />
              </Row>
              <Card>
                <Row style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1%' }}>
                  <FormGroup row style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '0.5%' }}>
                    <Label for="productoFiltro" sm={1}>{t('Product')}</Label>
                    <Col sm={2}>
                      <Input
                        type="select"
                        id="productoFiltro"
                        onChange={(event) => actualizarTabla(event)}
                      >
                        <option
                          value=""
                        >
                          Todos
                        </option>
                        {productos.map(producto => {
                          return (
                            <option key={producto}>
                              {producto}
                            </option>
                          );
                        })}
                      </Input>
                    </Col>
                  </FormGroup>
                </Row>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={accionesVisibles}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={accionesVisibles}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    remote={{ pagination: true }}
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    onTableChange={handleTableChange}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={() => <NoDataIndication />}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
            <Modal
              isOpen={isLoading}
              centered
            >
              <div
                className="modal-body"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Row>
                  <h5 id="myModalLabel">
                    {t('Loading data')}
                  </h5>
                </Row>
              </div>
              <div
                className="modal-body"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Spinner />
              </div>
            </Modal>
            {/* <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Whatsapp automation info title')}</span>
                  <br />
                  {t('Whatsapp automation info text')}
                  <br />
                  {/* <a target="_blank" href="http://helpcenter.wifreezone.com/es/articles/5849058-campsWhatsapp-de-feedback" rel="noreferrer">{t('Read more')}</a> *//* }
                </CardText>
              </Card>
            </Col> */}
          </Row>
        </div>
      </div>
    </>
  );
};

LogAcceso.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(LogAcceso);
