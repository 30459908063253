import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth
} from '../../store/actions';

// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Rightbar from '../CommonForBoth/RightSidebar';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    };
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
  }

  componentDidMount() {
    const {
      isPreloader,
      leftSideBarTheme,
      leftSideBarThemeImage,
      layoutWidth,
      leftSideBarType,
      topbarTheme
    } = this.props;
    if (isPreloader === true) {
      document.getElementById('preloader').style.display = 'block';
      document.getElementById('status').style.display = 'block';

      setTimeout(() => {
        document.getElementById('preloader').style.display = 'none';
        document.getElementById('status').style.display = 'none';
      }, 2500);
    } else {
      document.getElementById('preloader').style.display = 'none';
      document.getElementById('status').style.display = 'none';
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);
    // let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

    // document.title =
    //   currentage + " | Skote - React Admin & Dashboard Template"
    if (leftSideBarTheme) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.changeSidebarTheme(leftSideBarTheme);
    }
    if (leftSideBarThemeImage) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.changeSidebarThemeImage(leftSideBarThemeImage);
    }

    if (layoutWidth) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.changeLayoutWidth(layoutWidth);
    }

    if (leftSideBarType) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.changeSidebarType(leftSideBarType);
    }
    if (topbarTheme) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.changeTopbarTheme(topbarTheme);
    }
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  toggleMenuCallback = () => {
    const { leftSideBarType } = this.props;
    const { isMobile } = this.state;
    if (leftSideBarType === 'default') {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.changeSidebarType('condensed', isMobile);
    } else if (leftSideBarType === 'condensed') {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.changeSidebarType('default', isMobile);
    }
  };

  render() {
    const { leftSideBarType, leftSideBarTheme, children, showRightSidebar } = this.props;
    const { isMobile } = this.state;
    return (
      <>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>

        <div id="layout-wrapper">
          <Header toggleMenuCallback={this.toggleMenuCallback} />
          <Sidebar
            theme={leftSideBarTheme}
            type={leftSideBarType}
            isMobile={isMobile}
          />
          <div id="contedor-principal" className="main-content con-alerta">{children}</div>
          <Footer />
        </div>
        {showRightSidebar ? <Rightbar /> : null}
      </>
    );
  }
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  // location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
};

const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth
})(Layout);
