import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  CardText
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useHistory } from 'react-router-dom';
import axiosApi from '../../../helpers/apiResquests';

// import componente de confirmación
import Confirmacion from '../confirmacion';
// import componente de aviso
import Aviso from '../aviso';

// Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

const AlertasMessagingCenter = props => {
  const { t } = props;
  const [alertas, setAlertas] = useState([]);
  const [alertaEliminar, setAlertaElminar] = useState();
  const [alertaCambiar, setAlertaCambiar] = useState();
  const history = useHistory();
  const [confirmacion, setConfirmacion] = useState(false);
  const [successDlg, setSuccessDlg] = useState(false);
  const [errorDlg, setErrorDlg] = useState(false);

  const cargaDatos = async () => {
    try {
      const response = await axiosApi.get('/api/alertas/messagingcenter');
      let { data } = response;
      data = data.map(alerta => {
        alerta.opciones = false;
        return alerta;
      });
      setAlertas(data);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  const cambiarEstado = (event, id) => {
    event.preventDefault();
    setAlertaCambiar(id);
    const alerta = alertas.find(alertaAux => alertaAux.id_alertas === id);
    if (alerta.activa === 1) {
      setErrorDlg(true);
    } else {
      setSuccessDlg(true);
    }
  };

  const editar = (event, id) => {
    event.preventDefault();
    history.push(`/alertas/messagingcenter/${id}`);
  };

  const borrar = (event, id) => {
    event.preventDefault();
    setAlertaElminar(id);
    setConfirmacion(true);
  };

  const cambiarEstadoAlerta = async id => {
    const idAlertaCambiar = {
      id
    };
    try {
      await axiosApi.post('/api/alertas/cambiarEstado', idAlertaCambiar);
      const alertasAux = alertas.map(alerta => {
        if (alerta.id_alertas === id) {
          alerta.activa = +!alerta.activa;
        }
        return alerta;
      });
      setAlertas([]);
      setAlertas(alertasAux);
    } catch (e) {
      console.log(e);
    }
  };

  const eliminarAlerta = async id => {
    const idAlertaEliminar = {
      id
    };
    try {
      const response = await axiosApi.post('/api/alertas/borrar', idAlertaEliminar);
      const { data } = response;
      const alertasAux = alertas.filter(item => {
        return item.id_alertas !== id;
      });
      setAlertas(alertasAux);
      return data;
    } catch (e) {
      return false;
    }
  };

  const columns = [
    {
      dataField: 'activa',
      text: t('Status'),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (value) => {
        if (value === 1) {
          return <Badge className="me-1 rounded-pill bg-success">{t('Active')}</Badge>;
        }
        return <Badge className="me-1 rounded-pill bg-danger">{t('Inactive')}</Badge>;
      }
    },
    {
      dataField: 'descripcion',
      text: t('Description'),
      sort: true
    },
    {
      dataField: 'correo_receptor',
      headerAlign: 'center',
      text: t('Recipients'),
      sort: true,
      formatter: (value) => {
        if (value) {
          return (
            <ul>
              {
              value.map((correo) => {
                return <li key={correo}>{correo}</li>;
              })
              }
            </ul>
          );
        }
        return '';
      }
    },
    {
      dataField: 'periodo',
      text: t('After'),
      sort: true,
      formatter: (value) => {
        if (value % 1440 === 0) {
          return `${t('After')} ${value / 1440} ${t('Days')}`;
        }
        if (value % 60 === 0) {
          return `${t('After')} ${value / 60} ${t('Hours')}`;
        }
        return `${t('After')} ${value} ${t('Minutes')}`;
      }
    },
    {
      dataField: 'id_alertas',
      align: 'center',
      text: t('Options'),
      headerAlign: 'center',
      sort: false,
      formatter: (value, row) => {
        return (
          <UncontrolledDropdown
            direction="left"
          >
            <DropdownToggle tag="a" caret>
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu
              className="lista-opciones"
            >
              <DropdownItem
                onClick={(event) => cambiarEstado(event, value)}
              >
                {row.activa === 1 &&
                t('Disable')}
                {row.activa === 0 &&
                t('Enable')}
              </DropdownItem>
              <DropdownItem onClick={(event) => editar(event, value)}>{t('Edit')}</DropdownItem>
              <DropdownItem onClick={(event) => borrar(event, value)}>{t('Remove')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: alertas.length,
    sizePerPageRenderer,
    custom: true,
    showTotal: true
  };

  document.title = `${t('Messaging Center reports')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Messaging Center reports')} breadcrumbItems={[{ title: t('Settings') }, { title: t('Messaging Center reports') }]} />

          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }}>
                  <Button
                    color="primary"
                    className="mt-1"
                    onClick={() => {
                      history.push('/alertas/messagingcenter/crear');
                    }}
                  >
                    {t('New report')}
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id_alertas"
                    columns={columns}
                    data={alertas}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id_alertas"
                        columns={columns}
                        data={alertas}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id_alertas"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('MC reports info title')}</span>
                  <br />
                  {t('MC reports info text')}
                  <br />
                  <a target="_blank" href="http://helpcenter.journeytok.com/es/articles/5850511-alertas-de-messaging-center" rel="noreferrer">{t('Read more')}</a>
                </CardText>
              </Card>
            </Col>
            <Confirmacion
              confirmacion={confirmacion}
              onConfirmacionChange={setConfirmacion}
              eliminarAlerta={eliminarAlerta}
              alertaEliminar={alertaEliminar}
            />
            <Aviso
              successDlg={successDlg}
              errorDlg={errorDlg}
              cambiarEstadoAlerta={cambiarEstadoAlerta}
              onSuccessDlgChange={setSuccessDlg}
              onErrorDlgChange={setErrorDlg}
              alertaCambiar={alertaCambiar}
            />
          </Row>
        </div>
      </div>
    </>
  );
};

AlertasMessagingCenter.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(AlertasMessagingCenter);
