/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/react-in-jsx-scope */
import { defaultValidator, QueryBuilder } from 'react-querybuilder';
import './filtrosEstilos.scss';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryBuilderMaterial, MaterialActionElement } from '@react-querybuilder/material';
import {
  Button
} from 'reactstrap';
// import ReactSelect from 'react-select';
import fields from './ListadoFiltros';
import CustomValueEditor from './CustomValueEditor';
import CustomFieldSelector from './CustomFieldSelector';

const muiTheme = createTheme({
  typography: {
    allVariants: {
      color: '#495057'
    }
  }
});

const combinators = [
  { name: 'and', label: 'Y' },
  { name: 'or', label: 'O' },
];

const FiltrosSegmentos = (props) => {
  const { t, query, setQuery } = props;
  const { objeto } = props;

  const translations = {
    fields: {
      title: 'Fields',
      placeholderName: '~',
      placeholderLabel: t('Select filter'),
      placeholderGroupLabel: ''

    },
    operators: {
      title: 'Operators',
      placeholderName: '~',
      placeholderLabel: '------',
      placeholderGroupLabel: '------'
    },
    value: {
      title: 'Value'
    },
    removeRule: {
      label: t('Remove rule'),
      title: t('Remove rule')
    },
    removeGroup: {
      label: t('Remove'),
      title: t('Remove')
    },
    addRule: {
      label: t('Y'),
      title: t('Add rule')
    },
    addGroup: {
      label: t('O'),
      title: 'Add group'
    },
    combinators: {
      title: 'Combinators'
    },
    notToggle: {
      label: 'Not',
      title: 'Invert this group'
    },
    cloneRule: {
      label: '⧉',
      title: 'Clone rule'
    },
    cloneRuleGroup: {
      label: '⧉',
      title: 'Clone group'
    },
    dragHandle: {
      label: '⁞⁞',
      title: 'Drag handle'
    },
    lockRule: {
      label: '🔓',
      title: 'Lock rule'
    },
    lockGroup: {
      label: '🔓',
      title: 'Lock group'
    },
    lockRuleDisabled: {
      label: '🔒',
      title: 'Unlock rule'
    },
    lockGroupDisabled: {
      label: '🔒',
      title: 'Unlock group'
    }
  };

  const addRuleAction = props => {
    const { level } = props;
    if (level !== 0) {
      return <MaterialActionElement {...props} />;
    }
    return null;
  };

  const addGroupAction = props => {
    const { level } = props;
    if (level === 0) {
      return <MaterialActionElement className="mt-1 btn btn-link" {...props} />;
    }
    return null;
  };

  const combinatorSelector = props => {
    const { value } = props;
    if (value.toLowerCase() === 'and') {
      return <div>Y</div>;
    }
    return <div>O</div>;
  };

  const removeRuleAction = props => {
    const { handleOnClick, ...rest } = props;
    delete rest?.testID;
    delete rest?.ruleOrGroup;
    return (
      <Button
        {...rest}
        onClick={handleOnClick}
      >
        <i className="bx bx-trash" />
      </Button>
    );
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <QueryBuilderMaterial>
        <QueryBuilder
          fields={fields}
          query={query}
          addRuleToNewGroups
          autoSelectField={false}
          combinators={combinators}
          showCombinatorsBetweenRules
          onQueryChange={q => {
            setQuery(q);
          }}
          validator={defaultValidator}
          translations={translations}
          controlElements={{
            valueEditor: CustomValueEditor,
            // deshabilitamos la creación de grupos en los subgrupos,
            // sólo disponible en el primer nivel
            addGroupAction,
            // deshabilitamos la creacción de reglas en el primer nivel,
            // sólo disponible en los subgrupos
            addRuleAction,
            // ocultamos los selectores de los operandos entre los grupos
            // los operandos serán fijos OR entre los grupos y AND en el mismo grupo
            // removeGroupAction,
            // el selector de la condición lo dejamos fijo con el valor que tenga
            combinatorSelector,
            removeRuleAction,
            fieldSelector: (props) => <CustomFieldSelector objeto={objeto} {...props} />
          }}
        />
      </QueryBuilderMaterial>
    </ThemeProvider>
  );
};

FiltrosSegmentos.propTypes = {
  t: PropTypes.any,
  query: PropTypes.any,
  setQuery: PropTypes.func
};

export default withTranslation()(FiltrosSegmentos);
