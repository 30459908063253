import i18n from 'i18next';

import languageList from './languageList.json';

function traducirListaIdiomas() {
  const nuevaLista = {};
  Object.keys(languageList).forEach(clave => {
    nuevaLista[clave] = i18n.t(languageList[clave]);
  });
  return nuevaLista;
}

const listaTraducida = traducirListaIdiomas();

export default listaTraducida;
