import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Media
} from 'reactstrap';
import moment from 'moment';

// import Charts
import { withTranslation } from 'react-i18next';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import axiosApi from '../../helpers/apiResquests';

// Pages Components
import Pie from '../Charts/Pie';
import BarChartApex from '../Charts/BarChartApex';
import ColumnChart from '../Charts/ColumnChart';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';

const Costes = props => {
  const { t } = props;

  const [conversacionesTotal, setConversacionesTotal] = useState(0);
  const [costeTotal, setCosteTotal] = useState(0);
  const [gratisRestantes, setGratisRestantes] = useState(0);
  const [costeCliente, setCosteCliente] = useState(0);
  const [totalCliente, setTotalCliente] = useState(0);
  const [costeEmpresa, setCosteEmpresa] = useState(0);
  const [totalEmpresa, setTotalEmpresa] = useState(0);
  const [countriesCostsData, setCountriesCostsData] = useState({ categories: [], data: [] });
  const [costesHistoricos, setCostesHistoricos] = useState({ categories: [], data: [] });
  const [countriesConversationsData, setCountriesConversationsData] = useState({
    categories: [],
    data: []
  });
  const [columnCategories, setColumnCategories] = useState([]);
  const canalesDataOriginal = [
    {
      name: t('Customer'),
      data: []
    },
    {
      name: t('Company'),
      data: []
    }
  ];
  const [canalesData, setCanalesData] = useState(canalesDataOriginal);

  const [reports, setReports] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function togLoadingModal() {
    setModalLoading(!modalLoading);
    removeBodyCss();
  }

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const loadData = async () => {
    try {
      const response = await axiosApi.get(
        '/api/whatsapp/costes'
      );
      const {
        datos,
        anteriores,
        error
      } = response.data;
      if (error) {
        setModalLoading(false);
        showToast(t('Error getting data'), t('The Whatsapp API costs can\'t be fetched'), 'error');
        return;
      }
      const {
        totalConversaciones,
        freeTier,
        totalCoste,
        cliente,
        empresa,
        paises
      } = datos;
      if (totalConversaciones) {
        setConversacionesTotal(totalConversaciones);
      }
      if (freeTier) {
        setGratisRestantes(1000 - freeTier);
      }
      if (totalCoste) {
        setCosteTotal(parseFloat(totalCoste.toFixed(2)));
      }
      if (cliente?.total) {
        setTotalCliente(cliente.total);
      }
      if (cliente?.coste) {
        setCosteCliente(parseFloat(cliente.coste.toFixed(2)));
      }
      if (empresa?.total) {
        setTotalEmpresa(empresa.total);
      }
      if (empresa?.coste) {
        setCosteEmpresa(parseFloat(empresa.coste.toFixed(2)));
      }
      if (paises) {
        const countriesDataCostsParaGrafica = {};
        countriesDataCostsParaGrafica.categories = [];
        countriesDataCostsParaGrafica.data = [];
        Object.keys(paises).forEach(element => {
          countriesDataCostsParaGrafica.categories.push(element);
          countriesDataCostsParaGrafica.data.push(parseFloat(paises[element].coste.toFixed(2)));
        });
        setCountriesCostsData(countriesDataCostsParaGrafica);

        const countriesDataParaGrafica = {};
        countriesDataParaGrafica.categories = [];
        countriesDataParaGrafica.data = [];
        Object.keys(paises).forEach(element => {
          countriesDataParaGrafica.categories.push(element);
          countriesDataParaGrafica.data.push(paises[element].total);
        });
        setCountriesConversationsData(countriesDataParaGrafica);
      }

      const costesPorMesesParaGrafica = {};
      costesPorMesesParaGrafica.categories = [];
      costesPorMesesParaGrafica.data = [];
      if (anteriores && anteriores.length > 0) {
        let fecha;
        anteriores.forEach(a => {
          fecha = moment(a.fecha).subtract(1, 'months');
          const d = JSON.parse(a.datos);
          const month = fecha.format('MMMM');
          const year = fecha.format('YY');
          costesPorMesesParaGrafica.categories.push(t(month) + year);
          costesPorMesesParaGrafica.data.push(
            parseFloat(parseFloat(d.totalCoste).toFixed(2), 10)
          );
        });
      }
      setCostesHistoricos(costesPorMesesParaGrafica);

      const canalesParaGrafica = structuredClone(canalesDataOriginal);
      const categoriesParaGrafica = [];
      if (anteriores && anteriores.length > 0) {
        let fecha;
        anteriores.forEach(a => {
          fecha = moment(a.fecha).subtract(1, 'months');
          const d = JSON.parse(a.datos);
          const month = fecha.format('MMMM');
          const year = fecha.format('YY');

          categoriesParaGrafica.push(t(month) + year);
          if (d.cliente.coste) {
            canalesParaGrafica[0].data.push(parseFloat(d.cliente.coste.toFixed(2)));
          } else {
            canalesParaGrafica[0].data.push(0);
          }
          if (d.empresa.coste) {
            canalesParaGrafica[1].data.push(parseFloat(d.empresa.coste.toFixed(2)));
          } else {
            canalesParaGrafica[1].data.push(0);
          }
        });
      }

      setCanalesData(canalesParaGrafica);
      setColumnCategories(categoriesParaGrafica);
    } catch (error) {
      console.log(error);
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setReports([
      {
        icon: 'bx bxs-chat',
        title: t('This month\'s conversations'),
        value: conversacionesTotal,
      },
      {
        icon: 'bx bx-euro',
        title: t('Spent this month'),
        value: `${costeTotal} €`
      },
      {
        icon: 'bx bxs-chat',
        title: t('Remaining free'),
        value: gratisRestantes
      }
    ]);
  }, [
    conversacionesTotal,
    costeTotal,
    gratisRestantes
  ]);

  document.title = `${t('Whatsapp API pricing')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t('Whatsapp API pricing')}
            breadcrumbItems={[{ title: t('Whatsapp') }, { title: t('Whatsapp API pricing') }]}
          />
          <Row>
            <Col xl="12">
              <Col xl="12">
                <Row>
                  {reports.map((report, key) => (
                    <Col md="4" key={`_col_${key}`}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.value}</h4>
                            </Media>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i className={`bx ${report.icon} font-size-24`} />
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>{t('Conversations per communication direction')}</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <Pie
                      title={t('Conversations per communication direction')}
                      data={[
                        { name: t('Initiated by customer'), value: totalCliente, color: '#9405E6' },
                        { name: t('Initiated by company'), value: totalEmpresa, color: '#0530FE' },
                      ]}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>{t('Conversations per country')}</CardTitle>
                  <div id="doughnut-chart" className="e-chart">
                    <BarChartApex categories={countriesConversationsData.categories} data={countriesConversationsData.data} horizontal seriesName={t('Conversations per country')} color="#6812FC" toolbar />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>{t('Cost per communication direction')}</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <Pie
                      title={t('Cost per communication direction')}
                      data={[
                        { name: t('Initiated by customer'), value: costeCliente, color: '#00af87' },
                        { name: t('Initiated by company'), value: costeEmpresa, color: '#f4c20d' },
                      ]}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>{t('Costs per country')}</CardTitle>
                  <div id="doughnut-chart" className="e-chart">
                    <BarChartApex categories={countriesCostsData.categories} data={countriesCostsData.data} horizontal seriesName={t('Costs per country')} color="#34c38f" toolbar />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>{t('Historical costs')}</CardTitle>
                  <div id="doughnut-chart" className="e-chart">
                    <BarChartApex categories={costesHistoricos.categories} data={costesHistoricos.data} horizontal={false} seriesName={t('Total costs')} color="#556EE6" toolbar />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>{t('Cost per communication direction')}</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <ColumnChart categories={columnCategories} series={canalesData} title={t('New')} toolbar />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={modalLoading}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t('Loading data')}
            </h5>
            <button
              type="button"
              onClick={() => {
                togLoadingModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-3">
              {t('We are loading the data. They will appear shortly.')}
            </div>
          </div>
          <div className="modal-footer" />
        </Modal>
      </div>
    </>
  );
};

Costes.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Costes);
