import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardText, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';

// Import Cards
import Plantilla from './Plantilla';

const Plantillas = props => {
  const { t } = props;
  const [templates, setTemplates] = useState([]);
  const history = useHistory();

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargaDatos = async () => {
    try {
      const response = await axiosApi.get('/api/whatsapp/plantillas');
      let { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      if (data) {
        data = data.filter(e => {
          return !(e.name.startsWith('sample_') || e.name === 'hello_world');
        });
        data.forEach(template => {
          template.components.forEach(component => {
            switch (component.type) {
              case 'HEADER':
                if (component.example?.header_text) {
                  component.example.header_text.forEach((element, index) => {
                    component.text = component.text.replace(`{{${index + 1}}}`, element);
                  });
                }
                if (component.text) {
                  component.text = component.text
                    .replace(/\*(.*?)\*/gm, '<strong>$1</strong>')
                    .replace(/\n/gm, '<br>')
                    .replace(/~(.*?)~/gm, '<i>$1</i>');
                }
                break;
              case 'BODY':
                if (component.example?.body_text[0]) {
                  component.example.body_text[0].forEach((element, index) => {
                    component.text = component.text.replace(`{{${index + 1}}}`, element);
                  });
                }
                if (component.text) {
                  component.text = component.text
                    .replace(/\*(.*?)\*/gm, '<strong>$1</strong>')
                    .replace(/\n/gm, '<br>')
                    .replace(/~(.*?)~/gm, '<i>$1</i>');
                }
                break;
              default:
            }
          });
        });
      }
      setTemplates(data);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  document.title = `${t('Templates')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={t('Templates')}
            breadcrumbItems={[{ title: t('Whatsapp') }, { title: t('Templates') }]}
          />

          <Row>
            <Row className="mb-2">
              <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }}>
                <Button
                  color="primary"
                  className="mt-1"
                  onClick={() => {
                    history.push('/whatsapp/plantillas/nuevaPlantilla');
                  }}
                >
                  {t('New Whatsapp template')}
                </Button>
              </Col>
            </Row>
            <Plantilla templates={templates} setTemplates={setTemplates} />
            <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Whatsapp templates info title')}</span>
                  <br />
                  {t('Whatsapp templates info text')}
                  <br />
                  {/* <a target="_blank" href="http://helpcenter.wifreezone.com/es/articles/5849058-campsWhatsapp-de-feedback" rel="noreferrer">{t('Read more')}</a> */}
                </CardText>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </>
  );
};

Plantillas.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Plantillas);
