import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input,
  // Badge,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Modal,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';
import listaTraducida from '../../common/traducirListaIdiomas';

const ListadoDuplicados = props => {
  const { t } = props;
  const [duplicados, setDuplicados] = useState([]);
  const [nombreCompletoDuplicado, setNombreCompletoDuplicado] = useState();
  const [nombreDuplicado, setNombreDuplicado] = useState();
  const [apellidoDuplicado, setApellidoDuplicado] = useState();
  const [emailDuplicado, setEmailDuplicado] = useState();
  const [email2Duplicado, setEmail2Duplicado] = useState();
  const [tlfDuplicado, setTlfDuplicado] = useState();
  const [dniDuplicado, setDniDuplicado] = useState();
  const [rgpdDuplicado, setRgpdDuplicado] = useState();
  const [idiomaDuplicado, setIdiomaDuplicado] = useState();
  const [fecNacDuplicado, setFecNacDuplicado] = useState();
  const [nacionalidadDuplicado, setNacionalidadDuplicado] = useState();
  const [tipoDocumento, setTipoDocumento] = useState();
  const [rgpd2Duplicado, setRgpd2Duplicado] = useState();
  const [rgpd3Duplicado, setRgpd3Duplicado] = useState();
  const [fechaExpDoc, setFechaExpDoc] = useState();
  const [fechaCadDoc, setFechaCadDoc] = useState();
  const [generoDuplicado, setGeneroDuplicado] = useState();
  const [cpDuplicado, setCPDuplicado] = useState();
  const [checkNombre, setCheckNombre] = useState(true);
  const [checkApellido, setCheckApellido] = useState(true);
  const [checkEmail, setCheckEmail] = useState(true);
  const [checkEmail2, setCheckEmail2] = useState(true);
  const [checkTlf, setCheckTlf] = useState(true);
  const [checkDni, setCheckDni] = useState(true);
  const [checkIdioma, setCheckIdioma] = useState(true);
  const [checkFecNac, setCheckFecNac] = useState(true);
  const [checkNacionalidadDuplicado, setCheckNacionalidadDuplicado] = useState(true);
  const [checkTipoDocumento, setCheckTipoDocumento] = useState(true);
  const [checkFechaExpDoc, setCheckFechaExpDoc] = useState(true);
  const [checkFechaCadDoc, setCheckFechaCadDoc] = useState(true);
  const [checkGenero, setCheckGenero] = useState(true);
  const [checkCP, setCheckCP] = useState(true);
  const [start, setStart] = useState(0);
  const [len, setLen] = useState(50);
  const [size, setSize] = useState(10);
  const [totalDuplicados, setTotalDuplicados] = useState(0);
  const [duplicadosVisibles, setDuplicadosVisibles] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [cargando, setCargando] = useState(true);
  const [orden, setOrden] = useState('desc');
  const [porcentaje, setPorcentaje] = useState(0);
  const [duplicado1, setDuplicado1] = useState(0);
  const [duplicado2, setDuplicado2] = useState(0);
  const [modalMantenerSeparados, setModalMantenerSeparados] = useState(false);

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  function cambiarEstado(id, prim) {
    setDuplicadosVisibles((prevDuplicados) => {
      return prevDuplicados.map((duplicado) => {
        if (duplicado.id === id) {
          return { ...duplicado, mostrar_fusion: !duplicado.mostrar_fusion };
        }
        return { ...duplicado, mostrar_fusion: false };
      });
    });
    setCheckNombre(prim);
    setCheckApellido(prim);
    if (prim) {
      setNombreCompletoDuplicado(duplicados[id].usuario1.nombreCompleto);
      setNombreDuplicado(duplicados[id].usuario1.nombre);
      setApellidoDuplicado(duplicados[id].usuario1.apellido);

      if (duplicados[id].usuario1.tlf || !duplicados[id].usuario2.tlf) {
        setTlfDuplicado(duplicados[id].usuario1.tlf);
        setCheckTlf(true);
        setRgpd3Duplicado(duplicados[id].usuario1.acepta_comercial3);
      } else {
        setTlfDuplicado(duplicados[id].usuario2.tlf);
        setCheckTlf(false);
        setRgpd3Duplicado(duplicados[id].usuario2.acepta_comercial3);
      }

      if (duplicados[id].usuario1.dni || !duplicados[id].usuario2.dni) {
        setDniDuplicado(duplicados[id].usuario1.dni);
        setCheckDni(true);
      } else {
        setDniDuplicado(duplicados[id].usuario2.dni);
        setCheckDni(false);
      }

      if (duplicados[id].usuario1.email && !duplicados[id].usuario1.email.includes('booking') &&
        !duplicados[id].usuario1.email.includes('expedia')) {
        setEmailDuplicado(duplicados[id].usuario1.email);
        setRgpdDuplicado(duplicados[id].usuario1.acepta_comercial);
        if (duplicados[id].usuario1.email2 && !duplicados[id].usuario1.email2.includes('booking') &&
        !duplicados[id].usuario1.email2.includes('expedia')) {
          setEmail2Duplicado(duplicados[id].usuario1.email2);
          setRgpd2Duplicado(duplicados[id].usuario1.acepta_comercial2);
        } else if (duplicados[id].usuario2.email && !duplicados[id].usuario2.email.includes('booking') &&
        !duplicados[id].usuario2.email.includes('expedia') && duplicados[id].usuario1.email !== duplicados[id].usuario2.email) {
          setEmail2Duplicado(duplicados[id].usuario2.email);
          setRgpd2Duplicado(duplicados[id].usuario2.acepta_comercial);
        }
        setCheckEmail(true);
        setCheckEmail2(true);
      } else {
        setEmailDuplicado(duplicados[id].usuario2.email);
        setRgpdDuplicado(duplicados[id].usuario2.acepta_comercial);
        setEmail2Duplicado(duplicados[id].usuario1.email);
        setRgpd2Duplicado(duplicados[id].usuario1.acepta_comercial);
        setCheckEmail(true);
      }

      if (duplicados[id].usuario1.idioma || !duplicados[id].usuario2.idioma) {
        setIdiomaDuplicado(duplicados[id].usuario1.idioma);
        setCheckIdioma(true);
      } else {
        setIdiomaDuplicado(duplicados[id].usuario2.idioma);
        setCheckIdioma(false);
      }

      if (duplicados[id].usuario1.fec_nac || !duplicados[id].usuario2.fec_nac) {
        setFecNacDuplicado(duplicados[id].usuario1.fec_nac);
        setCheckFecNac(true);
      } else {
        setFecNacDuplicado(duplicados[id].usuario2.fec_nac);
        setCheckFecNac(false);
      }
    } else {
      setNombreCompletoDuplicado(duplicados[id].usuario1.nombreCompleto);
      setNombreDuplicado(duplicados[id].usuario2.nombre);
      setApellidoDuplicado(duplicados[id].usuario2.apellido);

      if (duplicados[id].usuario2.tlf || !duplicados[id].usuario1.tlf) {
        setTlfDuplicado(duplicados[id].usuario2.tlf);
        setCheckTlf(false);
        setRgpd3Duplicado(duplicados[id].usuario2.acepta_comercial3);
      } else {
        setTlfDuplicado(duplicados[id].usuario1.tlf);
        setCheckTlf(true);
        setRgpd3Duplicado(duplicados[id].usuario1.acepta_comercial3);
      }

      if (duplicados[id].usuario2.dni || !duplicados[id].usuario1.dni) {
        setDniDuplicado(duplicados[id].usuario2.dni);
        setCheckDni(false);
      } else {
        setDniDuplicado(duplicados[id].usuario1.dni);
        setCheckDni(true);
      }

      if (duplicados[id].usuario2.email && !duplicados[id].usuario2.email.includes('booking') &&
        !duplicados[id].usuario2.email.includes('expedia')) {
        setEmailDuplicado(duplicados[id].usuario2.email);
        setRgpdDuplicado(duplicados[id].usuario2.acepta_comercial);
        if (duplicados[id].usuario2.email2 && !duplicados[id].usuario2.email2.includes('booking') &&
        !duplicados[id].usuario2.email2.includes('expedia')) {
          setEmail2Duplicado(duplicados[id].usuario2.email2);
          setRgpdDuplicado(duplicados[id].usuario2.acepta_comercial2);
        } else if (duplicados[id].usuario1.email && !duplicados[id].usuario1.email.includes('booking') &&
        !duplicados[id].usuario1.email.includes('expedia') && duplicados[id].usuario1.email !== duplicados[id].usuario2.email) {
          setEmail2Duplicado(duplicados[id].usuario1.email);
          setRgpdDuplicado(duplicados[id].usuario1.acepta_comercial);
        }
        setCheckEmail(false);
        setCheckEmail2(false);
      } else {
        setEmailDuplicado(duplicados[id].usuario1.email);
        setEmail2Duplicado(duplicados[id].usuario2.email);
        setRgpdDuplicado(duplicados[id].usuario1.acepta_comercial);
        setRgpd2Duplicado(duplicados[id].usuario2.acepta_comercial);
        setCheckEmail(true);
        setCheckEmail2(true);
      }

      if (duplicados[id].usuario2.idioma || !duplicados[id].usuario1.idioma) {
        setIdiomaDuplicado(duplicados[id].usuario2.idioma);
        setCheckIdioma(false);
      } else {
        setIdiomaDuplicado(duplicados[id].usuario1.idioma);
        setCheckIdioma(true);
      }

      if (duplicados[id].usuario2.fec_nac || !duplicados[id].usuario1.fec_nac) {
        setFecNacDuplicado(duplicados[id].usuario2.fec_nac);
        setCheckFecNac(false);
      } else {
        setFecNacDuplicado(duplicados[id].usuario1.fec_nac);
        setCheckFecNac(true);
      }
    }
  }

  const cargaTotalDuplicados = async () => {
    try {
      const response = await axiosApi.get(`/api/locales/totalMiembrosDuplicados/${porcentaje}`);
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setTotalDuplicados(data.total);
    } catch (error) {
      // error
    }
  };

  const cargaDatos = async (inicio = 0, longitud = 50, tamaño = 10, minIndex = 0) => {
    try {
      const response = await axiosApi.get(`/api/locales/miembrosDuplicados/${inicio + minIndex}/${tamaño}/${orden}/${porcentaje}`);
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      let i = minIndex;
      const datos = [];
      data.usuarios.forEach((arrayUsuario) => {
        let fec1 = arrayUsuario[0].fecha_nacimiento ? arrayUsuario[0].fecha_nacimiento.split(' ')[0] : '';
        let fec2 = arrayUsuario[1].fecha_nacimiento ? arrayUsuario[1].fecha_nacimiento.split(' ')[0] : '';
        if (fec1 < '1900-12-31') {
          fec1 = '';
        }
        if (fec2 < '1900-12-31') {
          fec2 = '';
        }
        const dato = {
          id: i,
          similitud: arrayUsuario[2],
          usuario1: {
            nombreCompleto: arrayUsuario[0].nombre,
            nombre: arrayUsuario[0].first_name,
            apellido: arrayUsuario[0].last_name,
            dni: arrayUsuario[0].dni,
            email: arrayUsuario[0].email,
            email2: arrayUsuario[0].email2,
            tlf: arrayUsuario[0].telefono,
            incorporacion: arrayUsuario[0].fec_incorporacion,
            via_visita: arrayUsuario[0].via_visita,
            ultima_estancia: `${arrayUsuario[0].checkin ? arrayUsuario[0].checkin.split(' ')[0] : ''} - ${arrayUsuario[0].checkout ? arrayUsuario[0].checkout.split(' ')[0] : ''}`,
            num_estancias: arrayUsuario[0].num_estancias || '-',
            idioma: arrayUsuario[0].locale,
            acepta_comercial: arrayUsuario[0].rgpd_email,
            encripted: arrayUsuario[0].encripted,
            fec_nac: fec1,
            via_reserva: arrayUsuario[0].via_entrada,
            macs: arrayUsuario[0].MACs ? arrayUsuario[0].MACs.split(', ') : '',
            acepta_comercial2: arrayUsuario[0].rgpd_email2,
            acepta_comercial3: arrayUsuario[0].rgpd_tlf,
            nacionalidad: arrayUsuario[0].nacionalidad,
            tipo_documento: arrayUsuario[0].tipo_identificador,
            id: arrayUsuario[0].id
          },
          usuario2: {
            nombreCompleto: arrayUsuario[1].nombre,
            nombre: arrayUsuario[1].first_name,
            apellido: arrayUsuario[1].last_name,
            dni: arrayUsuario[1].dni,
            email: arrayUsuario[1].email,
            email2: arrayUsuario[1].email2,
            tlf: arrayUsuario[1].telefono,
            incorporacion: arrayUsuario[1].fec_incorporacion,
            via_visita: arrayUsuario[1].via_visita,
            ultima_estancia: `${arrayUsuario[1].checkin ? arrayUsuario[1].checkin.split(' ')[0] : ''} - ${arrayUsuario[1].checkout ? arrayUsuario[1].checkout.split(' ')[0] : ''}`,
            num_estancias: arrayUsuario[1].num_estancias || '-',
            idioma: arrayUsuario[1].locale,
            acepta_comercial: arrayUsuario[1].rgpd_email,
            encripted: arrayUsuario[1].encripted,
            fec_nac: fec2,
            via_reserva: arrayUsuario[1].via_entrada,
            macs: arrayUsuario[1].MACs ? arrayUsuario[1].MACs.split(', ') : '',
            acepta_comercial2: arrayUsuario[1].rgpd_email2,
            acepta_comercial3: arrayUsuario[1].rgpd_tlf,
            nacionalidad: arrayUsuario[1].nacionalidad,
            tipo_documento: arrayUsuario[1].tipo_identificador,
            id: arrayUsuario[1].id
          },
          mostrar_fusion: false,
          primario: true
        };
        i += 1;
        datos.push(dato);
      });
      setDuplicadosVisibles(datos);
      setCargando(false);
    } catch (error) {
      console.log(`error: ${error}`);
    }
    try {
      const response = await axiosApi.get(`/api/locales/miembrosDuplicados/${inicio}/${longitud}/${orden}/${porcentaje}`);
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      let i = 0;
      const datos = [];
      data.usuarios.forEach((arrayUsuario) => {
        let fec1 = arrayUsuario[0].fecha_nacimiento ? arrayUsuario[0].fecha_nacimiento.split(' ')[0] : '';
        let fec2 = arrayUsuario[1].fecha_nacimiento ? arrayUsuario[1].fecha_nacimiento.split(' ')[0] : '';
        if (fec1 < '1900-12-31') {
          fec1 = '';
        }
        if (fec2 < '1900-12-31') {
          fec2 = '';
        }
        const dato = {
          id: i,
          similitud: arrayUsuario[2],
          usuario1: {
            nombreCompleto: arrayUsuario[0].nombre,
            nombre: arrayUsuario[0].first_name,
            apellido: arrayUsuario[0].last_name,
            dni: arrayUsuario[0].dni,
            email: arrayUsuario[0].email,
            email2: arrayUsuario[0].email2,
            tlf: arrayUsuario[0].telefono,
            incorporacion: arrayUsuario[0].fec_incorporacion,
            via_visita: arrayUsuario[0].via_visita,
            ultima_estancia: `${arrayUsuario[0].checkin ? arrayUsuario[0].checkin.split(' ')[0] : ''} - ${arrayUsuario[0].checkout ? arrayUsuario[0].checkout.split(' ')[0] : ''}`,
            num_estancias: arrayUsuario[0].num_estancias || '-',
            idioma: arrayUsuario[0].locale,
            acepta_comercial: arrayUsuario[0].rgpd_email,
            encripted: arrayUsuario[0].encripted,
            fec_nac: fec1,
            via_reserva: arrayUsuario[0].via_entrada,
            macs: arrayUsuario[0].MACs ? arrayUsuario[0].MACs.split(', ') : '',
            acepta_comercial2: arrayUsuario[0].rgpd_email,
            acepta_comercial3: arrayUsuario[0].rgpd3
          },
          usuario2: {
            nombreCompleto: arrayUsuario[1].nombre,
            nombre: arrayUsuario[1].first_name,
            apellido: arrayUsuario[1].last_name,
            dni: arrayUsuario[1].dni,
            email: arrayUsuario[1].email,
            email2: arrayUsuario[1].email2,
            tlf: arrayUsuario[1].telefono,
            incorporacion: arrayUsuario[1].fec_incorporacion,
            via_visita: arrayUsuario[1].via_visita,
            ultima_estancia: `${arrayUsuario[1].checkin ? arrayUsuario[1].checkin.split(' ')[0] : ''} - ${arrayUsuario[1].checkout ? arrayUsuario[1].checkout.split(' ')[0] : ''}`,
            num_estancias: arrayUsuario[1].num_estancias || '-',
            idioma: arrayUsuario[1].locale,
            acepta_comercial: arrayUsuario[1].rgpd,
            encripted: arrayUsuario[1].encripted,
            fec_nac: fec2,
            via_reserva: arrayUsuario[1].via_entrada,
            macs: arrayUsuario[1].MACs ? arrayUsuario[1].MACs.split(', ') : '',
            acepta_comercial2: arrayUsuario[0].rgpd2,
            acepta_comercial3: arrayUsuario[0].rgpd3
          },
          mostrar_fusion: false,
          primario: true
        };
        i += 1;
        datos.push(dato);
      });
      setDuplicados(datos);
    } catch (error) {
      console.log(`error: ${error}`);
    }
  };

  function cambiarPrimario(id) {
    setDuplicadosVisibles((prevDuplicados) => {
      return prevDuplicados.map((duplicado) => {
        if (duplicado.id === id) {
          return { ...duplicado, primario: !duplicado.primario };
        }
        return { ...duplicado };
      });
    });
  }

  function cambiarFusion(campo, valor1, valor2, valor3 = '', valor4 = '', valor5 = '', valor6 = '') {
    let valor;
    let valorE2;
    let valorRpgd1;
    let valorRpgd2;
    switch (campo) {
      case 1:
        valor = !checkNombre ? valor1 : valor2;
        setCheckNombre(!checkNombre);
        setNombreDuplicado(valor);
        setNombreCompletoDuplicado(`${valor} ${apellidoDuplicado}`);
        break;
      case 2:
        valor = !checkApellido ? valor1 : valor2;
        setCheckApellido(!checkApellido);
        setApellidoDuplicado(valor);
        setNombreCompletoDuplicado(`${nombreDuplicado} ${valor}`);
        break;
      case 3:
        valor = !checkEmail ? valor1 : valor2;
        valorE2 = checkEmail ? valor1 : valor2;
        valorRpgd1 = !checkEmail ? valor5 : valor6;
        valorRpgd2 = checkEmail ? valor5 : valor6;
        if (!valor4 && !valor3 && valor !== valorE2) {
          setEmail2Duplicado(valorE2);
          setRgpd2Duplicado(valorRpgd2);
        }
        setCheckEmail(!checkEmail);
        setEmailDuplicado(valor);
        setRgpdDuplicado(valorRpgd1);
        break;
      case 4:
        valor = !checkEmail2 ? valor1 : valor2;
        valorE2 = !checkEmail2 ? valor3 : valor4;
        setCheckEmail2(!checkEmail2);
        setRgpd2Duplicado(valorE2);
        setEmail2Duplicado(valor);
        break;
      case 5:
        valor = !checkTlf ? valor1 : valor2;
        valorE2 = !checkTlf ? valor3 : valor4;
        setCheckTlf(!checkTlf);
        setRgpd3Duplicado(valorE2);
        setTlfDuplicado(valor);
        break;
      case 6:
        valor = !checkDni ? valor1 : valor2;
        setCheckDni(!checkDni);
        setDniDuplicado(valor);
        break;
      case 10:
        valor = !checkIdioma ? valor1 : valor2;
        setCheckIdioma(!checkIdioma);
        setIdiomaDuplicado(valor);
        break;
      case 11:
        valor = !checkFecNac ? valor1 : valor2;
        setCheckFecNac(!checkFecNac);
        setFecNacDuplicado(valor);
        break;
      case 13:
        valor = !checkNacionalidadDuplicado ? valor1 : valor2;
        setCheckNacionalidadDuplicado(!checkNacionalidadDuplicado);
        setNacionalidadDuplicado(valor);
        break;
      case 14:
        valor = !checkCP ? valor1 : valor2;
        setCheckCP(!checkCP);
        setCPDuplicado(valor);
        break;
      case 15:
        valor = !checkFechaExpDoc ? valor1 : valor2;
        setCheckFechaExpDoc(!checkFechaExpDoc);
        setFechaExpDoc(valor);
        break;
      case 16:
        valor = !checkFechaCadDoc ? valor1 : valor2;
        setCheckFechaCadDoc(!checkFechaCadDoc);
        setFechaCadDoc(valor);
        break;
      case 17:
        valor = !checkTipoDocumento ? valor1 : valor2;
        setCheckTipoDocumento(!checkTipoDocumento);
        setTipoDocumento(valor);
        break;
      case 18:
        valor = !checkGenero ? valor1 : valor2;
        setCheckGenero(!checkGenero);
        setGeneroDuplicado(valor);
        break;
      default:
        break;
    }
  }

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];

  const columns = [
    {
      dataField: 'id',
      text: '',
      formatter: () => {

      }
    }
  ];
  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={() => {
              onSizePerPageChange(option.page);
              // if (e) e.preventDefault();
              setCargando(true);
              setLen(5 * option.page);
              setSize(option.page);
              cargaDatos(0, 5 * option.page, option.page);
              setPagina(1);
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const handlePageChange = (page) => {
    if (Math.floor((page - 1) / 5) * len !== start) {
      const minIndex = Math.floor((page - 1) / 5) * len;
      const indiceArray = Math.floor(((page - 1) % 5) * size);
      cargaDatos(minIndex, len, size, indiceArray);
      setStart(minIndex);
    }
    setPagina(page);
  };

  const pageOptions = {
    sizePerPage: size,
    page: pagina,
    totalSize: totalDuplicados,
    sizePerPageRenderer,
    custom: true,
    showTotal: true,
    onPageChange: (page) => handlePageChange(page)
  };

  const handleTableChange = (type, { page }) => {
    if (type === 'pagination') {
      const datos = duplicados.slice(0);
      const minIndex = ((page - 1) % 5) * size;
      setDuplicadosVisibles(datos.slice(minIndex, minIndex + size));
    }
  };

  useEffect(() => {
    setCargando(true);
    cargaTotalDuplicados();
    cargaDatos(0, len, size);
  }, [porcentaje, orden]);

  const dynamicBackgroundStyles = `
  .dynamic {
    color: #fff;
    background: linear-gradient(to right, #D3D3D3, #F0F0F0, #D3D3D3, #F0F0F0);
    background-size: 400% 400%;
    -webkit-animation: Gradient 3s ease infinite;
    -moz-animation: Gradient 3s ease infinite;
    animation: Gradient 3s ease infinite;
    border-radius: 10px
    }
  
  @-webkit-keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
  
  @-moz-keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
  background-position: 0% 50%
    }
  }
  
  @keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
  `;

  const handleFusion = async () => {
    try {
      const dataFusion = {
        first_name: nombreDuplicado,
        last_name: apellidoDuplicado,
        genero: generoDuplicado,
        email: emailDuplicado,
        rgpd_email: rgpdDuplicado,
        email2: email2Duplicado,
        rgpd_email2: rgpd2Duplicado,
        dni: dniDuplicado,
        tipo_identificador: tipoDocumento,
        fecha_expedicion: fechaExpDoc,
        fecha_caducidad: fechaCadDoc,
        locale: idiomaDuplicado,
        nacionalidad: nacionalidadDuplicado,
        telefono: tlfDuplicado,
        codigo_postal: cpDuplicado,
        fecha_nacimiento: fecNacDuplicado,
        rgpd_telefono: rgpd3Duplicado
      };
      const datosEnviar = {
        id_primario: duplicado1,
        id_secundario: duplicado2,
        data: dataFusion
      };
      const result = await axiosApi.post('/api/visitantes/fusionaVisitantesDuplicados', datosEnviar);
      if (result.data.result === 'error') {
        throw new Error(result.data.msg);
      }
      const index = duplicadosVisibles.findIndex(dup => dup.mostrar_fusion === true);
      const { id } = duplicadosVisibles[index];
      const nuevosDups = duplicados.filter(dup => dup.id !== id);
      setDuplicadosVisibles(nuevosDups.slice((pagina - 1) % 5), ((pagina - 1) % 5) + size);
      setDuplicados(nuevosDups);
      if (totalDuplicados - 1 >= len) {
        const minIndex = Math.floor((pagina - 1) / 5) * len;
        const indiceArray = Math.floor(((pagina - 1) % 5) * size);
        cargaDatos(minIndex, len, size, indiceArray);
      }
      setTotalDuplicados(totalDuplicados - 1);
      setNombreCompletoDuplicado();
      setNombreDuplicado();
      setApellidoDuplicado();
      setEmailDuplicado();
      setEmail2Duplicado();
      setTlfDuplicado();
      setDniDuplicado();
      setRgpdDuplicado();
      setIdiomaDuplicado();
      setFecNacDuplicado();
      setNacionalidadDuplicado();
      setTipoDocumento();
      setRgpd2Duplicado();
      setRgpd3Duplicado();
      setFechaExpDoc();
      setFechaCadDoc();
      setGeneroDuplicado();
      setCPDuplicado();
    } catch (e) {
      console.log(e);
    }
  };

  const handleMantenerSeparados = async (duplicado) => {
    try {
      const result = await axiosApi.post('/api/duplicados/mantener', { id_primario: duplicado1, id_secundario: duplicado2, duplicado });
      if (result.data.valid) {
        setModalMantenerSeparados(false);
        const index = duplicadosVisibles.findIndex(dup => dup.usuario1.id === duplicado1 &&
          dup.usuario2.id === duplicado2);
        const { id } = duplicadosVisibles[index];
        const nuevosDups = duplicados.filter(dup => dup.id !== id);
        setDuplicadosVisibles(nuevosDups.slice((pagina - 1) % 5), ((pagina - 1) % 5) + size);
        setDuplicados(nuevosDups);
        if (totalDuplicados - 1 >= len) {
          const minIndex = Math.floor((pagina - 1) / 5) * len;
          const indiceArray = Math.floor(((pagina - 1) % 5) * size);
          cargaDatos(minIndex, len, size, indiceArray);
        }
        setTotalDuplicados(totalDuplicados - 1);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  document.title = `${t('Duplicated customers')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Duplicated customers')} breadcrumbItems={[{ title: t('Customers') }, { title: t('Duplicated customers') }]} />
          <div>
            <Card>
              {(cargando || duplicadosVisibles.length > 0) && (
              <Row style={{ marginLeft: '2%', marginTop: '1.5%', fontSize: '16px' }}>
                <Col sm="auto">
                  {`${totalDuplicados} ${t('Duplicated customers')}`}
                </Col>
                <Col sm="auto" style={{ marginLeft: '5%' }}>
                  <Input type="select" onChange={(e) => setOrden(e.target.value)}>
                    <option value="desc">
                      {t('Order from most similar to least similar')}
                    </option>
                    <option value="asc">
                      {t('Order from least similar to most similar')}
                    </option>
                  </Input>
                </Col>
              </Row>
              )}
              <CardBody>
                <Nav
                  className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto"
                  style={{ marginLeft: '1%', marginRight: '1%' }}
                >
                  <NavItem>
                    <NavLink
                      to="#"
                      onClick={() => setPorcentaje(0)}
                      active={porcentaje === 0}
                    >
                      {t('All')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      onClick={() => setPorcentaje(0.9)}
                      active={porcentaje === 0.9}
                    >
                      90%
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      onClick={() => setPorcentaje(0.8)}
                      active={porcentaje === 0.8}
                    >
                      80%
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      onClick={() => setPorcentaje(0.7)}
                      active={porcentaje === 0.7}
                    >
                      70%
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      onClick={() => setPorcentaje(0.6)}
                      active={porcentaje === 0.6}
                    >
                      60%
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      onClick={() => setPorcentaje(0.5)}
                      active={porcentaje === 0.5}
                    >
                      50%
                    </NavLink>
                  </NavItem>
                </Nav>
                {cargando && (
                  <Row style={{ marginLeft: '1%', marginRight: '1%', marginTop: '2%' }}>
                    <Card style={{ border: '2px', borderStyle: '' }}>
                      <CardHeader
                        style={{
                          marginTop: '1%',
                          shadowOpacity: 1,
                          color: '#fff0',
                          textShadowColor: 'rgba(255,255,255,0.8)',
                          textShadowOffset: {
                            width: 0,
                            height: 0,
                          },
                          textShadowRadius: 10,
                          fontSize: 14,
                          fontWeight: '600',
                          textTransform: 'capitalize',
                        }}
                      >
                        {t('nombre')}
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                          </Col>
                          <Col>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                          </Col>
                          <Col>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%', color: 'white' }}>
                                .
                              </span>
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%', color: 'white' }}>
                                .
                              </span>
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%', color: 'white' }}>
                                .
                              </span>
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardHeader
                        style={{
                          marginTop: '1%',
                          shadowOpacity: 1,
                          color: '#fff0',
                          textShadowColor: 'rgba(255,255,255,0.8)',
                          textShadowOffset: {
                            width: 0,
                            height: 0,
                          },
                          textShadowRadius: 10,
                          fontSize: 14,
                          fontWeight: '600',
                          textTransform: 'capitalize',
                        }}
                      >
                        {t('nombre')}
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                          </Col>
                          <Col>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'inline-block', width: '10px', verticalAlign: 'middle', color: 'white' }} />
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  marginLeft: '10px',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                          </Col>
                          <Col>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%', color: 'white' }}>
                                .
                              </span>
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%', color: 'white' }}>
                                .
                              </span>
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%', color: 'white' }}>
                                .
                              </span>
                              <span
                                className="dynamic"
                                style={{
                                  display: 'inline-block',
                                  width: '50%',
                                  color: 'gray',
                                  fontSize: '14px',
                                }}
                              >
                                <style>{dynamicBackgroundStyles}</style>
                              </span>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardBody style={{ marginLeft: 'auto' }}>
                        <Button
                          color="success"
                        >
                          {t('Previsualize')}
                        </Button>
                        <Button
                          color="link"
                        >
                          {t('Keep splited')}
                        </Button>
                      </CardBody>
                    </Card>
                  </Row>
                )}
                {!cargando && duplicadosVisibles.map((duplicado) => (
                  <Row style={{ marginLeft: '1%', marginRight: '1%', marginTop: '2%', marginBottom: '1%' }} key={duplicado.id}>
                    {!duplicado.mostrar_fusion && (
                    <Card style={{ border: '2px', borderStyle: '' }}>
                      <CardHeader style={{ marginTop: '1.5%', marginLeft: '1%', marginRight: '1%', fontSize: '16px' }}>
                        <strong>
                          <a href={`${process.env.REACT_APP_HOST_PANEL}/listado/ficha_visitante/${duplicado.usuario1.encripted}`} target="_blank" rel="noreferrer">
                            {duplicado.usuario1.nombreCompleto.toUpperCase()}
                          </a>
                        </strong>
                        <span style={{ position: 'absolute', right: '4%', border: '1px', borderColor: '#6b68ff', borderStyle: 'solid', padding: '2px', marginBottom: '1px', marginTop: '-2px' }}>
                          <span style={{ color: '#6b68ff' }}>
                            Primario
                          </span>
                          <Input
                            style={{ marginLeft: '5px', verticalAlign: 'top' }}
                            type="checkbox"
                            checked={duplicado.primario}
                            onChange={() => cambiarPrimario(duplicado.id)}
                          />
                        </span>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            {duplicado.usuario1.email && (
                            <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i className="bx bx-envelope font-size-20" style={{ display: 'flex', width: '10px', verticalAlign: 'middle', justifyContent: 'center', marginLeft: '10px' }} id={`correoUsuario1${duplicado.id}`} />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement="bottom" target={`correoUsuario1${duplicado.id}`}>
                                {t('Email')}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto', verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario1.email}
                              </span>
                            </Row>
                            )}
                            {duplicado.usuario1.tlf && (
                            <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i className="bx bx-phone font-size-20" style={{ display: 'flex', width: '10px', verticalAlign: 'middle', justifyContent: 'center', marginLeft: '10px' }} id={`tlfUsuario1${duplicado.id}`} />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement="bottom" target={`tlfUsuario1${duplicado.id}`}>
                                {t('Phone')}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto', verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario1.tlf}
                              </span>
                            </Row>
                            )}
                            {duplicado.usuario1.dni && (
                            <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i className="bx bx-id-card font-size-20" style={{ display: 'flex', width: '10px', verticalAlign: 'middle', justifyContent: 'center', marginLeft: '10px' }} id={`dniUsuario1${duplicado.id}`} />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement="bottom" target={`dniUsuario1${duplicado.id}`}>
                                {`${t('DNI')}/${t('Pasaporte')}`}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto', verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario1.dni}
                              </span>
                            </Row>
                            )}
                          </Col>
                          <Col>
                            <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i
                                className="bx bx-home-alt font-size-20"
                                style={{ display: 'flex', width: '10px', verticalAlign: 'middle', justifyContent: 'center', marginLeft: '10px' }}
                                id={`estanciasUsuario1${duplicado.id}`}
                              />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement="bottom" target={`estanciasUsuario1${duplicado.id}`}>
                                {t('Numero de estancias')}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto', verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario1.num_estancias}
                              </span>
                            </Row>
                            {/* <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i
                                className="bx bx-check-square font-size-20"
                                style={{ display: 'flex', width: '10px', verticalAlign:
                                'middle', justifyContent: 'center', marginLeft: '10px' }}
                                id={`comercialUsuario1${duplicado.id}`}
                              />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement=
                              "bottom" target={`comercialUsuario1${duplicado.id}`}>
                                {t('Acepta comercial')}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto',
                              verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario1.acepta_comercial === 1 ? t('Yes') : t('No')}
                              </span>
                            </Row> */}
                            <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i
                                className="bx bx-calendar-event font-size-20"
                                style={{ display: 'flex', width: '10px', verticalAlign: 'middle', justifyContent: 'center', marginLeft: '10px' }}
                                id={`reservaUsuario1${duplicado.id}`}
                              />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement="bottom" target={`reservaUsuario1${duplicado.id}`}>
                                {t('Via reserva')}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto', verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario1.via_reserva || '-'}
                              </span>
                            </Row>
                          </Col>
                          <Col>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%' }}>
                                {`${t('Registration')}:`}
                              </span>
                              <span style={{ fontSize: '16px', display: 'inline-block', width: '50%' }}>
                                {`${duplicado.usuario1.incorporacion}`}
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%' }}>
                                {`${t('Origin')}:`}
                              </span>
                              <span style={{ fontSize: '16px', display: 'inline-block', width: '50%' }}>
                                {`${duplicado.usuario1.via_visita}`}
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%' }}>
                                {`${t('Last stay')}:`}
                              </span>
                              <span style={{ fontSize: '16px', display: 'inline-block', width: '50%' }}>
                                {`${duplicado.usuario1.ultima_estancia}`}
                              </span>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardHeader style={{ fontSize: '16px', marginLeft: '1%', marginRight: '1%' }}>
                        <strong>
                          <a href={`${process.env.REACT_APP_HOST_PANEL}/listado/ficha_visitante/${duplicado.usuario2.encripted}`} target="_blank" rel="noreferrer">
                            {duplicado.usuario2.nombreCompleto.toUpperCase()}
                          </a>
                        </strong>
                        <span style={{ position: 'absolute', right: '4%', border: '1px', borderColor: '#6b68ff', borderStyle: 'solid', padding: '2px', marginBottom: '1px', marginTop: '-2px' }}>
                          <span style={{ color: '#6b68ff' }}>
                            Primario
                          </span>
                          <Input
                            style={{ marginLeft: '5px', verticalAlign: 'top' }}
                            type="checkbox"
                            checked={!duplicado.primario}
                            onChange={() => cambiarPrimario(duplicado.id)}
                          />
                        </span>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            {duplicado.usuario2.email && (
                            <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i
                                className="bx bx-envelope font-size-20"
                                style={{ display: 'flex', width: '10px', verticalAlign: 'middle', justifyContent: 'center', marginLeft: '10px' }}
                                id={`emailUsuario2${duplicado.id}`}
                              />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement="bottom" target={`emailUsuario2${duplicado.id}`}>
                                {t('Email')}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto', verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario2.email}
                              </span>
                            </Row>
                            )}
                            {duplicado.usuario2.tlf && (
                            <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i
                                className="bx bx-phone font-size-20"
                                style={{ display: 'flex', width: '10px', verticalAlign: 'middle', justifyContent: 'center', marginLeft: '10px' }}
                                id={`tlfUsuario2${duplicado.id}`}
                              />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement="bottom" target={`tlfUsuario2${duplicado.id}`}>
                                {t('Phone')}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto', verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario2.tlf}
                              </span>
                            </Row>
                            )}
                            {duplicado.usuario2.dni && (
                            <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i
                                className="bx bx-id-card font-size-20"
                                style={{ display: 'flex', width: '10px', verticalAlign: 'middle', justifyContent: 'center', marginLeft: '10px' }}
                                id={`dniUsuario2${duplicado.id}`}
                              />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement="bottom" target={`dniUsuario2${duplicado.id}`}>
                                {`${t('DNI')}/${t('Pasaporte')}`}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto', verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario2.dni}
                              </span>
                            </Row>
                            )}
                          </Col>
                          <Col>
                            <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i
                                className="bx bx-home-alt font-size-20"
                                style={{ display: 'flex', width: '10px', verticalAlign: 'middle', justifyContent: 'center', marginLeft: '10px' }}
                                id={`estanciasUsuario2${duplicado.id}`}
                              />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement="bottom" target={`estanciasUsuario2${duplicado.id}`}>
                                {t('Numero de estancias')}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto', verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario2.num_estancias}
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%', display: 'flex' }}>
                              <i
                                className="bx bx-calendar-event font-size-20"
                                style={{ display: 'flex', width: '10px', verticalAlign: 'middle', justifyContent: 'center', marginLeft: '10px' }}
                                id={`reservaUsuario2${duplicado.id}`}
                              />
                              <UncontrolledTooltip style={{ backgroundColor: 'black' }} placement="bottom" target={`reservaUsuario2${duplicado.id}`}>
                                {t('Via reserva')}
                              </UncontrolledTooltip>
                              <span style={{ display: 'inline-block', width: 'auto', verticalAlign: 'text-top', fontSize: '16px' }}>
                                {duplicado.usuario2.via_reserva || '-'}
                              </span>
                            </Row>
                          </Col>
                          <Col>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%' }}>
                                {`${t('Registration')}:`}
                              </span>
                              <span style={{ fontSize: '16px', display: 'inline-block', width: '50%' }}>
                                {`${duplicado.usuario2.incorporacion}`}
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%' }}>
                                {`${t('Origin')}:`}
                              </span>
                              <span style={{ fontSize: '16px', display: 'inline-block', width: '50%' }}>
                                {`${duplicado.usuario2.via_visita}`}
                              </span>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <span style={{ width: '20%' }}>
                                {`${t('Last stay')}:`}
                              </span>
                              <span style={{ fontSize: '16px', display: 'inline-block', width: '50%' }}>
                                {`${duplicado.usuario2.ultima_estancia}`}
                              </span>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardBody>
                        <Button
                          color="primary"
                          outline
                          style={{ marginLeft: 'auto', display: 'inline-block' }}
                          disabled
                        >
                          {`${t('Similitud')}: ${duplicado.similitud * 100}%`}
                        </Button>
                        <Button
                          style={{ marginLeft: 'auto', display: 'inline-block', float: 'right' }}
                          color="link"
                          onClick={() => {
                            setDuplicado1(duplicado.usuario1.id);
                            setDuplicado2(duplicado.usuario2.id);
                            setModalMantenerSeparados(true);
                          }}
                        >
                          {t('Keep splited')}
                        </Button>
                        <Button
                          style={{ marginLeft: 'auto', display: 'inline-block', float: 'right' }}
                          color="success"
                          onClick={() => {
                            cambiarEstado(duplicado.id, duplicado.primario);
                            setDuplicado1(duplicado.usuario1.id);
                            setDuplicado2(duplicado.usuario2.id);
                          }}
                        >
                          {t('Previsualize')}
                        </Button>
                      </CardBody>
                    </Card>
                    )}
                    {duplicado.mostrar_fusion && (
                    <Card style={{ border: '2px', borderStyle: '' }}>
                      <Row>
                        <Col>
                          <CardHeader style={{ marginTop: '2%', fontSize: '16px', textAlign: 'center' }}>
                            <strong>
                              <a href={`${process.env.REACT_APP_HOST_PANEL}/listado/ficha_visitante/${duplicado.usuario1.encripted}`} target="_blank" rel="noreferrer">
                                {duplicado.usuario1.nombreCompleto.toUpperCase()}
                              </a>
                            </strong>
                          </CardHeader>
                          <CardBody>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Origin')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.via_visita}
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('First name')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.nombre}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkNombre}
                                  onChange={
                                    () => cambiarFusion(
                                      1,
                                      duplicado.usuario1.nombre,
                                      duplicado.usuario2.nombre
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Last name')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.apellido}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkApellido}
                                  onChange={
                                    () => cambiarFusion(
                                      2,
                                      duplicado.usuario1.apellido,
                                      duplicado.usuario2.apellido
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Email')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.email}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkEmail}
                                  onChange={
                                    () => cambiarFusion(
                                      3,
                                      duplicado.usuario1.email,
                                      duplicado.usuario2.email,
                                      duplicado.usuario1.email2,
                                      duplicado.usuario2.email2,
                                      duplicado.usuario1.acepta_comercial,
                                      duplicado.usuario2.acepta_comercial,
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {`${t('Rgpd')} ${t('Email')}`}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.acepta_comercial === 1 ? t('Yes') : t('No')}
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {`${t('Email')} 2`}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.email2}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkEmail2}
                                  disabled={!duplicado.usuario1.email2}
                                  onChange={
                                    () => cambiarFusion(
                                      4,
                                      duplicado.usuario1.email2,
                                      duplicado.usuario2.email2,
                                      duplicado.usuario1.acepta_comercial2,
                                      duplicado.usuario2.acepta_comercial2,
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {`${t('Rgpd')} ${t('Email')} 2`}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.acepta_comercial2 === 1 ? t('Yes') : t('No')}
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Phone')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.tlf}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkTlf}
                                  onChange={
                                    () => cambiarFusion(
                                      5,
                                      duplicado.usuario1.tlf,
                                      duplicado.usuario2.tlf,
                                      duplicado.usuario1.acepta_comercial3,
                                      duplicado.usuario2.acepta_comercial3,
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {`${t('Rgpd')} ${t('Phone')}`}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.acepta_comercial3 === 1 ? t('Yes') : t('No')}
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Document type')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.tipo_documento}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkTipoDocumento}
                                  onChange={
                                    () => cambiarFusion(
                                      17,
                                      duplicado.usuario1.tipo_documento,
                                      duplicado.usuario2.tipo_documento
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {`${t('DNI')}/${'Pasaporte'}`}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.dni}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkDni}
                                  onChange={
                                    () => cambiarFusion(
                                      6,
                                      duplicado.usuario1.dni,
                                      duplicado.usuario2.dni
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Issue date')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.fecha_expedicion}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkFechaExpDoc}
                                  onChange={
                                    () => cambiarFusion(
                                      15,
                                      duplicado.usuario1.fecha_expedicion,
                                      duplicado.usuario2.fecha_expedicion
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Expiration date')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.fecha_caducidad}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkFechaCadDoc}
                                  onChange={
                                    () => cambiarFusion(
                                      16,
                                      duplicado.usuario1.fecha_caducidad,
                                      duplicado.usuario2.fecha_caducidad
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Date of birth')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.fec_nac}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkFecNac}
                                  onChange={
                                    () => cambiarFusion(
                                      11,
                                      duplicado.usuario1.fec_nac,
                                      duplicado.usuario2.fec_nac
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Language')}
                              </Col>
                              <Col xs="6">
                                {listaTraducida[duplicado.usuario1.idioma]}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkIdioma}
                                  onChange={
                                    () => cambiarFusion(
                                      10,
                                      duplicado.usuario1.idioma,
                                      duplicado.usuario2.idioma
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Nationality')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.nacionalidad}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkNacionalidadDuplicado}
                                  onChange={
                                    () => cambiarFusion(
                                      13,
                                      duplicado.usuario1.nacionalidad,
                                      duplicado.usuario2.nacionalidad
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Gender')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario1.genero}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={checkGenero}
                                  onChange={
                                    () => cambiarFusion(
                                      18,
                                      duplicado.usuario1.genero,
                                      duplicado.usuario2.genero
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                          </CardBody>
                        </Col>
                        <Col>
                          <CardHeader style={{ marginTop: '2%', fontSize: '16px', textAlign: 'center' }}>
                            <strong>
                              <a href={`${process.env.REACT_APP_HOST_PANEL}/listado/ficha_visitante/${duplicado.usuario2.encripted}`} target="_blank" rel="noreferrer">
                                {duplicado.usuario2.nombreCompleto.toUpperCase()}
                              </a>
                            </strong>
                          </CardHeader>
                          <CardBody>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Origin')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.via_visita}
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('First name')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.nombre}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkNombre}
                                  onChange={
                                    () => cambiarFusion(
                                      1,
                                      duplicado.usuario1.nombre,
                                      duplicado.usuario2.nombre
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Last name')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.apellido}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkApellido}
                                  onChange={
                                    () => cambiarFusion(
                                      2,
                                      duplicado.usuario1.apellido,
                                      duplicado.usuario2.apellido
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Email')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.email}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkEmail}
                                  onChange={
                                    () => cambiarFusion(
                                      3,
                                      duplicado.usuario1.email,
                                      duplicado.usuario2.email,
                                      duplicado.usuario1.email2,
                                      duplicado.usuario2.email2,
                                      duplicado.usuario1.acepta_comercial,
                                      duplicado.usuario2.acepta_comercial,
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {`${t('Rgpd')} ${t('Email')}`}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.acepta_comercial === 1 ? t('Yes') : t('No')}
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {`${t('Email')} 2`}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.email2}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkEmail2}
                                  disabled={!duplicado.usuario2.email2}
                                  onChange={
                                    () => cambiarFusion(
                                      4,
                                      duplicado.usuario1.email2,
                                      duplicado.usuario2.email2,
                                      duplicado.usuario1.acepta_comercial2,
                                      duplicado.usuario2.acepta_comercial2,
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {`${t('Rgpd')} ${t('Email')} 2`}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.acepta_comercial2 === 1 ? t('Yes') : t('No')}
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Phone')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.tlf}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkTlf}
                                  onChange={
                                    () => cambiarFusion(
                                      5,
                                      duplicado.usuario1.tlf,
                                      duplicado.usuario2.tlf,
                                      duplicado.usuario1.acepta_comercial3,
                                      duplicado.usuario2.acepta_comercial3,
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {`${t('Rgpd')} ${t('Phone')}`}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.acepta_comercial3 === 1 ? t('Yes') : t('No')}
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Document type')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.tipo_documento}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkTipoDocumento}
                                  onChange={
                                    () => cambiarFusion(
                                      17,
                                      duplicado.usuario1.tipo_documento,
                                      duplicado.usuario2.tipo_documento
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {`${t('DNI')}/${'Pasaporte'}`}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.dni}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkDni}
                                  onChange={
                                    () => cambiarFusion(
                                      6,
                                      duplicado.usuario1.dni,
                                      duplicado.usuario2.dni
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Issue date')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.fecha_expedicion}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkFechaExpDoc}
                                  onChange={
                                    () => cambiarFusion(
                                      15,
                                      duplicado.usuario1.fecha_expedicion,
                                      duplicado.usuario2.fecha_expedicion
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Expiration date')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.fecha_caducidad}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkFechaCadDoc}
                                  onChange={
                                    () => cambiarFusion(
                                      16,
                                      duplicado.usuario1.fecha_caducidad,
                                      duplicado.usuario2.fecha_caducidad
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Date of birth')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.fec_nac}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkFecNac}
                                  onChange={
                                    () => cambiarFusion(
                                      11,
                                      duplicado.usuario1.fec_nac,
                                      duplicado.usuario2.fec_nac
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Language')}
                              </Col>
                              <Col xs="6">
                                {listaTraducida[duplicado.usuario2.idioma]}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkIdioma}
                                  onChange={
                                    () => cambiarFusion(
                                      10,
                                      duplicado.usuario1.idioma,
                                      duplicado.usuario2.idioma
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Nationality')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.nacionalidad}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkNacionalidadDuplicado}
                                  onChange={
                                    () => cambiarFusion(
                                      13,
                                      duplicado.usuario1.nacionalidad,
                                      duplicado.usuario2.nacionalidad
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                            <Row xs="9">
                              <Col xs="3">
                                {t('Gender')}
                              </Col>
                              <Col xs="6">
                                {duplicado.usuario2.genero}
                              </Col>
                              <Col xs="1">
                                <Input
                                  type="checkbox"
                                  checked={!checkGenero}
                                  onChange={
                                    () => cambiarFusion(
                                      18,
                                      duplicado.usuario1.genero,
                                      duplicado.usuario2.genero
                                    )
                                  }
                                />
                              </Col>
                              <hr />
                            </Row>
                          </CardBody>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1%' }}>
                        <Col style={{ maxWidth: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                          <CardHeader style={{ marginTop: '1%', display: 'flex' }}>
                            <strong style={{ marginLeft: '2%' }}>
                              {nombreCompletoDuplicado.toUpperCase()}
                            </strong>
                            <span style={{ display: 'flex', color: '#6b68ff', position: 'absolute', right: '4%' }}>
                              {t('Vista preeliminar')}
                            </span>
                          </CardHeader>
                          <CardBody>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('First name')}
                                :
                              </Col>
                              <Col>
                                {nombreDuplicado}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('Last name')}
                                :
                              </Col>
                              <Col>
                                {apellidoDuplicado}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('Email')}
                                :
                              </Col>
                              <Col>
                                {emailDuplicado}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {`${t('Rgpd')} ${t('Email')}:`}
                              </Col>
                              <Col>
                                {rgpdDuplicado === 1 ? t('Yes') : t('No')}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {`${t('Email')} 2:`}
                              </Col>
                              <Col>
                                {email2Duplicado}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {`${t('Rgpd')} ${t('Email')} 2:`}
                              </Col>
                              <Col>
                                {rgpd2Duplicado === 1 ? t('Yes') : t('No')}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('Phone')}
                                :
                              </Col>
                              <Col>
                                {tlfDuplicado}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {`${t('Rgpd')} ${t('Phone')}:`}
                              </Col>
                              <Col>
                                {rgpd3Duplicado === 1 ? t('Yes') : t('No')}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('Document type')}
                                :
                              </Col>
                              <Col>
                                {tipoDocumento}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {`${t('DNI')}/${'Pasaporte'}`}
                                :
                              </Col>
                              <Col>
                                {dniDuplicado}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('Issue date')}
                                :
                              </Col>
                              <Col>
                                {fechaExpDoc}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('Expiration date')}
                                :
                              </Col>
                              <Col>
                                {fechaCadDoc}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('Date of birth')}
                                :
                              </Col>
                              <Col>
                                {fecNacDuplicado}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('Language')}
                                :
                              </Col>
                              <Col>
                                {listaTraducida[idiomaDuplicado]}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('Nationality')}
                                :
                              </Col>
                              <Col>
                                {nacionalidadDuplicado}
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5%' }}>
                              <Col style={{ alignContent: 'right', textAlign: 'right' }}>
                                {t('Gender')}
                                :
                              </Col>
                              <Col>
                                {generoDuplicado}
                              </Col>
                            </Row>
                          </CardBody>
                        </Col>
                      </Row>
                      <CardBody style={{ marginLeft: 'auto' }}>
                        <Button
                          color="success"
                          onClick={() => handleFusion()}
                        >
                          {t('Fuse')}
                        </Button>
                        <Button
                          color="link"
                          onClick={() => cambiarEstado(duplicado.id)}
                        >
                          {t('Cancel')}
                        </Button>
                      </CardBody>
                    </Card>
                    )}
                  </Row>
                ))}
                {!cargando && duplicadosVisibles.length === 0 && (
                  <>
                    <strong style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
                      {t('No duplicated users')}
                    </strong>
                    <hr style={{ color: 'blue' }} />
                  </>
                )}
                {!cargando && duplicadosVisibles.length > 0 && (
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={[]}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={[]}
                      search
                    >
                      {toolkitProps => (
                        <>
                          <Row>
                            <Col xl="12">
                              <div>
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  remote={{ pagination: true }}
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes="table align-middle table-nowrap"
                                  headerWrapperClasses="thead-light"
                                  onTableChange={handleTableChange}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone {...paginationProps} />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
                )}
              </CardBody>
            </Card>
          </div>
          <Modal isOpen={modalMantenerSeparados}>
            <div className="modal-header">
              <h5 className="modal-title">
                {t('Are you sure?')}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalMantenerSeparados(false);
                }}
                className="btn-close"
              />
            </div>
            <div className="modal-body">
              <p>{t("You won't see them unless they are duplicated again")}</p>
              <p>{t('Why do you want them to keep them splitted?')}</p>
            </div>
            <div className="modal-footer">
              <Button onClick={() => setModalMantenerSeparados(false)}>
                {t('Cancel')}
              </Button>
              <Button color="primary" onClick={() => handleMantenerSeparados(0)}>
                {t('Not duplicated')}
              </Button>
              <Button color="primary" onClick={() => handleMantenerSeparados(1)}>
                {t('I want to have both')}
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

ListadoDuplicados.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(ListadoDuplicados);
