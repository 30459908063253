/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import DatePicker, { registerLocale } from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { ValueEditor } from 'react-querybuilder';
import CreatableSelect from 'react-select/creatable';
import axiosApi from 'helpers/apiResquests';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';

import listaTraducida from 'common/traducirListaIdiomas';
import listaTraducidaPaises from 'common/traducirListaPaises';

registerLocale('es', es);
registerLocale('pt', pt);
const dateFormat = 'dd/MM/yyyy';
const estadosReservas = [
  { label: 'Reserved', value: 'Reserved' },
  { label: 'In-house', value: 'In-house' },
  { label: 'Checked-Out', value: 'Checked-Out' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'No-show', value: 'No-show' },
];

const CustomValueEditor = (props) => {
  const { t } = props;
  const { fieldData, value, handleOnChange, operator } = props;
  const { datatype, valorSwitchOpciones, name } = fieldData;
  const [selectAnadible, setSelectAnadible] = useState(false);
  const [opcionesSelect, setOpcionesSelect] = useState([]);
  const [defaultValueSelect, setDefaultValueSelect] = useState([]);
  const [canales, setCanales] = useState([]);
  const [encuestas, setEncuestas] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [grupoHabitaciones, setGrupoHabitaciones] = useState([]);
  const [opcionesIdiomas, setOpcionesIdiomas] = useState([]);
  const [opcionesNacionalidad, setOpcionesNacionalidad] = useState([]);
  const [opcionesTags, setOpcionesTags] = useState([]);

  function cargaListadosExistentes() {
    const optIdiom = Object.keys(listaTraducida).map(v => {
      return { label: listaTraducida[v], value: v };
    });
    optIdiom.push({ label: t('Unknown'), value: 'DESCONOCIDO' });
    setOpcionesIdiomas(optIdiom);
    const optNac = Object.keys(listaTraducidaPaises).map(v => {
      return { label: listaTraducidaPaises[v], value: listaTraducidaPaises[v] };
    });
    optNac.push({ label: t('Unknown'), value: 'DESCONOCIDA' });
    setOpcionesNacionalidad(optNac);
  }

  const cargaCanales = async () => {
    try {
      const result = await axiosApi.get('/api/filtros/canales');
      const can = result.data.canales.filter(c => c.via_reserva !== '' && c.via_reserva);
      const canal = can.map(c => {
        return { label: c.via_reserva, value: c.via_reserva };
      });
      setCanales(canal);
    } catch (e) {
      console.log(e);
    }
  };

  const cargaEncuentas = async () => {
    try {
      const result = await axiosApi.get('/api/segmentos/encuestas');
      const { data } = result;
      const opcionesEncuestas = data.encuestas.map(e => {
        return { value: e[0], label: e[1] };
      });
      setEncuestas(opcionesEncuestas);
      const opcionesPreguntas = data.preguntas.map(e => {
        return { value: e[0], label: e[1] };
      });
      setPreguntas(opcionesPreguntas);
    } catch (e) {
      console.log(e);
    }
  };

  const cargaGruposHabitaciones = async () => {
    try {
      const result = await axiosApi.get('/api/segmentos/gruposHabitaciones');
      const { data } = result.data;
      if (data) {
        const grupos = data.map(v => {
          return { value: v.id_superhabitaciones, label: v.denominacion };
        });
        setGrupoHabitaciones(grupos);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const cargaEtiquetas = async () => {
    try {
      const result = await axiosApi.get('/api/locales/clientes/etiquetas');
      const tags = result.data.etiquetas;
      const nuevasEtiquetas = tags.map(tag => {
        return {
          label: tag.nombre_etiqueta,
          value: tag.id_etiquetas,
          color: tag.color,
          descripcion: tag.descripcion
        };
      });
      setOpcionesTags(nuevasEtiquetas);
    } catch (e) {
      console.log(e);
    }
  };

  const getOpcionesSelect = async () => {
    let opciones = [];
    const opcionesGenero = [
      { value: 'male', label: t('Male') },
      { value: 'female', label: t('Female') },
      { value: 'sin_info', label: t('No info') }
    ];
    const opcionesMetodosRegistros = [
      { value: 'Acceso directo', label: t('Direct access') },
      { value: 'CHECKIN', label: t('CHECKIN') },
      { value: 'CSV', label: 'CSV' },
      { value: 'Codigo acceso', label: t('Access code') },
      { value: 'Facebook', label: t('Facebook') },
      { value: 'Formulario', label: t('Form') },
      { value: 'Google Review', label: t('Google Review') },
      { value: 'LinkedIn', label: t('LinkedIn') },
      { value: 'MC', label: 'Messaging Center' },
      { value: 'telefono', label: t('Phone number') },
      { value: 'PMS', label: t('PMS') },
      { value: 'Twitter', label: t('Twitter') },
      { value: 'Usuarios', label: t('User') },
      { value: 'VK', label: t('VK') }
    ];
    const opcionesSO = [
      { value: '-', label: t('Unknown') },
      { value: 'Windows Phone', label: t('Windows Phone') },
      { value: 'Windows', label: t('Windows') },
      { value: 'Android', label: t('Android') },
      { value: 'Mac', label: t('MacOS') },
      { value: 'iPhone', label: t('iPhone') },
      { value: 'ipad', label: t('iPad') },
      { value: 'iPod', label: t('iPod') },
      { value: 'Linux', label: t('Linux') },
    ];
    const opcionesDiaSemana = [
      { value: 2, label: t('Monday') },
      { value: 3, label: t('Tuesday') },
      { value: 4, label: t('Wednesday') },
      { value: 5, label: t('Thursday') },
      { value: 6, label: t('Friday') },
      { value: 7, label: t('Saturday') },
      { value: 1, label: t('Sunday') },
    ];
    const opcionesCanalFeedback = [
      { value: 'Email', label: t('Email') },
      { value: 'Hotspot', label: t('Wifi') },
      { value: 'Whatsapp', label: t('Whatsapp') }
    ];
    const opcionesComercial = [
      { value: 0, label: t('Not accepted') },
      { value: 1, label: t('Accept') }
    ];
    const opcionesAlojado = [
      { value: 0, label: t('Not hosted') },
      { value: 1, label: t('Hosted') }
    ];
    const opcionesNiños = [
      { value: 0, label: t('No children') },
      { value: 1, label: t('Children') }
    ];
    const opcionesFeedbackResponde = [
      { value: 0, label: t('No') },
      { value: 1, label: t('Yes') }
    ];
    switch (valorSwitchOpciones) {
      case 0:
        setSelectAnadible(false);
        setOpcionesSelect(canales);
        opciones = canales.filter(c => value.includes(c.value) && c.value !== '');
        break;
      case 1:
        setSelectAnadible(false);
        setOpcionesSelect(estadosReservas);
        opciones = estadosReservas.filter(c => value.includes(c.value));
        break;
      case 2:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesGenero);
        opciones = opcionesGenero.filter(c => value.includes(c.value));
        break;
      case 3:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesIdiomas);
        opciones = opcionesIdiomas.filter(c => value.includes(c.value));
        break;
      case 4:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesNacionalidad);
        opciones = opcionesNacionalidad.filter(c => value.includes(c.value));
        break;
      case 5:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesMetodosRegistros);
        opciones = opcionesMetodosRegistros.filter(c => value.includes(c.value));
        break;
      case 6:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesSO);
        opciones = opcionesSO.filter(c => value.includes(c.value));
        break;
      case 7:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesDiaSemana);
        opciones = opcionesDiaSemana.filter(c => value.includes(c.value));
        break;
      case 8:
        setSelectAnadible(false);
        setOpcionesSelect(encuestas);
        opciones = encuestas.filter(c => value.includes(c.value));
        break;
      case 9:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesCanalFeedback);
        opciones = opcionesCanalFeedback.filter(c => value.includes(c.value));
        break;
      case 10:
        setSelectAnadible(false);
        setOpcionesSelect(preguntas);
        opciones = preguntas.filter(c => value.includes(c.value));
        break;
      case 11:
        setSelectAnadible(false);
        setOpcionesSelect(grupoHabitaciones);
        opciones = grupoHabitaciones.filter(c => value.includes(c.value));
        break;
      case 12:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesComercial);
        opciones = opcionesComercial.filter(c => value.includes(c.value));
        break;
      case 13:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesAlojado);
        opciones = opcionesAlojado.filter(c => value.includes(c.value));
        break;
      case 14:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesNiños);
        opciones = opcionesNiños.filter(c => value.includes(c.value));
        break;
      case 15:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesFeedbackResponde);
        opciones = opcionesFeedbackResponde.filter(c => value.includes(c.value));
        break;
      case 16:
        setSelectAnadible(false);
        setOpcionesSelect(opcionesTags);
        opciones = opcionesTags.filter(c => value.includes(c.value));
        break;
      default:
        setSelectAnadible(false);
        setOpcionesSelect([]);
    }
    setDefaultValueSelect(opciones);
  };

  useEffect(() => {
    getOpcionesSelect();
  }, [canales, preguntas, grupoHabitaciones, props]);

  useEffect(() => {
    cargaCanales();
    cargaEncuentas();
    cargaGruposHabitaciones();
    cargaListadosExistentes();
    cargaEtiquetas();
  }, []);

  function convertSelectToArray(e) {
    if (e) {
      const array = e.map(opcion => {
        return opcion.value;
      });
      handleOnChange(array);
      setDefaultValueSelect(e);
    } else {
      handleOnChange([]);
      setDefaultValueSelect([]);
    }
    return [];
  }

  if (datatype === 'date') {
    const [startDate, endDate] = value.split(',');
    const arrayOperadorSinValor = ['today', 'last_week', 'this_month', 'last_30_days', 'this_year', 'last_year', 'empty', 'notEmpty'];
    if (arrayOperadorSinValor.some(i => i === operator)) {
      return '';
    }
    if (operator !== 'between') {
      const fecha = startDate;
      if (endDate) handleOnChange(format(parse(fecha, dateFormat, new Date()), dateFormat));
      return (
        <div style={{ display: 'inline-flex', width: '16vw', marginLeft: '20px' }}>
          <DatePicker
            showIcon
            dateFormat={dateFormat}
            selected={!fecha ? null : parse(fecha, dateFormat, new Date())}
            onChange={(d) => handleOnChange(d ? format(d, dateFormat) : null)}
            locale={localStorage.getItem('I18N_LANGUAGE')}
          />
        </div>
      );
    }
    return (
      <div style={{ display: 'inline-flex', width: '16vw', marginLeft: '20px' }}>
        <DatePicker
          selectsRange
          dateFormat={dateFormat}
          startDate={!startDate ? null : parse(startDate, dateFormat, new Date())}
          endDate={!endDate ? null : parse(endDate, dateFormat, new Date())}
          locale={localStorage.getItem('I18N_LANGUAGE')}
          onChange={(update) => {
            const [s, e] = update;
            handleOnChange(
              [!s ? '' : format(s, dateFormat), !e ? '' : format(e, dateFormat)].join(',')
            );
          }}
        />
      </div>
    );
  } if (datatype === 'dateRange') {
    const [startDate, endDate] = value.split(',');
    return (
      <div style={{ display: 'inline-flex', width: '16vw', marginLeft: '20px' }}>
        <DatePicker
          selectsRange
          locale={localStorage.getItem('I18N_LANGUAGE')}
          dateFormat={dateFormat}
          startDate={!startDate ? null : parse(startDate, dateFormat, new Date())}
          endDate={!endDate ? null : parse(endDate, dateFormat, new Date())}
          onChange={(update) => {
            const [s, e] = update;
            handleOnChange(
              [!s ? '' : format(s, dateFormat), !e ? '' : format(e, dateFormat)].join(',')
            );
          }}
        />
      </div>
    );
  } if (datatype === 'select') {
    if (name === 'reservas_pagado' || name === 'reservas_checkin_estado_nuevo' ||
      name === 'reservas_checkin_estado_cliente' || name === 'reservas_pagado_clientes') {
      return (
        <>
        </>
      );
    }
    if (name === 'etiquetas') {
      if (operator === 'empty' || operator === 'notEmpty') {
        return (
          <>
          </>
        );
      }
    }
    return (
      <div style={{ display: 'inline-flex', width: '16vw', marginLeft: '20px' }}>
        <CreatableSelect
          options={opcionesSelect}
          isMulti
          onChange={(e) => convertSelectToArray(e)}
          styles={{
            control: (base) => ({
              ...base, width: '100%', border: '0px', boxShadow: 'none'
            }),
            menu: (base) => ({
              ...base, width: '100%'
            }),
            container: (base) => ({
              ...base, width: '100%'
            })
          }}
          isClearable
          formatCreateLabel={(v) => v}
          isValidNewOption={() => selectAnadible}
          placeholder={t('Select')}
          value={defaultValueSelect}
        />
      </div>
    );
  }
  if (operator === 'between') {
    const [val1, val2] = value === '' ? ['', ''] : value;

    return (
      <>
        <input type="text" style={{ width: '6vh' }} value={val1} onChange={e => handleOnChange([e.target.value, val2])} />
        <span className="mx-1">{t('and')}</span>
        <input type="text" style={{ width: '6vh' }} value={val2} onChange={e => handleOnChange([val1, e.target.value])} />
      </>
    );
  }
  if (operator === 'empty') {
    return (
      <>
      </>
    );
  }
  if (operator === 'notEmpty') {
    return (
      <>
      </>
    );
  }
  if (operator === 'notBetween') {
    const [val1, val2] = value === '' ? ['', ''] : value;

    return (
      <>
        <input type="text" style={{ width: '6vh' }} value={val1} onChange={e => handleOnChange([e.target.value, val2])} />
        <span className="mx-1">{t('and')}</span>
        <input type="text" style={{ width: '6vh' }} value={val2} onChange={e => handleOnChange([val1, e.target.value])} />
      </>
    );
  }
  const operadoresEmailSinValor = ['verified', 'valid', 'unvalidated', 'erroneous', 'blackList', 'null', 'notNull'];
  if (operadoresEmailSinValor.includes(operator)) return '';
  return <ValueEditor {...props} />;
};

CustomValueEditor.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(CustomValueEditor);
