import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { DatePicker, DatePickerInput } from 'carbon-components-react';
import { withTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/material_blue.css';

const DateRangePicker = React.memo(props => {
  const { startDate, endDate, setDates } = props;
  const { filtrar, t } = props;
  return (
    <Row>
      <Col className="mb-2" style={{ marginLeft: 'auto', paddingRight: 0 }} sm={{ size: 'auto' }}>
        <DatePicker
          datePickerType="range"
          className="date-picker-range-classname"
          size="md"
          locale="es"
          maxDate={new Date().toISOString()}
          dateFormat="d M Y"
          onChange={event => {
            setDates(event);
          }}
        >
          <DatePickerInput
            id="date-picker-input-id-start"
            labelText=""
            placeholder="dd/mm/yyyy"
            size="md"
            value={startDate}
          />
          <DatePickerInput
            id="date-picker-input-id-finish"
            labelText=""
            placeholder="dd/mm/yyyy"
            size="md"
            value={endDate}
          />
        </DatePicker>
      </Col>
      <Col className="mb-2" style={{ paddingLeft: 0 }} sm={{ size: 'auto' }}>
        <button
          type="button"
          className="btn boton-filtrar-fecha"
          onClick={() => { filtrar(); }}
        >
          {/* <i className="bx bx-calendar-event font-size-16 align-middle me-2" /> */}
          {t('Apply')}
        </button>
      </Col>
    </Row>
  );
});

DateRangePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setDates: PropTypes.func,
  filtrar: PropTypes.func,
  t: PropTypes.any
};

export default withTranslation()(DateRangePicker);
