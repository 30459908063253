import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  Form,
  Input,
  Label,
  FormFeedback,
  Alert
} from 'reactstrap';
import SlideButton from 'react-slide-button';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import './whatsapp-styles.scss';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import es from 'react-phone-input-2/lang/es.json';
import pt from 'react-phone-input-2/lang/pt.json';
import SweetAlert from 'react-bootstrap-sweetalert';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axiosApi from '../../helpers/apiResquests';

const Plantilla = ({ templates, setTemplates, t }) => {
  const [modalStandard, setModalStandard] = useState(false);
  const [modalMasivo, setModalMasivo] = useState(false);
  const [modalEliminacion, setModalEliminacion] = useState(false);
  const [phone, setPhone] = useState('');
  const [templateSelected, setTemplateSelected] = useState(null);
  const [publicoOjetivo, setPublicoOjetivo] = useState(0);
  const [mostrarEnvio, setMostrarEnvio] = useState(false);
  const [botonConfirmacion, setBotonConfirmacion] = useState(false);
  const [reset, setReset] = useState(0);
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
  const [modalInstantanteo, setModalIntantaneo] = useState(false);
  const [telefonoSinPrefijo, setTelefonoSinPrefijo] = useState();
  const [nombrePlantilla, setNombrePlantilla] = useState();
  const [idiomaPlantilla, setIdiomaPlantilla] = useState();
  const [camposEnvioUnico, setCamposEnvioUnico] = useState([]);
  const [expandirEnvio, setExpandirEnvio] = useState(false);
  const [camposEnviar, setCamposEnviar] = useState([]);
  const [buscaReserva, setBuscaReserva] = useState([]);
  const [idReservaInvalida, setIdReservaInvalida] = useState(false);
  const [campoRequerido, setCampoRequerido] = useState([]);
  const [idUsuarioEnviar, setIdUsuarioEnvia] = useState();
  const [idiomaTlf, setIdiomaTlf] = useState();
  const [modalErrorInstantaneo, setModalErrorInstantaneo] = useState(false);

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  useEffect(() => {
    switch (localStorage.getItem('I18N_LANGUAGE')) {
      case 'es':
        setIdiomaTlf(es);
        break;
      case 'pt':
        setIdiomaTlf(pt);
        break;
      default:
        setIdiomaTlf('');
    }
  }, []);

  const cargaPlaceholderCamp = async (camp, idioma) => {
    try {
      const datos = await axiosApi.post('/api/campwhatsapp/getPlaceholderCamp', { camp, idioma });
      const variablesPlantillas = JSON.parse(
        datos.data.datos[0].estructura_placeholder_template_whatsapp
      );
      if (variablesPlantillas.id_pms_show) setBuscaReserva(true);
      else setBuscaReserva(false);
      const campos = Object.keys(variablesPlantillas).map(v => {
        if (v.includes('[[neocheckin_userlang]]')) return 'enlace_checkin';
        return v;
      });
      setCamposEnviar(Array(Object.keys(variablesPlantillas).length).fill(''));
      setCamposEnvioUnico(campos);
      setCampoRequerido(Array(campos.length).fill(false));
      setModalIntantaneo(true);
    } catch (e) {
      console.log(e);
      setModalErrorInstantaneo(true);
      setTemplateSelected(null);
    }
  };

  const buscarDatosCliente = async () => {
    let idReserva = false;
    try {
      const telefono = telefonoSinPrefijo || '';
      const index = camposEnvioUnico.findIndex(i => i === 'id_pms_show');
      let res = '';
      if (index !== -1) {
        res = camposEnviar[index];
        idReserva = true;
      }
      const response = await axiosApi.post('/api/campwhatsapp/getDatosPlaceholder', { telefono, res });
      const datos = response.data.datos[0];
      const copiaCampos = [...camposEnviar];
      camposEnvioUnico.forEach((c, i) => {
        let valor = '';
        switch (c) {
          case 'first_name':
          case 'username':
            valor = datos.nombre;
            break;
          case 'fecha_inicio':
          case 'checkin_date':
            valor = datos.fecha_inicio;
            break;
          case 'fecha_fin':
          case 'checkout_date':
            valor = datos.fecha_fin;
            break;
          case 'adultos':
          case 'adults':
            valor = datos.adultos;
            break;
          case 'menores':
          case 'children':
            valor = datos.menores;
            break;
          case 'room_type':
          case 'tipo':
            break;
          case 'payment_link':
            valor = `${process.env.REACT_APP_WEBCHAT_DOMAIN}/nuevo/pago?pay=${datos.enlace}&bookin=${datos.id_reservas}`;
            break;
          case 'checkin_link':
            valor = datos.enlace_checkin;
            break;
          default:
            valor = copiaCampos[i];
            break;
        }
        copiaCampos[i] = valor;
      });
      if (datos.telefono) {
        setTelefonoSinPrefijo(datos.telefono);
      }
      setIdUsuarioEnvia(datos.id_usuarios);
      setCamposEnviar(copiaCampos);
      setIdReservaInvalida(false);
    } catch (e) {
      console.log(e);
      setIdReservaInvalida(true);
    } finally {
      if (!buscaReserva || idReserva) setExpandirEnvio(true);
    }
  };

  function togStandard() {
    setModalStandard(!modalStandard);
    removeBodyCss();
  }

  function togInstantaneo() {
    setModalIntantaneo(!modalInstantanteo);
    removeBodyCss();
  }

  function togMasivo() {
    setModalMasivo(!modalMasivo);
    removeBodyCss();
  }

  function togEliminacion() {
    setModalEliminacion(!modalEliminacion);
    removeBodyCss();
  }

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  // Form validation
  const validationBulkSending = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnMount: true,

    initialValues: {
      plantilla: '',
      idioma: false,
      segmento: ''
    },
    validationSchema: Yup.object().shape({
      plantilla: Yup.string().required(t('VALUE_REQUIRED')),
      idioma: Yup.string().required(t('VALUE_REQUIRED')),
      segmento: Yup.string()
        .oneOf(['ALOJADOS_ES_CA', 'ALOJADOS_NOT_ES_NOT_CAT'])
        .required(t('VALUE_REQUIRED'))
    }),
    onSubmit: async () => {
      const { plantilla, idioma, segmento } =
        validationBulkSending.values;
      const datosEnvio = {
        plantilla, idioma, segmento
      };
      try {
        const response = await axiosApi.post('/api/whatsapp/bulk', datosEnvio);
        const { data } = response;
        if (data.error) {
          throw Error('Error');
        }
        const title = t('Whatsapp bulk sending');
        const message = t('Whatsapp bulk sending will be sent in few moments');
        showToast(title, message, 'success');
        togMasivo();
        validationBulkSending.resetForm();
        setBotonConfirmacion(false);
        setBotonDeshabilitado(false);
      } catch (error) {
        console.log(error);
        const title = t('Whatsapp bulk sending');
        const message = t('There was an error setting up the Whatsapp bulk sending');
        showToast(title, message, 'error');
      }
    }
  });

  const getPublicoOjetivo = async () => {
    const { segmento } = validationBulkSending.values;
    const datosEnvio = {
      segmento
    };
    try {
      const response = await axiosApi.post('/api/whatsapp/publicoObjetivo', datosEnvio);
      const { data } = response;
      if (data.err) {
        throw Error('Error');
      }
      if (!Number.isNaN(Number.parseInt(data?.publicoObjetivo, 10))) {
        setPublicoOjetivo(data.publicoObjetivo);
        setMostrarEnvio(true);
        setBotonDeshabilitado(true);
      }
    } catch (error) {
      console.log(error);
      const title = t('Whatsapp bulk sending');
      const message = t('The target public can\'t be fetched');
      showToast(title, message, 'error');
    }
  };

  const sendSample = async (name, components, language) => {
    const datos = {
      name,
      components,
      language,
      phone
    };
    try {
      const response = await axiosApi.post('/api/whatsapp/sample', datos);
      const { data } = response;
      const { enviado } = data;
      if (enviado) {
        showToast(t('Whatsapp sample'), t('The sample message has been sent successfully'), 'success');
      } else {
        showToast(t('Whatsapp sample'), t('The sample message has not been sent'), 'error');
      }
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const enviarTemplate = async (name, components, language) => {
    const datos = {
      name,
      components,
      language,
      phone: telefonoSinPrefijo,
      parametros: camposEnviar,
      id_usuarios: idUsuarioEnviar || ''
    };
    try {
      const response = await axiosApi.post('/api/whatsapp/unico', datos);
      const { data } = response;
      const { enviado } = data;
      if (enviado) {
        showToast(t('Whatsapp unique'), t('The unique message has been sent successfully'), 'success');
      } else {
        showToast(t('Whatsapp unique'), t('The unique message has not been sent'), 'error');
      }
      setIdUsuarioEnvia();
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const deleteTemplate = async (id, name, language) => {
    const datos = {
      id,
      name,
      language
    };
    try {
      const response = await axiosApi.post('/api/whatsapp/deletePlantilla', datos);
      const { data } = response;
      const { eliminada } = data;
      if (eliminada) {
        const auxTemplates = structuredClone(templates);
        const indexEliminar = auxTemplates.findIndex(
          r => r.id === id
        );
        if (indexEliminar !== -1) {
          auxTemplates.splice(indexEliminar, 1);
          setTemplates(auxTemplates);
        }
        showToast(t('Whatsapp template'), t('The template has been deleted'), 'success');
      } else {
        showToast(t('Whatsapp template'), t('The template has not been deleted'), 'error');
      }
    } catch (error) {
      console.log(error);
      showToast(t('Whatsapp template'), t('The template has not been deleted'), 'error');
    }
  };

  return (
    <>
      {map(templates, (template, key) => (
        <Col xl="4" sm="6" key={key}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-15">
                    <span className="text-dark">{template.name}</span>
                  </h5>
                  <p className="text-muted mb-2">{template.description}</p>
                  <div className="page">
                    <div className="marvel-device phone">
                      <div className="screen">
                        <div className="screen-container">
                          <div className="chat">
                            <div className="chat-container">
                              <div className="user-bar">
                                <div className="back">
                                  <i className="zmdi zmdi-arrow-left" />
                                </div>
                                <div className="avatar">
                                  <img
                                    style={{ backgroundColor: 'white' }}
                                    src={`${process.env.PUBLIC_URL}/logo192.png`}
                                    alt="Avatar"
                                  />
                                </div>
                                <div className="name">
                                  <span>Journeytok</span>
                                  <span className="status">online</span>
                                </div>
                                <div className="actions more">
                                  <i className="zmdi zmdi-more-vert" />
                                </div>
                                <div className="actions attachment">
                                  <i className="zmdi zmdi-attachment-alt" />
                                </div>
                                <div className="actions">
                                  <i className="zmdi zmdi-phone" />
                                </div>
                              </div>
                              <div className="conversation">
                                <div className="conversation-container">
                                  <div className="message received">
                                    {map(
                                      template.components,
                                      (component, key2) => (component.type === 'HEADER' && component.text ? (
                                        <div key={key2}>
                                          <span
                                            className="header"
                                            style={{ fontWeight: 'bold' }}
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{ __html: component.text }}
                                          />
                                          <br />
                                        </div>
                                      ) : null) ||
                                        (component.type === 'BODY' ? (
                                          <div key={key2}>
                                            <span
                                              // eslint-disable-next-line react/no-danger
                                              dangerouslySetInnerHTML={{ __html: component.text }}
                                            />
                                          </div>
                                        ) : null) ||
                                        (component.type === 'FOOTER' ? (
                                          <div key={key2}>
                                            <br />
                                            <span
                                              className="footer2"
                                              style={{ color: 'rgb(104, 121, 146)' }}
                                              // eslint-disable-next-line react/no-danger
                                              dangerouslySetInnerHTML={{ __html: component.text }}
                                            />
                                          </div>
                                        ) : null) ||
                                        (component.type === 'BUTTONS' ? (
                                          <div key={key2}>
                                            <br />
                                            <div className="contenedor-botones">
                                              {map(component.buttons, (button, key3) => (
                                                <div className="boton" key={key3}>
                                                  {button.text}
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        ) : null)
                                    )}
                                    <span className="metadata">
                                      <span className="time">{moment().format('HH:mm')}</span>
                                    </span>
                                  </div>
                                </div>
                                <form className="conversation-compose">
                                  <div className="emoji">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      id="smiley"
                                      x="3147"
                                      y="3209"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z"
                                        fill="#7d8489"
                                      />
                                    </svg>
                                  </div>
                                  <input
                                    className="input-msg"
                                    name="input"
                                    disabled
                                    placeholder=""
                                    autoComplete="off"
                                  />
                                  <div className="photo">
                                    <i className="bx bx-camera" />
                                  </div>
                                  <button type="button" className="send">
                                    <div className="circle">
                                      <i className="bx bx-send" />
                                    </div>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
            <div className="px-4 py-3 border-top">
              <ul className="list-inline mb-0">
                <li className="list-inline-item me-3">
                  {template.status === 'APPROVED' && (
                    <>
                      <Badge className="bg-success uppercase" id={`approved${template.id}`}>
                        {t(template.status)}
                      </Badge>
                      <UncontrolledTooltip placement="top" target={`approved${template.id}`}>
                        {t('approved_tooltip')}
                      </UncontrolledTooltip>
                    </>
                  )}
                  {template.status === 'REJECTED' && (
                    <>
                      <Badge className="bg-danger uppercase" id={`rejected${template.id}`}>
                        {t(template.status)}
                      </Badge>
                      <UncontrolledTooltip placement="top" target={`rejected${template.id}`}>
                        {t('rejected_tooltip')}
                      </UncontrolledTooltip>
                    </>
                  )}
                  {template.status === 'PENDING' && (
                    <>
                      <Badge className="bg-warning uppercase" id={`pending${template.id}`}>
                        {t(template.status)}
                      </Badge>
                      <UncontrolledTooltip placement="top" target={`pending${template.id}`}>
                        {t('pending_tooltip')}
                      </UncontrolledTooltip>
                    </>
                  )}
                </li>
                <li className="list-inline-item me-3">
                  <i className="bx bxs-flag" id="language" />
                  <span style={{ marginLeft: '1vh' }}>{template.language}</span>
                  <UncontrolledTooltip placement="right" target="language">
                    {t('Language')}
                  </UncontrolledTooltip>
                </li>
                <li className="list-inline-item me-3" style={{ float: 'right' }}>
                  <UncontrolledDropdown direction="left">
                    <DropdownToggle tag="a" caret>
                      <i className="bx bx-dots-vertical-rounded" />
                    </DropdownToggle>
                    <DropdownMenu className="lista-opciones">
                      <DropdownItem
                        disabled={template.status !== 'APPROVED'}
                        onClick={() => {
                          setTemplateSelected(key);
                          togStandard();
                        }}
                        id={`infoPruebaPlantillaWa${key}`}
                      >
                        {t('Send sample')}
                        <UncontrolledTooltip placement="top" target={`infoPruebaPlantillaWa${key}`}>
                          {t('infoPruebaPlantillaWa')}
                        </UncontrolledTooltip>
                      </DropdownItem>
                      <DropdownItem
                        disabled={template.status !== 'APPROVED'}
                        onClick={() => {
                          setTemplateSelected(key);
                          setNombrePlantilla(template.name);
                          setIdiomaPlantilla(template.language);
                          cargaPlaceholderCamp(template.name, template.language);
                        }}
                        id={`infoUnicoPlantillaWa${key}`}
                      >
                        {t('Unique send')}
                        <UncontrolledTooltip placement="right" target={`infoUnicoPlantillaWa${key}`}>
                          {t('infoUnicoPlantillaWa')}
                        </UncontrolledTooltip>
                      </DropdownItem>
                      <DropdownItem
                        disabled={template.status !== 'APPROVED'}
                        onClick={() => {
                          validationBulkSending.setFieldValue(
                            'plantilla',
                            template.name
                          );
                          validationBulkSending.setFieldValue(
                            'idioma',
                            template.language
                          );
                          setTemplateSelected(key);
                          togMasivo();
                          setMostrarEnvio(false);
                        }}
                        id={`infoMasivoPlantillaWa${key}`}
                      >
                        {t('Bulk sending')}
                        <UncontrolledTooltip placement="right" target={`infoMasivoPlantillaWa${key}`}>
                          {t('infoMasivoPlantillaWa')}
                        </UncontrolledTooltip>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setTemplateSelected(key);
                          togEliminacion();
                        }}
                      >
                        {t('Remove')}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              </ul>
            </div>
          </Card>
        </Col>
      ))}
      <Modal
        isOpen={modalStandard}
        toggle={() => {
          togStandard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t('Phone number')}
          </h5>
          <button
            type="button"
            onClick={() => {
              togStandard();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{t('Please enter the phone number with the international code (without the +)')}</p>
          <Form>
            <Row>
              <Col md="10">
                <Input
                  type="text"
                  style={{ width: '50%', height: '50%' }}
                  className="form-control form-check-input"
                  onChange={event => {
                    setPhone(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              togStandard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            {t('Close')}
          </button>
          <button
            onClick={() => {
              if (templateSelected >= 0) {
                sendSample(
                  templates[templateSelected].name,
                  templates[templateSelected].components,
                  templates[templateSelected].language
                );
              }
              setTemplateSelected(null);
              togStandard();
            }}
            type="button"
            className="btn btn-primary "
          >
            {t('Send')}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalEliminacion}
        toggle={() => {
          togEliminacion();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t('Template removal')}
          </h5>
          <button
            type="button"
            onClick={() => {
              togEliminacion();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            {t('Press confirm to delete the template', {
              template: templates[templateSelected]?.name,
              language: templates[templateSelected]?.language
            })}
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              togEliminacion();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            {t('Close')}
          </button>
          <button
            onClick={() => {
              if (templateSelected >= 0) {
                deleteTemplate(
                  templates[templateSelected].id,
                  templates[templateSelected].name,
                  templates[templateSelected].language
                );
              }
              setTemplateSelected(null);
              togEliminacion();
            }}
            type="button"
            className="btn btn-primary "
          >
            {t('Confirm')}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalMasivo}
        toggle={() => {
          togMasivo();
          validationBulkSending.resetForm();
          setBotonConfirmacion(false);
          setBotonDeshabilitado(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t('Whatsapp bulk sending')}
          </h5>
          <button
            type="button"
            onClick={() => {
              togMasivo();
              setReset(counter => counter + 1);
              setBotonConfirmacion(false);
              validationBulkSending.resetForm();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col md="3">
                <Label>
                  {t('Template')}
                  :
                </Label>
              </Col>
              <Col md="6">
                <span>{templates[templateSelected]?.name}</span>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Label>
                  {t('Language')}
                  :
                </Label>
              </Col>
              <Col md="6">
                <span>{templates[templateSelected]?.language}</span>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Label>
                  {t('Segment')}
                  :
                </Label>
              </Col>
              <Col md="6">
                <Select
                  name="segmento"
                  className={(
                    validationBulkSending.touched.segmento &&
                    validationBulkSending.errors.segmento
                  ) ? ' is-invalid' : ''}
                  placeholder={t('Segment')}
                  onChange={event => {
                    validationBulkSending.setFieldValue(
                      'segmento',
                      event.value
                    );
                  }}
                  options={[
                    {
                      options: [
                        { label: t('ALOJADOS_ES_CA'), value: 'ALOJADOS_ES_CA' },
                        { label: t('ALOJADOS_NOT_ES_NOT_CAT'), value: 'ALOJADOS_NOT_ES_NOT_CAT' }
                      ]
                    }
                  ]}
                  classNamePrefix="select2-selection"
                />
                {validationBulkSending.touched.segmento &&
                validationBulkSending.errors.segmento ? (
                  <FormFeedback type="invalid">
                    {validationBulkSending.errors.segmento}
                  </FormFeedback>
                  ) : null}
              </Col>
            </Row>
          </Form>
          {mostrarEnvio && (
            <Row className="mt-1">
              <Col>
                <Alert color="warning" role="alert">
                  {t('The whatsapp template is to be sent to a total of recipients', { destinatarios: publicoOjetivo })}
                  <br />
                  {t('If you are sure, move the button to the right and press send')}
                  <SlideButton
                    mainText=""
                    overlayText=""
                    overlayClassList="custom-width"
                    classList="mainText"
                    caretClassList="custom-caret-list"
                    reset={reset}
                    caret={<p style={{ color: 'white', paddingTop: '10%' }}>{t('Slide')}</p>}
                    customCaretWidth={180}
                    onSlideDone={() => {
                      setBotonConfirmacion(true);
                      setBotonDeshabilitado(false);
                    }}
                  />
                </Alert>
              </Col>
            </Row>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              togMasivo();
              setBotonConfirmacion(false);
              setBotonDeshabilitado(false);
              setReset(counter => counter + 1);
              validationBulkSending.resetForm();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            {t('Close')}
          </button>
          <button
            disabled={botonDeshabilitado}
            onClick={() => {
              validationBulkSending.setFieldTouched('segmento', true);
              if (botonConfirmacion && validationBulkSending.isValid) {
                validationBulkSending.submitForm();
              } else if (templateSelected >= 0 && validationBulkSending.isValid) {
                getPublicoOjetivo();
              }
            }}
            type="button"
            className="btn btn-primary "
          >
            {t('Send')}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalInstantanteo}
        toggle={() => {
          togInstantaneo();
          setExpandirEnvio(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t('Unique Whatsapp send')}
          </h5>
          <button
            type="button"
            onClick={() => {
              togInstantaneo();
              setIdiomaPlantilla('');
              setTelefonoSinPrefijo('');
              setCamposEnviar([]);
              setCamposEnvioUnico([]);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row style={{ marginBottom: '15px' }}>
            <Col sm={3} xs={3}>
              {t('Template')}
            </Col>
            <Col>
              {nombrePlantilla}
            </Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col sm={3} xs={3}>
              {t('Language')}
            </Col>
            <Col>
              {idiomaPlantilla}
            </Col>
          </Row>
          {!buscaReserva && (
            <Row style={{ marginBottom: '15px' }}>
              <Col
                sm={3}
                xs={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '5vh'
                }}
              >
                {t('Send to')}
              </Col>

              <Col
                sm={6}
                xs={6}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '5vh'
                }}
              >
                <PhoneInput
                  onChange={setTelefonoSinPrefijo}
                  value={telefonoSinPrefijo}
                  enableSearch
                  country={localStorage.getItem('I18N_LANGUAGE')}
                  localization={idiomaTlf}
                />
              </Col>
            </Row>
          )}
          {buscaReserva && (
            <Row style={{ marginBottom: '15px' }}>
              <Col
                sm={3}
                xs={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('Booking ID')}
              </Col>
              <Col>
                <Input
                  onChange={(e) => {
                    const index = camposEnvioUnico.indexOf('id_pms_show');
                    setIdReservaInvalida(false);
                    setExpandirEnvio(false);
                    let copiaCampos = [...camposEnviar];
                    copiaCampos = copiaCampos.map((v, i) => {
                      if (i !== index) return '';
                      return e.target.value;
                    });
                    setCamposEnviar(copiaCampos);
                  }}
                  id="idReserva"
                  invalid={idReservaInvalida}
                />
                {idReservaInvalida && (
                  <FormFeedback
                    target="idReserva"
                  >
                    {t('Invalid reservation')}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          )}
          {expandirEnvio && buscaReserva && (
            <>
              <Row style={{ marginBottom: '15px' }}>
                <Col
                  sm={3}
                  xs={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '5vh'
                  }}
                >
                  {t('Send to')}
                </Col>
                <Col
                  sm={6}
                  xs={6}
                  style={{
                    height: '5vh'
                  }}
                >
                  <PhoneInput
                    onChange={setTelefonoSinPrefijo}
                    value={telefonoSinPrefijo}
                    enableSearch
                    country={localStorage.getItem('I18N_LANGUAGE')}
                  />
                </Col>
              </Row>
            </>
          )}
          {expandirEnvio && (
            <Row style={{ marginBottom: '15px' }}>
              <strong>
                {t('Las variables de la plantilla serán sustituidas con esta información:')}
              </strong>
            </Row>
          )}
          {expandirEnvio && camposEnvioUnico.map((c, i) => {
            if (c !== 'id_pms_show') {
              return (
                <Row key={i} style={{ marginBottom: '15px' }}>
                  <Col sm={3} xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                    {t(c)}
                  </Col>
                  <Col>
                    <Input
                      value={camposEnviar[i]}
                      onChange={(e) => {
                        const temp = [...camposEnviar];
                        temp[i] = e.target.value;
                        setCamposEnviar(temp);
                        const nuevosRequeridos = [...campoRequerido];
                        nuevosRequeridos[i] = false;
                        setCampoRequerido(nuevosRequeridos);
                      }}
                      invalid={campoRequerido[i]}
                    />
                    {campoRequerido[i] && (
                      <FormFeedback>
                        {t('This value is required')}
                      </FormFeedback>
                    )}
                  </Col>
                </Row>
              );
            }
            return '';
          })}
        </div>
        <div className="modal-footer">
          {!expandirEnvio && (
            <button
              type="button"
              onClick={() => {
                togInstantaneo();
                setExpandirEnvio(false);
                setIdiomaPlantilla('');
                setTelefonoSinPrefijo('');
                setCamposEnviar([]);
                setCamposEnvioUnico([]);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              {t('Close')}
            </button>
          )}
          {expandirEnvio && (
            <button
              type="button"
              onClick={() => {
                setExpandirEnvio(false);
                let temp = [...camposEnviar];
                const index = camposEnvioUnico.indexOf('id_pms_show');
                if (index !== -1) setTelefonoSinPrefijo('34');
                temp = temp.map((v, i) => {
                  if (i !== index) return '';
                  return v;
                });
                setCamposEnviar(temp);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              {t('Return')}
            </button>
          )}
          <button
            onClick={() => {
              if (templateSelected >= 0 && camposEnviar.filter(c => c === '').length === 0 && telefonoSinPrefijo) {
                enviarTemplate(
                  templates[templateSelected].name,
                  templates[templateSelected].components,
                  templates[templateSelected].language
                );
                setTemplateSelected(null);
                setExpandirEnvio(false);
                togInstantaneo();
                setIdiomaPlantilla('');
                setTelefonoSinPrefijo('');
                setCamposEnviar([]);
                setCamposEnvioUnico([]);
              } else if (!expandirEnvio) {
                buscarDatosCliente();
              } else {
                const nuevosRequeridos = camposEnviar.map(v => {
                  return !v;
                });
                setCampoRequerido(nuevosRequeridos);
              }
            }}
            type="button"
            className="btn btn-primary"
          >
            {camposEnviar.filter(c => c === '').length === 0 ? t('Send') : t('Next')}
          </button>
        </div>
      </Modal>
      <SweetAlert
        show={modalErrorInstantaneo}
        title={t('Error')}
        warning
        confirmBtnText={t('Ok').toUpperCase()}
        confirmBtnBsStyle="success"
        onConfirm={() => setModalErrorInstantaneo(false)}
      >
        {t('Error loading template')}
      </SweetAlert>
    </>
  );
};

Plantilla.propTypes = {
  templates: PropTypes.array,
  setTemplates: PropTypes.func,
  t: PropTypes.any
};

export default withTranslation()(Plantilla);
