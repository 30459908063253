import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardTitle
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';
import moment from 'moment';

import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

const Pipeline = props => {
  const { t } = props;
  const [idLocal, setIdLocal] = useState(null);
  const [agentesFilter, setAgentesFilter] = useState('0');
  const [estados, setEstados] = useState([{ id: 'PENDIENTE' }, { id: 'RESUELTA' }]);
  const [agentes, setAgentes] = useState([]);
  const [pipelineData, setPipelinaData] = useState([]);

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargaPipeline = async () => {
    try {
      const response = await axiosApi.get(`/mc/conversations/pipeline?idLocal=${idLocal}`);
      const { data } = response;
      const { conversaciones } = data;
      const {
        error,
      } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setPipelinaData(conversaciones);
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const cargaDatos = async () => {
    try {
      const whoami = await axiosApi.get('/mc/whoami');
      const { data } = whoami;
      setIdLocal(data.idLocal.toString());
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const cargaConfig = async () => {
    try {
      const response = await axiosApi.get(`/mc/companies/config?id=${idLocal}`);
      const { data } = response;
      const {
        error,
        estados: estadosAux,
        hayEstados,
        agentes: agentesAux
      } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      if (hayEstados) {
        setEstados(estadosAux);
      }
      if (agentesAux) {
        setAgentes(agentesAux);
      }
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  useEffect(() => {
    if (idLocal) {
      cargaPipeline();
      cargaConfig();
    }
  }, [idLocal]);

  document.title = `${t('Pipeline')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Pipeline')} breadcrumbItems={[{ title: t('Messaging Center') }, { title: t('Pipeline') }]} />

          <Row>
            <Col className="col-12">
              <Row>
                <Col className="col-2">
                  <select
                    className="form-select"
                    id="horizontal-agnetes-input"
                    defaultValue="0"
                    onChange={event => {
                      setAgentesFilter(event.target.value);
                    }}
                  >
                    <option value={0}>
                      {t('All')}
                    </option>
                    {agentes.map(agente => {
                      return (
                        <option key={`filtro_agente${agente.id}`} value={agente.id}>
                          {agente.nombre}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <Row className="pt-3">
                {estados.map(estado => {
                  return (
                    <Col key={estado.id}>
                      <Card style={{ backgroundColor: '#e9e9e9' }} body>
                        <CardTitle className="mt-0 text-center">{t(estado.id)}</CardTitle>
                      </Card>
                      {pipelineData[estado.id]?.filter(c => {
                        if (agentesFilter !== '0') {
                          return c.asignado?.id === agentesFilter;
                        }
                        return true;
                      }).map(conversacion => {
                        return (
                          <Card className="mb-1" key={conversacion.idConversacion} body>
                            <CardTitle className="text-justify nombre-capitalize">{conversacion.nombre?.toLowerCase()}</CardTitle>
                            <Row>
                              <Col md="7" xs="7">
                                {
                                conversacion.horaUltimoMensaje &&
                                moment.duration(
                                  moment(new Date())
                                    .diff(moment(conversacion.horaUltimoMensaje))
                                ).asMinutes() < 60 && t('mins', { mins: parseInt(moment.duration(
                                  moment(new Date())
                                    .diff(moment(conversacion.horaUltimoMensaje))
                                ).asMinutes(), 10) })
                                }
                                {
                                moment.duration(
                                  moment(new Date())
                                    .diff(moment(conversacion.horaUltimoMensaje))
                                ).asMinutes() > 60 && moment.duration(
                                  moment(new Date())
                                    .diff(moment(conversacion.horaUltimoMensaje))
                                ).asHours() < 24 && t('hours', { hours: parseInt(moment.duration(
                                  moment(new Date())
                                    .diff(moment(conversacion.horaUltimoMensaje))
                                ).asHours(), 10) })
                                }
                                {
                                moment.duration(
                                  moment(new Date())
                                    .diff(moment(conversacion.horaUltimoMensaje))
                                ).asHours() > 24 && t('days', { days: parseInt(moment.duration(
                                  moment(new Date())
                                    .diff(moment(conversacion.horaUltimoMensaje))
                                ).asDays(), 10) })
                                }
                              </Col>
                              {
                              conversacion.noLeido && (
                              <Col md="1" xs="1">
                                <i
                                  className="bx bxs-circle font-size-20 "
                                  style={{ color: '#25D366' }}
                                />
                              </Col>
                              )
                              }
                              {
                              conversacion.situacion === 'EN_ESPERA' && (
                              <Col md="1" xs="1">
                                <i
                                  className="bx bx-pause-circle font-size-20 "
                                  style={{ color: '#735392' }}
                                />
                              </Col>
                              )
                              }
                              {
                              conversacion.situacion === 'ACCION_REQUERIDA' && (
                              <Col md="1" xs="1">
                                <i
                                  className="bx bxs-error-alt font-size-20 "
                                  style={{ color: '#ff8c00' }}
                                />
                              </Col>
                              )
                              }
                            </Row>
                          </Card>
                        );
                      })}
                    </Col>
                  );
                })}
              </Row>
            </Col>
            {/* <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Whatsapp automation info title')}</span>
                  <br />
                  {t('Whatsapp automation info text')}
                  <br />
                  {/* <a target="_blank" href="http://helpcenter.wifreezone.com/es/articles/5849058-campsWhatsapp-de-feedback" rel="noreferrer">{t('Read more')}</a> *//* }
                </CardText>
              </Card>
            </Col> */}
          </Row>
        </div>
      </div>
    </>
  );
};

Pipeline.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Pipeline);
