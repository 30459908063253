import PropTypes from 'prop-types';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SplineArea = props => {
  const { data, dateArray, toolbar } = props;
  const series = [];
  const colors = [];
  data.forEach(element => {
    if (element.sum > 0) {
      series.push({ name: element.name, data: element.values });
      colors.push(element.color);
    }
  });

  const options = {
    chart: { toolbar: { show: toolbar } },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    colors,
    xaxis: {
      type: 'date',
      categories: dateArray
    },
    grid: {
      borderColor: '#f1f1f1'
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      }
    }
  };

  return <ReactApexChart options={options} series={series} type="area" height="350" />;
};

SplineArea.propTypes = {
  data: PropTypes.array,
  dateArray: PropTypes.array,
  toolbar: PropTypes.bool
};

export default SplineArea;
