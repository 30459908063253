import PropTypes from 'prop-types';
import React, { Component } from 'react';

class NonAuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.capitalizeFirstLetter.bind(this);
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  // componentDidMount() {
  //   let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

  //   document.title =
  //     currentage + " | Skote - React Admin & Dashboard Template"
  // }
  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

NonAuthLayout.propTypes = {
  children: PropTypes.any
  // location: PropTypes.object
};

export default NonAuthLayout;
