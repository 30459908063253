// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA
import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
// Initialize the Firebase app in the service worker by passing the generated config
const firebaseConfig = {
  apiKey: 'AIzaSyBvD3emHDNml7oOaycdlcm6e9idP-7fAPo',
  authDomain: 'panel-wfz.firebaseapp.com',
  projectId: 'panel-wfz',
  storageBucket: 'panel-wfz.appspot.com',
  messagingSenderId: '996300013158',
  appId: '1:996300013158:web:f90b1485ded5becb6cf7a7'
};

let app;
let messaging;
try {
  app = initializeApp(firebaseConfig);

  // Retrieve firebase messaging
  messaging = getMessaging(app);
} catch (err) {
  console.error('Failed to initialize Firebase Messaging', err);
}

const isLocalhost = Boolean(
  window.location.hostname === 'panel.localhost' ||
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

/* function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' }
  })
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
} */

export function register() {
  try {
    if (!isLocalhost) {
      if ('serviceWorker' in navigator && navigator.serviceWorker) {
        navigator.serviceWorker
          .register('/nuevo/firebase-messaging-sw.js', { scope: '/' })
          // eslint-disable-next-line no-unused-vars
          .then((registration) => {
            getToken(messaging, {
              vapidKey:
                'BIeq0Pm7QO3moyHJhUrGkQAhkwC_bxZPHt1GfdQ4r_CKcBCEZuk9McS40Q9GStPBtEsn1KvBpNv4ZmdID96ebxY'
            })
              .then(currentToken => {
                if (currentToken) {
                  fetch('/sendFirebaseToken', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ currentToken }),
                  }).catch(error => {
                    console.error(error);
                  });
                } else {
                  // Show permission request UI
                  console.log('No registration token available. Request permission to generate one.');
                }
              })
              .catch((err) => {
                console.log('Service worker registration failed, error:', err);
              });
          })
          .catch(err => {
            console.log('An error occurred while retrieving token. ', err);
          });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}
