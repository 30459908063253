import React, { useState, useMemo } from 'react';
import ReactSelect from 'react-select';
import i18n from 'i18n';
import PropTypes from 'prop-types';

const CustomFieldSelector = (props) => {
  const {
    options,
    handleOnChange,
    rule,
    objeto
  } = props;

  const [groupedOptions, setGroupedOptions] = useState([]);
  const [defaultSelected, setDefaultSelected] = useState();

  function generaOpciones() {
    const nuevasOpciones = options?.map(opt => {
      const opciones = opt.options?.filter(filtro => {
        return filtro?.objetos?.includes(objeto);
      })?.map(filtro => {
        if (filtro.name === rule.field) {
          setDefaultSelected({ label: filtro.label, value: filtro.name });
        }
        return { label: filtro.label, value: filtro.name };
      });
      return { label: opt.label, options: opciones };
    });
    setGroupedOptions(nuevasOpciones);
  }

  useMemo(() => {
    generaOpciones();
  }, []);

  const formatGroupLabel = (data) => (
    <div style={{ fontSize: '125%', color: 'initial' }}>
      <span><strong>{data.label}</strong></span>
    </div>
  );

  return (
    <div style={{ display: 'inline-block', width: '30%' }}>
      <ReactSelect
        onChange={(e) => {
          handleOnChange(e?.value);
          setDefaultSelected(e);
        }}
        className="single-select"
        classNamePrefix="react-select"
        options={groupedOptions}
        placeholder={i18n.t('Select filter')}
        value={defaultSelected}
        formatGroupLabel={formatGroupLabel}
      />
    </div>
  );
};

CustomFieldSelector.propTypes = {
  options: PropTypes.any,
  handleOnChange: PropTypes.any,
  rule: PropTypes.any,
  objeto: PropTypes.any
};

export default CustomFieldSelector;
