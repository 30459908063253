import uk from '../assets/images/flags/uk.jpg';
import spain from '../assets/images/flags/spain.jpg';
import portugal from '../assets/images/flags/pt.jpg';

const languages = {
  es: {
    label: 'Spanish',
    flag: spain
  },
  en: {
    label: 'English',
    flag: uk
  },
  pt: {
    label: 'Portuguese',
    flag: portugal
  }
};

export default languages;
