import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import 'assets/scss/estilos.scss';
import Select from 'react-select';
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Modal,
  Form,
  Input,
  FormFeedback,
  TabContent,
  TabPane,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { createPicker } from 'picmo';
import { map } from 'lodash';
import moment from 'moment';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import TextareaAutosize from 'react-textarea-autosize';

import axiosApi from '../../helpers/apiResquests';
import prefixList from '../../common/prefixList.json';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import languagesWhatsapp from '../../common/languagesWhatsapp.json';

const REGEX_NOMBRE = /[a-z0-9_]/gi;
const REGEX_NOMBRE_VALIDO = /[^a-z0-9 _]+/gi;
const REGEX_VARIABLES = /\{\{\d+\}\}/g;
// regex para {{NUMERO} que no tenga un } después
const REGEX_VARIABLES_INCORRECTAS_1 = /\{\{\d+\}(?!\})/g;
// regex para {NUMERO}} que no tenga un { antes
const REGEX_VARIABLES_INCORRECTAS_2 = /(?<!\{)\{\d+\}\}/g;
// regex para {NUMERO} que no tenga un { antes ni un } después
const REGEX_VARIABLES_INCORRECTAS_3 = /(?<!\{)\{\d+\}(?!\})/g;

const BOTONES_LIMITE = 3;
const ACCIONES_LIMITE = 2;

const NOMBRE_LENGTH = 128;
const CABECERA_LENGTH = 60;
const TEXTO_LENGTH = 1024;
const PIE_LENGTH = 60;
const BOTON_LENGTH = 25;
const TELEFONO_LENGTH = 20;
const URL_LENGTH = 2000;

const NuevaPlantilla = props => {
  const [plantillas, setPlantillas] = useState({});
  const [modalStandard, setModalStandard] = useState(false);
  const textArea = useRef();
  const wrapperRef = useRef(null);
  const botonRef = useRef(null);
  const validationWhatsappTemplate = useRef(null);
  const [numBotones, setNumBotones] = useState(0);
  const [numAcciones, setNumAcciones] = useState(0);
  const [menuEmojis, setMenuEmojis] = useState(false);
  const [variablesTexto, setVariablesTexto] = useState(0);
  const [variableCabecera, setVariableCabecera] = useState(0);
  const [variablesIncorrectas, setVariablesIncorretas] = useState(false);
  const [demasidasVariables, setDemasidasVariables] = useState(false);
  const [idiomasSelected, setIdiomasSelected] = useState([]);
  const [tab, setTab] = useState(1);
  const [idiomaEditando, setIdiomaEditando] = useState({});
  const [plantillasCompletas, setPlantillasCompletas] = useState(false);
  const [botonEnviarActivo, setBotonEnviarActivo] = useState(true);

  const { t } = props;

  const PLACEHOLDERS_DISPONIBLES = [
    { label: t('User name'), value: 'username' },
    { label: t('Booking ID'), value: 'booking_id' },
    { label: t('Checkin date'), value: 'checkin_date' },
    { label: t('Checkout date'), value: 'checkout_date' },
    { label: t('Adults'), value: 'adults' },
    { label: t('Children'), value: 'children' },
    { label: t('Room type'), value: 'room_type' },
    { label: t('Payment link'), value: 'payment_link' },
    { label: t('Checkin link'), value: 'checkin_link' }
  ];

  const history = useHistory();

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function togStandard() {
    setModalStandard(!modalStandard);
    removeBodyCss();
  }

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  function formatoParaWhatsapp(texto = '', caracter) {
    const ta = textArea.current;
    const inicio = ta.selectionStart;
    const fin = ta.selectionEnd;
    texto = `${texto?.slice(0, fin)}${caracter}${texto?.slice(fin)}`;
    texto = `${texto?.slice(0, inicio)}${caracter}${texto?.slice(inicio)}`;
    return texto;
  }

  function comprobacionesTexto(texto, n) {
    if (
      texto.match(REGEX_VARIABLES_INCORRECTAS_1)
        ?.length > 0 ||
      texto.match(REGEX_VARIABLES_INCORRECTAS_2)
        ?.length > 0 ||
      texto.match(REGEX_VARIABLES_INCORRECTAS_3)
        ?.length > 0
    ) {
      setVariablesIncorretas(true);
    } else {
      setVariablesIncorretas(false);
    }
    if ((texto.split(' ')?.length / 3) < n) {
      setDemasidasVariables(true);
    } else {
      setDemasidasVariables(false);
    }
  }

  // Form validation
  validationWhatsappTemplate.current = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nombre: '',
      categoria: 'otro',
      cabecera: 'sin',
      botones: 'sin',
      texto: '',
      idioma: '',
      tipoUrl: 'estatica',
      variablesEjemplo: [],
      placeholdersPlantilla: [],
      variableCabeceraEjemplo: [],
      variableUrlEjemplo: [],
      llamadaAccion: [],
      respuestaRapida: []
    },
    validationSchema: Yup.object().shape({
      nombre: Yup.string()
        .required(t('VALUE_REQUIRED'))
        .matches(REGEX_NOMBRE, 'ttt')
        .max(NOMBRE_LENGTH, t('TOO_LONG')),
      idioma: Yup.string()
        .required(t('VALUE_REQUIRED')),
      cabecera: Yup.string().oneOf(['sin', 'texto']),
      cabeceraTexto: Yup.string().max(CABECERA_LENGTH, 'TOO_LONG'),
      texto: Yup.string().required(t('VALUE_REQUIRED')).max(TEXTO_LENGTH, 'TOO_LONG'),
      variablesEjemplo: Yup.array().of(Yup.string().required(t('VALUE_REQUIRED'))),
      variableCabeceraEjemplo: Yup.array().of(Yup.string().required(t('VALUE_REQUIRED'))),
      placeholdersPlantilla: Yup.array().of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string().required(t('VALUE_REQUIRED'))
        })
      ),
      pieTexto: Yup.string().max(PIE_LENGTH, 'TOO_LONG'),
      categoria: Yup.string().oneOf(['marketing', 'utility'], t('VALUE_REQUIRED')),
      botones: Yup.string()
        .required(t('VALUE_REQUIRED'))
        .oneOf(['sin', 'llamada_accion', 'respuesta_rapida']),
      llamadaAccion: Yup.array().of(
        Yup.object().shape({
          tipo: Yup.string().oneOf(['llamar_telefono', 'ir_web']),
          texto: Yup.string().min(0).max(BOTON_LENGTH).required(t('VALUE_REQUIRED')),
          pais: Yup.string().min(0).max(5),
          telefono: Yup.string().min(0).max(TELEFONO_LENGTH),
          url: Yup.string().min(0).max(URL_LENGTH)
        })
      ),
      tipoUrl: Yup.string().oneOf(['estatica', 'dinamica']),
      respuestaRapida: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string().min(0).max(BOTON_LENGTH).required(t('VALUE_REQUIRED'))
        })
      )
    }),
    onSubmit: async () => {
      try {
        setBotonEnviarActivo(false);
        const response = await axiosApi.post('/api/whatsapp/plantilla', plantillas);
        const { data } = response;
        if (data.creada) {
          const title = t('Template created');
          const message = t('The template has been created');
          showToast(title, message, 'success');
          history.push('/whatsapp/plantillas');
        } else if (data?.nombreDuplicado) {
          const title = t('Error creating template');
          const message = t('The name already exists');
          showToast(title, message, 'error');
          setTab(1);
        } else {
          console.log(data?.plantillasError);
          console.log(data?.error);
          const title = t('Error creating template');
          let message = t('The template has not been created');
          if (data?.mensajesError) {
            data?.mensajesError.forEach(m => {
              message += m;
            });
          }
          showToast(title, message, 'error');
        }
        setBotonEnviarActivo(true);
      } catch (error) {
        console.log(error);
        const title = t('Error creating template');
        const message = t('The template has not been created');
        showToast(title, message, 'error');
        setBotonEnviarActivo(true);
      }
    }
  });

  function reemplazarVariableCabecera(texto) {
    let textoReemplazado = texto;
    const { variableCabeceraEjemplo } = validationWhatsappTemplate.current.values;
    for (let i = 1; i <= variableCabecera; i++) {
      if (variableCabeceraEjemplo[i - 1]) {
        textoReemplazado = textoReemplazado.replace(`{{${i}}}`, variableCabeceraEjemplo[i - 1]);
      }
    }
    return textoReemplazado;
  }

  function reemplazarVariables(texto) {
    let textoReemplazado = texto;
    const { variablesEjemplo } = validationWhatsappTemplate.current.values;
    for (let i = 1; i <= variablesTexto; i++) {
      if (variablesEjemplo[i - 1]) {
        textoReemplazado = textoReemplazado.replace(`{{${i}}}`, variablesEjemplo[i - 1]);
      }
    }
    return textoReemplazado;
  }

  const template = {
    name: 'pagos',
    components: [
      {
        type: 'HEADER',
        text: reemplazarVariableCabecera(validationWhatsappTemplate.current.values.cabeceraTexto)
      },
      {
        type: 'BODY',
        text: reemplazarVariables(validationWhatsappTemplate.current.values.texto)
          ?.replace(/~(?:(?!\s))(~*[^~\n]+~*)(?<!\s)~/g, '<s>$1</s>')
          ?.replace(/_(?:(?!\s))(_*[^_\n]+_*)(?<!\s)_/g, '<i>$1</i>')
          ?.replace(/`{3}(`*[^`\n]+`*)`{3}/g, '<tt>$1</tt>')
          ?.replace(/\*(?:(?!\s))(\**[^*\n]+\**)(?<!\s)\*/g, '<b>$1</b>')
      },
      {
        type: 'FOOTER',
        text: validationWhatsappTemplate.current.values.pieTexto
      }
    ]
  };

  if (validationWhatsappTemplate.current.values.llamadaAccion.length > 0) {
    const { llamadaAccion } = validationWhatsappTemplate.current.values;
    const buttons = [];
    llamadaAccion.forEach(b => {
      if (b.tipo === 'llamar_telefono') {
        if (b.texto !== '') {
          buttons.push({
            type: 'PHONE_NUMBER',
            text: b.texto
          });
        }
      } else if (b.tipo === 'ir_web') {
        if (b.texto !== '') {
          buttons.push({
            type: 'URL',
            text: b.texto
          });
        }
      }
    });
    const index = template.components.findIndex(c => c.type === 'BUTTONS');
    if (index > 0) {
      delete template.components[index];
    }
    if (buttons.length) {
      const b = {
        type: 'BUTTONS',
        buttons
      };
      template.components.push(b);
    }
  }

  if (validationWhatsappTemplate.current.values.respuestaRapida.length > 0) {
    const { respuestaRapida } = validationWhatsappTemplate.current.values;
    const buttons = [];
    respuestaRapida.forEach(b => {
      if (b.texto !== '') {
        buttons.push({
          type: 'QUICK_REPLY',
          text: b.texto
        });
      }
    });
    const index = template.components.findIndex(c => c.type === 'BUTTONS');
    if (index > 0) {
      delete template.components[index];
    }
    if (buttons.length) {
      const b = {
        type: 'BUTTONS',
        buttons
      };
      template.components.push(b);
    }
  }

  useEffect(() => {
    let idiomasCompletos = 0;
    try {
      Object.keys(plantillas).forEach(p => {
        if (Object.keys(plantillas[p]).length > 0) {
          idiomasCompletos++;
        }
      });
      if (idiomasCompletos >= (idiomasSelected.length - 1) || idiomasSelected.length === 1) {
        setPlantillasCompletas(true);
      } else {
        setPlantillasCompletas(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [plantillas]);

  useEffect(() => {
    if (idiomaEditando?.value) {
      validationWhatsappTemplate.current.setFieldValue(
        'idioma',
        idiomaEditando.value
      );
    } else {
      validationWhatsappTemplate.current.setFieldValue(
        'idioma',
        ''
      );
    }
  }, [idiomaEditando]);

  const onChangeTexo = async (e) => {
    let texto = e;
    const n = e.match(REGEX_VARIABLES)?.length || 0;
    const coincidencias = e.match(REGEX_VARIABLES);
    if (coincidencias?.length > 0) {
      coincidencias.forEach((c, index) => {
        const size = texto.split(c).length;
        if (size - 1 === 1) {
          texto = texto.replace(c, `{{${index + 1}}}`);
        }
      });
    }
    setVariablesTexto(n);
    validationWhatsappTemplate.current.setFieldValue('texto', texto);
    comprobacionesTexto(texto, n);
  };

  function useDetectarSiPulsacionDentro(ref, refBoton) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref?.current && !ref?.current?.contains(event?.target)) {
          if (refBoton?.current && !refBoton?.current?.contains(event?.target)) {
            setMenuEmojis(false);
          }
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [wrapperRef, botonRef]);
  }

  useDetectarSiPulsacionDentro(wrapperRef, botonRef);

  // Create the picker
  useEffect(
    () => {
      const rootElement = document.querySelector('#pickerContainer');
      if (rootElement) {
        const picker = createPicker({
          rootElement,
          categories: [
            'smileys-emotion',
            'people-body',
            'animals-nature',
            'food-drink',
            'travel-places',
            'activities',
            'objects',
            'symbols',
          ]
        });
        // The picker emits an event when an emoji is selected.
        // Do with it as you will!
        picker.addEventListener('emoji:select', event => {
          let texto = validationWhatsappTemplate.current?.values?.texto ? validationWhatsappTemplate.current.values.texto : '';
          const ta = textArea.current;
          const fin = ta.selectionEnd !== 0 ? ta.selectionEnd : texto.length;
          texto = `${texto?.slice(0, fin)}${event.emoji}${texto?.slice(fin)}`;
          ta.selectionEnd = fin + 1;
          validationWhatsappTemplate.current.setFieldValue(
            'texto',
            texto
          );
        });
      }
    }, [menuEmojis]
  );

  document.title = `${t('New Whatsapp template')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={{ size: 7, offset: 2 }} md={{ size: 8, offset: 2 }}>
              <Breadcrumbs title={t('Whatsapp')} breadcrumbItem={t('Whatsapp templates')} back />
            </Col>
          </Row>
          <Row>
            <Col lg={{ size: 2 }} md={{ size: 2 }}>
              {tab === 2 && (
                <Card>
                  <CardBody>
                    <h5>{t('Languages')}</h5>
                    {idiomasSelected.map(idioma => (
                      <Button
                        type="button"
                        color="light"
                        onClick={() => {
                          try {
                            if (!validationWhatsappTemplate.current.isValid) {
                              Object.entries(validationWhatsappTemplate.current.errors)
                                .forEach(key => {
                                  validationWhatsappTemplate
                                    .current.setFieldTouched(key[0], true);
                                });
                              return;
                            }
                            const plantillasAux = structuredClone(plantillas);
                            const {
                              nombre,
                              categoria
                            } = validationWhatsappTemplate.current.values;
                            plantillasAux[idiomaEditando.value] =
                              validationWhatsappTemplate.current.values;
                            console.log(plantillas);
                            console.log(validationWhatsappTemplate.current.values);
                            if (Object.keys(plantillasAux[idioma.value]).length > 0) {
                              validationWhatsappTemplate.current.setValues(
                                plantillasAux[idioma.value]
                              );
                              onChangeTexo(plantillasAux[idioma.value].texto);
                              setNumBotones(
                                plantillasAux[idioma.value].respuestaRapida.length
                              );
                              setNumAcciones(
                                plantillasAux[idioma.value].llamadaAccion.length
                              );
                            } else {
                              validationWhatsappTemplate.current.resetForm();
                              validationWhatsappTemplate.current.setFieldValue('nombre', nombre);
                              validationWhatsappTemplate.current.setFieldValue('categoria', categoria);
                              setNumBotones(0);
                              setNumAcciones(0);
                              onChangeTexo('');
                            }
                            setPlantillas(plantillasAux);
                            setIdiomaEditando(idioma);
                          } catch (e) {
                            console.log(e);
                          }
                        }}
                        className={
                          idioma?.value === idiomaEditando?.value ? 'listado-idiomas bg-light' : 'listado-idiomas bg-transparent'
                        }
                        key={idioma.value}
                      >
                        {idioma.label}
                      </Button>
                    ))}
                    <Select
                      value={null}
                      placeholder={t('Add language')}
                      className="mt-1"
                      onChange={event => {
                        const idiomasAux = structuredClone(idiomasSelected);
                        idiomasAux.push(event);
                        const plantillasAux = structuredClone(plantillas);
                        plantillasAux[event.value] = {};
                        setPlantillas(plantillasAux);
                        setIdiomasSelected(idiomasAux);
                      }}
                      options={[
                        {
                          options: languagesWhatsapp.filter(
                            el1 => !idiomasSelected.filter(el2 => el2?.value === el1?.value).length
                          )
                        }
                      ]}
                      classNamePrefix="select2-selection"
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col lg={{ size: 7 }} md={{ size: 8 }}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {t('New Whatsapp template')}
                    {' '}
                    {tab === 2 && (
                      <span>
                        (
                        {validationWhatsappTemplate.current.values.nombre || ''}
                        )
                      </span>
                    )}
                    {idiomasSelected.length > 1 && tab === 2 && (
                      <Button
                        type="button"
                        color="link"
                        onClick={() => {
                          const idiomasSelectedAux = idiomasSelected.filter(
                            i => i.value !== idiomaEditando.value
                          );
                          const plantillasAux = structuredClone(plantillas);
                          delete plantillasAux[idiomaEditando.value];
                          setPlantillas(plantillasAux);
                          setIdiomasSelected(idiomasSelectedAux);
                          setIdiomaEditando(idiomasSelectedAux[0]);
                          validationWhatsappTemplate.current.setValues(
                            plantillasAux[idiomasSelectedAux[0].value]
                          );
                        }}
                      >
                        <i className="bx bx-trash font-size-18" />
                      </Button>
                    )}
                  </CardTitle>
                  <TabContent activeTab={tab}>
                    <TabPane tabId={1}>
                      <Form>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-nombre-Input"
                            className="col-sm-4 col-form-label"
                          >
                            {t('Name')}
                          </Label>
                          <Col
                            sm={7}
                            style={{
                              paddingRight: 0
                            }}
                          >
                            <Input
                              type="text"
                              name="nombre"
                              className="form-control"
                              maxLength={NOMBRE_LENGTH}
                              id="horizontal-nombre-Input"
                              onChange={event => {
                                let nombre = event.target.value;
                                nombre = nombre
                                  .replace(REGEX_NOMBRE_VALIDO, '')
                                  .replace(/ /g, '_')
                                  .replace(/_+/g, '_')
                                  .toLowerCase();
                                validationWhatsappTemplate.current.setFieldValue('nombre', nombre);
                              }}
                              onBlur={validationWhatsappTemplate.current.handleBlur}
                              value={validationWhatsappTemplate.current.values.nombre || ''}
                              invalid={
                                !!(
                                  validationWhatsappTemplate.current.touched.nombre &&
                                  validationWhatsappTemplate.current.errors.nombre
                                )
                              }
                            />
                            {validationWhatsappTemplate.current.touched.nombre &&
                            validationWhatsappTemplate.current.errors.nombre ? (
                              <FormFeedback type="invalid">
                                {validationWhatsappTemplate.current.errors.nombre}
                              </FormFeedback>
                              ) : null}
                          </Col>
                          <Col
                            sm={1}
                            style={{
                              paddingRight: 0,
                              paddingLeft: 0
                            }}
                          >
                            <span
                              className="form-control"
                              style={{
                                border: 'none',
                                paddingLeft: '3px'
                              }}
                            >
                              {validationWhatsappTemplate.current.values.nombre?.length || 0}
                              /
                              {NOMBRE_LENGTH}
                            </span>
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label htmlFor="categoria-input" className="col-sm-4 col-form-label">
                            {t('Category')}
                          </Label>
                          <Col sm={8}>
                            <select
                              value={validationWhatsappTemplate.current.values.categoria || 'otro'}
                              className={(
                                validationWhatsappTemplate.current.touched.categoria &&
                                validationWhatsappTemplate.current.errors.categoria
                              ) ? 'form-select is-invalid' : 'form-select'}
                              name="categoria"
                              id="categoria-input"
                              onChange={event => {
                                validationWhatsappTemplate.current.setFieldValue(
                                  'categoria',
                                  event.target.value
                                );
                              }}
                            >
                              <option disabled value="otro">
                                {t('Select an option')}
                              </option>
                              <option value="marketing">{t('Marketing')}</option>
                              <option value="utility">{t('Utility')}</option>
                            </select>
                            {validationWhatsappTemplate.current.touched.categoria &&
                            validationWhatsappTemplate.current.errors.categoria ? (
                              <FormFeedback type="invalid">
                                {validationWhatsappTemplate.current.errors.categoria}
                              </FormFeedback>
                              ) : null}
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label className="col-sm-4 col-form-label">
                            {t('Language')}
                          </Label>
                          <Col sm={8}>
                            <Select
                              id="idioma-input"
                              placeholder={t('Language')}
                              isMulti
                              onChange={event => {
                                if (event) {
                                  setIdiomasSelected(event);
                                  setIdiomaEditando(event[0]);
                                } else {
                                  setIdiomasSelected([]);
                                  setIdiomaEditando({});
                                }
                              }}
                              options={[
                                {
                                  options: languagesWhatsapp.filter(
                                    el1 => !idiomasSelected
                                      .filter(el2 => el2?.value === el1?.value).length
                                  )
                                }
                              ]}
                              classNamePrefix="select2-selection"
                              className={(
                                validationWhatsappTemplate.current.touched.idioma &&
                                validationWhatsappTemplate.current.errors.idioma
                              ) ? ' is-invalid' : ''}
                            />
                            {validationWhatsappTemplate.current.errors.idioma ? (
                              <FormFeedback type="invalid">
                                {validationWhatsappTemplate.current.errors.idioma}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </div>
                        <div className="row justify-content-end">
                          <Col sm={8}>
                            <div>
                              <Button
                                type="button"
                                color="success"
                                className="w-md"
                                onClick={() => {
                                  const { nombre, categoria } =
                                    validationWhatsappTemplate.current.values;
                                  validationWhatsappTemplate.current.setFieldTouched('nombre', true);
                                  validationWhatsappTemplate.current.setFieldTouched('categoria', true);
                                  validationWhatsappTemplate.current.setFieldTouched('idioma', true);
                                  console.log(validationWhatsappTemplate.current.errors);
                                  if (nombre !== '' && categoria !== 'otro' && idiomasSelected.length > 0) {
                                    const plantillasAux = {};
                                    idiomasSelected.forEach(i => {
                                      plantillasAux[i.value] = {};
                                    });
                                    setPlantillas(plantillasAux);
                                    setTab(2);
                                  }
                                }}
                              >
                                {t('Continue')}
                              </Button>
                              <Button
                                type="button"
                                color="link"
                                className="w-md"
                                onClick={() => {
                                  setModalStandard(true);
                                }}
                              >
                                {t('Cancel')}
                              </Button>
                            </div>
                          </Col>
                        </div>
                      </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          const plantillasAux = structuredClone(plantillas);
                          plantillasAux[idiomaEditando.value] =
                            validationWhatsappTemplate.current.values;
                          setPlantillas(plantillasAux);
                          validationWhatsappTemplate.current.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-4">
                          <div className="row">
                            <Col sm={2}>
                              <Label htmlFor="cabecera-input" className="col-form-label">
                                {t('Header')}
                              </Label>
                            </Col>
                            <Col sm={3}>
                              <select
                                value={validationWhatsappTemplate.current.values.cabecera || 'sin'}
                                className="form-select"
                                id="cabecera-input"
                                onChange={event => {
                                  validationWhatsappTemplate.current.setFieldValue(
                                    'cabecera',
                                    event.target.value
                                  );
                                  if (event.target.value === 'sin') {
                                    validationWhatsappTemplate.current.setFieldValue(
                                      'cabeceraTexto',
                                      ''
                                    );
                                  }
                                }}
                              >
                                <option value="sin">{t('Ninguno')}</option>
                                <option value="texto">{t('Texto')}</option>
                              </select>
                            </Col>
                            {validationWhatsappTemplate.current.values.cabecera === 'texto' && (
                              <>
                                <Col
                                  sm={6}
                                  style={{
                                    paddingRight: 0
                                  }}
                                >
                                  <Input
                                    type="text"
                                    maxLength={CABECERA_LENGTH}
                                    value={
                                      validationWhatsappTemplate.current.values.cabeceraTexto || ''
                                    }
                                    onChange={event => {
                                      validationWhatsappTemplate.current.setFieldValue(
                                        'cabeceraTexto',
                                        event.target.value
                                      );
                                      const n =
                                        event.target.value.match(REGEX_VARIABLES)?.length || 0;
                                      setVariableCabecera(n);
                                      if (n === 0) {
                                        validationWhatsappTemplate.current.setFieldValue(
                                          'variableCabeceraEjemplo',
                                          []
                                        );
                                      }
                                    }}
                                  />
                                </Col>
                                <Col
                                  sm={1}
                                  style={{
                                    paddingRight: 0,
                                    paddingLeft: 0
                                  }}
                                >
                                  <span
                                    className="form-control"
                                    style={{
                                      border: 'none',
                                      paddingLeft: '3px'
                                    }}
                                  >
                                    {validationWhatsappTemplate
                                      .current.values.cabeceraTexto?.length || 0}
                                    /
                                    {CABECERA_LENGTH}
                                  </span>
                                </Col>
                              </>
                            )}
                          </div>
                          {validationWhatsappTemplate.current.values.cabecera === 'texto' && (
                            <Row>
                              <Col sm={10} style={{ textAlign: 'end' }}>
                                <Button
                                  type="button"
                                  disabled={variableCabecera === 1}
                                  onClick={async () => {
                                    let { cabeceraTexto } =
                                      validationWhatsappTemplate.current.values;
                                    cabeceraTexto = cabeceraTexto || '';
                                    validationWhatsappTemplate.current.setFieldValue(
                                      'cabeceraTexto',
                                      `${cabeceraTexto}{{1}}`
                                    );
                                    const variableCabeceraEjemplo =
                                    structuredClone(
                                      validationWhatsappTemplate.current
                                        .values.variableCabeceraEjemplo
                                    );
                                    variableCabeceraEjemplo.push('');
                                    await validationWhatsappTemplate.current.setFieldValue(
                                      'variableCabeceraEjemplo',
                                      variableCabeceraEjemplo
                                    );
                                    setVariableCabecera(variableCabecera + 1);
                                  }}
                                  className="link-file"
                                  color="link"
                                  style={{ display: 'none' }}
                                >
                                  {t('Add variable')}
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </div>
                        {variableCabecera > 0 && (
                          <div className="shadow bg-light rounded mb-4 mx-4 px-4">
                            <h5 className="pt-4">{t('Examples of header content')}</h5>
                            <p>
                              {t(
                                'To help us review the content, provide examples of variables in the header. Do not include any customer information.'
                              )}
                            </p>
                            {[...Array(variableCabecera)].map((a, i) => (
                              <div key={`variable${i}`} className="row p-2">
                                <Col sm={1}>
                                  <Label
                                    htmlFor={`placeholder_cabecera_${i}`}
                                    className="col-form-label"
                                  >
                                    {`{{${i + 1}}}`}
                                  </Label>
                                </Col>
                                <Col sm={11}>
                                  <Input
                                    id={`placeholder_cabecera_${i}`}
                                    className="col-form-label"
                                    onBlur={validationWhatsappTemplate.current.handleBlur}
                                    value={validationWhatsappTemplate.current.values?.variableCabeceraEjemplo?.[0] || ''}
                                    name="variableCabeceraEjemplo[0].texto"
                                    invalid={
                                      !!(validationWhatsappTemplate
                                        .current.touched?.variableCabeceraEjemplo?.[0] &&
                                        validationWhatsappTemplate.current
                                          .errors?.variableCabeceraEjemplo?.[0]
                                      )
                                    }
                                    placeholder={`${t('Enter content for')} {{1}}`}
                                    onChange={async event => {
                                      await validationWhatsappTemplate.current.setFieldValue(
                                        'variableCabeceraEjemplo[0]',
                                        event.target.value
                                      );
                                    }}
                                  />
                                  {validationWhatsappTemplate.current
                                    .errors?.variableCabeceraEjemplo?.[0] ? (
                                      <FormFeedback type="invalid">
                                        {validationWhatsappTemplate.current
                                          .errors?.variableCabeceraEjemplo?.[0]}
                                      </FormFeedback>
                                    ) : null}
                                </Col>
                              </div>
                            ))}
                          </div>
                        )}
                        <div className="row mb-4">
                          <Row>
                            <Col sm={2}>
                              <Label htmlFor="textarea-contenido" className="col-form-label">
                                {t('Text')}
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <TextareaAutosize
                                ref={textArea}
                                maxRows={12}
                                minRows={4}
                                maxLength={TEXTO_LENGTH}
                                className={(
                                  validationWhatsappTemplate.current.touched.texto &&
                                    validationWhatsappTemplate.current.errors.texto
                                ) ? 'form-control is-invalid' : 'form-control'}
                                name="texto"
                                id="textarea-contenido"
                                onBlur={validationWhatsappTemplate.current.handleBlur}
                                value={validationWhatsappTemplate.current.values.texto || ''}
                                onChange={event => onChangeTexo(event.target.value)}
                              />
                              {validationWhatsappTemplate.current.errors.texto ? (
                                <FormFeedback type="invalid">
                                  {validationWhatsappTemplate.current.errors.texto}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            {(variablesIncorrectas || demasidasVariables) && (
                              <Col sm={12}>
                                <div
                                  className="py-2 px-4 rounded"
                                  style={{
                                    backgroundColor: variablesIncorrectas ? 'rgba(255,235,235,1)' :
                                      'rgba(255,246,220,1)'
                                  }}
                                >
                                  <i
                                    className="bx bx-error font-size-20"
                                    style={{
                                      color: variablesIncorrectas ? '#FF0F0F' : 'rgba(210,120,5,1)'
                                    }}
                                  />
                                  <ul>
                                    {variablesIncorrectas && <li>{t('VARIABLES_INCORRECTAS')}</li>}
                                    {demasidasVariables && <li>{t('DEMASIADAS_VARIABLES')}</li>}
                                  </ul>
                                </div>
                              </Col>
                            )}
                            <Col sm={12} style={{ textAlign: 'end' }}>
                              <span
                                className="form-control"
                                style={{
                                  border: 'none',
                                  paddingLeft: '3px'
                                }}
                              >
                                {validationWhatsappTemplate
                                  .current.values.texto?.length || 0}
                                /
                                {TEXTO_LENGTH}
                              </span>
                              <Tooltip
                                placement="bottom-start"
                                target="boton-emoji"
                                isOpen={menuEmojis}
                                style={{ all: 'unset' }}
                              >
                                <div
                                  ref={wrapperRef}
                                  style={{ display: 'block' }}
                                  id="pickerContainer"
                                />
                              </Tooltip>
                              <div style={{ all: 'unset' }} ref={botonRef}>
                                <Button
                                  id="boton-emoji"
                                  type="button"
                                  onClick={() => {
                                    setMenuEmojis(!menuEmojis);
                                  }}
                                  color="link"
                                >
                                  😀
                                </Button>
                                <UncontrolledTooltip placement="top" target="boton-emoji">
                                  {t('Insertar emoji')}
                                </UncontrolledTooltip>
                              </div>
                              <Button
                                id="boton-negrita"
                                type="button"
                                onClick={() => {
                                  let { texto } = validationWhatsappTemplate.current.values;
                                  texto = formatoParaWhatsapp(texto, '*');
                                  validationWhatsappTemplate.current.setFieldValue('texto', texto);
                                }}
                                color="link"
                              >
                                <i className="bx bx-bold" />
                              </Button>
                              <UncontrolledTooltip placement="top" target="boton-negrita">
                                {t('Bold')}
                              </UncontrolledTooltip>
                              <Button
                                id="boton-cursiva"
                                type="button"
                                onClick={() => {
                                  let { texto } = validationWhatsappTemplate.current.values;
                                  texto = formatoParaWhatsapp(texto, '_');
                                  validationWhatsappTemplate.current.setFieldValue('texto', texto);
                                }}
                                color="link"
                              >
                                <i className="bx bx-italic" />
                              </Button>
                              <UncontrolledTooltip placement="top" target="boton-cursiva">
                                {t('Italics')}
                              </UncontrolledTooltip>
                              <Button
                                id="boton-tachado"
                                type="button"
                                onClick={() => {
                                  let { texto } = validationWhatsappTemplate.current.values;
                                  texto = formatoParaWhatsapp(texto, '~');
                                  validationWhatsappTemplate.current.setFieldValue('texto', texto);
                                }}
                                color="link"
                              >
                                <i className="bx bx-strikethrough" />
                              </Button>
                              <UncontrolledTooltip placement="top" target="boton-tachado">
                                {t('Strikethrough')}
                              </UncontrolledTooltip>
                              <Button
                                id="boton-monospace"
                                type="button"
                                onClick={() => {
                                  let { texto } = validationWhatsappTemplate.current.values;
                                  texto = formatoParaWhatsapp(texto, '```');
                                  validationWhatsappTemplate.current.setFieldValue('texto', texto);
                                }}
                                color="link"
                              >
                                <i className="bx bx-code-alt" />
                              </Button>
                              <UncontrolledTooltip placement="top" target="boton-monospace">
                                {t('Monospace')}
                              </UncontrolledTooltip>
                              <Button
                                type="button"
                                onClick={async () => {
                                  let { texto } = validationWhatsappTemplate.current.values;
                                  const { variablesEjemplo, placeholdersPlantilla } =
                                    validationWhatsappTemplate.current.values;
                                  const variablesEjemploAux = structuredClone(variablesEjemplo);
                                  const placeholdersPlantillaAux = structuredClone(
                                    placeholdersPlantilla
                                  );
                                  texto = texto || '';
                                  const variableInsertar = `{{${variablesTexto + 1}}}`;
                                  const cursorPosicion = textArea.current.selectionStart;
                                  const textoPrevio = texto.substring(0, cursorPosicion);
                                  const textoPosterior = texto.substring(
                                    cursorPosicion,
                                    texto.length
                                  );
                                  texto = textoPrevio + variableInsertar + textoPosterior;
                                  variablesEjemploAux.push('');
                                  await validationWhatsappTemplate.current.setFieldValue(
                                    'variablesEjemplo',
                                    variablesEjemploAux
                                  );
                                  placeholdersPlantillaAux.push({ label: '', value: '' });
                                  await validationWhatsappTemplate.current.setFieldValue(
                                    'placeholdersPlantilla',
                                    placeholdersPlantillaAux
                                  );
                                  await validationWhatsappTemplate.current.setFieldValue(
                                    'texto',
                                    texto
                                  );
                                  textArea.current.focus();
                                  setVariablesTexto(variablesTexto + 1);
                                  comprobacionesTexto(texto, variablesTexto + 1);
                                }}
                                className="link-file"
                                color="link"
                              >
                                {t('Add variable')}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        {variablesTexto > 0 && (
                          <div className="shadow bg-light rounded mb-4 mx-4 px-4 pb-2">
                            <h5 className="pt-4">{t('Examples of body content')}</h5>
                            <p>
                              {t(
                                'To help us review the content, provide examples of the variables in the body. Do not include any customer information.'
                              )}
                            </p>
                            {[...Array(variablesTexto)].map((a, i) => (
                              <div key={`variable${i}`} className="row p-2">
                                <Col sm={1}>
                                  <Label
                                    htmlFor={`placeholder_${i}`}
                                    className="col-form-label"
                                  >
                                    {`{{${i + 1}}}`}
                                  </Label>
                                </Col>
                                <Col sm={4}>
                                  <Input
                                    className="col-form-label"
                                    placeholder={`${t('Enter content for')} {{${i + 1}}}`}
                                    onChange={async event => {
                                      await validationWhatsappTemplate.current.setFieldValue(
                                        `variablesEjemplo[${i}]`,
                                        event.target.value
                                      );
                                    }}
                                    id={`variablesEjemplo[${i}]`}
                                    name={`variablesEjemplo[${i}]`}
                                    onBlur={validationWhatsappTemplate.current.handleBlur}
                                    value={validationWhatsappTemplate.current.values?.variablesEjemplo?.[i] || ''}
                                    invalid={
                                      !!(validationWhatsappTemplate
                                        .current.touched?.variablesEjemplo?.[i] &&
                                        validationWhatsappTemplate.current
                                          .errors?.variablesEjemplo?.[i]
                                      )
                                    }
                                  />
                                  {validationWhatsappTemplate.current
                                    .errors.variablesEjemplo?.[i] ? (
                                      <FormFeedback type="invalid">
                                        {validationWhatsappTemplate.current
                                          .errors.variablesEjemplo?.[i]}
                                      </FormFeedback>
                                    ) : null}
                                </Col>
                                <Col sm={6}>
                                  <Select
                                    placeholder={t('Variable')}
                                    id={`placeholdersPlantilla[${i}].value`}
                                    onBlur={validationWhatsappTemplate.current.handleBlur}
                                    value={validationWhatsappTemplate.current.values?.placeholdersPlantilla?.[i] || ''}
                                    invalid={
                                      !!(validationWhatsappTemplate
                                        .current.touched?.placeholdersPlantilla?.[i]?.value &&
                                        validationWhatsappTemplate.current
                                          .errors?.placeholdersPlantilla?.[i]?.value
                                      )
                                    }
                                    onChange={async (event) => {
                                      console.log(event);
                                      await validationWhatsappTemplate.current.setFieldValue(
                                        `placeholdersPlantilla[${i}]`,
                                        event
                                      );
                                    }}
                                    options={[
                                      {
                                        options: PLACEHOLDERS_DISPONIBLES
                                      }
                                    ]}
                                    className={(
                                      validationWhatsappTemplate.current.touched
                                        .placeholdersPlantilla?.[i]?.value &&
                                        validationWhatsappTemplate.current.errors
                                          .placeholdersPlantilla?.[i]?.value
                                    ) ? ' is-invalid' : ''}
                                    classNamePrefix="select2-selection"
                                  />
                                  {validationWhatsappTemplate.current.errors
                                    .placeholdersPlantilla?.[i]?.value ? (
                                      <FormFeedback type="invalid">
                                        {validationWhatsappTemplate.current.errors
                                          .placeholdersPlantilla?.[i]?.value}
                                      </FormFeedback>
                                    ) : null}
                                </Col>
                              </div>
                            ))}
                          </div>
                        )}
                        <div className="row mb-4">
                          <Col sm={2}>
                            <Label htmlFor="pie-input" className="col-form-label">
                              {t('Footer')}
                            </Label>
                          </Col>
                          <Col
                            sm={8}
                            style={{
                              paddingRight: 0
                            }}
                          >
                            <Input
                              id="pie-input"
                              type="text"
                              maxLength={PIE_LENGTH}
                              className="form-control"
                              value={validationWhatsappTemplate.current.values.pieTexto || ''}
                              onChange={event => {
                                validationWhatsappTemplate.current.setFieldValue(
                                  'pieTexto',
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                          <Col
                            sm={1}
                            style={{
                              paddingRight: 0,
                              paddingLeft: 0
                            }}
                          >
                            <span
                              className="form-control"
                              style={{
                                border: 'none',
                                paddingLeft: '3px'
                              }}
                            >
                              {validationWhatsappTemplate
                                .current.values.pieTexto?.length || 0}
                              /
                              {PIE_LENGTH}
                            </span>
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col sm={2}>
                            <Label htmlFor="botones-input" className="col-form-label">
                              {t('Buttons')}
                            </Label>
                          </Col>
                          <Col sm={4}>
                            <select
                              value={validationWhatsappTemplate.current.values.botones || 'sin'}
                              className="form-select"
                              id="botones-input"
                              onChange={async (event) => {
                                /* console.log(validationWhatsappTemplate.current.errors);
                                console.log(validationWhatsappTemplate.current.values); */
                                const valor = event.target.value;
                                if (valor === 'llamada_accion') {
                                  const llamadaAccion =
                                    structuredClone(
                                      validationWhatsappTemplate.current.values.llamadaAccion
                                    );
                                  llamadaAccion.push({ tipo: 'llamar_telefono', texto: '', pais: '+34', telefono: '' });
                                  await validationWhatsappTemplate.current.setFieldValue(
                                    'llamadaAccion',
                                    llamadaAccion
                                  );
                                  await validationWhatsappTemplate.current.setFieldValue(
                                    'respuestaRapida',
                                    []
                                  );
                                  setNumBotones(0);
                                  setNumAcciones(numAcciones + 1);
                                } else if (valor === 'respuesta_rapida') {
                                  const respuestaRapida =
                                    structuredClone(
                                      validationWhatsappTemplate.current.values.respuestaRapida
                                    );
                                  respuestaRapida.push({ texto: '' });
                                  await validationWhatsappTemplate.current.setFieldValue(
                                    'respuestaRapida',
                                    respuestaRapida
                                  );
                                  await validationWhatsappTemplate.current.setFieldValue(
                                    'llamadaAccion',
                                    []
                                  );
                                  setNumAcciones(0);
                                  setNumBotones(numBotones + 1);
                                } else {
                                  await validationWhatsappTemplate.current.setFieldValue(
                                    'llamadaAccion',
                                    []
                                  );
                                  await validationWhatsappTemplate.current.setFieldValue(
                                    'respuestaRapida',
                                    []
                                  );
                                  setNumAcciones(0);
                                  setNumBotones(0);
                                }
                                await validationWhatsappTemplate.current.setFieldValue(
                                  'botones',
                                  valor
                                );
                              }}
                            >
                              <option value="sin">{t('Ninguno')}</option>
                              <option value="llamada_accion">{t('Call to action')}</option>
                              <option value="respuesta_rapida">{t('Quick reply')}</option>
                            </select>
                          </Col>
                        </div>
                        {validationWhatsappTemplate.current.values.botones ===
                          'respuesta_rapida' && (
                          <>
                            {[...Array(numBotones)].map((b, i) => (
                              <div key={`botones${i}`} className="row mb-4">
                                <Col sm={2}>
                                  <Label
                                    htmlFor={`respuestaRapida[${i}].texto`}
                                    className="col-form-label"
                                  >
                                    {t('Button text')}
                                  </Label>
                                </Col>
                                <Col sm={3} className="pr-0">
                                  <Input
                                    type="text"
                                    id={`respuestaRapida[${i}].texto`}
                                    className="form-control"
                                    name={`respuestaRapida[${i}].texto`}
                                    onBlur={validationWhatsappTemplate.current.handleBlur}
                                    value={validationWhatsappTemplate.current.values?.respuestaRapida?.[i]?.texto || ''}
                                    invalid={
                                      !!(validationWhatsappTemplate
                                        .current.touched?.respuestaRapida?.[i]?.texto &&
                                        validationWhatsappTemplate.current.errors
                                          ?.respuestaRapida?.[i]?.texto
                                      )
                                    }
                                    maxLength={25}
                                    onChange={event => {
                                      validationWhatsappTemplate.current.setFieldValue(
                                        `respuestaRapida[${i}].texto`,
                                        event.target.value
                                      );
                                    }}
                                  />
                                  {validationWhatsappTemplate.current.errors
                                    ?.respuestaRapida?.[i]?.texto ? (
                                      <FormFeedback type="invalid">
                                        {validationWhatsappTemplate.current.errors
                                          ?.respuestaRapida?.[i]?.texto}
                                      </FormFeedback>
                                    ) : null}
                                </Col>
                                {numBotones > 1 && (
                                  <Col sm={1} className="px-0">
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        setNumBotones(numBotones - 1);
                                      }}
                                      color="link"
                                    >
                                      <i className="bx bx-x font-size-20 ms-0" />
                                    </Button>
                                  </Col>
                                )}
                              </div>
                            ))}
                            {numBotones < BOTONES_LIMITE && (
                              <div className="row mb-4">
                                <Col sm={{ size: 2, offset: 2 }}>
                                  <Button
                                    type="button"
                                    outline
                                    onClick={async () => {
                                      const respuestaRapida =
                                        structuredClone(
                                          validationWhatsappTemplate.current.values.respuestaRapida
                                        );
                                      respuestaRapida.push({ texto: '' });
                                      await validationWhatsappTemplate.current.setFieldValue(
                                        'respuestaRapida',
                                        respuestaRapida
                                      );
                                      setNumBotones(numBotones + 1);
                                    }}
                                    className="link-file"
                                    color="info"
                                  >
                                    {t('Add button')}
                                  </Button>
                                </Col>
                              </div>
                            )}
                          </>
                        )}
                        {validationWhatsappTemplate.current.values.botones === 'llamada_accion' && (
                          <>
                            {[...Array(numAcciones)].map((a, i) => (
                              <div key={`accion${i}`} className="shadow bg-light rounded mb-4">
                                <div className="row p-2">
                                  <Col sm={3}>
                                    <Label
                                      htmlFor={`acciones_tipo_${i}`}
                                      className="col-form-label"
                                    >
                                      {t('Action type')}
                                    </Label>
                                  </Col>
                                  <Col sm={3}>
                                    <Label
                                      htmlFor={`acciones_texto_${i}`}
                                      className="col-form-label"
                                    >
                                      {t('Button text')}
                                    </Label>
                                  </Col>
                                  {validationWhatsappTemplate.current.values.llamadaAccion[i]
                                    ?.tipo === 'llamar_telefono' && (
                                    <>
                                      <Col sm={2}>
                                        <Label
                                          htmlFor={`acciones_pais_${i}`}
                                          className="col-form-label"
                                        >
                                          {t('Country')}
                                        </Label>
                                      </Col>
                                      <Col sm={2}>
                                        <Label
                                          htmlFor={`acciones_telefono_${i}`}
                                          className="col-form-label"
                                        >
                                          {t('Phone number')}
                                        </Label>
                                      </Col>
                                    </>
                                  )}
                                  {validationWhatsappTemplate.current.values.llamadaAccion[i]
                                    ?.tipo === 'ir_web' && (
                                    <>
                                      <Col sm={2}>
                                        <Label
                                          htmlFor={`acciones_tipo_url_${i}`}
                                          className="col-form-label"
                                        >
                                          {t('Url type')}
                                        </Label>
                                      </Col>
                                      <Col sm={2}>
                                        <Label
                                          htmlFor={`acciones_url_${i}`}
                                          className="col-form-label"
                                        >
                                          {t('Website URL')}
                                        </Label>
                                      </Col>
                                    </>
                                  )}
                                  {numAcciones > 1 && (
                                    <Col sm={1} className="px-0">
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          setNumAcciones(numAcciones - 1);
                                        }}
                                        color="link"
                                      >
                                        <i className="bx bx-x font-size-20 ms-0" />
                                      </Button>
                                    </Col>
                                  )}
                                </div>
                                <div className="row p-2">
                                  <Col sm={3}>
                                    <select
                                      value={
                                        validationWhatsappTemplate.current.values.llamadaAccion[i]
                                          ?.tipo || 'llamar_telefono'
                                      }
                                      className="form-select"
                                      id="llamada-accion"
                                      onChange={event => {
                                        validationWhatsappTemplate.current.setFieldValue(
                                          `llamadaAccion[${i}].tipo`,
                                          event.target.value
                                        );
                                      }}
                                    >
                                      <option
                                        disabled={
                                          validationWhatsappTemplate.current.values.llamadaAccion[i]
                                            ?.tipo === 'ir_web' && numAcciones === 2
                                        }
                                        value="llamar_telefono"
                                      >
                                        {t('Llamar al número de teléfono')}
                                      </option>
                                      <option
                                        disabled={
                                          validationWhatsappTemplate.current.values.llamadaAccion[i]
                                            ?.tipo === 'llamar_telefono' && numAcciones === 2
                                        }
                                        value="ir_web"
                                      >
                                        {t('Ir al sitio web')}
                                      </option>
                                    </select>
                                  </Col>
                                  <Col sm={3}>
                                    <Input
                                      type="text"
                                      name={`llamadaAccion[${i}].texto`}
                                      className="form-control"
                                      onBlur={validationWhatsappTemplate.current.handleBlur}
                                      value={validationWhatsappTemplate.current.values?.llamadaAccion?.[i]?.texto || ''}
                                      invalid={
                                        !!(validationWhatsappTemplate
                                          .current.touched?.llamadaAccion?.[i]?.texto &&
                                          validationWhatsappTemplate.current.errors
                                            ?.llamadaAccion?.[i]?.texto
                                        )
                                      }
                                      maxLength={25}
                                      onChange={event => {
                                        validationWhatsappTemplate.current.setFieldValue(
                                          `llamadaAccion[${i}].texto`,
                                          event.target.value
                                        );
                                      }}
                                    />
                                    {validationWhatsappTemplate.current.errors
                                      ?.llamadaAccion?.[i]?.texto ? (
                                        <FormFeedback type="invalid">
                                          {validationWhatsappTemplate.current.errors
                                            ?.llamadaAccion?.[i]?.texto}
                                        </FormFeedback>
                                      ) : null}
                                  </Col>
                                  {validationWhatsappTemplate.current.values.llamadaAccion[i]
                                    ?.tipo === 'llamar_telefono' && (
                                    <>
                                      <Col sm={2}>
                                        <select
                                          className="form-select"
                                          id="horizontal-prefix-Input"
                                          onChange={event => {
                                            validationWhatsappTemplate.current.setFieldValue(
                                              `llamadaAccion[${i}].pais`,
                                              event.target.value
                                            );
                                          }}
                                          value={
                                            validationWhatsappTemplate.current.values.prefix || 'ES'
                                          }
                                        >
                                          {map(prefixList, country => (
                                            <option key={country.code} value={country.code}>
                                              {country.dial_code}
                                            </option>
                                          ))}
                                        </select>
                                      </Col>
                                      <Col sm={2}>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          maxLength={20}
                                          value={validationWhatsappTemplate.current.values?.llamadaAccion?.[i]?.telefono || ''}
                                          onChange={event => {
                                            validationWhatsappTemplate.current.setFieldValue(
                                              `llamadaAccion[${i}].telefono`,
                                              event.target.value
                                            );
                                          }}
                                        />
                                      </Col>
                                    </>
                                  )}
                                  {validationWhatsappTemplate.current.values.llamadaAccion[i]
                                    ?.tipo === 'ir_web' && (
                                    <>
                                      <Col sm={2}>
                                        <select
                                          className="form-select"
                                          id="horizontal-prefix-Input"
                                          onChange={(event) => {
                                            validationWhatsappTemplate.current.setFieldValue(
                                              'tipoUrl',
                                              event.target.value
                                            );
                                          }}
                                          value={
                                            validationWhatsappTemplate.current.values.tipoUrl || 'estatica'
                                          }
                                        >
                                          <option value="estatica">{t('Static')}</option>
                                          <option value="dinamica">{t('Dynamic')}</option>
                                        </select>
                                      </Col>
                                      <Col sm={2}>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={validationWhatsappTemplate.current.values?.llamadaAccion?.[i]?.url || ''}
                                          onChange={event => {
                                            validationWhatsappTemplate.current.setFieldValue(
                                              `llamadaAccion[${i}].url`,
                                              event.target.value
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col sm={1} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                        <span>
                                          {'{{1}}'}
                                        </span>
                                      </Col>
                                    </>
                                  )}
                                  {validationWhatsappTemplate.current.values.llamadaAccion[i]
                                    ?.tipo === 'ir_web' && validationWhatsappTemplate.current.values.tipoUrl === 'dinamica' && (
                                    <div className="row p-2">
                                      <Col sm={1}>
                                        <Label
                                          htmlFor="horizontal-url-Input"
                                          className="col-form-label"
                                        >
                                          {'{{1}}'}
                                        </Label>
                                      </Col>
                                      <Col sm={11}>
                                        <Input
                                          htmlFor="horizontal-url-Input"
                                          className="col-form-label"
                                          placeholder="{{1}}"
                                          value={validationWhatsappTemplate.current.values?.variableUrlEjemplo[0] || ''}
                                          onChange={async event => {
                                            const variableUrl = event.target.value;
                                            await validationWhatsappTemplate.current.setFieldValue(
                                              'variableUrlEjemplo[0]',
                                              variableUrl
                                            );
                                          }}
                                        />
                                      </Col>
                                    </div>
                                  )}
                                </div>
                                {numBotones > 1 && (
                                  <Col sm={1} className="px-0">
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        setNumAcciones(numAcciones - 1);
                                      }}
                                      color="link"
                                    >
                                      <i className="bx bx-x font-size-20 ms-0" />
                                    </Button>
                                  </Col>
                                )}
                              </div>
                            ))}
                            {numAcciones < ACCIONES_LIMITE && (
                              <div className="row mb-4">
                                <Col sm={{ size: 2 }}>
                                  <Button
                                    type="button"
                                    outline
                                    onClick={async () => {
                                      const { llamadaAccion } =
                                        validationWhatsappTemplate.current.values;
                                      if (llamadaAccion[0]?.tipo === 'llamar_telefono') {
                                        llamadaAccion.push({ tipo: 'ir_web', texto: '', pais: '+34', telefono: '' });
                                      } else {
                                        llamadaAccion.push({ tipo: 'llamar_telefono', texto: '', pais: '+34', telefono: '' });
                                      }
                                      await validationWhatsappTemplate.current.setFieldValue(
                                        'llamadaAccion',
                                        structuredClone(llamadaAccion)
                                      );
                                      setNumAcciones(numAcciones + 1);
                                    }}
                                    className="link-file"
                                    color="info"
                                  >
                                    {t('Add button')}
                                  </Button>
                                </Col>
                              </div>
                            )}
                          </>
                        )}
                        <div className="row mb-2">
                          {idiomasSelected.findIndex(i => i.value === idiomaEditando.value) > 0 &&
                            idiomasSelected.length > 1 && (
                            <Col style={{ textAlign: 'start' }} size={{ sm: 3 }}>
                              <Button
                                type="button"
                                color="link"
                                className="w-md"
                                onClick={() => {
                                  if (!validationWhatsappTemplate.current.isValid) {
                                    Object.entries(validationWhatsappTemplate.current.errors)
                                      .forEach(key => {
                                        validationWhatsappTemplate
                                          .current.setFieldTouched(key[0], true);
                                      });
                                    return;
                                  }
                                  const index =
                                    idiomasSelected.findIndex(
                                      i => i.value === idiomaEditando.value
                                    );
                                  const idiomaPrevio = idiomasSelected[index];
                                  const idioma = idiomasSelected[index - 1];
                                  setIdiomaEditando(idioma);
                                  const plantillasAux = structuredClone(plantillas);
                                  const {
                                    nombre,
                                    categoria
                                  } = validationWhatsappTemplate.current.values;
                                  plantillasAux[idiomaPrevio.value] =
                                    validationWhatsappTemplate.current.values;
                                  if (Object.keys(plantillasAux[idioma.value]).length > 0) {
                                    validationWhatsappTemplate.current.setValues(
                                      plantillasAux[idioma.value]
                                    );
                                    onChangeTexo(plantillasAux[idioma.value].texto);
                                    setNumBotones(
                                      plantillasAux[idioma.value].respuestaRapida.length
                                    );
                                    setNumAcciones(
                                      plantillasAux[idioma.value].llamadaAccion.length
                                    );
                                  } else {
                                    validationWhatsappTemplate.current.resetForm();
                                    validationWhatsappTemplate.current.setFieldValue('nombre', nombre);
                                    validationWhatsappTemplate.current.setFieldValue('categoria', categoria);
                                    setNumBotones(0);
                                    setNumAcciones(0);
                                    onChangeTexo('');
                                  }
                                  setPlantillas(plantillasAux);
                                }}
                              >
                                {t('Previous language')}
                              </Button>
                            </Col>
                          )}
                          {idiomasSelected.findIndex(i => i.value === idiomaEditando.value) + 1 <
                          idiomasSelected.length &&
                            idiomasSelected.length > 1 && (
                            <Col style={{ textAlign: 'end' }} size={{ sm: 3 }}>
                              <Button
                                type="button"
                                color="link"
                                className="w-md"
                                onClick={() => {
                                  if (!validationWhatsappTemplate.current.isValid) {
                                    Object.entries(validationWhatsappTemplate.current.errors)
                                      .forEach(key => {
                                        validationWhatsappTemplate
                                          .current.setFieldTouched(key[0], true);
                                      });
                                    return;
                                  }
                                  const index =
                                    idiomasSelected.findIndex(
                                      i => i.value === idiomaEditando.value
                                    );
                                  const idiomaPrevio = idiomasSelected[index];
                                  const idioma = idiomasSelected[index + 1];
                                  setIdiomaEditando(idioma);
                                  const plantillasAux = structuredClone(plantillas);
                                  const {
                                    nombre,
                                    categoria
                                  } = validationWhatsappTemplate.current.values;
                                  plantillasAux[idiomaPrevio.value] =
                                    validationWhatsappTemplate.current.values;
                                  if (Object.keys(plantillasAux[idioma.value]).length > 0) {
                                    validationWhatsappTemplate.current.setValues(
                                      plantillasAux[idioma.value]
                                    );
                                    onChangeTexo(plantillasAux[idioma.value].texto);
                                    setNumBotones(
                                      plantillasAux[idioma.value].respuestaRapida.length
                                    );
                                    setNumAcciones(
                                      plantillasAux[idioma.value].llamadaAccion.length
                                    );
                                  } else {
                                    validationWhatsappTemplate.current.resetForm();
                                    validationWhatsappTemplate.current.setFieldValue('nombre', nombre);
                                    validationWhatsappTemplate.current.setFieldValue('categoria', categoria);
                                    setNumBotones(0);
                                    setNumAcciones(0);
                                    onChangeTexo('');
                                  }
                                  setPlantillas(plantillasAux);
                                }}
                              >
                                {t('Next language')}
                              </Button>
                            </Col>
                          )}
                        </div>
                        <div className="row justify-content-end">
                          <Col sm={8}>
                            <div>
                              <Button
                                type="submit"
                                color="success"
                                disabled={!plantillasCompletas || !botonEnviarActivo}
                                className="w-md"
                                onClick={() => {
                                  console.log(validationWhatsappTemplate.current.errors);
                                }}
                              >
                                {t('Send')}
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  setModalStandard(true);
                                }}
                                color="link"
                                className="w-md"
                              >
                                {t('Cancel')}
                              </Button>
                            </div>
                          </Col>
                          <Modal
                            isOpen={modalStandard}
                            toggle={() => {
                              togStandard();
                            }}
                          >
                            <div className="modal-header">
                              <h5 className="modal-title mt-0" id="myModalLabel">
                                {t('Are you sure?')}
                              </h5>
                              <button
                                type="button"
                                onClick={() => {
                                  setModalStandard(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <p>{t('If you click continue, unsaved changes will be lost.')}</p>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                onClick={() => {
                                  togStandard();
                                }}
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                {t('Close')}
                              </button>
                              <button
                                onClick={() => {
                                  history.goBack();
                                }}
                                type="button"
                                className="btn btn-primary"
                              >
                                {t('Continue')}
                              </button>
                            </div>
                          </Modal>
                        </div>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <Col lg={{ size: 3 }} md={{ size: 2 }}>
              {tab === 2 && (
                <Card>
                  <CardBody>
                    <div className="conversation">
                      <div className="conversation-container">
                        <div className="message received">
                          {map(
                            template.components,
                            (component, key2) => (component?.type === 'HEADER' && component.text ? (
                              <div key={key2}>
                                <span
                                  className="header"
                                  style={{ fontWeight: 'bold' }}
                                  // eslint-disable-next-line react/no-danger
                                  dangerouslySetInnerHTML={{ __html: component.text }}
                                />
                                <br />
                              </div>
                            ) : null) ||
                              (component?.type === 'BODY' ? (
                                <div key={key2}>
                                  <span
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: component.text }}
                                  />
                                </div>
                              ) : null) ||
                              (component?.type === 'FOOTER' ? (
                                <div key={key2}>
                                  <br />
                                  <span
                                    className="footer2"
                                    style={{ color: 'rgb(104, 121, 146)' }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: component.text }}
                                  />
                                </div>
                              ) : null) ||
                              (component?.type === 'BUTTONS' ? (
                                <div key={key2}>
                                  <br />
                                  <div className="contenedor-botones">
                                    {map(component.buttons, (button, key3) => (
                                      <div className="boton" key={key3}>
                                        {button.text}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : null)
                          )}
                          <span className="metadata">
                            <span className="time">{moment().format('HH:mm')}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

NuevaPlantilla.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(NuevaPlantilla);
