import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal
} from 'reactstrap';

// import Charts
import { withTranslation } from 'react-i18next';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import DateRangePicker from '../../components/Common/DateRangePicker';

import axiosApi from '../../helpers/apiResquests';

// Pages Components
import Pie from '../Charts/Pie';
import SplineArea from '../Charts/SplineArea';
import MiniWidget from '../MiniWidget';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';

const Dashboard = props => {
  const { t } = props;

  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  const [startDate, setStartDate] = useState(currentDate.toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [reviewRequests, setReviewRequests] = useState([]);
  const [tripadvisorReviews, setTripadvisorReviews] = useState(0);
  const [googleReviews, setGoogleReviews] = useState(0);
  const [holidaycheckReviews, setHolidaycheckReviews] = useState(0);
  const [tripadvisorGoogleReviews, setTripadvisorGoogleReviews] = useState(0);
  const [tripadvisorReviewsByDate, setTripadvisorReviewsByDate] = useState([]);
  const [googleReviewsByDate, setGoogleReviewsByDate] = useState([]);
  const [holidaycheckReviewsByDate, setHolidaycheckReviewsByDate] = useState([]);
  const [tripadvisorGoogleReviewsByDate, setTripadvisorGoogleReviewsByDate] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [clicksCount, setClicksCount] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [sentByEmailPercentage, setSentByEmailPercentage] = useState(0);
  const [sentByWhatsappPercentage, setSentByWhatsappPercentage] = useState(0);
  // const [sentPercentage, setSentPercentage] = useState('');
  // const [clicksPercentage, setClicksPercentage] = useState('');
  // const [customersPercentage, setCustomersPercentage] = useState('');
  const [resends, setResends] = useState(0);
  const [resendsPercentage, setResendsPercentage] = useState('');
  const [reports, setReports] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalStandard, setModalStandard] = useState(false);
  const [plantilla, setPlantilla] = useState('');

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function togStandard() {
    setModalStandard(!modalStandard);
    removeBodyCss();
  }

  function togLoadingModal() {
    setModalLoading(!modalLoading);
    removeBodyCss();
  }

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const loadLastRequests = async () => {
    try {
      const response = await axiosApi.get('/api/ultimasInvitaciones');
      const { reviewsEnviadas, error } = response.data;
      if (error) {
        showToast(t('Error getting data'), t('The last reviews can\'t be fetched'), 'error');
        return;
      }
      setReviewRequests(reviewsEnviadas);
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const getPlantilla = async (modo, locale) => {
    setPlantilla(`${process.env.REACT_APP_HOST_PANEL}/api/plantillaCorreoDiferido/${modo}/${locale}`);
    setModalStandard(true);
  };

  const loadData = async () => {
    try {
      const response = await axiosApi.get(
        `/api/estadisticasInvitacionesReview?startDate=${Date.parse(startDate) / 1000}&endDate=${Date.parse(endDate) / 1000}`
      );
      const {
        reviewsEnviadas,
        reviewsEnviadasByPlataforma,
        // reviewsEnviadasPeriodoPrevioCount,
        clicks,
        // clicksPrevios,
        tripadvisorValuesReviews,
        googleReviewValuesReviews,
        holidaycheckValuesReviews,
        tripadvisorGoogleValuesReviews,
        arrayDates,
        correosRecordatorios,
        // clientesTotales,
        enviadasEmail,
        enviadasWhatsapp,
        error
      } = response.data;
      if (error) {
        setModalLoading(false);
        showToast(t('Error getting data'), t('The statistics can\'t be fetched'), 'error');
        return;
      }
      // const customersSet = new Set();
      /* const customersFiltered = reviewsEnviadas.filter(review => {
        const isPresentInSet = customersSet.has(review.mail_receptor);
        customersSet.add(review.mail_receptor);
        return !isPresentInSet;
      }); */
      setCustomers(reviewsEnviadas.length);
      setClicksCount(clicks);
      setTripadvisorReviewsByDate(tripadvisorValuesReviews);
      setHolidaycheckReviewsByDate(holidaycheckValuesReviews);
      setGoogleReviewsByDate(googleReviewValuesReviews);
      setTripadvisorGoogleReviewsByDate(tripadvisorGoogleValuesReviews);
      setDateArray(arrayDates);
      setResends(correosRecordatorios);
      setTripadvisorReviews(
        reviewsEnviadasByPlataforma?.tripadvisor ?
          reviewsEnviadasByPlataforma?.tripadvisor : 0
      );
      setGoogleReviews(
        reviewsEnviadasByPlataforma?.['google review'] ?
          reviewsEnviadasByPlataforma?.['google review'] : 0
      );
      setHolidaycheckReviews(
        reviewsEnviadasByPlataforma?.holidaycheck ?
          reviewsEnviadasByPlataforma?.holidaycheck : 0
      );
      setTripadvisorGoogleReviews(
        reviewsEnviadasByPlataforma?.['google + tripadvisor'] ?
          reviewsEnviadasByPlataforma?.['google + tripadvisor'] : 0
      );
      const porcentajeEmail = (enviadasEmail / reviewsEnviadas.length) * 100;
      const porcentajeWhatsapp = (enviadasWhatsapp / reviewsEnviadas.length) * 100;
      if (!Number.isNaN(porcentajeEmail)) {
        setSentByEmailPercentage(porcentajeEmail);
      }
      if (!Number.isNaN(porcentajeWhatsapp)) {
        setSentByWhatsappPercentage(porcentajeWhatsapp);
      }
      // desactivamos periodo previo
      /* if (reviewsEnviadas.length > 0 && reviewsEnviadasPeriodoPrevioCount > 0) {
        setSentPercentage(
          `${parseFloat(
            ((reviewsEnviadas.length - reviewsEnviadasPeriodoPrevioCount) /
            reviewsEnviadasPeriodoPrevioCount) *
              100
          ).toFixed(0)}%`
        );
      } else {
        setSentPercentage('');
      } */
      // desactivamos periodo previo
      /* if (clicksPrevios > 0 && clicks > 0) {
        setClicksPercentage(
          `${parseFloat(((clicks - clicksPrevios) / clicksPrevios) * 100).toFixed(0)}%`
        );
      } else {
        setClicksPercentage('');
      } */
      // desactivamos clientes totales
      /* if (clientesTotales > 0 && customersFiltered.length > 0) {
        setCustomersPercentage(
          `${parseFloat((customersFiltered.length / clientesTotales) * 100).toFixed(0)}%`
        );
      } else {
        setCustomersPercentage('0%');
      } */
      if (correosRecordatorios > 0 && reviewsEnviadas.length > 0) {
        setResendsPercentage(
          `${parseFloat((correosRecordatorios / reviewsEnviadas.length) * 100).toFixed(0)}%`
        );
      } else {
        setResendsPercentage('0%');
      }
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    loadLastRequests();
    loadData();
  }, []);

  useEffect(() => {
    setReports([
      {
        icon: 'bx bx-copy-alt',
        title: t('Sent'),
        value: tripadvisorReviews + googleReviews + holidaycheckReviews + tripadvisorGoogleReviews,
        badgeValue: '_',
        color: '',
        desc: ''
        /* badgeValue: (parseFloat(sentPercentage, 10) > 0 ? '+' : '') + sentPercentage,
        color: sentPercentage.startsWith('-') ? 'danger' : 'success',
        desc: t('previous period') */
      },
      {
        icon: 'bx bx-archive-in',
        title: (
          <div>
            <span id="resendTooltip">{t('Resend')}</span>
            <UncontrolledTooltip placement="bottom" target="resendTooltip">
              {t('Resends are emails that are sent days after receiving the review request if you have not opened the email or clicked on the link')}
            </UncontrolledTooltip>
          </div>
        ),
        value: resends,
        badgeValue: resendsPercentage,
        color: 'info',
        desc: t('of the requests sent')
      },
      {
        icon: 'bx bx-link',
        title: t('Clicks'),
        value: clicksCount,
        badgeValue: '_',
        color: '',
        desc: ''
        /* badgeValue: (parseFloat(clicksPercentage, 10) > 0 ? '+' : '') + clicksPercentage,
        color: clicksPercentage.startsWith('-') ? 'danger' : 'success',
        desc: t('previous period') */
      },
      {
        icon: 'bx bxs-user',
        /* title: (
          <div>
            <span id="customersTooltip">{t('Customers reached')}</span>
            <UncontrolledTooltip placement="bottom" target="customersTooltip">
              {t('Reached customers are customers who meet the
               conditions set up in Review 360 to receive review requests')}
            </UncontrolledTooltip>
          </div>
        ), */
        title: (
          <div>
            <span>{t('Channel')}</span>
          </div>
        ),
        value: tripadvisorReviews + googleReviews + holidaycheckReviews + tripadvisorGoogleReviews,
        badgeValue: `${sentByEmailPercentage}% Email ${sentByWhatsappPercentage}% Whatsapp`,
        color: 'info',
        desc: ''
        /* badgeValue: customersPercentage,
        color: 'info',
        desc: t('of total customers') */
      }
    ]);
  }, [
    tripadvisorReviews,
    googleReviews,
    holidaycheckReviews,
    clicksCount,
    customers,
    // customersPercentage,
    resends,
    resendsPercentage,
    // sentPercentage,
    // clicksPercentage,
    sentByEmailPercentage,
    sentByWhatsappPercentage
  ]);

  const columns = [
    {
      dataField: 'fechaEstadoEnvio',
      text: t('Date'),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (value) => {
        return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
      }
    },
    {
      dataField: 'canal',
      text: t('Channel'),
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: (value, row) => {
        if (value.toLowerCase() === 'email') {
          return (
            <div>
              <i className="fas fa-envelope h4" id={`email-icon${row.id}`} />
              <UncontrolledTooltip placement="top" target={`email-icon${row.id}`}>
                Email
              </UncontrolledTooltip>
            </div>
          );
        }
        if (value.toLowerCase() === 'sms') {
          return (
            <div>
              <i className="fas fa-phone fa-1x" id={`sms-icon${row.id}`} />
              <UncontrolledTooltip placement="top" target={`sms-icon${row.id}`}>
                SMS
              </UncontrolledTooltip>
            </div>
          );
        }
        if (value.toLowerCase() === 'whatsapp') {
          return (
            <div>
              <i className="fab fa-whatsapp h4" id={`whatsapp-icon${row.id}`} />
              <UncontrolledTooltip placement="top" target={`whatsapp-icon${row.id}`}>
                WhatsApp
              </UncontrolledTooltip>
            </div>
          );
        }
        return <i className="fas fa-envelope h4" />;
      }
    },
    {
      dataField: 'plantilla_correo',
      text: t('Template'),
      align: 'center',
      headerAlign: 'center',
      sort: true,
      formatter: (value, row) => {
        if (value.toLowerCase().includes('google + tripadvisor')) {
          return (
            <>
              <button
                type="button"
                className="btn btn-link boton-filtrar-fecha"
                onClick={() => {
                  getPlantilla(row.modo, row.locale);
                }}
              >
                {`Google Review + TripAdvisor (${row.locale.toString().toUpperCase()})`}
              </button>
            </>
          );
        }
        if (value.toLowerCase().includes('tripadvisor')) {
          return (
            <>
              <button
                type="button"
                className="btn btn-link boton-filtrar-fecha"
                onClick={() => {
                  getPlantilla(row.modo, row.locale);
                }}
              >
                {`TripAdvisor (${row.locale.toString().toUpperCase()})`}
              </button>
            </>
          );
        }
        if (value.toLowerCase().includes('google')) {
          return (
            <>
              <button
                type="button"
                className="btn btn-link boton-filtrar-fecha"
                onClick={() => {
                  getPlantilla(row.modo, row.locale);
                }}
              >
                {`Google Review (${row.locale.toString().toUpperCase()})`}
              </button>
            </>
          );
        }
        if (value.toLowerCase().includes('holidaycheck')) {
          return (
            <>
              <button
                type="button"
                className="btn btn-link boton-filtrar-fecha"
                onClick={() => {
                  getPlantilla(row.modo, row.locale);
                }}
              >
                {`HolidayCheck (${row.locale.toString().toUpperCase()})`}
              </button>
            </>
          );
        }
        return '';
      }
    },
    {
      dataField: 'abierto',
      text: t('Open'),
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'click',
      headerAlign: 'center',
      align: 'center',
      text: t('Clicks'),
      sort: true
    },
    {
      dataField: 'devuelto',
      text: t('Bounce'),
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'nombre_receptor',
      align: 'left',
      headerAlign: 'center',
      text: t('Name'),
      sort: true,
      formatter: (value, row) => {
        return <a href={`${process.env.REACT_APP_HOST_PANEL}/listado/ficha_visitante/${row.ficha_visitante}`} target="_blank" rel="noreferrer">{value}</a>;
      }
    },
    {
      dataField: 'mail_receptor',
      align: 'left',
      headerAlign: 'center',
      text: t('Email'),
      sort: true
    }
  ];

  const defaultSorted = [
    {
      dataField: 'fechaEstadoEnvio',
      order: 'desc'
    }
  ];

  const setDates = (event) => {
    setStartDate(event[0]?.toISOString());
    setEndDate(event[1]?.toISOString());
  };

  const filtrar = () => {
    loadData();
  };

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: reviewRequests.length,
    sizePerPageRenderer,
    custom: true,
    showTotal: true
  };

  document.title = `${t('Review requests')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t('Review requests')}
            breadcrumbItems={[{ title: t('Reputation') }, { title: t('Review requests') }]}
          />
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            setDates={setDates}
            filtrar={filtrar}
          />
          <Row>
            <Col xl="12">
              <Col xl="12">
                <Row>
                  <MiniWidget reports={reports} />
                </Row>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>{t('Requests sent')}</CardTitle>
                  <div id="doughnut-chart" className="e-chart">
                    <SplineArea
                      dateArray={dateArray}
                      data={[
                        { name: 'Tripadvisor', values: tripadvisorReviewsByDate, sum: tripadvisorReviews, color: '#00af87' },
                        { name: 'Google', values: googleReviewsByDate, sum: googleReviews, color: '#f4c20d' },
                        { name: 'HolidayCheck', values: holidaycheckReviewsByDate, sum: holidaycheckReviews, color: '#556ee6' },
                        { name: 'Google + Tripadvisor', values: tripadvisorGoogleReviewsByDate, sum: tripadvisorGoogleReviews, color: '#db3236' }
                      ]}
                      toolbar
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>{t('Reputation platforms')}</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <Pie
                      title={t('Review requests')}
                      data={[
                        { name: 'Tripadvisor', value: tripadvisorReviews, color: '#00af87' },
                        { name: 'Google Review', value: googleReviews, color: '#f4c20d' },
                        { name: 'HolidayCheck', value: holidaycheckReviews, color: '#556ee6' },
                        { name: 'Tripadvisor y Google', value: tripadvisorGoogleReviews, color: '#db3236' }
                      ]}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{t('Last requests')}</CardTitle>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={reviewRequests}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider keyField="id" columns={columns} data={reviewRequests} search>
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          size="lg"
          style={{ maxWidth: '700px', width: '100%' }}
          isOpen={modalStandard}
          toggle={() => {
            togStandard();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t('Template')}
            </h5>
            <button
              type="button"
              onClick={() => {
                setModalStandard(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <iframe style={{ width: '650px', height: '950px' }} title="Plantilla" src={plantilla} />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                togStandard();
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              {t('Close')}
            </button>
          </div>
        </Modal>
        <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }} isOpen={modalLoading}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t('Loading data')}
            </h5>
            <button
              type="button"
              onClick={() => {
                togLoadingModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-3">
              {t('We are loading the data. They will appear shortly.')}
            </div>
          </div>
          <div className="modal-footer" />
        </Modal>
      </div>
    </>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Dashboard);
