import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import 'assets/scss/estilos.scss';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Modal,
  Form,
  Input,
  FormFeedback
} from 'reactstrap';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Import Flatepicker
// import 'flatpickr/dist/themes/material_blue.css';
// import Flatpickr from 'react-flatpickr';

import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const Miembro = props => {
  const { idMiembro } = useParams();
  const [cambiosPendientes, setCambiosPendientes] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [departamentos, SetDepartamentos] = useState([]);
  const [modalStandard, setModalStandard] = useState(false);
  const [rol, setRol] = useState();
  const [depto, setDepartamento] = useState();
  const [nombre, setNombre] = useState('');
  const [apellidoUsuario, setApellido] = useState('');
  const [correo, setCorreo] = useState('');
  const [activo, setActivo] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [tlf, setTelefono] = useState('');

  const { t } = props;
  const history = useHistory();

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function togStandard() {
    setModalStandard(!modalStandard);
    removeBodyCss();
  }

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }
  // Form validation
  const validationMemberForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: correo,
      role: roleList[0] ? roleList[0].id_roles : 1,
      name: nombre,
      apellido: apellidoUsuario,
      telefono: tlf,
      departamento: departamentos[0] ? departamentos[0].id_deptos_equipo : '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(t('Must be a valid Email')).max(255).required(t('Email is required')),
      name: Yup.string().required(t('This value is required')),
      apellido: Yup.string(),
      password: Yup.string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\d\w\W].*$/,
          t('Enter a valid password!')
        )
        .required(t('This value is required')),
      confirmPassword: Yup.string().when('password', {
        is: val => !!(val && val.length > 0),
        then: Yup.string().oneOf([Yup.ref('password')], t('password_error'))
      }),
      telefono: Yup.string().matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Enter a valid phone number!'
      )
    }),
    onSubmit: async () => {
      const {
        departamento,
        role,
        name,
        email,
        password,
        apellido,
        telefono,
      } = validationMemberForm.values;
      const datosMiembro = {
        email,
        nombre: name,
        role,
        departamento,
        apellido,
        password,
        telefono,
      };
      try {
        const response = await axiosApi.post('/api/administracion/miembros/crear', datosMiembro);
        const { data } = response;
        if (data.error) {
          throw Error('Error');
        }
        const title = t('User saved');
        const message = t('The user has been created');
        showToast(title, message, 'success');
        setRedirect(true);
      } catch (error) {
        const title = t('Error saving user');
        const message = t('The user has not been created');
        showToast(title, message, 'error');
      }
    }
  });

  const validationEditMemberForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      activa: activo,
      email: correo,
      name: nombre,
      apellido: apellidoUsuario,
      role: rol,
      departamento: depto,
      telefono: tlf
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(t('Must be a valid Email')).max(255).required(t('Email is required')),
      name: Yup.string().required(t('This value is required')),
      apellido: Yup.string().required(t('This value is required')),
    }),
    onSubmit: async () => {
      const {
        activa,
        departamento,
        role,
        name,
        apellido,
        email,
      } = validationEditMemberForm.values;
      const datosMiembro = {
        user_id: idMiembro,
        activo: activa ? '1' : '0',
        role,
        departamento,
        email,
        nombre: name,
        apellido
      };
      try {
        console.log(datosMiembro);
        const response = await axiosApi.post('/api/administracion/miembros/editar', datosMiembro);
        const { data } = response;
        if (data.error) {
          throw Error('Error');
        }
        const title = t('User saved');
        const message = t('The user has been edited');
        showToast(title, message, 'success');
        setRedirect(true);
      } catch (error) {
        const title = t('Error saving user');
        const message = t('The user has not been edited');
        showToast(title, message, 'error');
        setRedirect(false);
      }
    }
  });

  function cancelar() {
    if (cambiosPendientes) {
      togStandard();
    } else {
      history.goBack();
    }
  }

  const cargaDatos = async () => {
    try {
      const response = await axiosApi.get('/api/administracion/roles');
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setRoleList(data);
    } catch (error) {
      // error
    }
    try {
      const response = await axiosApi.get('/api/administracion/departamentos');
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      SetDepartamentos(data);
    } catch (error) {
      // error
    }
    if (idMiembro !== 'crear') {
      try {
        const response = await axiosApi.get(`/api/administracion/miembro/${idMiembro}`);
        const { data } = response;
        const { error } = data;
        if (error) {
          showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
          return;
        }
        const roleID = data.role_depto[0].id_roles;
        const departamentoID = data.role_depto[0].id_deptos_equipo;
        setNombre(data.user.nombre);
        setApellido(data.user.apellido);
        setCorreo(data.user.email);
        setActivo(data.user.activo === '1');
        setRol(roleID || 0);
        setDepartamento(departamentoID || null);
        setTelefono(data.user.telefono);
      } catch (error) {
        // error
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  document.title = `${t('Report data')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      {redirect && <Redirect to="/administracion/miembros" />}
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={{ size: 8, offset: 2 }}>
              <Breadcrumbs title={t('Management')} breadcrumbItem={t('Team members')} back />
            </Col>
          </Row>
          <Row>
            <Col lg={{ size: 8, offset: 2 }}>
              <Card>
                <CardBody>
                  {idMiembro === 'crear' && <CardTitle className="mb-4">{t('New member')}</CardTitle>}
                  {idMiembro !== 'crear' && <CardTitle className="mb-4">{t('Edit member')}</CardTitle>}
                  {
                    !isLoading &&
                    (
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          if (idMiembro === 'crear') { validationMemberForm.handleSubmit(); } else { validationEditMemberForm.handleSubmit(); }
                          return false;
                        }}
                      >
                        <div className="row mb-4" hidden={idMiembro === 'crear'}>
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-4 col-form-label"
                          >
                            {t('Enable')}
                          </Label>
                          <Col sm={1} className="form-check-label">
                            <div className="form-check form-switch form-switch-md">
                              <input
                                checked={activo}
                                type="checkbox"
                                className="form-control form-check-input"
                                id="customSwitchsizemd"
                                onChange={event => {
                                  if (!cambiosPendientes) {
                                    setCambiosPendientes(true);
                                  }
                                  validationEditMemberForm.setFieldValue('activa', event.target.checked);
                                  setActivo(event.target.checked);
                                }}
                              />
                            </div>
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-email-Input"
                            className="col-sm-4 col-form-label"
                          >
                            {t('Email')}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              name="email"
                              className="form-control"
                              id="horizontal-email-Input"
                              onChange={event => {
                                if (!cambiosPendientes) {
                                  setCambiosPendientes(true);
                                }
                                validationMemberForm.setFieldValue(
                                  'email',
                                  event.target.value
                                );
                                validationEditMemberForm.setFieldValue(
                                  'email',
                                  event.target.value
                                );
                                setCorreo(event.target.value);
                              }}
                              onBlur={validationMemberForm.handleBlur}
                              value={validationMemberForm.values.email || ''}
                              invalid={
                                !!(
                                  validationMemberForm.touched.email &&
                                  validationMemberForm.errors.email
                                )
                              }
                            />
                            {validationMemberForm.touched.email &&
                            validationMemberForm.errors.email ? (
                              <FormFeedback type="invalid">
                                {validationMemberForm.errors.email}
                              </FormFeedback>
                              ) : null}
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-name-Input"
                            className="col-sm-4 col-form-label"
                          >
                            {t('Name')}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              name="name"
                              className="form-control"
                              id="horizontal-name-Input"
                              onChange={event => {
                                if (!cambiosPendientes) {
                                  setCambiosPendientes(true);
                                }
                                validationMemberForm.setFieldValue(
                                  'name',
                                  event.target.value
                                );
                                validationEditMemberForm.setFieldValue(
                                  'name',
                                  event.target.value
                                );
                                setNombre(event.target.value);
                              }}
                              onBlur={validationMemberForm.handleBlur}
                              value={validationMemberForm.values.name || ''}
                              invalid={
                                !!(
                                  validationMemberForm.touched.name &&
                                  validationMemberForm.errors.name
                                )
                              }
                            />
                            {validationMemberForm.touched.name &&
                            validationMemberForm.errors.name ? (
                              <FormFeedback type="invalid">
                                {validationMemberForm.errors.name}
                              </FormFeedback>
                              ) : null}
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-lastname-Input"
                            className="col-sm-4 col-form-label"
                          >
                            {t('Last name')}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              name="apellido"
                              className="form-control"
                              id="horizontal-lastname-Input"
                              onChange={event => {
                                if (!cambiosPendientes) {
                                  setCambiosPendientes(true);
                                }
                                validationMemberForm.setFieldValue(
                                  'apellido',
                                  event.target.value
                                );
                                validationEditMemberForm.setFieldValue(
                                  'apellido',
                                  event.target.value
                                );
                                setApellido(event.target.value);
                              }}
                              onBlur={validationMemberForm.handleBlur}
                              value={validationMemberForm.values.apellido || ''}
                              invalid={
                                !!(
                                  validationMemberForm.touched.apellido &&
                                  validationMemberForm.errors.apellido
                                )
                              }
                            />
                            {validationMemberForm.touched.apellido &&
                            validationMemberForm.errors.apellido ? (
                              <FormFeedback type="invalid">
                                {validationMemberForm.errors.apellido}
                              </FormFeedback>
                              ) : null}
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-lastname-Input"
                            className="col-sm-4 col-form-label"
                          >
                            {t('Phone')}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="text"
                              name="telefono"
                              className="form-control"
                              id="horizontal-lastname-Input"
                              onChange={event => {
                                if (!cambiosPendientes) {
                                  setCambiosPendientes(true);
                                }
                                validationMemberForm.setFieldValue(
                                  'telefono',
                                  event.target.value
                                );
                                validationEditMemberForm.setFieldValue(
                                  'telefono',
                                  event.target.value
                                );
                                setTelefono(event.target.value);
                              }}
                              onBlur={validationMemberForm.handleBlur}
                              value={validationMemberForm.values.telefono || ''}
                              invalid={
                                !!(
                                  validationMemberForm.touched.telefono &&
                                  validationMemberForm.errors.telefono
                                )
                              }
                            />
                            {validationMemberForm.touched.telefono &&
                            validationMemberForm.errors.telefono ? (
                              <FormFeedback type="invalid">
                                {validationMemberForm.errors.telefono}
                              </FormFeedback>
                              ) : null}
                          </Col>
                        </div>
                        <div className="row mb-4" hidden={idMiembro !== 'crear'}>
                          <Label
                            htmlFor="horizontal-password-Input"
                            className="col-sm-4 col-form-label"
                          >
                            {t('New password')}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="password"
                              name="password"
                              className="form-control"
                              id="horizontal-password-Input"
                              onChange={event => {
                                if (!cambiosPendientes) {
                                  setCambiosPendientes(true);
                                }
                                validationMemberForm.setFieldValue(
                                  'password',
                                  event.target.value
                                );
                              }}
                              onBlur={validationMemberForm.handleBlur}
                              value={validationMemberForm.values.password || ''}
                              invalid={
                                !!(
                                  validationMemberForm.touched.password &&
                                  validationMemberForm.errors.password
                                )
                              }
                            />
                            {validationMemberForm.touched.password &&
                            validationMemberForm.errors.password ? (
                              <FormFeedback type="invalid">
                                {validationMemberForm.errors.password}
                              </FormFeedback>
                              ) : null}
                          </Col>
                        </div>
                        <div className="row mb-4" hidden={idMiembro !== 'crear'}>
                          <Label
                            htmlFor="horizontal-confirm-password-Input"
                            className="col-sm-4 col-form-label"
                          >
                            {t('Confirm password')}
                          </Label>
                          <Col sm={8}>
                            <Input
                              type="password"
                              name="confirmPassword"
                              className="form-control"
                              id="horizontal-confirm-password-Input"
                              onChange={event => {
                                if (!cambiosPendientes) {
                                  setCambiosPendientes(true);
                                }
                                validationMemberForm.setFieldValue(
                                  'confirmPassword',
                                  event.target.value
                                );
                              }}
                              onBlur={validationMemberForm.handleBlur}
                              value={validationMemberForm.values.confirmPassword || ''}
                              invalid={
                                !!(
                                  validationMemberForm.touched.confirmPassword &&
                                  validationMemberForm.errors.confirmPassword
                                )
                              }
                            />
                            {validationMemberForm.touched.confirmPassword &&
                            validationMemberForm.errors.confirmPassword ? (
                              <FormFeedback type="invalid">
                                {validationMemberForm.errors.confirmPassword}
                              </FormFeedback>
                              ) : null}
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label htmlFor="horizontal-department-Input" className="col-sm-4 col-form-label">
                            {t('Department')}
                          </Label>
                          <Col sm={8}>
                            <select
                              value={depto || 1}
                              className="form-select"
                              id="horizontal-department-Input"
                              onChange={event => {
                                if (!cambiosPendientes) {
                                  setCambiosPendientes(true);
                                }
                                validationMemberForm.setFieldValue(
                                  'departamento',
                                  event.target.value
                                );
                                validationEditMemberForm.setFieldValue(
                                  'departamento',
                                  event.target.key
                                );
                                setDepartamento(event.target.value);
                              }}
                            >
                              {
                                departamentos.map(dpt => (
                                  <option
                                    value={dpt.id_deptos_equipo}
                                    key={dpt.id_deptos_equipo}
                                  >
                                    {t(dpt.descripcion)}
                                  </option>
                                ))
                              }
                            </select>
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label htmlFor="horizontal-Role-Input" className="col-sm-4 col-form-label">
                            {t('Role')}
                          </Label>
                          <Col sm={8}>
                            <select
                              defaultValue={rol}
                              className="form-select"
                              id="horizontal-role-Input"
                              onChange={event => {
                                if (!cambiosPendientes) {
                                  setCambiosPendientes(true);
                                }
                                validationMemberForm.setFieldValue(
                                  'role',
                                  event.target.value
                                );
                                validationEditMemberForm.setFieldValue(
                                  'role',
                                  event.target.value
                                );
                                setRol(event.target.value);
                              }}
                            >
                              {
                                roleList.map(roles => (
                                  <option
                                    value={roles.id_roles}
                                    key={roles.id_roles}
                                  >
                                    {t(roles.descripcion)}
                                  </option>
                                ))
                              }
                            </select>
                          </Col>
                        </div>
                        <div className="row justify-content-end">
                          <Col sm={8}>
                            <div>
                              <Button type="submit" color="success" className="w-md">
                                {t('Save')}
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  cancelar();
                                }}
                                color="link"
                                className="w-md"
                              >
                                {t('Cancel')}
                              </Button>
                            </div>
                          </Col>
                          <Modal
                            isOpen={modalStandard}
                            toggle={() => {
                              togStandard();
                            }}
                          >
                            <div className="modal-header">
                              <h5 className="modal-title mt-0" id="myModalLabel">
                                {t('Are you sure?')}
                              </h5>
                              <button
                                type="button"
                                onClick={() => {
                                  setModalStandard(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <p>
                                {t('If you click continue, unsaved changes will be lost.')}
                              </p>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                onClick={() => {
                                  togStandard();
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                              >
                                {t('Close')}
                              </button>
                              <button
                                onClick={() => {
                                  history.goBack();
                                }}
                                type="button"
                                className="btn btn-primary "
                              >
                                {t('Continue')}
                              </button>
                            </div>
                          </Modal>
                        </div>
                      </Form>
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Miembro.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Miembro);
