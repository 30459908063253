import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, BreadcrumbItem } from 'reactstrap';

const Breadcrumb = props => {
  const { breadcrumbItem, title, back, url } = props;
  const location = useLocation();
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          {/* <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4> */}
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {back && (
                <BreadcrumbItem>
                  <Link to={location.pathname.substring(0, location.pathname.lastIndexOf('/'))}>
                    <i className="dripicons-chevron-left" />
                  </Link>
                </BreadcrumbItem>
              )}
              <BreadcrumbItem>
                <Link to={url || '#'}>{title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to={url || '#'}>{breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  back: PropTypes.bool
};

export default Breadcrumb;
