import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';
import moment from 'moment';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

const Tareas = props => {
  const { t } = props;
  const [tareasData, setTareasData] = useState([]);
  const [idLocal, setIdLocal] = useState(null);
  const refIdLocal = useRef();
  const [modalNuevaTarea, setModalNuevaTarea] = useState(false);
  const [checkMarcarCompletado, setCheckMarcarCompletado] = useState(true);
  const [contactoSelected, setContactoSelected] = useState(null);
  const [filter, setFilter] = useState(null);
  const [agentesFilter, setAgentesFilter] = useState(null);
  const [tareasFiltered, setTareasFiltered] = useState([]);
  const [agentes, setAgentes] = useState([]);

  const contactos = [
    { id: 1, nombre: 'Antonio Gutierrez', email: 'agutierrez@gmail.com', telefono: '+34666123456' },
    { id: 2, nombre: 'Antonio Sanchez', email: 'asanchez@gmail.com', telefono: '+34666341256' },
    { id: 3, nombre: 'Vanessa Cruz', email: 'vanessa@gmail.com', telefono: '+34611223445' }
  ];

  const filtroBuscar = [
    { id: 1, text: t('To do') },
    { id: 2, text: t('Expired') },
    { id: 3, text: t('Today') },
    { id: 4, text: t('Tomorrow') },
    { id: 5, text: t('This week') },
    { id: 6, text: t('Next week') },
  ];

  const filtrarTareas = tarea => {
    switch (filter) {
      case 0:
        // unfiltered
        return tarea;
      case 1:
        // To do tasks
        if (moment(tarea.fecha_vencimiento).isAfter() && tarea.completada === 0) {
          return tarea;
        }
        break;
      case 2:
        // Expired tasks
        if (moment(tarea.fecha_vencimiento).isBefore() && tarea.completada === 0) {
          return tarea;
        }
        break;
      case 3:
        // Today tasks
        if (moment(tarea.fecha_vencimiento).isSame(new Date(), 'day')) {
          return tarea;
        }
        break;
      case 4:
        // Tomorrow tasks
        if (moment(tarea.fecha_vencimiento).subtract(1, 'day').isSame(new Date(), 'day')) {
          return tarea;
        }
        break;
      case 5:
        // This week tasks
        if (moment(tarea.fecha_vencimiento).isSame(new Date(), 'week')) {
          return tarea;
        }
        break;
      case 6:
        // Next week tasks
        if (moment(tarea.fecha_vencimiento).week() === moment(new Date()).week() + 1) {
          return tarea;
        }
        break;
      default: return tarea;
    }
    return null;
  };

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargaTareas = async () => {
    try {
      const response = await axiosApi.get(`/mc/companies/tareas?id=${refIdLocal.current}`);
      const { data } = response;
      const { tareas, error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setTareasData(tareas);
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const cargaDatos = async () => {
    try {
      const whoami = await axiosApi.get('/mc/whoami');
      const { data } = whoami;
      setIdLocal(data.idLocal.toString());
      refIdLocal.current = data.idLocal.toString();
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const cargaAgentes = async () => {
    try {
      const response = await axiosApi.get(`/mc/companies/config?id=${refIdLocal.current}`);
      const { data } = response;
      const {
        error,
        agentes: agentesAux
      } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      if (agentesAux) {
        setAgentes(agentesAux);
      }
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  useEffect(() => {
    if (idLocal) {
      cargaAgentes();
    }
  }, [idLocal]);

  useEffect(() => {
    if (idLocal) {
      cargaTareas();
    }
  }, [agentes]);

  useEffect(() => {
    if (tareasData.length > 0) {
      const filteredData = tareasData?.filter(tarea => filtrarTareas(tarea));
      if (agentesFilter && agentesFilter !== '0') {
        setTareasFiltered(filteredData?.filter(tarea => tarea.asignada === agentesFilter));
      } else {
        setTareasFiltered(filteredData);
      }
    }
  }, [filter, tareasData, agentesFilter]);

  const rowStyle = (row) => {
    if (moment(row.fecha_vencimiento).isBefore() && row.completada === 0) {
      return { color: 'red' };
    }
    return {};
  };

  const columns = [
    {
      dataField: 'completada',
      text: t('Complete'),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (value) => {
        if (value === 1) {
          return <i style={{ color: '#007500' }} className="bx bxs-check-circle font-size-18" />;
        }
        return <i style={{ color: 'black' }} className="bx bx-circle font-size-18" />;
      }
    },
    {
      dataField: 'nombre',
      text: t('Task'),
      headerStyle: { maxWidth: '10vw' },
      style: { maxWidth: '10vw',
        height: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      sort: true
    },
    {
      dataField: 'contacto',
      text: t('Contact'),
      headerStyle: { maxWidth: '10vw' },
      style: { maxWidth: '10vw',
        height: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      sort: true
    },
    {
      dataField: 'email_contacto',
      text: t('Email'),
      sort: true
    },
    {
      dataField: 'telefono_contacto',
      text: t('Phone'),
      sort: true
    },
    {
      dataField: 'fecha_vencimiento',
      text: t('Expiry date'),
      sort: true,
      formatter: (value) => {
        return moment(value).format('DD/MM/YYYY');
      }
    },
    {
      dataField: 'asignada',
      text: t('Assigned to'),
      headerStyle: { maxWidth: '10vw' },
      style: { maxWidth: '10vw',
        height: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      sort: true,
      formatter: (value) => {
        return agentes.find(a => a.id === value)?.nombre;
      }
    },
    {
      dataField: 'id_tareas_mc',
      text: t('Options'),
      sort: true,
      formatter: () => {
        return (
          <UncontrolledDropdown
            direction="left"
          >
            <DropdownToggle tag="a" caret>
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu
              className="lista-opciones"
            >
              <DropdownItem disabled>{t('Edit')}</DropdownItem>
              <DropdownItem disabled>{t('Remove')}</DropdownItem>
              <DropdownItem disabled>{t('Mark as completed')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const selectRow = {
    mode: 'checkbox'
  };

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tareasFiltered.length,
    sizePerPageRenderer,
    custom: true,
    showTotal: true
  };

  document.title = `${t('Tasks')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Tasks')} breadcrumbItems={[{ title: t('Messaging Center') }, { title: t('Tasks') }]} />

          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }}>
                  <Button
                    color="primary"
                    className="mt-1"
                    onClick={() => { setModalNuevaTarea(true); }}
                  >
                    {t('New task')}
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-3">
                    <Row>
                      <Col className="col-2">
                        <select
                          className="form-select"
                          id="horizontal-Assigned-Input"
                          defaultValue={0}
                          onChange={event => {
                            setFilter(parseInt(event.target.value, 10));
                          }}
                        >
                          <option value={0}>
                            {t('Select an option')}
                          </option>
                          {filtroBuscar.map(filtro => {
                            return (
                              <option key={`filtro${filtro.id}`} value={filtro.id}>
                                {filtro.text}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                      <Col className="col-2">
                        <select
                          className="form-select"
                          id="horizontal-Assigned-Input"
                          defaultValue
                          onChange={event => {
                            setAgentesFilter(event.target.value);
                          }}
                        >
                          <option value={0}>
                            {t('Select an option')}
                          </option>
                          {agentes.map(agente => {
                            return (
                              <option key={`filtro_agente${agente.id}`} value={agente.id}>
                                {agente.nombre}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </Row>
                  </CardSubtitle>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id_tareas_mc"
                    columns={columns}
                    data={tareasFiltered}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id_tareas_mc"
                        columns={columns}
                        data={tareasFiltered}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id_tareas_mc"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    rowStyle={rowStyle}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
            {/* <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Whatsapp automation info title')}</span>
                  <br />
                  {t('Whatsapp automation info text')}
                  <br />
                  {/* <a target="_blank" href="http://helpcenter.wifreezone.com/es/articles/5849058-campsWhatsapp-de-feedback" rel="noreferrer">{t('Read more')}</a> *//* }
                </CardText>
              </Card>
            </Col> */}
          </Row>
          <Modal
            isOpen={modalNuevaTarea}
            toggle={() => {
              setModalNuevaTarea(!modalNuevaTarea);
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{t('New task')}</h5>
              <button
                type="button"
                onClick={() => {
                  setModalNuevaTarea(false);
                }}
                className="btn-close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="new-task-name" className="col-form-label">
                    {t('Task')}
                    :
                  </label>
                  <input type="text" className="form-control" id="new-task-name" />
                  <div className="mb-3">
                    <label htmlFor="description-text" className="col-form-label">
                      {t('Description')}
                      :
                    </label>
                    <textarea className="form-control" id="description-text" />
                  </div>
                  <label htmlFor="new-task-contact" className="col-form-label">
                    {t('Contact')}
                    :
                  </label>
                  <select
                    className="form-select"
                    id="horizontal-language-Input"
                    defaultValue
                    onChange={event => {
                      setContactoSelected(contactos.find(c => {
                        return c.id === parseInt(event.target.value, 10);
                      }));
                    }}
                    // value={newContactLanguage}
                  >
                    <option value disabled>
                      {t('Select an option')}
                    </option>
                    {contactos.map(contacto => {
                      return (
                        <option key={`contacto_${contacto.id}`} value={contacto.id}>
                          {contacto.nombre}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="new-task-email" className="col-form-label">
                    {t('Email')}
                    :
                  </label>
                  <input readOnly value={contactoSelected?.email} type="text" className="form-control" id="new-task-email" />
                  <label htmlFor="new-task-phone" className="col-form-label">
                    {t('Phone')}
                    :
                  </label>
                  <input readOnly value={contactoSelected?.telefono} type="text" className="form-control" id="new-task-phone" />
                  <label htmlFor="new-task-expiry" className="col-form-label">
                    {t('Expiry date')}
                    :
                  </label>
                  <input type="text" className="form-control" id="new-task-expiry" />
                  <label htmlFor="new-task-phone" className="col-form-label">
                    {t('Assigned to')}
                    :
                  </label>
                  <select
                    className="form-select"
                    id="horizontal-Assigned-Input"
                    defaultValue
                    onChange={event => {
                      // setNewContactLanguage(event.target.value);
                      console.log(event.target.value);
                    }}
                    // value={newContactLanguage}
                  >
                    <option value disabled>
                      {t('Select an option')}
                    </option>
                    {agentes.map(agente => {
                      return (
                        <option key={`contacto_${agente.id}`} value={agente.id}>
                          {agente.nombre}
                        </option>
                      );
                    })}
                  </select>
                  <Row>
                    <Col sm={6} className="form-check-label">
                      <label htmlFor="new-task--mark-completed" className="col-form-label">
                        {t('Mark as completed')}
                        :
                      </label>
                    </Col>
                    <Col sm={2} className="form-check-label pt-1">
                      <div className="form-check form-switch form-switch-md">
                        <input
                          checked={checkMarcarCompletado}
                          type="checkbox"
                          className="form-control form-check-input"
                          id="customSwitchsizemd"
                          onChange={event => {
                            setCheckMarcarCompletado(event.target.checked);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setModalNuevaTarea(false);
                }}
              >
                {t('Cancel')}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setModalNuevaTarea(false);
                }}
              >
                {t('Save')}
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

Tareas.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Tareas);
