import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// import SweetAlert
import SweetAlert from 'react-bootstrap-sweetalert';

const Confirmacion = props => {
  const { t, confirmacion, onConfirmacionChange, eliminarAlerta, alertaEliminar } = props;

  const [successDlg, setSuccessDlg] = useState(false);
  const [errorDlg, setErrorDlg] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState('');
  const [dynamicDescription, setDynamicDescription] = useState('');

  return (
    <>
      {successDlg ? (
        <SweetAlert
          success
          title={dynamicTitle}
          onConfirm={() => {
            setSuccessDlg(false);
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}

      {errorDlg ? (
        <SweetAlert
          error
          title={dynamicTitle}
          onConfirm={() => {
            setErrorDlg(false);
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}
      {confirmacion ? (
        <SweetAlert
          title={t('Are you sure?')}
          warning
          showCancel
          confirmBtnText={t('Confirm')}
          cancelBtnText={t('Cancel')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            const result = await eliminarAlerta(alertaEliminar);
            if (result) {
              onConfirmacionChange(false);
              setSuccessDlg(true);
              setDynamicTitle(t('Deleted'));
              setDynamicDescription(t('The report has been deleted'));
            } else {
              onConfirmacionChange(false);
              setErrorDlg(true);
              setDynamicTitle(t('Error'));
              setDynamicDescription(t("The report hasn't been deleted"));
            }
          }}
          onCancel={() => {
            onConfirmacionChange(false);
            setErrorDlg(true);
            setDynamicTitle(t('Cancelled'));
            setDynamicDescription(t("The report hasn't been deleted"));
          }}
        >
          {t("You won't be able to revert this!")}
        </SweetAlert>
      ) : null}
    </>
  );
};

Confirmacion.propTypes = {
  t: PropTypes.any,
  confirmacion: PropTypes.bool,
  alertaEliminar: PropTypes.number,
  onConfirmacionChange: PropTypes.func,
  eliminarAlerta: PropTypes.func
};

export default withTranslation()(Confirmacion);
