import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Button,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

const Miembros = props => {
  const { t } = props;
  const [miembrosData, setMiembrosData] = useState([]);
  const history = useHistory();
  const [deletingRow, setDeletingRow] = useState();
  const [modalStandard, setModalStandard] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState(false);
  const rolesacciones = [
    {
      roles: 'Editor',
      productos: 'Automated Guest Journey,Messaging Center,Smart Wifi & Analytics'
    },
    {
      roles: 'editar feedback + mailmarketing',
      productos: 'Automated Guest Journey,Smart Wifi & Analytics'
    },
    {
      roles: '',
      productos: 'Messaging Center'
    },
    {
      roles: 'Editor Pantallas',
      productos: 'Automated Guest Journey'
    },
    {
      roles: 'Editor Pantallas + descarga',
      productos: 'Automated Guest Journey'
    },
    {
      roles: 'email marketing',
      productos: 'Smart Wifi & Analytics'
    },
    {
      roles: 'Gestor Chat',
      productos: 'Automated Guest Journey,Messaging Center'
    },
    {
      roles: 'Gestor MAC Whitelist',
      productos: 'Smart Wifi & Analytics'
    },
    {
      roles: 'HSM lector encuestas',
      productos: 'Smart Wifi & Analytics'
    },
    {
      roles: 'Lectura',
      productos: 'Smart Wifi & Analytics'
    },
    {
      roles: 'Lectura + anular encuestas',
      productos: 'Smart Wifi & Analytics'
    },
    {
      roles: 'Lectura + descarga',
      productos: 'Smart Wifi & Analytics'
    },
    {
      roles: 'Lectura + MAC + Sesiones Activas',
      productos: 'Checkin Online,Smart Wifi & Analytics'
    },
    {
      roles: 'User API REST Lectura',
      productos: 'Smart Wifi & Analytics'
    },
  ];
  const productos = ['Automated Guest Journey', 'Messaging Center', 'Smart Wifi & Analytics', 'Checkin Online'];

  const [filtroTabla, setFiltroTabla] = useState('');
  const [usuariosVisibles, setUsuariosVisibles] = useState([]);

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargaDatos = async () => {
    try {
      const response = await axiosApi.get('/api/administracion/miembros');
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      data.forEach(dato => {
        dato.estaActivo = dato.activo === '1';
      });
      // const respuestaRolesAcciones = await axiosApi.get('/api/administracion/accionesroles');
      // const dataRoles = respuestaRolesAcciones.data.rolesacc;
      // setRolesacciones(dataRoles);
      setMiembrosData(data);
      setUsuariosVisibles(data);
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const handleCambiarActivo = async (value, row) => {
    try {
      await axiosApi.post(`/api/administracion/miembros/cambiarActivo/${value}/${row.activo}`);
      const miembrosAux = miembrosData.map(miembro => {
        if (miembro.id === value) {
          miembro.activo = miembro.activo === '1' ? '0' : '1';
          miembro.estaActivo = miembro.activo === '1';
        }
        return miembro;
      });
      setMiembrosData([]);
      setMiembrosData(miembrosAux);
    } catch (error) {
      // error
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  useEffect(() => {
    if (filtroTabla === '') {
      setUsuariosVisibles(miembrosData);
    } else {
      const copiaMiembros = [...miembrosData];
      const nuevosVisibles = copiaMiembros.filter(miembro => {
        const role = miembro.rol;
        const rolproducto = rolesacciones.filter((rol) => rol.roles === role)[0];
        console.log(rolproducto);
        if (rolproducto.productos.includes(filtroTabla)) {
          return true;
        }
        return false;
      });
      setUsuariosVisibles(nuevosVisibles);
    }
  }, [filtroTabla]);

  async function eliminar() {
    setModalStandard(false);
    const response = await axiosApi.post(`/api/administracion/miembros/deleteLocalDashboardUsuario/${deletingRow.id}`);
    const { data } = response;
    const { error } = data;
    if (error) {
      setErrorDeleting(true);
    } else {
      const miembrosAux = miembrosData.filter(person => person.id !== deletingRow.id);
      setMiembrosData([]);
      setIsDeleted(true);
      setMiembrosData(miembrosAux);
      setDeletingRow(null);
    }
  }

  function handleDelete(row) {
    setDeletingRow(row);
    setModalStandard(true);
  }

  const columns = [
    {
      dataField: 'activo',
      text: t('Status'),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (value) => {
        if (value === '1') {
          return <Badge className="me-1 rounded-pill bg-success">{t('Active')}</Badge>;
        }
        return <Badge className="me-1 rounded-pill bg-danger">{t('Inactive')}</Badge>;
      }
    },
    {
      dataField: 'nombre',
      text: t('Name'),
      sort: true,
      align: 'center',
      headerAlign: 'center'
    },
    {
      dataField: 'email',
      text: t('Email'),
      headerStyle: { maxWidth: '25vh' },
      style: { maxWidth: '25vh',
        height: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      sort: true
    },
    {
      dataField: 'telefono',
      text: t('Phone'),
      sort: false,
      align: 'center',
      headerAlign: 'center'
    },
    {
      dataField: 'id',
      text: t('Almacenamiento'),
      sort: false,
      align: 'center',
      headerAlign: 'center',
      formatter: () => {
        return '50 GB';
      }
    },
    {
      dataField: 'departamento',
      text: t('Department'),
      sort: false,
      formatter: (value) => {
        return value?.toString();
      }
    },
    {
      dataField: 'rol',
      text: t('Rol'),
      headerStyle: { maxWidth: '25vh' },
      sort: false,
      formatter: (value) => {
        return value?.toString();
      }
    },
    {
      dataField: '',
      text: t('Product'),
      headerStyle: { maxWidth: '25vh' },
      sort: false,
      formatExtraData: filtroTabla,
      formatter: (value, row, _, extraData) => {
        const producto = rolesacciones.find(rolacc => rolacc.roles === row.rol).productos;
        const productosArray = producto.split(',');
        if (extraData) {
          return (
            <ul className="estilos-vacio">
              <li>
                {extraData}
              </li>
            </ul>
          );
        }
        return (
          <ul className="estilos-vacio">
            {productosArray.map((item, index) => (
              <li key={index} style={{ padding: '2px', fontSize: '14px' }}>
                {item}
              </li>
            ))}
          </ul>
        );
      }
    },
    {
      dataField: 'ultima_accion',
      text: t('Latest action'),
      sort: false
    },
    {
      dataField: 'estaActivo',
      align: 'center',
      headerAlign: 'center',
      text: t('Options'),
      sort: false,
      formatter: (value, row) => {
        return (
          <UncontrolledDropdown
            direction="left"
          >
            <DropdownToggle tag="a" caret>
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu
              className="lista-opciones"
            >
              <DropdownItem onClick={() => history.push(`/administracion/miembros/${row.id}`)}>{t('Edit')}</DropdownItem>
              <DropdownItem
                onClick={(event) => {
                  event.preventDefault();
                  handleCambiarActivo(row.id, row);
                }}
              >
                {value ? t('Disable') : t('Enable')}
              </DropdownItem>
              <DropdownItem onClick={(event) => {
                handleDelete(row);
                event.preventDefault();
              }}
              >
                {t('Remove')}
              </DropdownItem>
              <DropdownItem disabled>{t('Access log')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: miembrosData.length,
    sizePerPageRenderer,
    custom: true,
    showTotal: true
  };

  document.title = `${t('Team members')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Team members')} breadcrumbItems={[{ title: t('Management') }, { title: t('Team members') }]} />

          <Row>
            <Col className="col-12">
              <Row className="mb-2" style={{ marginTop: '1%' }}>
                <Col>
                  <FormGroup row style={{ marginLeft: '0.5%' }}>
                    <Label for="productoFiltro" sm={1}>{t('Product')}</Label>
                    <Col sm={2}>
                      <Input
                        type="select"
                        id="productoFiltro"
                        onChange={(event) => setFiltroTabla(event.target.value)}
                      >
                        <option
                          value=""
                        >
                          Todos
                        </option>
                        {productos.map(producto => {
                          return (
                            <option key={producto}>
                              {producto}
                            </option>
                          );
                        })}
                      </Input>
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }}>
                  <Button
                    color="primary"
                    className="mt-1"
                    onClick={() => {
                      history.push('/administracion/miembros/crear');
                    }}
                  >
                    {t('New member')}
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={usuariosVisibles}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={usuariosVisibles}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
            {modalStandard && (
            <SweetAlert
              isOpen={modalStandard}
              title={t('Are you sure?')}
              warning
              showCancel
              confirmBtnText={t('Confirm')}
              cancelBtnText={t('Cancel')}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => eliminar()}
              onCancel={() => setModalStandard(false)}
            />
            )}
            {isDeleted && (
            <SweetAlert
              success
              confirmBtnText={t('Continue')}
              title={t('Successfully deleted')}
              onConfirm={() => setIsDeleted(false)}
            />
            )}
            {errorDeleting && (
            <SweetAlert
              error
              confirmBtnText={t('Continue')}
              title={t('Error deleting')}
              onConfirm={() => setErrorDeleting(false)}
            />
            )}
            {/* <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Whatsapp automation info title')}</span>
                  <br />
                  {t('Whatsapp automation info text')}
                  <br />
                  {/* <a target="_blank" href="http://helpcenter.wifreezone.com/es/articles/5849058-campsWhatsapp-de-feedback" rel="noreferrer">{t('Read more')}</a> *//* }
                </CardText>
              </Card>
            </Col> */}
          </Row>
        </div>
      </div>
    </>
  );
};

Miembros.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Miembros);
