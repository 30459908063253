import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledTooltip,
  CardText
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

const Automatizacion = props => {
  const { t } = props;
  const [campsWhatsapp, setCampsWhatsapp] = useState([]);

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargaDatos = async () => {
    try {
      const response = await axiosApi.get('/api/whatsapp/automatizacion');
      let { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      data = data.map(camp => {
        camp.opciones = false;
        return camp;
      });
      setCampsWhatsapp(data);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  const columns = [
    {
      dataField: 'activa',
      text: t('Status'),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (value) => {
        if (value === 1) {
          return <Badge className="me-1 rounded-pill bg-success">{t('Active')}</Badge>;
        }
        return <Badge className="me-1 rounded-pill bg-danger">{t('Inactive')}</Badge>;
      }
    },
    {
      dataField: 'via',
      text: t('Channel'),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (value, row) => {
        return (
          <div>
            <i className="fab fa-whatsapp h4" id={`whatsapp-icon${row.id}`} />
            <UncontrolledTooltip placement="top" target={`whatsapp-icon${row.id}`}>
              WhatsApp
            </UncontrolledTooltip>
          </div>
        );
      }
    },
    {
      dataField: 'nombre',
      text: t('Description'),
      sort: true
    },
    {
      dataField: 'nombre_plantilla',
      text: t('Template'),
      headerStyle: { maxWidth: '25vh' },
      style: { maxWidth: '25vh',
        height: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      sort: true
    },
    {
      dataField: 'filtro',
      headerAlign: 'center',
      text: t('Segment'),
      sort: true,
      formatter: (value, row) => {
        if (Array.isArray(value)) {
          return (
            <>
              {
              value.length === 1 &&
                <span style={{ color: '#4789D5' }} id={`alerta${row.id_camp}`}>{`1 ${t('Applied filter')}`}</span>
              }
              {
              value.length !== 1 &&
                <span style={{ color: '#4789D5' }} id={`alerta${row.id_camp}`}>{`${value.length} ${t('Applied filters')}`}</span>
              }
              <UncontrolledTooltip style={{ backgroundColor: 'white' }} placement="bottom" target={`alerta${row.id_camp}`}>
                <ul className="estilos-vacio filtros-condicion" style={{ color: '#495057', listStyleType: 'none' }}>
                  {value.map((item, index) => (
                    <li key={index}>
                      <strong>
                        { item[0] }
                        :
                      </strong>
                      {' '}
                      { item[1] }
                    </li>
                  ))}
                </ul>
              </UncontrolledTooltip>
            </>
          );
        }
        return <span>{value}</span>;
      }
    },
    {
      dataField: 'value_momento',
      text: t('When to send'),
      headerStyle: { maxWidth: '25vh' },
      style: { maxWidth: '25vh',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      sort: true,
      formatter: (value, row) => {
        const json = JSON.parse(value);
        if (row.id_tipo_momento === 2) {
          return t('hours hours after connection and do not repeat until the following connections', { hours: json.despues_conectarse });
        }
        if (row.id_tipo_momento === 3) {
          if (json.cumple === '0') {
            return t('On the day of the user\'s birthday');
          }
          return t('days days before the user\'s birthday', { days: json.cumple });
        }
        if (row.id_tipo_momento === 4) {
          return t('Every day days of the month', { days: json.mes });
        }
        if (row.id_tipo_momento === 5) {
          return t('Every days of the week', { days: json.semana });
        }
        if (row.id_tipo_momento === 1) {
          return '';
        }
        if (row.id_tipo_momento === 6) {
          return '';
        }
        if (row.id_tipo_momento === 7) {
          return t('hours hours after connection and do not repeat until the following connections', { hours: json.despues_conectarse });
        }
        if (row.id_tipo_momento === 8) {
          if (json.cumple === '0') {
            return t('On the day of the user\'s birthday');
          }
          return t('days days before the user\'s birthday', { hours: json.cumple });
        }
        if (row.id_tipo_momento === 9) {
          return t('Every day days of the month', { hours: json.mes });
        }
        if (row.id_tipo_momento === 10) {
          return t('Every days of the week', { hours: json.semana });
        }
        if (row.id_tipo_momento === 11) {
          return t('hour hours after last user connection', { hours: json.despues_checkout });
        }
        if (row.id_tipo_momento === 12) {
          return t('hours hours after last user connection', { hours: json.despues_checkout });
        }
        if (row.id_tipo_momento === 13) {
          return t('hours hours before checkin. (If the user makes the reservation with less advance notice, he will receive the email at the time of the reservation)', { hours: json.antes_checkin });
        }
        if (row.id_tipo_momento === 14) {
          return t('hours hours before checkin. (If the user makes the reservation with less advance notice, he will receive the email at the time of the reservation)', { hours: json.antes_checkin });
        }
        if (row.id_tipo_momento === 15) {
          return t('hours hours after reservation', { hours: json.despues_reserva });
        }
        if (row.id_tipo_momento === 16) {
          return t('hours hours after reservation', { hours: json.despues_reserva });
        }
        if (row.id_tipo_momento === 17) {
          return t('hours hours before checkout', { hours: json.antes_checkout });
        }
        if (row.id_tipo_momento === 18) {
          return t('hours hours before checkout', { hours: json.antes_checkout });
        }
        return '-';
      }
    },
    {
      dataField: 'id_tipo_momento',
      text: t('Duration'),
      sort: true,
      formatter: (value) => {
        if (value === 2) {
          return t('Periodically');
        }
        if (value === 3) {
          return t('Periodically');
        }
        if (value === 4) {
          return t('Periodically');
        }
        if (value === 5) {
          return t('Periodically');
        }
        if (value === 1) {
          return t('Specific date');
        }
        if (value === 6) {
          return t('Send now');
        }
        if (value === 7) {
          return t('Permanent');
        }
        if (value === 8) {
          return t('Permanent');
        }
        if (value === 9) {
          return t('Permanent');
        }
        if (value === 10) {
          return t('Permanent');
        }
        if (value === 11) {
          return t('Periodically');
        }
        if (value === 12) {
          return t('Permanent');
        }
        if (value === 13) {
          return t('Periodically');
        }
        if (value === 14) {
          return t('Permanent');
        }
        if (value === 15) {
          return t('Periodically');
        }
        if (value === 16) {
          return t('Permanent');
        }
        if (value === 17) {
          return t('Periodically');
        }
        if (value === 18) {
          return t('Permanent');
        }
        return '';
      }
    },
    {
      dataField: 'id_camp',
      align: 'center',
      headerAlign: 'center',
      text: t('Options'),
      sort: false,
      formatter: (value, row) => {
        return (
          <UncontrolledDropdown
            direction="left"
          >
            <DropdownToggle tag="a" caret>
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu
              className="lista-opciones"
            >
              <DropdownItem
                disabled
              >
                {row.activa === 1 &&
                t('Disable')}
                {row.activa === 0 &&
                t('Enable')}
              </DropdownItem>
              <DropdownItem disabled>{t('Edit')}</DropdownItem>
              <DropdownItem disabled>{t('Remove')}</DropdownItem>
              <DropdownItem disabled>{t('Clone')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: campsWhatsapp.length,
    sizePerPageRenderer,
    custom: true,
    showTotal: true
  };

  document.title = `${t('Automation')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Automation')} breadcrumbItems={[{ title: t('Whatsapp') }, { title: t('Automation') }]} />

          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }} />
              </Row>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id_camp"
                    columns={columns}
                    data={campsWhatsapp}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id_camp"
                        columns={columns}
                        data={campsWhatsapp}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id_camp"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Whatsapp automation info title')}</span>
                  <br />
                  {t('Whatsapp automation info text')}
                  <br />
                  {/* <a target="_blank" href="http://helpcenter.wifreezone.com/es/articles/5849058-campsWhatsapp-de-feedback" rel="noreferrer">{t('Read more')}</a> */}
                </CardText>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

Automatizacion.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Automatizacion);
