import PropTypes from 'prop-types';
import React from 'react';

import ReactApexChart from 'react-apexcharts';

const LineApexChart = props => {
  const { categories, series } = props;
  const options = {
    chart: { zoom: { enabled: true }, toolbar: { show: false } },
    colors: ['#556ee6', '#34c38f'],
    dataLabels: { enabled: false },
    stroke: { width: [3, 3], curve: 'straight' },
    grid: {
      row: { colors: ['transparent', 'transparent'], opacity: 0.2 },
      borderColor: '#f1f1f1'
    },
    markers: { style: 'inverted', size: 6 },
    xaxis: {
      categories
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'right',
      floating: false,
      offsetY: -25,
      offsetX: -5
    },
    responsive: [
      {
        breakpoint: 600,
        options: { chart: { toolbar: { show: false } }, legend: { show: false } }
      }
    ]
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="350"
    />
  );
};

LineApexChart.propTypes = {
  categories: PropTypes.array,
  series: PropTypes.array
};

export default LineApexChart;
