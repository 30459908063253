import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// import images
import logoJourneytok from '../../assets/images/logo_journeytok_azul.png';

const CompruebaEmail = props => {
  const { t } = props;
  const history = useHistory();
  const { email } = history?.location?.state || '';
  document.title = t('Journeytok dashboard');

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-4 mb-md-5">
                <Link to="/login" className="d-block auth-logo">
                  <img src={logoJourneytok} alt="" height="46" className="logo-dark-element" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary" />
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>{t('Verify your email')}</h4>
                        <p>
                          {t('We have sent you verification email')}
                          {' '}
                          <span className="fw-semibold">
                            {email}
                          </span>
                          {', '}
                          {t('Please check it')}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t('Didn\'t receive an email ?')}
                  {' '}
                  <Link to="/password-recovery" className="fw-medium text-primary">
                    {' '}
                    {t('Resend email')}
                    {' '}
                  </Link>
                  {' '}
                </p>
                <p>
                  ©
                  {' '}
                  {new Date().getFullYear()}
                  {' '}
                  Journeytok
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

CompruebaEmail.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(CompruebaEmail);
