/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import moment from 'moment';
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Input,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormFeedback
} from 'reactstrap';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Select from 'react-select';
import classnames from 'classnames';
import { io } from 'socket.io-client';
import md5 from 'md5';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import { withTranslation } from 'react-i18next';

import TextareaAutosize from 'react-textarea-autosize';
import Flag from 'react-world-flags';
import { parsePhoneNumberWithError, isValidPhoneNumber } from 'libphonenumber-js';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
// Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import InfiniteScroll from 'react-infinite-scroll-component';
import SimpleBar from 'simplebar-react';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb2';
import axios from 'axios';
import axiosApi from '../../helpers/apiResquests';
import languageList from '../../common/languageList.json';
import prefixList from '../../common/prefixList.json';

const { CancelToken } = axios;

const SIZE_FILE_MAX = 20971520;

const Inbox = props => {
  const { t } = props;
  const queryParameters = new URLSearchParams(window.location.search);

  const myRefImg = useRef([]);
  const refSocket = useRef();
  const fileRef = useRef();
  const source = useRef();
  const refIdLocal = useRef();
  const refConversaciones = useRef();
  const refConversacionActiva = useRef();
  const refAbierta = useRef();
  refAbierta.current = false;

  const [otherMenu, setOtherMenu] = useState(false);
  const [channelMenu, setChannelMenu] = useState(false);
  const [resolveMenu, setResolveMenu] = useState(false);
  const [assignMenu, setAssignMenu] = useState(false);
  const [activeTab, setActiveTab] = useState('PENDIENTE');
  const [canal, setCanal] = useState({ id: 'chat-send', icon: 'bx bxs-chat align-middle align-middle', color: '#FBBC05' });
  const [curMessage, setcurMessage] = useState('');
  const [notaInterna, setNotaInterna] = useState('');
  const [modalNotaInterna, setModalNotaInterna] = useState(false);
  const [avisoWhatsapp, setAvisoWhatsapp] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState(null);
  const [plantillasFiltetered, setPlantillasFiltered] = useState([]);
  const [filterSearchTemplate, setFilterSearchTemplate] = useState('');
  const [listadoPlantillas, setListadoPlantillas] = useState(false);
  const [plantillaParaEnviar, setPlantillaParaEnviar] = useState(false);
  const [plantillaPlaceholders, setPlantillaPlaceholders] = useState(false);
  const [cabeceraPlantillaTienePlaceolder, setCabeceraPlantillaTienePlaceolder] = useState(false);
  const [mostrarPlantilla, setMostrarPlantilla] = useState(false);
  const [placeholderValues, setPlaceholderValues] = useState([]);
  const [placeholderCabecera, setPlaceholderCabecera] = useState('');
  const [placeholdersPlantilla, setPlaceholdersPlantilla] = useState(0);
  const [botonEnviarPlantillaEnabled, setBotonEnviarPlantillaEnabled] = useState(true);
  const [conversaciones, setConversaciones] = useState([]);
  const [conversacionActiva, setConversacionActiva] = useState(null);
  const [canalEmailActivo, setCanalEmailActivo] = useState(true);
  const [canalWhatsappActivo, setCanalWhatsappActivo] = useState(true);
  const [canalWebchatActivo, setCanalWebchatActivo] = useState(true);
  const [idLocal, setIdLocal] = useState(null);
  const [plataforma, setPlataforma] = useState('');
  const [idUsuario, setIdUsuario] = useState('');
  const [masConversaciones, setMasConversaciones] = useState(false);
  const [totalConversaciones, setTotalConversaciones] = useState(0);
  const [busquedaClientes, setBusquedaClientes] = useState(false);
  const [resultadosBusqueda, setResultadosBusqueda] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [modalCreacionUsuarios, setModalCreacionUsuarios] = useState(false);
  const [checkOpenConversation, setCheckOpenConversation] = useState(true);
  const [modalListaFicheros, setModalListaFicheros] = useState(false);
  const [ficheros, setFicheros] = useState([]);
  const [estadosMenu, setEstadosMenu] = useState(false);
  const [estados, setEstados] = useState([{ id: 'PENDIENTE' }, { id: 'RESUELTA' }]);
  const [modalConfirmacionCambioEstado, setModalConfirmacionCambioEstado] = useState(false);
  const [estadoCambio, setEstadoCambio] = useState(null);
  const [checkNoLeido, setCheckNoLeido] = useState(false);
  const [checkAccionRequerida, setCheckAccionRequerida] = useState(false);
  const [departamentos, setDepartamentos] = useState([]);
  const [asignado, setAsignado] = useState();
  const [estadoFiltrar, setEstadoFiltrar] = useState({ id: 'RESUELTA' });
  const [estadosFiltrosMenu, setEstadosFiltrosMenu] = useState(false);
  const [asignadasFiltrar, setAsignadasFiltrar] = useState();
  const [asignadasMenu, setAsignadasMenu] = useState(false);
  const [agentes, setAgentes] = useState([]);
  const [peticionPendiente, setPeticionPendiente] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [respuestasRapidas, setRespuestasRapidas] = useState([]);
  const [menuRespuestasRapidas, setMenuRespuestasRapidas] = useState(false);
  const [desplegableRespuestasRapidas, setDesplegableRespuestasRapidas] = useState({});
  const [tieneWhatsapp, setTieneWhatsapp] = useState(false);
  const [abrir] = useState(queryParameters.get('abrir'));
  const [controlApertura, setControlApertura] = useState(() => ({
    abrir: false,
    conversaciones: false
  }));
  const [prefixCountry, setPrefixCountry] = useState('ES');

  const canales = {
    chat: { id: 'chat-send', icon: 'bx bxs-chat align-middle align-middle', color: '#FBBC05', name: 'chat' },
    whatsapp: { id: 'whatsapp-send', icon: 'bx bxl-whatsapp align-middle', color: '#25D366', name: 'whatsapp' },
    email: { id: 'email-send', icon: 'bx bx-envelope align-middle', color: '#4285F4', name: 'email' },
    sms: { id: 'message-send', icon: 'bx bxs-message-square-detail align-middle', color: '#EA4335', name: 'sms' }
  };

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const buscarClientes = async () => {
    if (!source.current) {
      source.current = CancelToken.source();
    } else {
      source.current.cancel();
      source.current = CancelToken.source();
    }
    try {
      const response = await axiosApi
        .get(
          `/mc/customers/customersCompany?id=${refIdLocal.current}&plataforma=${plataforma}&name=${searchQuery}`,
          {
            cancelToken: source.current.token
          }
        );
      const { data } = response;
      setBusquedaClientes(true);
      setResultadosBusqueda(data);
    } catch (error) {
      // posiblemente se ha cancelado la petición
    }
  };

  useEffect(() => {
    if (searchQuery.length > 3) {
      buscarClientes();
    } else {
      setBusquedaClientes(false);
    }
  }, [searchQuery]);

  const cargaConfigLocal = async () => {
    try {
      const response = await axiosApi.get(`/mc/companies/config?id=${refIdLocal.current}`);
      const { data } = response;
      const {
        estados: estadosAux,
        error,
        hayEstados,
        departamentosEquipo,
        agentes: agentesAux,
        whatsapp
      } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      if (hayEstados) {
        setEstados(estadosAux);
      }
      if (agentesAux) {
        setAgentes(agentesAux);
      }
      if (departamentosEquipo) {
        setDepartamentos(departamentosEquipo);
      }
      if (whatsapp) {
        setTieneWhatsapp(whatsapp);
      }
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const cargaInicial = async () => {
    const whoami = await axiosApi.get('/mc/whoami');
    const { data: whoamiData } = whoami;
    setIdLocal(whoamiData.idLocal.toString());
    refIdLocal.current = whoamiData.idLocal.toString();
    setIdUsuario(whoamiData.idUser);
    setPlataforma(whoamiData.plataforma);
  };

  const cargarConversaciones = async (estado = null) => {
    try {
      setPeticionPendiente(true);
      let url = `/mc/conversations/list?idLocal=${idLocal}&plataforma=${plataforma}&page=0`;
      if (estado) {
        url += `&estado=${estado}`;
      }
      const response = await axiosApi.get(url);
      const { data } = response;
      if (data) {
        setConversaciones(data);
      }
      setPeticionPendiente(false);
    } catch (e) {
      console.log(e);
    }
  };

  const scrollConversacion = () => {
    document.getElementById('conversation-container').scrollTop =
      document.getElementById('conversation-container').scrollHeight;
  };

  const getTotalConversaciones = async (estado = null) => {
    let url = `/mc/conversations/conversations?idLocal=${idLocal}`;
    if (estado) {
      url += `&estado=${estado}`;
    }
    const response = await axiosApi.get(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const { data } = response;
    // eslint-disable-next-line camelcase
    const { count_conversaciones } = data;
    setTotalConversaciones(count_conversaciones);
  };

  const cargarRespuestasRapidas = async () => {
    try {
      const response = await axiosApi.get(`/mc/companies/respuestasRapidas?id=${refIdLocal.current}`);
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setRespuestasRapidas(data.respuestasRapidas);
    } catch (error) {
      console.log(error);
    }
  };

  const cargarPlantillas = async () => {
    try {
      const response = await axiosApi.get('/api/whatsapp/plantillas');
      let { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      const idiomas = new Set();
      data = data.filter(e => {
        return !(e.name.startsWith('sample_') || e.name === 'hello_world');
      });
      const plantillas = [];
      data.filter(p => p.status === 'APPROVED').forEach(p => {
        idiomas.add(p.language);
        const plantilla = {};
        plantilla.nombre = p.name;
        plantilla.idioma = p.language;
        p.components.forEach(c => {
          if (c.type === 'BODY') {
            plantilla.cuerpo = c.text;
          }
          if (c.type === 'HEADER' && c.format === 'TEXT') {
            plantilla.cabecera = c.text;
          }
          if (c.type === 'FOOTER' && c.format === 'TEXT') {
            plantilla.pie = c.text;
          }
          if (c.type === 'BUTTONS') {
            plantilla.botones = [];
            c.buttons.forEach(b => plantilla.botones.push({ texto: b.text }));
          }
        });
        plantillas.push(plantilla);
      });
      const languageOptions = [];
      idiomas.forEach(idioma => languageOptions.push({ label: t(idioma), value: idioma }));
      setLanguages([
        {
          options: languageOptions
        }
      ]);
      setTemplates(plantillas);
    } catch (error) {
      console.log('Error con las plantillas');
      setTemplates([]);
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const cargarMasConversaciones = async () => {
    const conversacionesAux = structuredClone(conversaciones);
    const page = parseInt(conversacionesAux.length / 10, 10);
    let URL = '';

    URL = `/mc/conversations/list?idLocal=${idLocal}`;
    if (estadoFiltrar.id && activeTab !== 'PENDIENTE') {
      URL += `&estado=${estadoFiltrar.id}`;
    }
    const response = await axiosApi.get(`${URL}&plataforma=${plataforma}&page=${page}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const { data } = response;
    if (data.length > 0) {
      let arrayChats = conversacionesAux.concat(data);
      arrayChats = arrayChats.filter(
        (v, i, a) => a.findIndex(
          v2 => (v2.conversations.idConversacion === v.conversations.idConversacion)
        ) === i
      );
      setConversaciones(arrayChats);
    }
  };

  const cargarImagen = async (index, imgAdjunto) => {
    try {
      const response = await axiosApi.get(`/mc/cargarImagen?file=${imgAdjunto}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
      const { data } = response;
      const { imagen } = data;
      myRefImg.current[index].src =
      `data:image/jpeg;base64,${imagen}`;
    } catch (error) {
      console.error(error);
    }
  };

  const inicializacionSocketIO = () => {
    if (idLocal) {
      refSocket.current = io(process.env.REACT_APP_API_DOMAIN);
      refSocket.current.on('connect', () => {
        refSocket.current.emit('room', md5(refIdLocal.current));
      });

      refSocket.current.on('disconnect', reason => {
        console.log('DESCONEXION');
        console.log(reason);
      });

      refSocket.current.on('crea', data => {
        try {
          if (data.idLocal.toString() === refIdLocal.current) {
            const { conversacion } = data;
            const conversacionesAux = structuredClone(refConversaciones.current);
            const index = conversacionesAux.findIndex(c => {
              return c.conversations.idConversacion ===
              conversacion.conversations.idConversacion;
            });
            if (index >= 0) {
              setConversacionActiva(structuredClone(conversacionesAux[index]));
              setConversaciones(conversacionesAux);
              setBusquedaClientes(false);
              return;
            }
            conversacionesAux.unshift(conversacion);
            setConversacionActiva(conversacion);
            setConversaciones(conversacionesAux);
            setBusquedaClientes(false);
          }
        } catch (error) {
          console.log(error);
        }
      });

      refSocket.current.on('broad', data => {
        let idConversacion;
        try {
          if (data.idConversacion) {
            // eslint-disable-next-line prefer-destructuring
            idConversacion = data.idConversacion;
          }
          delete data.idConversacion;
          const conversacionesAux = structuredClone(refConversaciones.current);
          if (data.idLocal.toString() === refIdLocal.current) {
            let index = conversacionesAux.findIndex(c => {
              return c.conversations.idConversacion ===
              idConversacion;
            });
            if (index >= 0) {
              conversacionesAux[index].conversations
                .mensajes.push({
                  enviadoPor: data.enviadoPor,
                  horaEnvio: data.horaEnvio,
                  imgAdjunto: data.imgAdjunto,
                  imgPre: data.imgPre,
                  mensaje: data.mensaje,
                  source: data.source,
                  id: data.idMensaje,
                  notaPrivada: data.notaPrivada,
                  idArchivo: data.idArchivo ? data.idArchivo : undefined,
                  tipoAdjunto: data.tipoAdjunto ? data.tipoAdjunto : undefined,
                  url: data.url ? data.url : undefined,
                  idWhatsapp: data.idWhatsapp ? data.idWhatsapp : undefined
                });
            } else {
              index = conversacionesAux.length;
              if (data.enviadoPor === 'Automatico') {
                // el mensaje es automático y no hay conversación
                // por lo que no añadimos el mensaje
                return;
              }
              conversacionesAux.push({
                conversations: {
                  idCliente: data.enviadoPor,
                  idConversacion,
                  idLocal: data.idLocal,
                  mensajes: [
                    {
                      enviadoPor: data.enviadoPor,
                      horaEnvio: data.horaEnvio,
                      imgAdjunto: data.imgAdjunto,
                      imgPre: data.imgPre,
                      mensaje: data.mensaje,
                      source: data.source,
                      id: data.idMensaje,
                      notaPrivada: data.notaPrivada,
                      idArchivo: data.idArchivo ? data.idArchivo : undefined,
                      tipoAdjunto: data.tipoAdjunto ? data.tipoAdjunto : undefined,
                      url: data.url ? data.url : undefined,
                      idWhatsapp: data.idWhatsapp ? data.idWhatsapp : undefined
                    }
                  ]
                }
              });
            }
            const aux = conversacionesAux[index];
            conversacionesAux.splice(index, 1);
            conversacionesAux.unshift(aux);
            if (conversacionesAux[index].conversations.idConversacion ===
                refConversacionActiva?.current?.conversations.idConversacion) {
              setConversacionActiva(structuredClone(conversacionesAux[index]));
            }
            setConversaciones(conversacionesAux);
          }
        } catch (error) {
          console.log(error);
        }
      });

      refSocket.current.on('actualizaHora', data => {
        const conversacionesAux = structuredClone(refConversaciones.current);
        const indexCliente = conversacionesAux.findIndex(conversacion => {
          return conversacion.cliente.idUsuarios === data.idCliente;
        });
        conversacionesAux[indexCliente].cliente.ultimaConexion = data.ultimaConexion;
        setConversaciones(conversacionesAux);
        setCanal(canales.email);
        setCanalWebchatActivo(false);
      });

      refSocket.current.on('marcarLeidos', data => {
        const { idCliente } = data;
        const conversacionesAux = structuredClone(refConversaciones.current);
        if (data.idLocal.toString() === idLocal) {
          let i = 0;
          while (
            i < conversacionesAux.length &&
            conversacionesAux[i].conversations.idCliente !== idCliente
          ) {
            i++;
          }
          if (i < conversacionesAux.length) {
            for (let j = 0; j < conversacionesAux[i].conversations.mensajes.length; j++) {
              if (
                conversacionesAux[i].conversations.mensajes[j].id &&
                conversacionesAux[i].conversations.mensajes[j].enviadoPor !== idCliente
              ) {
                conversacionesAux[i].conversations.mensajes[j].leido = true;
              }
            }
            setConversaciones(conversacionesAux);
          }
        }
      });

      refSocket.current.on('marcarLeidoWhatsapp', data => {
        const { idWhatsapp } = data;
        const conversacionesAux = structuredClone(refConversaciones.current);
        if (data.idLocal.toString() === idLocal) {
          let i = 0;
          while (i < conversacionesAux.length) {
            let j = 0;
            while (j < conversacionesAux[i].conversations.mensajes.length) {
              if (conversacionesAux[i].conversations.mensajes[j].idWhatsapp) {
                if (conversacionesAux[i].conversations.mensajes[j].idWhatsapp === idWhatsapp) {
                  conversacionesAux[i].conversations.mensajes[j].leido = true;
                  break;
                }
              }
              j++;
            }
            i++;
          }
          setConversaciones(conversacionesAux);
        }
      });

      refSocket.current.on('clientes', data => {
        try {
          const dataAux = data[plataforma];
          const conversacionesAux = structuredClone(refConversaciones.current);
          const conversacionActivaAux = structuredClone(refConversacionActiva.current);
          Object.keys(dataAux).forEach(e => {
            const index = conversacionesAux.findIndex(c => c.cliente.idUsuarios === e);
            if (index >= 0) {
              conversacionesAux[index].cliente.ultimaConexion = 'En linea';
            }
            if (refConversacionActiva.current?.cliente?.idCliente === e) {
              setCanal(canales.chat);
              conversacionActivaAux.cliente.ultimaConexion = 'En linea';
            }
          });
          setConversaciones(conversacionesAux);
          setConversacionActiva(conversacionActivaAux);
        } catch (error) {
          console.log(error);
        }
      });

      refSocket.current.on('marcarLeido', data => {
        const { idConversacion, idMensaje } = data;
        const conversacionesAux = structuredClone(refConversaciones.current);
        if (idLocal.toString() === idLocal) {
          let i = 0;
          while (
            i < conversacionesAux.length &&
            conversacionesAux[i].conversations.idConversacion !== idConversacion
          ) {
            i++;
          }
          if (i < conversacionesAux.length) {
            let j = 0;
            while (j < conversacionesAux[i].conversations.mensajes.length) {
              if (conversacionesAux[i].conversations.mensajes[j].id === idMensaje) {
                conversacionesAux[i].conversations.mensajes[j].leido = true;
                break;
              }
              j++;
            }
            setConversaciones(conversacionesAux);
          }
        }
      });

      return () => {
        refSocket.current.off('close');
      };
    }
    return undefined;
  };

  const marcarLeidosMensajesClientes = () => {
    let i = 0;
    const conversacionesAux = structuredClone(conversaciones);
    while (
      i < conversacionesAux.length &&
      conversacionesAux[i].conversations.idCliente !== conversacionActiva.conversations.idCliente
    ) {
      i++;
    }
    if (i < conversacionesAux.length) {
      for (let j = 0; j < conversacionesAux[i].conversations.mensajes.length; j++) {
        if (
          conversacionesAux[i].conversations.mensajes[j].id &&
          conversacionesAux[i].conversations.mensajes[j].enviadoPor ===
            conversacionActiva.conversations.idCliente
        ) {
          conversacionesAux[i].conversations.mensajes[j].leido = true;
        }
      }
      if (conversacionesAux[i].conversations.noLeido) {
        conversacionesAux[i].conversations.noLeido = false;
      }
      setConversaciones(conversacionesAux);
      refSocket.current.emit('marcarLeidosCliente', {
        idCliente: conversacionActiva.conversations.idCliente,
        idLocal,
        idConversacion: conversacionActiva.conversations.idConversacion
      });
    }
  };

  useEffect(() => {
    // ya no hace falta cargar las conversaciones al iniciar
    // se cargan en cambioEstado al ejecutarse al inicio
    cargaInicial();
    // cargarConversaciones();
    cargarPlantillas();
  }, []);

  useEffect(() => {
    inicializacionSocketIO();
    if (idLocal) {
      getTotalConversaciones();
      cargaConfigLocal();
      cargarRespuestasRapidas();
    }
  }, [idLocal]);

  useEffect(() => {
    refConversaciones.current = conversaciones;
    if (conversaciones.length < totalConversaciones) {
      setMasConversaciones(true);
    } else {
      setMasConversaciones(false);
    }
    if (conversaciones.length > 0) {
      setControlApertura(prevControlApertura => ({ ...prevControlApertura, conversaciones: true }));
    }
  }, [conversaciones]);

  useEffect(() => {
    refConversacionActiva.current = conversacionActiva;
    if (conversacionActiva) {
      marcarLeidosMensajesClientes();
    }
    scrollConversacion();
  }, [conversacionActiva]);

  useEffect(() => {
    if (canal?.id === 'whatsapp-send') {
      const lastWhatsappMessage = conversacionActiva?.conversations?.mensajes.filter(
        mensaje => mensaje.source.toLowerCase() === 'whatsapp' && mensaje.enviadoPor === conversacionActiva?.conversations?.idCliente
      ).slice(-1)[0];
      const diffHoursBetweenLastWhatsappMessage =
        moment.duration(moment().diff(moment(new Date(lastWhatsappMessage?.horaEnvio)))).asHours();
      if (lastWhatsappMessage?.horaEnvio && diffHoursBetweenLastWhatsappMessage < 24) {
        setAvisoWhatsapp(false);
      } else {
        setAvisoWhatsapp(true);
      }
    } else {
      setAvisoWhatsapp(false);
    }
  }, [canal]);

  useEffect(() => {
    const desplegableRespuestasRapidasAux = {};
    if (respuestasRapidas) {
      Object.keys(respuestasRapidas).forEach(r => {
        desplegableRespuestasRapidasAux[r] = false;
      });
      setDesplegableRespuestasRapidas(desplegableRespuestasRapidasAux);
    }
  }, [respuestasRapidas]);

  useEffect(() => {
    setCanal(null);
    if (conversacionActiva?.cliente?.email) {
      setCanalEmailActivo(true);
      setCanal(canales.email);
    } else {
      setCanalEmailActivo(false);
    }
    if (conversacionActiva?.cliente?.telefono && tieneWhatsapp) {
      setCanal(canales.whatsapp);
      setCanalWhatsappActivo(true);
    } else {
      setCanalWhatsappActivo(false);
    }
    if (conversacionActiva?.cliente?.ultimaConexion === 'En linea') {
      setCanalWebchatActivo(true);
      setCanal(canales.chat);
    } else {
      setCanalWebchatActivo(false);
    }
  }, [conversacionActiva]);

  useEffect(() => {
    if (plantillaParaEnviar) {
      const numPlaceholders = (plantillaParaEnviar.cuerpo.match(/\{\{([0-9}]+)\}\}/g) || []).length;
      setPlaceholdersPlantilla(numPlaceholders);
      const numPlaceholdersCabecera = (plantillaParaEnviar.cabecera?.match(/\{\{([0-9}]+)\}\}/g) || []).length;
      if (numPlaceholdersCabecera > 0) {
        setCabeceraPlantillaTienePlaceolder(true);
        setPlaceholderCabecera('');
      } else {
        setCabeceraPlantillaTienePlaceolder(false);
      }
      setBotonEnviarPlantillaEnabled(numPlaceholders === 0 && numPlaceholdersCabecera === 0);
    }
  }, [plantillaParaEnviar]);

  useEffect(() => {
    setPlaceholderValues([...Array(placeholdersPlantilla)]);
  }, [placeholdersPlantilla]);

  useEffect(() => {
    if (selectLanguage && filterSearchTemplate) {
      setPlantillasFiltered(
        templates.filter(
          plantilla => plantilla.idioma === selectLanguage.value && (
            plantilla.cuerpo.toLowerCase().indexOf(filterSearchTemplate.toLowerCase()) !== -1 ||
            plantilla.nombre.toLowerCase().indexOf(filterSearchTemplate.toLowerCase()) !== -1)
        )
      );
    } else if (filterSearchTemplate) {
      setPlantillasFiltered(
        templates.filter(
          plantilla => plantilla.cuerpo.toLowerCase().indexOf(
            filterSearchTemplate.toLowerCase()
          ) !== -1 || plantilla.nombre.toLowerCase()
            .indexOf(filterSearchTemplate.toLowerCase()) !== -1
        )
      );
    } else if (selectLanguage) {
      setPlantillasFiltered(
        templates.filter(
          plantilla => plantilla.idioma === selectLanguage.value
        )
      );
    } else {
      setPlantillasFiltered(templates);
    }
  }, [selectLanguage, filterSearchTemplate]);

  useEffect(() => {
    setPlantillasFiltered(templates);
  }, [templates]);

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function togNotaInterna() {
    setModalNotaInterna(!modalNotaInterna);
    removeBodyCss();
  }

  const toggleRightCanvas = () => {
    setMenuRespuestasRapidas(!menuRespuestasRapidas);
  };

  function handleSelectLanguage(option) {
    setSelectLanguage(option);
  }

  function setPlaceHolderCabecera(value) {
    setPlaceholderCabecera(value);
    const plantillaParaEnviarAux = structuredClone(plantillaParaEnviar);
    let placeholderValidos = 0;
    placeholderValues.forEach((v, indexAux) => {
      if (v) {
        placeholderValidos++;
        plantillaParaEnviarAux.cuerpo = plantillaParaEnviarAux.cuerpo.replace(`{{${indexAux + 1}}}`, v);
      }
    });
    if (value) {
      plantillaParaEnviarAux.cabecera = plantillaParaEnviarAux.cabecera.replace('{{1}}}', value);
    }
    if (placeholdersPlantilla === placeholderValidos && value) {
      setBotonEnviarPlantillaEnabled(true);
    } else {
      setBotonEnviarPlantillaEnabled(false);
    }
    setPlantillaPlaceholders(plantillaParaEnviarAux);
  }

  function setPlaceHolder(index, value) {
    const placeholderValuesAux = structuredClone(placeholderValues);
    placeholderValuesAux[index] = value;
    if (value === '') {
      value = `{{${index + 1}}}`;
      placeholderValuesAux[index] = '';
    }
    const plantillaParaEnviarAux = structuredClone(plantillaParaEnviar);
    let placeholderValidos = 0;
    if (placeholderCabecera !== '') {
      plantillaParaEnviarAux.cabecera = plantillaParaEnviarAux.cabecera.replace('{{1}}}', placeholderCabecera);
    }
    placeholderValuesAux.forEach((v, indexAux) => {
      if (v) {
        placeholderValidos++;
        plantillaParaEnviarAux.cuerpo = plantillaParaEnviarAux.cuerpo.replace(`{{${indexAux + 1}}}`, v);
      }
    });
    if (placeholdersPlantilla !== placeholderValidos || (cabeceraPlantillaTienePlaceolder && placeholderCabecera === '')) {
      setBotonEnviarPlantillaEnabled(false);
    } else {
      setBotonEnviarPlantillaEnabled(true);
    }
    setPlantillaPlaceholders(plantillaParaEnviarAux);
    setPlaceholderValues(placeholderValuesAux);
  }

  const onChangeCanal = (value) => {
    setCanal(canales[value]);
  };

  useEffect(() => {
    if (modalListaFicheros) {
      const ficherosAux = structuredClone(conversacionActiva.conversations.mensajes.filter(m => {
        return m.tipoAdjunto === 'file' || m.imgAdjunto || m.idArchivo;
      }));
      ficherosAux.forEach(f => {
        if (f.enviadoPor === conversacionActiva.cliente.idUsuarios) {
          f.enviadoPor = conversacionActiva.cliente.nombre;
        } else {
          f.enviadoPor = 'Enviado al cliente';
        }
      });
      setFicheros(ficherosAux);
    }
  }, [modalListaFicheros]);

  const toggleChannel = () => {
    setChannelMenu(!channelMenu);
  };

  const toggleOther = () => {
    setOtherMenu(!otherMenu);
  };

  const toggleResolve = () => {
    setResolveMenu(!resolveMenu);
  };

  const toggleAssign = () => {
    setAssignMenu(!assignMenu);
  };

  const toggleTab = tab => {
    if (peticionPendiente) {
      return;
    }
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const cambioEstado = async () => {
    setConversaciones([]);
    if (activeTab !== 'PENDIENTE') {
      await getTotalConversaciones(estadoFiltrar.id);
      await cargarConversaciones(estadoFiltrar.id);
    } else {
      await getTotalConversaciones();
      await cargarConversaciones();
    }
    if (!refAbierta.current) {
      setConversacionActiva(null);
      refAbierta.current = false;
    }
  };

  useEffect(() => {
    if (idLocal) {
      cambioEstado();
    }
  }, [activeTab, estadoFiltrar, idLocal]);

  const toggleEstados = () => {
    setEstadosMenu(!estadosMenu);
  };

  const toggleEstadosMenu = () => {
    setEstadosFiltrosMenu(!estadosFiltrosMenu);
  };

  const toggleAsignadas = () => {
    setAsignadasMenu(!asignadasMenu);
  };

  const onKeyPress = e => {
    const { key, value } = e;
    if (key === 'Enter') {
      setcurMessage(value);
    }
  };

  const asignar = async () => {
    let situacion = false;
    let noLeido = false;
    if (checkAccionRequerida) {
      situacion = 'ACCION_REQUERIDA';
    }
    if (checkNoLeido) {
      noLeido = true;
    }
    try {
      const { idConversacion } = conversacionActiva.conversations;
      const conversacionesAux = structuredClone(conversaciones);
      const conversacionActivaAux = structuredClone(conversacionActiva);
      const response = await axiosApi.post('/mc/conversations/cambiar', {
        idConversacion,
        situacion,
        noLeido,
        asignado
      });
      const { error } = response;
      if (error) {
        showToast(t('Error updating status'), t('The status can\'t be updated'), 'error');
        return;
      }
      conversacionesAux.forEach(c => {
        if (c.conversations.idConversacion === idConversacion) {
          if (noLeido) {
            c.conversations.noLeido = true;
          }
          if (checkAccionRequerida) {
            c.conversations.situacion = 'ACCION_REQUERIDA';
          }
          if (asignado) {
            c.conversations.asignado = asignado;
          }
        }
      });
      if (checkAccionRequerida) {
        conversacionActivaAux.conversations.situacion = 'ACCION_REQUERIDA';
      }
      if (asignado) {
        conversacionActivaAux.conversations.asignado = asignado;
      }
      if (noLeido) {
        setConversacionActiva(null);
      } else {
        setConversacionActiva(conversacionActivaAux);
      }
      setConversaciones(conversacionesAux);
      setCheckNoLeido(false);
      setCheckAccionRequerida(false);
    } catch (error) {
      console.log(error);
    }
  };

  const buscarConversacionCliente = async (idCliente) => {
    refSocket.current.emit('buscarConversacionCliente', {
      idCliente,
      idLocal,
      plataforma
    });
  };

  const translate = async (id) => {
    try {
      const idioma = window.navigator.language;

      const mensaje = conversacionActiva.conversations.mensajes.find(o => o.id === id);

      const response = await axiosApi.post('/mc/traducir', {
        mensaje: mensaje.mensaje,
        idioma
      });
      const { data } = response;
      if (data.message === 'ok') {
        document.getElementById(`mensaje_traducido_${id}`).innerHTML = data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const abrirConversacion = async (id) => {
    const index = conversaciones.findIndex(c => c.cliente.idUsuarios === id);
    if (index >= 0) {
      refAbierta.current = true;
      setConversacionActiva(conversaciones[index]);
    } else {
      buscarConversacionCliente(abrir);
    }
  };

  useEffect(() => {
    if (controlApertura.abrir && controlApertura.conversaciones && !peticionPendiente) {
      if (abrir) {
        abrirConversacion(abrir);
      }
      setControlApertura(() => ({ abrir: false, conversaciones: false }));
    }
  }, [controlApertura, abrir, conversaciones, peticionPendiente]);

  useEffect(() => {
    setControlApertura(prevControlApertura => ({ ...prevControlApertura, abrir: true }));
  }, [abrir]);

  // Form validation
  const validationUserData = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: '',
      nombre: '',
      apellidos: '',
      email: '',
      telefono: '',
      idioma: 'es',
      observaciones: ''
    },
    validationSchema: Yup.object().shape({
      nombre: Yup.string().required(t('VALUE_REQUIRED')),
      idioma: Yup.string().required(t('VALUE_REQUIRED')),
      email: Yup.string().email(t('VALID_EMAIL')).when(['telefono'], {
        is: (a) => !a || a.length === 0,
        then: Yup.string().email(t('VALID_EMAIL')).required(t('EMAIL_PHONE_REQUIRED'))
      }),
      telefono: Yup.number().typeError(t('VALID_PHONE')).when(['email'], {
        is: (a) => !a || a.length === 0,
        then: Yup.number().typeError(t('VALID_PHONE')).required(t('EMAIL_PHONE_REQUIRED'))
      })
    }, ['email', 'telefono'], ['telefono', 'email']),
    onSubmit: async () => {
      const { id, nombre, apellidos, email, telefono, idioma, observaciones } =
        validationUserData.values;
      const prefijo = prefixList.find(p => p.code === prefixCountry).dial_code?.replace('+', '');
      let telefonoConPrefijo = '';
      if (telefono && telefono !== '') {
        telefonoConPrefijo = `${prefijo}${telefono}`;
      }
      const datosUsuario = {
        id,
        nombre,
        apellidos,
        email,
        telefono: telefonoConPrefijo,
        idioma,
        observaciones
      };
      try {
        const response = await axiosApi.post('/api/cliente/add', datosUsuario);
        const { data } = response;
        const { error, errorCode } = data;
        if (error) {
          if (errorCode) {
            const title = t('Error saving user');
            const message = t(errorCode);
            showToast(title, message, 'error');
            return;
          }
          throw Error('Error');
        }
        const title = t('User saved');
        const message = t('The user has been added');
        showToast(title, message, 'success');
        // sólo se debe abrir conversación en el caso de añadir un nuevo usuario
        if (checkOpenConversation && !datosUsuario.id && data.user?.id_usuarios) {
          buscarConversacionCliente(data.user.id_usuarios.toString());
        }
        if (data.user?.id_usuarios && datosUsuario.id) {
          const idUsuarios = data.user.id_usuarios.toString();
          const index = conversaciones.findIndex(
            c => c.cliente.idUsuarios === idUsuarios
          );
          if (index < 0) {
            return;
          }
          const conversacionesCopia = structuredClone(conversaciones);
          conversacionesCopia[index].cliente.nombre = `${nombre} ${apellidos}`;
          conversacionesCopia[index].cliente.email = email;
          conversacionesCopia[index].cliente.telefono = telefonoConPrefijo;
          conversacionesCopia[index].cliente.locale = idioma;
          conversacionesCopia[index].cliente.observaciones = observaciones;
          setConversaciones(conversacionesCopia);
          setConversacionActiva(structuredClone(conversacionesCopia[index]));
        }
        setModalCreacionUsuarios(false);
        validationUserData.resetForm();
      } catch (error) {
        console.log(error);
        const title = t('Error saving user');
        const message = t('The user has not been modified');
        showToast(title, message, 'error');
      }
    }
  });

  const editarUsuario = async (idCliente) => {
    const index = conversaciones.findIndex(c => c.cliente.idUsuarios === idCliente);
    if (index < 0) {
      return;
    }
    const { cliente } = conversaciones[index];
    validationUserData.setFieldValue('id', cliente.idUsuarios);
    if (cliente.firstName) {
      validationUserData.setFieldValue('nombre', cliente.firstName);
    }
    if (cliente.lastName) {
      validationUserData.setFieldValue('apellidos', cliente.lastName);
    }
    if (cliente.email) {
      validationUserData.setFieldValue('email', cliente.email);
    }
    if (cliente.telefono) {
      try {
        const phoneNumber = parsePhoneNumberWithError(`+${cliente.telefono}`, {
          defaultCountry: 'ES'
        });
        if (!isValidPhoneNumber(`+${cliente.telefono}`)) {
          throw Error('El telefono no es valido');
        }
        setPrefixCountry(phoneNumber.country);
        validationUserData.setFieldValue('telefono', phoneNumber.nationalNumber);
      } catch (error) {
        validationUserData.setFieldValue('telefono', cliente.telefono);
      }
    }
    if (cliente.locale) {
      validationUserData.setFieldValue('idioma', cliente.locale);
    }
    if (cliente.observaciones) {
      validationUserData.setFieldValue('observaciones', cliente.observaciones);
    }
    setModalCreacionUsuarios(true);
  };

  const cambiarEstadoConversacion = async (id) => {
    try {
      const { idConversacion } = conversacionActiva.conversations;
      const conversacionesAux = structuredClone(conversaciones);
      const response = await axiosApi.post('/mc/conversations/actualizarEstado', {
        idConversacion,
        estado: id
      });
      const { data, error } = response;
      const { estado } = data;
      if (error) {
        showToast(t('Error updating status'), t('The status can\'t be updated'), 'error');
        return;
      }
      if (estado === 'NOT_CHANGED') {
        const title = t('Status not updated');
        const message = t('The status hasn\'t been updated');
        showToast(title, message, 'info');
        return;
      }
      const indexConversacion = conversacionesAux.findIndex(c => {
        return c.conversations.idConversacion === idConversacion;
      });
      conversacionesAux.splice(indexConversacion, 1);
      setConversaciones(conversacionesAux);
      setTotalConversaciones(totalConversaciones - 1);
      setConversacionActiva(null);
      const title = t('Status updated');
      const message = t('The status has been updated');
      showToast(title, message, 'success');
    } catch (e) {
      console.log(e);
    }
  };

  const marcarConversacionNoLeida = async () => {
    try {
      const { idConversacion } = conversacionActiva.conversations;
      const conversacionesAux = structuredClone(conversaciones);
      const response = await axiosApi.post('/mc/conversations/cambiar', {
        idConversacion,
        noLeido: true
      });
      const { error } = response;
      if (error) {
        showToast(t('Error updating status'), t('The status can\'t be updated'), 'error');
        return;
      }
      conversacionesAux.forEach(c => {
        if (c.conversations.idConversacion === idConversacion) {
          c.conversations.noLeido = true;
        }
      });
      setConversacionActiva(null);
      setConversaciones(conversacionesAux);
    } catch (e) {
      console.log(e);
    }
  };

  const cambiarSituacionConversacion = async (situacion, desmarcar = false) => {
    try {
      const { idConversacion } = conversacionActiva.conversations;
      const conversacionesAux = structuredClone(conversaciones);
      const conversacionActivaAux = structuredClone(conversacionActiva);
      const response = await axiosApi.post('/mc/conversations/cambiar', {
        idConversacion,
        situacion,
        desmarcar
      });
      const { error } = response;
      if (error) {
        showToast(t('Error updating conversation'), t('The conversation can\'t be updated'), 'error');
        return;
      }
      conversacionesAux.forEach(c => {
        if (c.conversations.idConversacion === idConversacion) {
          if (desmarcar) {
            delete c.conversations.situacion;
          } else {
            c.conversations.situacion = situacion;
          }
        }
      });
      if (desmarcar) {
        delete conversacionActivaAux.conversations.situacion;
      } else {
        conversacionActivaAux.conversations.situacion = situacion;
      }
      setConversacionActiva(conversacionActivaAux);
      setConversaciones(conversacionesAux);
      const title = t('Conversation updated');
      const message = t('The conversation has been updated');
      showToast(title, message, 'success');
    } catch (e) {
      console.log(e);
    }
  };

  const envioPlantillaWhatsapp = async () => {
    const { telefono } = conversacionActiva.cliente;
    const plantillaParaEnviarAux = structuredClone(plantillaParaEnviar);
    if (placeholderCabecera !== '') {
      plantillaParaEnviarAux.cabecera = plantillaParaEnviarAux.cabecera.replace('{{1}}}', placeholderCabecera);
    }
    placeholderValues.forEach((v, indexAux) => {
      if (v) {
        plantillaParaEnviarAux.cuerpo = plantillaParaEnviarAux.cuerpo.replace(`{{${indexAux + 1}}}`, v);
      }
    });
    let mensaje = '';
    if (plantillaParaEnviarAux.cabecera) {
      mensaje += plantillaParaEnviarAux.cabecera;
      mensaje += '\n';
    }
    if (plantillaParaEnviarAux.cuerpo) {
      mensaje += plantillaParaEnviarAux.cuerpo;
    }
    if (plantillaParaEnviarAux.pie) {
      mensaje += '\n';
      mensaje += plantillaParaEnviarAux.pie;
    }

    const response = await axiosApi.post('/mc/conversations/envioPlantillaWhatsapp', {
      idLocal,
      plataforma,
      idCliente: conversacionActiva.cliente.idUsuarios,
      nombrePlantilla: plantillaParaEnviar.nombre,
      localePlantilla: plantillaParaEnviar.idioma,
      telefono,
      cabecera: placeholderCabecera,
      cuerpo: placeholderValues,
      mensaje
    });
    const { data } = response;
    const { error, enviado } = data;
    if (error) {
      showToast(t('Error sending template'), t('The template hasn\'t been sent'), 'error');
      return;
    }
    if (enviado) {
      setMostrarPlantilla(false);
    }
  };

  const enviarMensaje = () => {
    const { idConversacion } = conversacionActiva.conversations;
    const { email, telefono, idUsuarios } = conversacionActiva.cliente;

    const noenviar = false;
    if (canal.name === 'chat') {
      refSocket.current.emit('responderChat', {
        idConversacion,
        mensaje: curMessage,
        enviadoPor: idUsuario,
        horaEnvio: moment().toISOString(),
        idLocal,
        idCliente: idUsuarios,
        noenviar,
        origen: plataforma
      });
    } else if (canal.name === 'whatsapp') {
      refSocket.current.emit('envioMensajeWhatsApp', {
        idConversacion,
        mensaje: curMessage,
        enviadoPor: idUsuario,
        horaEnvio: moment().toISOString(),
        idLocal,
        idCliente: idUsuarios,
        telefono
      });
    } else if (canal.name === 'email') {
      refSocket.current.emit('envioMensajeEmail', {
        idConversacion,
        mensaje: curMessage,
        enviadoPor: idUsuario,
        horaEnvio: moment().toISOString(),
        idLocal,
        idCliente: idUsuarios,
        email
      });
    } else if (canal.name === 'sms') {
      refSocket.current.emit('envioMensajeSMS', {
        idConversacion,
        mensaje: curMessage,
        enviadoPor: idUsuario,
        horaEnvio: moment().toISOString(),
        idLocal,
        idCliente: idUsuarios,
        telefono
      });
    }
  };

  const enviarNotaPrivada = () => {
    const { idConversacion } = conversacionActiva.conversations;
    const { idUsuarios } = conversacionActiva.cliente;
    refSocket.current.emit('envioNotaPrivada', {
      idConversacion,
      mensaje: notaInterna,
      enviadoPor: idUsuario,
      horaEnvio: moment().toISOString(),
      idLocal,
      idCliente: idUsuarios
    });
  };

  const handleChangeFile = e => {
    const { telefono, idUsuarios } = conversacionActiva.cliente;

    const [file] = e.target.files;
    if (file?.size > SIZE_FILE_MAX) {
      // eslint-disable-next-line no-alert
      alert(
        `El tamaño de archivo supera los ${
          SIZE_FILE_MAX / (1024 ** 2)
        } MB. El tamaño del fichero tiene que ser inferior.`
      );
      return;
    }
    // eslint-disable-next-line no-alert
    if (!window.confirm(`¿Desea subir el fichero ${file.name}?`)) {
      e.target.value = null;
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      refSocket.current.emit('envioMensajeWhatsApp', {
        idConversacion: conversacionActiva.conversations.idConversacion,
        enviadoPor: idUsuario,
        horaEnvio: moment().toISOString(),
        idCliente: idUsuarios,
        telefono,
        idLocal,
        mensaje: file.name,
        fichero: reader.result
      });
    };
    reader.readAsArrayBuffer(file);
    e.target.value = null;
  };

  const defaultSorted = [
    {
      dataField: 'timestamp',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = () => (
    <></>
  );
  const pageOptions = {
    sizePerPage: 15,
    totalSize: ficheros.length,
    sizePerPageRenderer,
    custom: true,
    showTotal: true
  };

  const columns = [
    {
      dataField: 'horaEnvio',
      text: t('Date'),
      sort: true,
      formatter: value => {
        return moment(value).format('DD/MM/YYYY');
      }
    },
    {
      dataField: 'enviadoPor',
      text: t('Uploaded by'),
      sort: true
    },
    {
      dataField: 'mensaje',
      text: t('Filename'),
      headerStyle: { width: '8vw' },
      style: { maxWidth: '8vw',
        height: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' },
      sort: true,
      formatter: (value, row) => {
        if (value) {
          return value;
        }
        return row.imgAdjunto;
      }
    },
    {
      dataField: 'id',
      align: 'center',
      headerAlign: 'center',
      text: t('Options'),
      sort: false,
      formatter: () => {
        return (
          <UncontrolledDropdown direction="left">
            <DropdownToggle tag="a" caret>
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu className="lista-opciones">
              <DropdownItem disabled>{t('Download')}</DropdownItem>
              <DropdownItem disabled>{t('Delete')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }
    }
  ];

  document.title = `${t('Messaging Center')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t('Messaging Center')}
            breadcrumbItems={[{ title: t('Messaging Center') }, { title: t('Inbox') }]}
          />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div className="">
                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder={t('Search customers')}
                          value={searchQuery}
                          onChange={e => setSearchQuery(e.target.value?.toLowerCase())}
                        />
                        <i className="bx bx-search-alt search-icon" />
                        <Dropdown
                          isOpen={busquedaClientes}
                          toggle={() => setBusquedaClientes(!busquedaClientes)}
                          className="dropdown d-inline-block"
                          tag="span"
                        >

                          <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-start">

                            <SimpleBar style={{ height: '230px' }}>
                              {map(resultadosBusqueda, (resultado) => (
                                <li
                                  key={`${resultado.idUsuarios}}`}
                                >
                                  <Link
                                    to="#"
                                    className="text-reset notification-item"
                                    onClick={() => {
                                      buscarConversacionCliente(
                                        structuredClone(resultado.idUsuarios)
                                      );
                                    }}
                                  >
                                    <div className="media">
                                      <div className="avatar-xs me-3">
                                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                                          {resultado.nombre?.trim().substring(0, 1).toUpperCase()}
                                        </span>
                                      </div>
                                      <div className="media-body">
                                        <h6 className="mt-2 mb-1">{resultado.nombre}</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              ))}
                            </SimpleBar>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <Row>
                        <Col md="8" xs="3">
                          <Nav pills justified>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === 'PENDIENTE'
                                })}
                                onClick={() => {
                                  toggleTab('PENDIENTE');
                                }}
                              >
                                <span className="d-sm-block">{t('PENDIENTE')}</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === estadoFiltrar.id
                                })}
                                onClick={() => {
                                  toggleTab(estadoFiltrar.id);
                                }}
                              >
                                <span className="d-sm-block">{t(estadoFiltrar.id)}</span>
                              </NavLink>
                            </NavItem>
                            <Dropdown isOpen={estadosFiltrosMenu} toggle={toggleEstadosMenu}>
                              <DropdownToggle className="btn nav-btn no-hover" tag="span" style={{ padding: '5px' }}>
                                <i className="bx bx-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                {map(estados.filter(e => e.id !== estadoFiltrar.id && e.id !== 'PENDIENTE'), (estado) => (
                                  <DropdownItem
                                    key={`estadosFilter${estado.id}`}
                                    onClick={() => {
                                      setEstadoFiltrar({ id: estado.id });
                                      setActiveTab(estado.id);
                                    }}
                                  >
                                    {t(estado.id)}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </Nav>
                        </Col>
                        <Col md="4" xs="9">
                          <Button
                            type="button"
                            color="none"
                            className="btn boton-filtrar-fecha no-hover"
                            onClick={() => {
                              setModalCreacionUsuarios(true);
                            }}
                          >
                            <i
                              className="bx bx-user-plus font-size-24 no-hover"
                              style={{ paddingTop: '25%' }}
                            />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Nav pills justified style={{ backgroundColor: 'transparent' }}>
                          <NavItem>
                            <NavLink
                              style={{ paddingLeft: 0 }}
                            >
                              <span className="d-sm-block">{t('Assigned to')}</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className="no-hover"
                              style={{ paddingLeft: 0 }}
                            >
                              {!asignadasFiltrar && (
                                <span className="d-sm-block">{t('All')}</span>
                              )}
                              {asignadasFiltrar?.id_deptos_equipo && (
                                <span className="d-sm-block">{asignadasFiltrar?.descripcion}</span>
                              )}
                              {asignadasFiltrar?.id && (
                                <span className="d-sm-block">{asignadasFiltrar?.nombre}</span>
                              )}
                            </NavLink>
                          </NavItem>
                          <>
                            <Dropdown isOpen={asignadasMenu} toggle={toggleAsignadas} direction="down">
                              <DropdownToggle className="btn nav-btn no-hover" tag="span" style={{ padding: '5px' }}>
                                <i className="bx bx-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                {asignadasFiltrar && (
                                <DropdownItem
                                  key="all"
                                  onClick={() => {
                                    setAsignadasFiltrar();
                                  }}
                                >
                                  {t('All')}
                                </DropdownItem>
                                )}
                                {map(agentes.filter(a => a.id !==
                                      asignadasFiltrar?.id), (a) => (
                                        <DropdownItem
                                          key={`$asignadasFilter${a.id}`}
                                          onClick={() => {
                                            setAsignadasFiltrar(
                                              { id: a.id, nombre: a.nombre }
                                            );
                                          }}
                                        >
                                          {a.nombre}
                                        </DropdownItem>
                                ))}
                                {map(departamentos.filter(d => d.id_deptos_equipo !==
                                    asignadasFiltrar?.id_deptos_equipo), (departamento) => (
                                      <DropdownItem
                                        key={`$asignadasFilter${departamento.id_deptos_equipo}`}
                                        onClick={() => {
                                          setAsignadasFiltrar(
                                            { id_deptos_equipo: departamento.id_deptos_equipo,
                                              descripcion: t(departamento?.descripcion)
                                            }
                                          );
                                        }}
                                      >
                                        {t(departamento?.descripcion)}
                                      </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </>
                        </Nav>
                      </Row>
                      <TabContent activeTab={activeTab} className="py-4">
                        <div>
                          <ul className="list-unstyled chat-list">
                            <PerfectScrollbar style={{ height: '410px' }} id="scrollableDiv">
                              <InfiniteScroll
                                dataLength={conversaciones.length}
                                next={cargarMasConversaciones}
                                hasMore={masConversaciones}
                                loader={(
                                  <div className="align-self-center me-3" style={{ textAlign: 'center' }}>
                                    <i
                                      className="bx bx-loader-circle bx-spin font-size-22"
                                      style={{ color: '#2A3042' }}
                                    />
                                  </div>
                                )}
                                scrollableTarget="scrollableDiv"
                              >
                                {map(conversaciones.filter(c => {
                                  if (asignadasFiltrar?.id) {
                                    return c.conversations.asignado?.id ===
                                    asignadasFiltrar?.id;
                                  }
                                  if (asignadasFiltrar?.id_deptos_equipo) {
                                    return c.conversations.asignado?.id ===
                                    asignadasFiltrar?.id_deptos_equipo;
                                  }
                                  return true;
                                }), (chat, index) => (
                                  <li
                                    key={`${chat?.conversations?.idConversacion}}`}
                                    className={chat?.conversations?.idConversacion === conversacionActiva?.conversations?.idConversacion ? 'active' : ''}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setConversacionActiva(
                                          structuredClone(conversaciones[index])
                                        );
                                        setListadoPlantillas(false);
                                        setMostrarPlantilla(false);
                                      }}
                                    >
                                      <Media>
                                        <div className="align-self-center me-3">
                                          <i
                                            className={
                                              chat?.cliente?.ultimaConexion === 'En linea' ?
                                                'mdi mdi-circle text-success font-size-10' :
                                                'mdi mdi-circle font-size-10'
                                            }
                                          />
                                        </div>
                                        <div className="avatar-xs align-self-center me-3">
                                          <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                            {
                                              chat
                                                .cliente
                                                ?.nombre
                                                ?.trim().substring(0, 1)
                                                .toUpperCase()
                                            }
                                          </span>
                                        </div>

                                        <Media className="overflow-hidden" body>
                                          <h5 className="text-truncate font-size-14 mb-1 nombre-capitalize">
                                            {chat.cliente?.nombre?.trim().toLowerCase()}
                                          </h5>
                                          <p className="text-truncate mb-0">{chat.conversations?.mensajes?.slice(-1)[0]?.mensaje?.substring(0, 20)}</p>
                                        </Media>
                                        <Media
                                          className="overflow-hidden text-end"
                                          body
                                          style={{ marginRight: '0.5em' }}
                                        >
                                          {chat.conversations?.noLeido && (
                                            <i
                                              className="bx bxs-circle font-size-20"
                                              style={{ color: '#25D366' }}
                                            />
                                          )}
                                          {chat.conversations?.mensajes
                                            .filter(val => {
                                              return val.id &&
                                                  val.enviadoPor ===
                                                  chat.conversations.idCliente &&
                                                  !val.leido;
                                            }).length !== 0 && (
                                            <p className="unread-number mb-0">
                                              {chat.conversations?.mensajes
                                                .filter(val => {
                                                  return val.id &&
                                                    val.enviadoPor ===
                                                    chat.conversations.idCliente &&
                                                    !val.leido;
                                                }).length}
                                            </p>
                                          )}
                                          {chat.conversations?.situacion === 'EN_ESPERA' && (
                                            <i
                                              className="bx bx-pause-circle font-size-20"
                                              style={{ color: '#735392' }}
                                            />
                                          )}
                                          {chat.conversations?.situacion === 'ACCION_REQUERIDA' && (
                                            <i
                                              className="bx bxs-error-alt font-size-20"
                                              style={{ color: '#ff8c00' }}
                                            />
                                          )}
                                          {chat.conversations?.mensajes?.slice(-1)[0] &&
                                            moment.duration(
                                              moment(new Date())
                                                .diff(
                                                  moment(
                                                    chat.conversations
                                                      ?.mensajes?.slice(-1)[0]?.horaEnvio
                                                  )
                                                )
                                            ).asHours() < 24 && (
                                            <div className="font-size-11">{moment(chat.conversations?.mensajes?.slice(-1)[0]?.horaEnvio).format('HH:mm')}</div>
                                          )}
                                          {chat.conversations?.mensajes?.slice(-1)[0] &&
                                            moment.duration(
                                              moment(new Date())
                                                .diff(
                                                  moment(
                                                    chat.conversations
                                                      ?.mensajes?.slice(-1)[0]?.horaEnvio
                                                  )
                                                )
                                            ).asHours() > 24 && (
                                            <div className="font-size-11">{moment(chat.conversations?.mensajes?.slice(-1)[0]?.horaEnvio).format('DD/MM/YY')}</div>
                                          )}
                                        </Media>
                                      </Media>
                                    </Link>
                                  </li>
                                ))}
                              </InfiniteScroll>
                            </PerfectScrollbar>
                          </ul>
                        </div>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  <Card>
                    {conversacionActiva && (
                      <div className="p-4 border-bottom">
                        <Row>
                          <Col md="10" xs="9">
                            <h5 className="font-size-15 mb-1 nombre-capitalize">
                              <a
                                style={{ textDecoration: 'none', color: '#495057' }}
                                target="_blank"
                                href={`${process.env.REACT_APP_HOST_PANEL}/listado/ficha_visitante/${conversacionActiva.cliente?.fichaCliente}`}
                                rel="noreferrer"
                              >
                                {conversacionActiva?.cliente?.nombre?.toLowerCase()}
                              </a>
                              <i
                                className="bx bx-pencil align-middle ms-2"
                                onClick={() => {
                                  editarUsuario(conversacionActiva.cliente?.idUsuarios);
                                }}
                              />
                            </h5>

                            <p className="text-muted mb-0">
                              {conversacionActiva?.cliente.email && (
                                <>
                                  <i className="bx bx-envelope align-middle me-1" />
                                  {conversacionActiva?.cliente.email}
                                </>
                              )}
                              {conversacionActiva?.cliente.telefono && (
                                <>
                                  <i className="bx bx-phone align-middle ms-1 me-1" />
                                  {conversacionActiva?.cliente.telefono}
                                </>
                              )}
                              {conversacionActiva?.cliente.locale && (
                                <>
                                  {' lang: '}
                                  {conversacionActiva
                                    ?.cliente.locale?.toLowerCase().substring(0, 2)}
                                </>
                              )}
                            </p>
                          </Col>
                          <Col md="2" xs="3">
                            <ul className="list-inline user-chat-nav text-end mb-0">
                              <li className="list-inline-item">
                                <Dropdown isOpen={otherMenu} toggle={toggleOther}>
                                  <DropdownToggle className="btn nav-btn no-hover" tag="i">
                                    <i className="bx bx-dots-horizontal-rounded" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem target="_blank" href={`${process.env.REACT_APP_HOST_PANEL}/listado/ficha_visitante/${conversacionActiva.cliente?.fichaCliente}`}>{t('Customers\' file')}</DropdownItem>
                                    <DropdownItem onClick={() => { setModalListaFicheros(true); }}>{t('Files')}</DropdownItem>
                                    <DropdownItem onClick={() => { editarUsuario(conversacionActiva.cliente?.idUsuarios); }}>{t('Edit customer')}</DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {conversacionActiva && (
                      <div className="p-2 border-bottom">
                        <Row>
                          <Col md="4" xs="6">
                            <Row>
                              <Col sm={{ size: 'auto' }} style={{ paddingRight: '0' }}>
                                <span
                                  className="btn no-hover"
                                  style={{
                                    width: 'fit-content',
                                    fontWeight: 'bold',
                                    paddingRight: '0',
                                    cursor: 'default'
                                  }}
                                >
                                  {t('Assigned')}
                                </span>
                              </Col>
                              <Col
                                sm={{ size: 'auto' }}
                                style={{ paddingRight: '0', paddingLeft: '0' }}
                              >
                                <span
                                  className="btn no-hover"
                                  style={{
                                    width: 'fit-content',
                                    paddingRight: '0',
                                    paddingLeft: '2',
                                    cursor: 'default'
                                  }}
                                >
                                  {conversacionActiva.conversations.asignado?.tipo === 'departamento' && (
                                    departamentos.find(
                                      d => d.id_deptos_equipo ===
                                        conversacionActiva.conversations.asignado.id
                                    )?.descripcion
                                  )}
                                  {conversacionActiva.conversations.asignado?.tipo === 'agente' && (
                                    agentes.find(
                                      a => a.id ===
                                        conversacionActiva.conversations.asignado.id
                                    )?.nombre
                                  )}
                                  {!conversacionActiva.conversations.asignado && (
                                    t('Not assigned')
                                  )}
                                </span>
                              </Col>
                              <Col
                                sm={{ size: 'auto' }}
                                style={{ paddingRight: '0', paddingLeft: '0' }}
                              >
                                <Dropdown
                                  className=""
                                  isOpen={assignMenu}
                                  toggle={toggleAssign}
                                >
                                  <DropdownToggle className="btn nav-btn no-hover" tag="span">
                                    <i className="bx bx-chevron-down" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-start"
                                    style={{ width: 'fit-content' }}
                                  >
                                    {departamentos.length > 0 && (
                                      <>
                                        <DropdownItem
                                          header
                                        >
                                          {`${t('Department')}:`}
                                        </DropdownItem>
                                        {departamentos.map(departamento => {
                                          return (
                                            <div
                                              key={`departamento${departamento.id_deptos_equipo}`}
                                              className="py-0"
                                              style={{ paddingLeft: '2vw', paddingRight: '1vw', whiteSpace: 'nowrap' }}
                                            >
                                              <Input
                                                onChange={() => {
                                                  setAsignado({ tipo: 'departamento', id: departamento.id_deptos_equipo });
                                                }}
                                                type="radio"
                                                id={`departamento${departamento.id_deptos_equipo}`}
                                                name="asignacion"
                                                defaultChecked={
                                                  conversacionActiva.conversations.asignado?.id ===
                                                  departamento.id_deptos_equipo &&
                                                  conversacionActiva.conversations.asignado?.tipo === 'departamento'
                                                }
                                              />
                                              <label
                                                htmlFor={`departamento${departamento.id_deptos_equipo}`}
                                                className="p-1"
                                                style={{ fontWeight: 400 }}
                                              >
                                                {departamento?.descripcion}
                                              </label>
                                            </div>
                                          );
                                        })}
                                      </>
                                    )}
                                    <DropdownItem header>{`${t('Agent')}:`}</DropdownItem>
                                    {agentes.map(agente => {
                                      return (
                                        <div
                                          key={`agente${agente.id}`}
                                          className="py-0"
                                          style={{ paddingLeft: '2vw', paddingRight: '1vw', whiteSpace: 'nowrap' }}
                                        >
                                          <Input
                                            onChange={() => { setAsignado({ tipo: 'agente', id: agente.id }); }}
                                            type="radio"
                                            id={`agente${agente.id}`}
                                            name="asignacion"
                                            defaultChecked={
                                              conversacionActiva.conversations.asignado?.id ===
                                              agente.id &&
                                              conversacionActiva.conversations.asignado?.tipo === 'agente'
                                            }
                                          />
                                          <label
                                            htmlFor={`agente${agente.id}`}
                                            className="p-1"
                                            style={{ fontWeight: 400 }}
                                          >
                                            {agente.nombre}
                                          </label>
                                        </div>
                                      );
                                    })}
                                    <DropdownItem divider />
                                    <div className="py-0" style={{ paddingLeft: '2vw', paddingRight: '1vw', whiteSpace: 'nowrap' }}>
                                      <Input
                                        defaultChecked={checkNoLeido}
                                        onChange={event => {
                                          setCheckNoLeido(event.target.checked);
                                        }}
                                        type="checkbox"
                                      />
                                      <label
                                        htmlFor="noLeido"
                                        className="p-1"
                                        style={{ fontWeight: 400 }}
                                      >
                                        {t('Mark as unread')}
                                      </label>
                                    </div>
                                    <div className="py-0" style={{ paddingLeft: '2vw', paddingRight: '1vw', whiteSpace: 'nowrap' }}>
                                      <Input
                                        defaultChecked={checkAccionRequerida}
                                        onChange={event => {
                                          setCheckAccionRequerida(event.target.checked);
                                        }}
                                        type="checkbox"
                                      />
                                      <label
                                        htmlFor="accionRequerida"
                                        className="p-1"
                                        style={{ fontWeight: 400 }}
                                      >
                                        {t('Mark as action required')}
                                      </label>
                                    </div>
                                    <DropdownItem className="text-center" onClick={() => { asignar(); }}>
                                      {t('Ok')}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6" xs="3" style={{ marginLeft: 'auto' }}>
                            <Row className="justify-content-end">
                              <Col sm={{ size: 'auto' }} style={{ paddingRight: '0' }}>
                                <span
                                  className="btn no-hover"
                                  style={{
                                    width: 'fit-content',
                                    fontWeight: 'bold',
                                    paddingRight: '0',
                                    cursor: 'default'
                                  }}
                                >
                                  {t('Status')}
                                  {':'}
                                </span>
                              </Col>
                              <Col
                                sm={{ size: 'auto' }}
                                style={{ paddingRight: '0', paddingLeft: '0' }}
                              >
                                <span
                                  className="btn no-hover"
                                  style={{
                                    width: 'fit-content',
                                    paddingRight: '0',
                                    paddingLeft: '2',
                                    cursor: 'default'
                                  }}
                                >
                                  {!conversacionActiva.conversations?.estadoActual && (
                                    t('Without status')
                                  )}
                                  {conversacionActiva.conversations?.estadoActual && (
                                    t(conversacionActiva.conversations?.estadoActual)
                                  )}
                                </span>
                              </Col>
                              <Col sm={{ size: 'auto' }}>
                                <Dropdown isOpen={estadosMenu} toggle={toggleEstados}>
                                  <DropdownToggle className="btn nav-btn no-hover" tag="span">
                                    <i className="bx bx-chevron-down" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    {map(estados.filter(e => e.id !==
                                        conversacionActiva.conversations?.estadoActual),
                                    (estado) => (
                                      <DropdownItem
                                        key={estado.id}
                                        onClick={() => {
                                          setModalConfirmacionCambioEstado(true);
                                          setEstadoCambio(estado.id);
                                        }}
                                      >
                                        {t(estado.id)}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                              <Col sm={{ size: 'auto' }}>
                                <Dropdown isOpen={resolveMenu} toggle={toggleResolve}>
                                  <DropdownToggle className="btn nav-btn no-hover" tag="i">
                                    <i className="bx bx-dots-vertical-rounded" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => { marcarConversacionNoLeida(); }}>{t('Mark as unread')}</DropdownItem>
                                    {conversacionActiva.conversations?.situacion === 'ACCION_REQUERIDA' && (
                                    <DropdownItem onClick={() => { cambiarSituacionConversacion('ACCION_REQUERIDA', true); }}>{t('Unmark required action')}</DropdownItem>
                                    )}
                                    {conversacionActiva.conversations?.situacion !== 'ACCION_REQUERIDA' && (
                                    <DropdownItem onClick={() => { cambiarSituacionConversacion('ACCION_REQUERIDA'); }}>{t('Mark as action required')}</DropdownItem>
                                    )}
                                    {conversacionActiva.conversations?.situacion === 'EN_ESPERA' && (
                                    <DropdownItem onClick={() => { cambiarSituacionConversacion('EN_ESPERA', true); }}>{t('Unmark on hold')}</DropdownItem>
                                    )}
                                    {conversacionActiva.conversations?.situacion !== 'EN_ESPERA' && (
                                    <DropdownItem onClick={() => { cambiarSituacionConversacion('EN_ESPERA'); }}>{t('Mark on hold')}</DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    )}

                    <div>
                      <div className="chat-conversation p-3">
                        <ul className="list-unstyled">
                          <PerfectScrollbar
                            id="conversation-container"
                            style={{ height: '470px' }}
                          >
                            <div />
                            {conversacionActiva?.conversations?.mensajes && (
                              conversacionActiva.conversations.mensajes.map((mensaje, index) => (
                                <li
                                  key={`${mensaje.id}_${index}`}
                                  className={mensaje.enviadoPor !== conversacionActiva.conversations.idCliente ? 'right' : ''}
                                >
                                  <div className="conversation-list">
                                    {mensaje.enviadoPor ===
                                      conversacionActiva
                                        .conversations.idCliente && (
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            href="#"
                                            className="btn nav-btn no-hover"
                                            tag="i"
                                          >
                                            <i className="bx bx-dots-vertical-rounded" />
                                          </DropdownToggle>
                                          <DropdownMenu direction="right">
                                            <DropdownItem
                                              onClick={() => {
                                                translate(mensaje.id);
                                              }}
                                            >
                                              {t('Translate')}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                    <div className={`ctext-wrap${mensaje.notaPrivada ? ' nota-privada' : ''}`}>
                                      {mensaje.tipoAdjunto === 'audio' && (
                                        <div className="message-audio">
                                          <AudioPlayer
                                            id="audio-reproductor"
                                            customControlsSection={['MAIN_CONTROLS',
                                              'VOLUME_CONTROLS']}
                                            showSkipControls={false}
                                            showJumpControls={false}
                                            src={mensaje.url}
                                          />
                                        </div>
                                      )}
                                      {mensaje.tipoAdjunto === 'file' && (
                                      <div className="message-file">
                                        <a className="link-file" href={mensaje.url} title={t('Click to download')} target="_blank" rel="noopener noreferrer">
                                          <i className="bx bxs-download" />
                                          {' '}
                                          {mensaje.mensaje}
                                        </a>
                                      </div>
                                      )}
                                      {mensaje.idArchivo && (
                                      <div className="message-file">
                                        <a className="link-file" href={`${process.env.REACT_APP_API_DOMAIN}/api/descargaFichero?idConversacion=${conversacionActiva.conversations.idConversacion}&idMensaje=${mensaje.id}`} title={t('Click to download')} target="_blank" rel="noopener noreferrer">
                                          <i className="bx bxs-download" />
                                          {' '}
                                          {mensaje.mensaje}
                                        </a>
                                      </div>
                                      )}
                                      {mensaje.imgAdjunto && (
                                        <div className="message-text">
                                          <img
                                            className="imagen"
                                            alt=""
                                            ref={(el) => { myRefImg.current[index] = el; }}
                                            src={
                                              mensaje.imgPre && mensaje.imgAdjunto ?
                                                mensaje.imgPre :
                                                mensaje.imgAdjunto
                                            }
                                            onClick={() => {
                                              cargarImagen(index, mensaje.imgAdjunto);
                                            }}
                                          />
                                        </div>
                                      )}
                                      {!mensaje.idArchivo && mensaje.tipoAdjunto !== 'file' && mensaje.mensaje !== '' && mensaje.mensaje && (
                                      <div className="message-text" style={{ textAlign: 'left' }}>
                                        {mensaje.mensaje.split('\n').map((item, key) => {
                                          return (
                                            <span key={key}>
                                              {item}
                                              <br />
                                            </span>
                                          );
                                        })}
                                        {' '}
                                      </div>
                                      )}
                                      <p id={`mensaje_traducido_${mensaje.id}`} />
                                      <p className="chat-time mb-0">
                                        {moment(mensaje.horaEnvio).format('DD/MM/YY HH:mm')}
                                        {mensaje.source?.toLowerCase() === 'email' && (
                                          <>
                                            <i
                                              id={`email${mensaje.id}`}
                                              className="bx bx-envelope align-middle ms-1 me-1"
                                            />
                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`email${mensaje.id}`}
                                            >
                                              {t('By email')}
                                            </UncontrolledTooltip>
                                          </>
                                        )}
                                        {mensaje.source?.toLowerCase() === 'chat' && (
                                          <>
                                            <i
                                              id={`chat${mensaje.id}`}
                                              className="bx bxs-chat align-middle ms-1 me-1"
                                            />
                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`chat${mensaje.id}`}
                                            >
                                              {t('By chat')}
                                            </UncontrolledTooltip>
                                          </>
                                        )}
                                        {mensaje.source?.toLowerCase() === 'sms' && (
                                          <>
                                            <i
                                              id={`message${mensaje.id}`}
                                              className="bx bxs-message-square-detail align-middle ms-1 me-1"
                                            />
                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`message${mensaje.id}`}
                                            >
                                              {t('By message')}
                                            </UncontrolledTooltip>
                                          </>
                                        )}
                                        {mensaje.source?.toLowerCase() === 'whatsapp' && (
                                          <>
                                            <i
                                              id={`whatsapp${mensaje.id}`}
                                              className="bx bxl-whatsapp align-middle ms-1 me-1"
                                            />
                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`whatsapp${mensaje.id}`}
                                            >
                                              {t('By WhatsApp')}
                                            </UncontrolledTooltip>
                                          </>
                                        )}
                                        {mensaje.source?.toLowerCase() === 'feedback' && (
                                          <>
                                            <i
                                              id={`feedback${mensaje.id}`}
                                              className="bx bx-star align-middle ms-1 me-1"
                                            />
                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`feedback${mensaje.id}`}
                                            >
                                              {t('Feedback')}
                                            </UncontrolledTooltip>
                                          </>
                                        )}
                                        {mensaje.horaEnvio &&
                                          !mensaje.horaLectura &&
                                          mensaje.enviadoPor !==
                                            conversacionActiva
                                              .conversations.idCliente &&
                                            !mensaje.notaPrivada &&
                                          (
                                          <>
                                            <i
                                              id={`sent${mensaje.id}`}
                                              className="bx bx-check align-middle me-1"
                                            />
                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`sent${mensaje.id}`}
                                            >
                                              {t('Sent message')}
                                            </UncontrolledTooltip>
                                          </>
                                          )}
                                        {mensaje.horaLectura &&
                                          mensaje.enviadoPor !==
                                            conversacionActiva
                                              .conversations.idCliente &&
                                            !mensaje.notaPrivada &&
                                          (
                                          <>
                                            <i
                                              id={`read${mensaje.id}`}
                                              className="bx bx-check-double align-middle me-1"
                                            />
                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`read${mensaje.id}`}
                                            >
                                              {t('Read')}
                                            </UncontrolledTooltip>
                                          </>
                                          )}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ))
                            )}
                          </PerfectScrollbar>
                        </ul>
                      </div>
                      <div className="p-3 chat-input-section">
                        {((!listadoPlantillas && conversacionActiva) &&
                          (conversacionActiva && !mostrarPlantilla)) && (
                          <>
                            <Row>
                              <Col className="p-0">
                                <div className="position-relative">
                                  {!avisoWhatsapp && (
                                    <TextareaAutosize
                                      autoFocus
                                      maxRows={4}
                                      value={curMessage}
                                      onKeyPress={onKeyPress}
                                      onChange={(e) => {
                                        setcurMessage(e.target.value);
                                      }}
                                      className="form-control chat-input-reply px-1 pt-0 no-resize"
                                      placeholder="Responder"
                                    />
                                  )}
                                  {avisoWhatsapp && canal?.id === 'whatsapp-send' && (
                                    <div
                                      style={{
                                        padding: '1vh',
                                        backgroundColor: '#CEE6FF',
                                        borderRadius: '15px'
                                      }}
                                    >
                                      <Row>
                                        <Col
                                          sm={{ size: 'auto' }}
                                          style={{ marginRight: 'auto', fontSize: '20px' }}
                                        >
                                          <i className="bx bx-info-circle align-middle me-1" />
                                        </Col>
                                        <Col>
                                          <h6>
                                            {t('The 24 hours whatsapp interval has been closed')}
                                          </h6>
                                          <p>{t('Whatsapp warn long text')}</p>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="pt-1">
                              <Col className="col-auto pr-0">
                                <i
                                  id={canal?.id}
                                  style={{
                                    fontSize: '20px',
                                    backgroundColor: canal?.color,
                                    color: 'white',
                                    borderRadius: '25px',
                                    padding: '2.5px'
                                  }}
                                  className={canal?.icon}
                                />
                              </Col>
                              <Col className="col-auto px-0">
                                <Dropdown isOpen={channelMenu} toggle={toggleChannel}>
                                  <DropdownToggle className="btn nav-btn no-hover px-0" tag="i">
                                    <i className="bx bx-chevron-down" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    {canalWhatsappActivo && (
                                    <DropdownItem
                                      onClick={() => {
                                        onChangeCanal('whatsapp');
                                      }}
                                    >
                                      <i
                                        id="whatsapp-send"
                                        style={{
                                          fontSize: '20px',
                                          backgroundColor: '#25D366',
                                          color: 'white',
                                          borderRadius: '25px',
                                          padding: '3px'
                                        }}
                                        className="bx bxl-whatsapp align-middle"
                                      />
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="whatsapp-send"
                                      >
                                        {t('Whatsapp')}
                                      </UncontrolledTooltip>
                                    </DropdownItem>
                                    )}
                                    {canalEmailActivo && (
                                      <DropdownItem
                                        onClick={() => {
                                          onChangeCanal('email');
                                        }}
                                      >
                                        <i
                                          id="email-send"
                                          style={{
                                            fontSize: '20px',
                                            backgroundColor: '#4285F4',
                                            color: 'white',
                                            borderRadius: '25px',
                                            padding: '3px'
                                          }}
                                          className="bx bx-envelope align-middle"
                                        />
                                        <UncontrolledTooltip placement="bottom" target="email-send">
                                          {t('Email')}
                                        </UncontrolledTooltip>
                                      </DropdownItem>
                                    )}
                                    {/* Canal SMS desactivado */}
                                    {/* <DropdownItem
                                      onClick={() => {
                                        onChangeCanal('sms');
                                      }}
                                    >
                                      <i
                                        id="message-send"
                                        style={{
                                          fontSize: '20px',
                                          backgroundColor: '#EA4335',
                                          color: 'white',
                                          borderRadius: '25px',
                                          padding: '3px'
                                        }}
                                        className="bx bxs-message-square-detail align-middle"
                                      />
                                      <UncontrolledTooltip placement="bottom" target="message-send">
                                        {t('SMS')}
                                      </UncontrolledTooltip>
                                    </DropdownItem> */}
                                    {canalWebchatActivo && (
                                      <DropdownItem
                                        onClick={() => {
                                          onChangeCanal('chat');
                                        }}
                                      >
                                        <i
                                          id="chat-send"
                                          style={{
                                            fontSize: '20px',
                                            backgroundColor: '#FBBC05',
                                            color: 'white',
                                            borderRadius: '25px',
                                            padding: '3px'
                                          }}
                                          className="bx bxs-chat align-middle align-middle"
                                        />
                                        <UncontrolledTooltip placement="bottom" target="chat-send">
                                          {t('Chat')}
                                        </UncontrolledTooltip>
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                              <Col
                                className="col-auto"
                                sm={{ size: 'auto' }}
                                style={{ marginLeft: 'auto' }}
                              >
                                <Row className="align-items-center">
                                  <Col
                                    className="col-auto"
                                    style={{ borderRight: '2px solid grey' }}
                                  >
                                    <Button
                                      type="button"
                                      color="light"
                                      style={{ padding: '5px' }}
                                      onClick={() => togNotaInterna()}
                                      className="btn btn-outline-light w-md p-0"
                                    >
                                      <span className="d-sm-inline-block">
                                        {t('Add note')}
                                      </span>
                                    </Button>
                                  </Col>
                                  {avisoWhatsapp && (
                                    <Col
                                      className="col-auto"
                                    >
                                      <Button
                                        type="button"
                                        color="link"
                                        style={{ padding: '5px' }}
                                        onClick={() => { setListadoPlantillas(true); }}
                                        className="btn btn-outline-link w-md p-0"
                                      >
                                        <span className="d-none d-sm-inline-block">
                                          {t('Choose a template')}
                                        </span>
                                      </Button>
                                    </Col>
                                  )}
                                  {!avisoWhatsapp && (
                                    <>
                                      <Col className="col-auto px-1">
                                        <Button
                                          type="button"
                                          color="none"
                                          className="btn boton-filtrar-fecha no-hover p-0"
                                          onClick={() => {
                                            setMenuRespuestasRapidas(true);
                                          }}
                                        >
                                          <i className="bx bx-message-dots font-size-20 no-hover p-0" />
                                        </Button>
                                      </Col>
                                      <Col className="col-auto px-1">
                                        <input
                                          ref={fileRef}
                                          onChange={handleChangeFile}
                                          multiple={false}
                                          type="file"
                                          className="input-oculto"
                                          hidden
                                        />
                                        <Button
                                          type="button"
                                          color="none"
                                          disabled={canal?.name !== 'whatsapp'}
                                          className="btn boton-filtrar-fecha no-hover p-0"
                                          onClick={() => {
                                            fileRef.current.click();
                                          }}
                                        >
                                          <i className="bx bx-paperclip font-size-20 no-hover rotate-315 p-0" />
                                        </Button>
                                      </Col>
                                      <Col className="col-auto px-1">
                                        <Button
                                          type="button"
                                          color="none"
                                          className="btn boton-filtrar-fecha no-hover p-0"
                                          disabled
                                          onClick={() => {
                                            alert('Traducir');
                                          }}
                                        >
                                          <i className="fas fa-language font-size-20 no-hover p-0" />
                                        </Button>
                                      </Col>
                                      <Col className="col-auto px-1">
                                        <Button
                                          type="button"
                                          color="light"
                                          className="btn boton-filtrar-fecha no-hover px-1"
                                          onClick={() => {
                                            if (curMessage.trim() !== '') {
                                              enviarMensaje();
                                              setcurMessage('');
                                            }
                                          }}
                                        >
                                          <i
                                            className="bx bx-send font-size-20 no-hover rotate-315 p-0"
                                            style={{ verticalAlign: 'middle' }}
                                          />
                                        </Button>
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </>
                        )}
                        {listadoPlantillas && (
                          <div>
                            <Row>
                              <Col xs="1">
                                <Button
                                  type="button"
                                  style={{ position: 'relative' }}
                                  onClick={() => {
                                    setListadoPlantillas(false);
                                  }}
                                  className="close"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </Button>
                              </Col>
                              <Col
                                xs="6"
                                style={{
                                  textAlign: 'center',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <h6 style={{ position: 'absolute', top: '25%' }}>
                                  {t('Choose a template')}
                                </h6>
                              </Col>
                              <Col xs="3">
                                <Select
                                  value={selectLanguage}
                                  placeholder={t('Language')}
                                  onChange={event => {
                                    handleSelectLanguage(event);
                                  }}
                                  options={languages}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                              <Col xs="2">
                                <Input
                                  value={filterSearchTemplate}
                                  onChange={e => setFilterSearchTemplate(e.target.value)}
                                  type="text"
                                  className="form-control"
                                  placeholder={t('Search')}
                                />
                              </Col>
                            </Row>
                            <Row className="pt-2">
                              <hr className="mb-3" />
                            </Row>
                            <Row
                              className="px-2"
                              style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}
                            >
                              {plantillasFiltetered.map((plantilla, index) => {
                                return (
                                  <div key={`${plantilla.nombre}_${plantilla.idioma}`}>
                                    <Row>
                                      <Col sm={{ size: 'auto' }}>
                                        <Button
                                          type="button"
                                          color="none"
                                          className="btn boton-filtrar-fecha no-hover"
                                          onClick={() => {
                                            setPlantillaParaEnviar(
                                              plantillasFiltetered[index]
                                            );
                                            setPlantillaPlaceholders(
                                              plantillasFiltetered[index]
                                            );
                                            setMostrarPlantilla(true);
                                            setListadoPlantillas(false);
                                          }}
                                        >
                                          <h6 style={{ textAlign: 'left' }}>{plantilla.nombre}</h6>
                                          <p
                                            style={{
                                              textAlign: 'justify',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              width: '40vw'
                                            }}
                                          >
                                            {plantilla.cuerpo}
                                          </p>
                                        </Button>
                                      </Col>
                                    </Row>
                                    {plantillasFiltetered.length !== index + 1 && (
                                      <hr
                                        className="mb-3"
                                        style={{ width: '95%', margin: '0 auto' }}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                            </Row>
                          </div>
                        )}
                        {mostrarPlantilla && (
                          <div>
                            <Row>
                              <Col xs="1">
                                <Button
                                  type="button"
                                  style={{ position: 'relative' }}
                                  onClick={() => {
                                    setListadoPlantillas(false);
                                    setMostrarPlantilla(false);
                                    setBotonEnviarPlantillaEnabled(false);
                                    setPlaceholderValues([]);
                                  }}
                                  className="close"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </Button>
                              </Col>
                              <Col
                                xs="6"
                                style={{
                                  textAlign: 'center',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <h6 style={{ position: 'absolute', top: '25%' }}>
                                  {t('Edit custom template')}
                                </h6>
                              </Col>
                            </Row>
                            <Row className="pt-2">
                              <hr className="mb-3" />
                            </Row>
                            <Row
                              className="px-2 pb-2"
                              style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}
                            >
                              <Row style={{ width: '35vw' }}>
                                <div className="chat-conversation p-3">
                                  <div className="conversation-list mb-1">
                                    <div className="ctext-wrap">
                                      <p><b>{plantillaPlaceholders.cabecera}</b></p>
                                      <p>{plantillaPlaceholders.cuerpo}</p>
                                      <p style={{ color: 'rgb(104, 121, 146)' }}>{plantillaPlaceholders.pie}</p>
                                      <div className="contenedor-botones">
                                        {map(plantillaPlaceholders.botones, (b, index2) => {
                                          return (
                                            <div className="boton" key={`boton_${index2}`}>
                                              {b.texto}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                {cabeceraPlantillaTienePlaceolder && (
                                  <Col key="placeholderCabecera" className="p-0" md="4" xs="4">
                                    <Col md="12" xs="12" className="px-1 pb-2">
                                      <label htmlFor="placeholderCabecera" className="p-1">
                                        {t('Custom header field:')}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control boton"
                                        style={{ textAlign: 'left' }}
                                        onChange={e => setPlaceHolderCabecera(e.target.value)}
                                      />
                                    </Col>
                                  </Col>
                                )}
                                {[...Array(placeholdersPlantilla)].map((x, i) => (
                                  <Col key={`placeholder${i}`} className="p-0" md="4" xs="4">
                                    <Col md="12" xs="12" className="px-1 pb-2">
                                      <label htmlFor={`placeholder${i + 1}`} className="p-1">
                                        {`Campo personalizado ${i + 1}:`}
                                      </label>
                                      <input
                                        id={`placeholder${i + 1}`}
                                        key={i + 1}
                                        type="text"
                                        className="form-control boton"
                                        style={{ textAlign: 'left' }}
                                        onChange={e => setPlaceHolder(i, e.target.value)}
                                      />
                                    </Col>
                                  </Col>
                                ))}
                              </Row>
                            </Row>
                            <Row className="pt-3">
                              <Col className="pl-1" style={{ textAlign: 'left' }}>
                                <Button
                                  type="button"
                                  color="none"
                                  className="btn boton-filtrar-fecha no-hover w-md p-1"
                                  onClick={() => {
                                    setListadoPlantillas(true);
                                    setMostrarPlantilla(false);
                                  }}
                                >
                                  {t('Back')}
                                </Button>
                              </Col>
                              <Col className="pr-1" style={{ textAlign: 'right' }}>
                                <Button
                                  color="light"
                                  className="btn btn-outline-light w-md p-1"
                                  id="boton-enviar-plantilla"
                                  type="button"
                                  disabled={!botonEnviarPlantillaEnabled}
                                  onClick={() => {
                                    envioPlantillaWhatsapp();
                                  }}
                                >
                                  {t('Send Whatsapp')}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 mt-5">
              <Card body className="puntos">
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Messaging Center')}</span>
                  <br />
                  <div className="mt-3">
                    <span>{t('Conversations can be tagged with the following tags:')}</span>
                  </div>
                  <div>
                    <i
                      className="bx bx-pause-circle font-size-20 mx-1"
                      style={{ color: '#735392', verticalAlign: 'middle' }}
                    />
                    <span>{t('Identifies a conversation that is waiting for the customer to respond.')}</span>
                  </div>
                  <div>
                    <i
                      className="bx bxs-error-alt font-size-20 mx-1"
                      style={{ color: '#ff8c00', verticalAlign: 'middle' }}
                    />
                    <span>{t('Identifies a conversation that has pending action on our part.')}</span>
                  </div>
                  <div>
                    <i
                      className="bx bxs-circle font-size-20 mx-1"
                      style={{ color: '#25D366', verticalAlign: 'middle' }}
                    />
                    <span>{t('Identifies a conversation that has been manually marked as unread to be dealt with later or by another agent.')}</span>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          size="lg"
          isOpen={modalNotaInterna}
          centered
          toggle={() => {
            togNotaInterna(!notaInterna);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="modalAddNote">
              {t('Add note')}
            </h5>
            <button
              type="button"
              onClick={() => {
                setModalNotaInterna(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <textarea
              placeholder={t(
                'Leave an internal note for this conversation. Only you and other agents will see it.'
              )}
              className="no-resize"
              style={{
                width: '100%',
                height: '15vh',
                boxSizing: 'border-box',
                border: 'none',
                outline: 'none'
              }}
              onChange={(e) => {
                setNotaInterna(e.target.value);
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                if (notaInterna.trim() !== '') {
                  enviarNotaPrivada();
                }
                setModalNotaInterna(false);
              }}
              type="button"
              className="btn btn-primary"
            >
              {t('Save')}
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={modalCreacionUsuarios}
          toggle={() => {
            setModalCreacionUsuarios(!modalCreacionUsuarios);
          }}
        >
          <div className="modal-header">
            {validationUserData.values.id !== '' ? (
              <h5 className="modal-title" id="newContactModal">
                {t('Edit contact')}
              </h5>
            ) : (
              <h5 className="modal-title" id="newContactModal">
                {t('New contact')}
              </h5>
            )}
            <button
              type="button"
              onClick={() => {
                setModalCreacionUsuarios(false);
                validationUserData.resetForm();
              }}
              className="btn-close"
            />
          </div>
          <div className="modal-body">
            <Form
              id="form-add-user"
              onSubmit={e => {
                e.preventDefault();
                validationUserData.handleSubmit();
                return false;
              }}
            >
              <div className="mb-3">
                <Row>
                  <label htmlFor="new-contact-name" className="col-form-label">
                    {t('First name')}
                    :
                  </label>
                </Row>
                <Input
                  type="text"
                  name="nombre"
                  className="form-control"
                  id="horizontal-nombre-Input"
                  onChange={event => {
                    validationUserData.setFieldValue(
                      'nombre',
                      event.target.value
                    );
                  }}
                  onBlur={validationUserData.handleBlur}
                  value={validationUserData.values.nombre || ''}
                  invalid={
                    !!(
                      validationUserData.touched.nombre &&
                      validationUserData.errors.nombre
                    )
                  }
                />
                {validationUserData.touched.nombre &&
                validationUserData.errors.nombre ? (
                  <FormFeedback type="invalid">
                    {validationUserData.errors.nombre}
                  </FormFeedback>
                  ) : null}
                <label htmlFor="new-contact-lastname" className="col-form-label">
                  {t('Last name')}
                  :
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="new-contact-lastname"
                  value={validationUserData.values.apellidos || ''}
                  onChange={event => {
                    validationUserData.setFieldValue(
                      'apellidos',
                      event.target.value
                    );
                  }}
                />
                <label htmlFor="new-contact-email" className="col-form-label">
                  {t('Email')}
                  :
                </label>
                <Input
                  type="text"
                  name="email"
                  className="form-control"
                  id="new-contact-email"
                  onChange={event => {
                    validationUserData.setFieldValue(
                      'email',
                      event.target.value
                    );
                  }}
                  onBlur={validationUserData.handleBlur}
                  value={validationUserData.values.email || ''}
                  invalid={
                    !!(
                      validationUserData.touched.email &&
                      validationUserData.errors.email
                    )
                  }
                />
                {validationUserData.touched.email &&
                validationUserData.errors.email ? (
                  <FormFeedback type="invalid">
                    {validationUserData.errors.email}
                  </FormFeedback>
                  ) : null}
                <label htmlFor="new-contact-phone" className="col-form-label">
                  {t('Phone')}
                  :
                </label>
                <Row>
                  <Col
                    sm={2}
                    xs={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '5vh'
                    }}
                  >
                    <Flag
                      code={prefixCountry}
                      height="70%"
                    />
                  </Col>
                  <Col sm={3} xs={4}>
                    <select
                      className="form-select"
                      id="horizontal-prefix-Input"
                      onChange={event => {
                        setPrefixCountry(event.target.value);
                      }}
                      value={validationUserData.values.prefix || prefixCountry}
                    >
                      {map(prefixList, (country) => (
                        <option key={country.code} value={country.code}>
                          {country.dial_code}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={7} xs={6}>
                    <Input
                      type="text"
                      name="telefono"
                      className="form-control"
                      id="new-contact-phone"
                      onChange={event => {
                        validationUserData.setFieldValue(
                          'telefono',
                          event.target.value
                        );
                      }}
                      onBlur={validationUserData.handleBlur}
                      value={validationUserData.values.telefono || ''}
                      invalid={
                        !!(
                          validationUserData.touched.telefono &&
                          validationUserData.errors.telefono
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 7, offset: 5 }}>
                    <Input
                      type="hidden"
                      invalid={
                          !!(
                            validationUserData.touched.telefono &&
                            validationUserData.errors.telefono
                          )
                        }
                    />
                    {validationUserData.touched.telefono &&
                    validationUserData.errors.telefono ? (
                      <FormFeedback type="invalid">
                        {validationUserData.errors.telefono}
                      </FormFeedback>
                      ) : null}
                  </Col>
                </Row>
                <label htmlFor="new-contact-language" className="col-form-label">
                  {t('Language')}
                  :
                </label>
                <select
                  className="form-select"
                  id="horizontal-language-Input"
                  onChange={event => {
                    validationUserData.setFieldValue(
                      'idioma',
                      event.target.value
                    );
                  }}
                  value={validationUserData.values.idioma || ''}
                >
                  {map(languageList, (language, indexLanguage) => (
                    <option key={indexLanguage} value={indexLanguage}>
                      {indexLanguage.toUpperCase()}
                      {' '}
                      {t(language)}
                    </option>
                  ))}
                </select>
                {validationUserData.touched.idioma &&
                validationUserData.errors.idioma ? (
                  <FormFeedback type="invalid">
                    {validationUserData.errors.idioma}
                  </FormFeedback>
                  ) : null}
                <div className="mb-3">
                  <label htmlFor="observations-text" className="col-form-label">
                    {t('Observations')}
                    :
                  </label>
                  <textarea
                    className="form-control"
                    id="observations-text"
                    value={validationUserData.values.observaciones || ''}
                    onChange={event => {
                      validationUserData.setFieldValue(
                        'observaciones',
                        event.target.value
                      );
                    }}
                  />
                  {validationUserData.touched.observaciones &&
                  validationUserData.errors.observaciones ? (
                    <FormFeedback type="invalid">
                      {validationUserData.errors.observaciones}
                    </FormFeedback>
                    ) : null}
                </div>
                {validationUserData.values.id === '' && (
                <Row>
                  <Col sm={6} className="form-check-label">
                    <label htmlFor="new-conversation-open-conversation" className="col-form-label">
                      {t('Open conversation on completion')}
                      :
                    </label>
                  </Col>
                  <Col sm={2} className="form-check-label pt-1">
                    <div className="form-check form-switch form-switch-md">
                      <input
                        checked={checkOpenConversation}
                        type="checkbox"
                        className="form-control form-check-input"
                        id="switch-open-conversation"
                        onChange={event => {
                          setCheckOpenConversation(event.target.checked);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                )}
              </div>
            </Form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setModalCreacionUsuarios(false);
                validationUserData.resetForm();
              }}
            >
              {t('Cancel')}
            </button>
            <button
              className="btn btn-primary"
              type="submit"
              form="form-add-user"
            >
              {t('Save')}
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={modalListaFicheros}
          toggle={() => {
            setModalListaFicheros(!modalListaFicheros);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="filesModalLabel">{t('Files')}</h5>
            <button
              type="button"
              onClick={() => {
                setModalListaFicheros(false);
              }}
              className="btn-close"
            />
          </div>
          <div className="modal-body">
            <p className="text-muted mb-1 pb-2 nombre-capitalize">{conversacionActiva?.cliente.nombre?.toLowerCase()}</p>

            <p className="text-muted mb-0">
              {conversacionActiva?.cliente.email && (
                <>
                  <i className="bx bx-envelope align-middle me-1" />
                  {conversacionActiva?.cliente.email}
                </>
              )}
              {conversacionActiva?.cliente.telefono && (
                <>
                  <i className="bx bx-phone align-middle ms-1 me-1" />
                  {conversacionActiva?.cliente.telefono}
                </>
              )}
              {conversacionActiva?.cliente.locale && (
                <>
                  {' lang: '}
                  {conversacionActiva
                    ?.cliente.locale?.toLowerCase().substring(0, 2)}
                </>
              )}
            </p>
            { ficheros.length > 0 && (
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={ficheros}
              >
                {({ paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={ficheros}
                    search
                  >
                    {toolkitProps => (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive tabla-datos">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes="table align-middle table-nowrap"
                                headerWrapperClasses="thead-light"
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
            { ficheros.length === 0 && (
            <h6 className="mb-1 pt-3 pb-2">{t('There are no files')}</h6>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setModalListaFicheros(false);
              }}
            >
              {t('Close')}
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={modalConfirmacionCambioEstado}
          centered
          toggle={() => {
            setModalConfirmacionCambioEstado(!modalConfirmacionCambioEstado);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="modalAddNote">
              {t('Change status')}
            </h5>
            <button
              type="button"
              onClick={() => {
                setModalConfirmacionCambioEstado(!false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              {t('Are you sure that you want to update the conversation status to ') + t(estadoCambio)}
              {' '}
              {'?'}
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setModalConfirmacionCambioEstado(false);
              }}
            >
              {t('Cancel')}
            </button>
            <button
              onClick={() => {
                cambiarEstadoConversacion(estadoCambio);
                setModalConfirmacionCambioEstado(false);
                setEstadoCambio(null);
              }}
              type="button"
              className="btn btn-primary"
            >
              {t('Change')}
            </button>
          </div>
        </Modal>
        <Offcanvas
          isOpen={menuRespuestasRapidas}
          direction="end"
          scrollable
          toggle={toggleRightCanvas}
        >
          <OffcanvasHeader className="mt-3" toggle={toggleRightCanvas}>
            {t('Quick replies')}
          </OffcanvasHeader>
          <OffcanvasBody>
            {Object.keys(respuestasRapidas).length > 0 ? (
              map(respuestasRapidas, (respuestas, indexRespuesta) => (
                <UncontrolledDropdown
                  direction="down"
                  className="mb-2"
                  key={indexRespuesta}
                  onClick={() => {
                    const desplegableRespuestasRapidasAux =
                      structuredClone(desplegableRespuestasRapidas);
                    const estadoActualDesplegable = desplegableRespuestasRapidasAux[indexRespuesta];
                    Object.keys(desplegableRespuestasRapidasAux).forEach(r => {
                      desplegableRespuestasRapidasAux[r] = false;
                    });
                    desplegableRespuestasRapidasAux[indexRespuesta] =
                      !estadoActualDesplegable;
                    setDesplegableRespuestasRapidas(desplegableRespuestasRapidasAux);
                  }}
                >
                  <DropdownToggle
                    tag="a"
                    caret
                  >
                    {desplegableRespuestasRapidas[indexRespuesta] ? (
                      <i className="bx bx-caret-down" />
                    ) : (
                      <i className="bx bx-caret-right" />
                    )}
                    {indexRespuesta}
                  </DropdownToggle>
                  <ul
                    style={{ listStyle: 'none', display: desplegableRespuestasRapidas[indexRespuesta] ? '' : 'none' }}
                  >
                    {respuestas.map(r => (
                      <li
                        className="mt-3"
                        key={r.titulo}
                        // onClick={() => { setcurMessage(r.contenido); toggleRightCanvas(); }}
                      >
                        {r.titulo}
                        {'('}
                        {typeof r.contenido !== 'string' &&
                        Object.keys(r.contenido).map((c, index) => {
                          return (
                            <>
                              <span
                                key={c}
                                onClick={() => {
                                  setcurMessage(r.contenido[c]);
                                  toggleRightCanvas();
                                }}
                                style={{ color: 'blue', textDecorationLine: 'underline', cursor: 'pointer' }}
                              >
                                {c.toUpperCase()}
                              </span>
                              {Object.keys(r.contenido).length - 1 !== index && (
                                <span>
                                  ,
                                </span>
                              )}
                            </>
                          );
                        })}
                        {')'}
                      </li>
                    ))}
                  </ul>
                </UncontrolledDropdown>
              ))) : (
                <p>
                  {t('No quick replies info')}
                  {' '}
                  <Link
                    to="/messagingcenter/respuestasRapidas"
                  >
                    {t('Link')}
                  </Link>
                  .
                </p>
            )}
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </>
  );
};

Inbox.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Inbox);
