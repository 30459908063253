import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledTooltip,
  CardText
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';
import moment from 'moment';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

const RegistroEnvios = props => {
  const { t } = props;
  const [logEnvios, setLogEnvios] = useState([]);

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargaDatos = async () => {
    try {
      const response = await axiosApi.get('/api/whatsapp/logenvios');
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setLogEnvios(data);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  const columns = [
    {
      dataField: 'fecha',
      text: t('Date'),
      sort: true,
      align: 'center',
      headerAlign: 'center'
    },
    {
      dataField: 'via',
      text: t('Channel'),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (value, row) => {
        return (
          <div>
            <i className="fab fa-whatsapp h4" id={`whatsapp-icon${row.id}`} />
            <UncontrolledTooltip placement="top" target={`whatsapp-icon${row.id}`}>
              WhatsApp
            </UncontrolledTooltip>
          </div>
        );
      }
    },
    {
      dataField: 'camp',
      text: t('Automation'),
      sort: true
    },
    {
      dataField: 'plantilla',
      text: t('Template'),
      sort: true
    },
    {
      dataField: 'recibido',
      headerAlign: 'center',
      align: 'center',
      text: t('Received'),
      sort: true,
      formatter: (value, row) => {
        if (value) {
          return (
            <div>
              <i className="bx bx-check-double h4" id={`whatsapp-received${row.id}`} />
              <UncontrolledTooltip placement="top" target={`whatsapp-received${row.id}`}>
                {t('The user has received the message')}
                {' ('}
                {moment(value).format('HH:mm:ss DD/MM/YYYY')}
                {')'}
              </UncontrolledTooltip>
            </div>
          );
        }
        return <></>;
      }
    },
    {
      dataField: 'leido',
      text: t('Read'),
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: (value, row) => {
        if (value) {
          return (
            <div>
              <i className="bx bx-check-double h4" style={{ color: '#34B7F1' }} id={`whatsapp-read${row.id}`} />
              <UncontrolledTooltip placement="top" target={`whatsapp-read${row.id}`}>
                {t('The user has read the message')}
                {' ('}
                {moment(value).format('HH:mm:ss DD/MM/YYYY')}
                {')'}
              </UncontrolledTooltip>
            </div>
          );
        }
        return <></>;
      }
    },
    {
      dataField: 'nombre',
      text: t('Name'),
      sort: true,
      headerStyle: { maxWidth: '25vh' },
      style: { maxWidth: '25vh',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line' }
    },
    {
      dataField: 'telefono',
      text: t('Phone'),
      sort: true
    },
    {
      dataField: 'idioma',
      text: t('Language'),
      sort: true
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: logEnvios.length,
    sizePerPageRenderer,
    custom: true,
    showTotal: true
  };

  document.title = `${t('Sent log')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Sent log')} breadcrumbItems={[{ title: t('Whatsapp') }, { title: t('Automation') }]} />

          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }} />
              </Row>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={logEnvios}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={logEnvios}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Whatsapp sent log info title')}</span>
                  <br />
                  {t('Whatsapp sent log info text')}
                  <br />
                  {/* <a target="_blank" href="http://helpcenter.wifreezone.com/es/articles/5849058-campsWhatsapp-de-feedback" rel="noreferrer">{t('Read more')}</a> */}
                </CardText>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

RegistroEnvios.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(RegistroEnvios);
