import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ReactSelect, { components } from 'react-select';

const CabeceraLista = props => {
  const {
    t,
    filtros,
    modificarFiltroActivo,
    textoMostrarTodos,
    total,
    vista,
    handleChange,
    funcionExportar,
    isLoading,
    nSelected,
    handleEliminar,
    handleTags,
    textoNuevo,
    handleNuevo
  } = props;
  const [filtroSelect, setFiltroSelect] = useState([{ label: t(textoMostrarTodos), value: '' }]);
  const [mostrarExportar, setMostrarExportar] = useState(false);
  const [busquedaSafe, setBusquedaSafe] = useState('');
  const [sobreExportarCSV, setSobreExportarCSV] = useState(false);
  const [sobreExportarExcel, setSobreExportarExcel] = useState(false);
  function preparaFiltroParaSelect() {
    const nuevoF = Object.keys(filtros).map((f) => {
      return { value: f, label: t(filtros[f]) };
    });
    setFiltroSelect([{ label: t(textoMostrarTodos), value: '' }, ...nuevoF]);
  }

  const Menu = ({ ...p }) => (
    <>
      <components.Menu {...p}>
        {p.children}
        <div
          className="css-yt9ioa-option"
          style={{
            borderTop: '1px',
            borderColor: 'lightgray',
            borderTopStyle: 'solid',
            borderRadius: '0px 0px 4px 4px',
          }}
        >
          <Link
            to="/segmentos"
            target="_blank"
            rel="noreferrer"
            style={{ display: 'flex', justifyContent: 'center', opacity: '1' }}
          >
            {t('Manage segments')}
          </Link>
        </div>
      </components.Menu>
    </>
  );

  useEffect(() => {
    preparaFiltroParaSelect();
  }, [filtros]);
  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <ReactSelect
        className="basic-single"
        options={filtroSelect}
        onChange={(e) => modificarFiltroActivo(e.value)}
        defaultValue={filtroSelect[0]}
        styles={{
          container: (base) => ({
            ...base, width: '20%'
          })
        }}
        components={{ Menu }}
        isSearchable
      />
      <Label style={{ marginLeft: '10%' }} className={isLoading ? 'placeholder-dinamic' : ''}>
        {nSelected < total && nSelected > 0 && `${nSelected} ${t('of')} ${total} ${t(vista)} ${t('selected')}`}
        {nSelected === total && total > 0 && `${total} ${t(vista)} ${t('selected')}`}
        {(nSelected === 0 || !nSelected) && `${total} ${t(vista)}`}
      </Label>
      {nSelected > 0 && (
        <Dropdown
          style={{ marginLeft: '2%' }}
          isOpen={mostrarExportar}
          toggle={() => setMostrarExportar(!mostrarExportar)}
        >
          <DropdownToggle
            outline
            caret
            style={{ borderColor: mostrarExportar ? '#2684FF' : 'hsl(0,0%,80%)', color: 'hsl(0, 0%, 20%)', backgroundColor: 'initial', boxShadow: mostrarExportar ? '0 0 0 1px #2684FF' : 'none' }}
          >
            <span>
              {t('Batch actions')}
              {'\t'}
              <div style={{ display: 'inline' }}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg">
                  <path fill="#CCCCCC" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                </svg>
              </div>
            </span>
          </DropdownToggle>
          <DropdownMenu>
            {handleTags && (
            <DropdownItem
              id="dropdownTagsMasivo"
              onMouseEnter={() => setSobreExportarCSV(true)}
              onMouseLeave={() => setSobreExportarCSV(false)}
              style={sobreExportarCSV ? { backgroundColor: '#deebff' } : {}}
              onClick={(e) => {
                if (handleTags !== false) handleTags(true);
                else e.preventDefault();
              }}
            >
              {t('Edit tags')}
            </DropdownItem>
            )}
            <DropdownItem
              onMouseEnter={() => setSobreExportarExcel(true)}
              onMouseLeave={() => setSobreExportarExcel(false)}
              style={sobreExportarExcel ? { backgroundColor: '#deebff' } : {}}
              onClick={() => handleEliminar(true)}
            >
              {t('Delete')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      {!nSelected && (
        <>
          <div style={{ marginLeft: '2%', width: '20%' }}>
            <div className="position-relative">
              <Input
                type="text"
                placeholder={t('Search')}
                onChange={(e) => { handleChange(e.target.value); setBusquedaSafe(e.target.value); }}
                style={{ paddingLeft: '25px', paddingRight: '65px' }}
                value={busquedaSafe}
              />
              <span className="bx bx-search-alt font-size-18" style={{ position: 'absolute', top: '9px', left: '6px' }} />
              <span style={{ position: 'absolute', top: '10px', right: '6px', fontSize: '11px', cursor: 'pointer' }} onClick={() => { handleChange(''); setBusquedaSafe(''); }}>
                <span>
                  &#10005;
                </span>
                <span>
                  {` ${t('Clear')}`}
                </span>
              </span>
            </div>
          </div>
          <Dropdown
            style={{ marginLeft: '2%' }}
            isOpen={mostrarExportar}
            toggle={() => setMostrarExportar(!mostrarExportar)}
          >
            <DropdownToggle
              outline
              caret
              style={{ borderColor: mostrarExportar ? '#2684FF' : 'hsl(0,0%,80%)', color: 'hsl(0, 0%, 20%)', backgroundColor: 'initial', boxShadow: mostrarExportar ? '0 0 0 1px #2684FF' : 'none' }}
            >
              <span>
                {t('Export')}
                {'\t'}
                <div style={{ display: 'inline' }}>
                  <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg">
                    <path fill="#CCCCCC" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                  </svg>
                </div>
              </span>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onMouseEnter={() => setSobreExportarCSV(true)}
                onMouseLeave={() => setSobreExportarCSV(false)}
                style={sobreExportarCSV ? { backgroundColor: '#deebff' } : {}}
                onClick={() => funcionExportar('csv')}
              >
                {t('Export to CSV')}
              </DropdownItem>
              <DropdownItem
                onMouseEnter={() => setSobreExportarExcel(true)}
                onMouseLeave={() => setSobreExportarExcel(false)}
                style={sobreExportarExcel ? { backgroundColor: '#deebff' } : {}}
                onClick={() => funcionExportar('excel')}
              >
                {t('Export to Excel')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </>
      )}
      {textoNuevo && (
        <Button onClick={() => handleNuevo(null)} color="primary" style={{ position: 'absolute', right: '10px' }}>
          {t(textoNuevo)}
        </Button>
      )}
    </div>
  );
};

CabeceraLista.propTypes = {
  t: PropTypes.any,
  filtros: PropTypes.object,
  modificarFiltroActivo: PropTypes.func,
  textoMostrarTodos: PropTypes.string,
  total: PropTypes.number,
  vista: PropTypes.string,
  handleChange: PropTypes.func,
  funcionExportar: PropTypes.func,
  isLoading: PropTypes.bool,
  nSelected: PropTypes.number,
  handleEliminar: PropTypes.func,
  handleTags: PropTypes.any,
  textoNuevo: PropTypes.string,
  handleNuevo: PropTypes.func
};

export default withTranslation()(CabeceraLista);
