import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
  Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';

const ReglasAsignacion = props => {
  const { t } = props;
  const [reglasData, setReglasData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [idLocal, setIdLocal] = useState(null);
  const refIdLocal = useRef();

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  const cargaDatos = async () => {
    try {
      const whoami = await axiosApi.get('/mc/whoami');
      const { data: whoamiData } = whoami;
      setIdLocal(whoamiData.idLocal.toString());
      refIdLocal.current = whoamiData.idLocal.toString();
      const response = await axiosApi.get(`/mc/companies/reglasAsignacion?id=${refIdLocal.current}`);
      const { data } = response;
      const { reglas, error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setReglasData(reglas);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  const columns = [
    {
      dataField: 'estado',
      text: t('Status'),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (value) => {
        if (value === 1) {
          return <Badge className="me-1 rounded-pill bg-success">{t('Active')}</Badge>;
        }
        return <Badge className="me-1 rounded-pill bg-danger">{t('Inactive')}</Badge>;
      }
    },
    {
      dataField: 'descripcion',
      text: t('Description'),
      sort: true,
    },
    {
      dataField: 'criterio_name',
      text: t('Criteria'),
      headerStyle: { maxWidth: '25vh' },
      sort: true
    },
    {
      dataField: 'departamento',
      text: t('Department'),
      sort: true,
    },
    {
      dataField: 'criterio_id',
      text: t('Options'),
      sort: true,
      formatter: (value, row) => {
        return (
          <UncontrolledDropdown
            direction="left"
          >
            <DropdownToggle tag="a" caret>
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu
              className="lista-opciones"
            >
              <DropdownItem
                disabled
              >
                {row.activa === 1 &&
                t('Disable')}
                {row.activa === 0 &&
                t('Enable')}
              </DropdownItem>
              <DropdownItem disabled>{t('Edit')}</DropdownItem>
              <DropdownItem disabled>{t('Remove')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: reglasData.length,
    sizePerPageRenderer,
    custom: true,
    showTotal: true
  };

  document.title = `${t('Assignment rules')} - ${t('Journeytok dashboard')}`;

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={t('Assignment rules')} breadcrumbItems={[{ title: t('Messaging Center') }, { title: t('Assignment rules') }]} />

          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }}>
                  <Button
                    color="primary"
                    className="mt-1"
                  >
                    {t('New rule')}
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={reglasData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={reglasData}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
            {/* <Col className="col-12 mt-5">
              <Card body className="puntos">
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Whatsapp automation info title')}</span>
                  <br />
                  {t('Whatsapp automation info text')}
                  <br />
                  {/* <a target="_blank" href="http://helpcenter.wifreezone.com/es/articles/5849058-campsWhatsapp-de-feedback" rel="noreferrer">{t('Read more')}</a> *//* }
                </CardText>
              </Card>
            </Col> */}
          </Row>
        </div>
      </div>
    </>
  );
};

ReglasAsignacion.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(ReglasAsignacion);
