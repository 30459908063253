/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { useLocation, Link } from 'react-router-dom';

// i18n
import { withTranslation } from 'react-i18next';

const store = require('store');
const eventPlugin = require('store/plugins/events');

store.addPlugin(eventPlugin);

const SidebarContent = props => {
  const ref = useRef();

  const [funcionalidades, setFuncionalidades] = useState(store.get('funcionalidades'));
  const [mostrarAdm] = useState(store.get('adm'));

  store.watch('funcionalidades', () => {
    setFuncionalidades(store.get('funcionalidades'));
  });
  const { t } = props;
  const location = useLocation();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = `/nuevo${location.pathname}`;

    function scrollElement(item) {
      if (item) {
        const currentPosition = item.offsetTop;
        if (currentPosition + 450 > window.innerHeight) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }

    function activateParentDropdown(item) {
      item.classList.add('active');
      const parent = item.parentElement;
      const parent2El = parent.childNodes[1];
      if (parent2El && parent2El.id !== 'side-menu') {
        parent2El.classList.add('mm-show');
      }

      if (parent) {
        parent.classList.add('mm-active');
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.add('mm-show'); // ul tag

          const parent3 = parent2.parentElement; // li tag

          if (parent3) {
            parent3.classList.add('mm-active'); // li
            parent3.childNodes[0].classList.add('mm-active'); // a
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.add('mm-show'); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('mm-show'); // li
                parent5.childNodes[0].classList.add('mm-active'); // a tag
              }
            }
          }
        }
        scrollElement(item);
        return false;
      }
      scrollElement(item);
      return false;
    }

    const initMenu = () => {
      /* eslint-disable no-new */
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname]);

  const recalculate = async () => {
    ref.current.recalculate();
  };

  useEffect(() => {
    recalculate();
  });

  return (
    <>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{t('Menu')}</li>

            {!funcionalidades?.estadisticas?.estadisticasGeneral &&
            (
            <li>
              <a
                href={`${process.env.REACT_APP_HOST_PANEL}/estadisticas/graficas`}
                target="_self"
                className=""
              >
                {/* <Link to={{ pathname: `${process.env.REACT_APP_HOST_PANEL}
                /estadisticas/graficas`}} target="_self" className=""> */}
                <i className="dripicons-graph-line" />
                <span>{t('Statistics')}</span>
                {/* </Link> */}
              </a>
            </li>
            )}

            <li>
              <a
                className="has-arrow"
              >
                <i className="bx bxs-group" />
                <span>{t('Customers')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.clientes?.panelVisitantes &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/panelVisitantes`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('General')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.clientes?.clientesUnicos &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/listado/visitantes`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Customers list')}
                    {/* </Link> */}
                  </a>
                  {/* {<Link to="/listado/clientes">
                    {t('Customers list')}
                  </Link>} */}
                </li>
                )}
                {!funcionalidades?.configuracion?.segmentos && (
                  <li>
                    <Link to="/segmentos">
                      {t('Segments')}
                    </Link>
                  </li>
                )}
                {!funcionalidades?.clientes?.conexiones &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/listado/visitas`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('WiFi connections')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.clientes?.sesionesActivas &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/listado/sesionesActivas`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Active sessions')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
              </ul>
            </li>
            {!(funcionalidades?.reservas?.listado && funcionalidades?.clientes?.checkin) && (
            <li>
              <a
                className="has-arrow"
              >
                <i className="bx bx-calendar" />
                <span>{t('Reservations')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.reservas?.listado &&
                (
                <li>
                  <Link to="/locales/reservas">
                    {t('Reservations list')}
                  </Link>
                </li>
                )}
                {!funcionalidades?.clientes?.checkin &&
                (
                <li>
                  <Link to="/listado/checkin">
                    {t('Checkin')}
                  </Link>
                </li>
                )}
              </ul>
            </li>
            )}
            <li>
              <a
                className="has-arrow"
              >
                <i className="mdi mdi-thumb-up" />
                <span>{t('Reputation')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.social?.invitacionesReview &&
                (
                <li>
                  <Link to="/reputacion/invitaciones">
                    {t('Review requests')}
                  </Link>
                </li>
                )}
                {!funcionalidades?.social?.review360 &&
                (
                <li>
                  <Link to="/reputacion/review360">
                    {t('Review 360')}
                  </Link>
                </li>
                )}
              </ul>
            </li>

            <li>
              <a
                className="has-arrow"
              >
                <i className="bx bx-comment" />
                <span>{t('Feedback')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.feedback?.general &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/encuestas/estadisticasEncuestas`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('General')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.feedback?.departamentos &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/encuestas/estadisticasFeedbackDepartamentos`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Departments')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.feedback?.objetivos &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/encuestas/objetivosDepartamentos`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Goals')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.feedback?.respuestas &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/encuestas/resultados`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Answers')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.feedback?.encuestas &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/encuestas/encuestas`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Surveys')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.feedback?.departamentos &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/departamentos/administrar`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Departments management')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
              </ul>
            </li>

            <li>
              <a
                className="has-arrow"
              >
                <i className="bx bx-envelope" />
                <span>{t('Mail marketing')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.mailMarketing?.estadisticasCorreos &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/mailing/estadisticas`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Mail stats')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.mailMarketing?.registroEnvios &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/mailing/correosEnviados`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Emails sent log')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.mailMarketing?.plantillas &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/plantillasGrid`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Templates')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.mailMarketing?.camps &&
                (
                <li>
                  <a href={`${process.env.REACT_APP_HOST_PANEL}/camps`} target="_self" className="">
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Campaigns')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.mailMarketing?.campsSMS &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/campsSMS`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('SMS campaigns')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
              </ul>
            </li>
            { !(funcionalidades?.whatsapp?.automatizacion &&
            funcionalidades?.whatsapp?.plantillas &&
            funcionalidades?.whatsapp?.registroEnvios &&
            funcionalidades?.whatsapp?.costes) &&
            (
            <li>
              <a
                className="has-arrow"
              >
                <i className="bx bxl-whatsapp" />
                <span>{t('Whatsapp')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.whatsapp?.automatizacion &&
                (
                <li>
                  <Link to="/whatsapp/automatizacion">
                    {t('Automation')}
                  </Link>
                </li>
                )}
                {!funcionalidades?.whatsapp?.plantillas &&
                (
                <li>
                  <Link to="/whatsapp/plantillas">
                    {t('Templates')}
                  </Link>
                </li>
                )}
                {!funcionalidades?.whatsapp?.registroEnvios &&
                (
                <li>
                  <Link to="/whatsapp/logEnvios">
                    {t('Sent log')}
                  </Link>
                </li>
                )}
                {!funcionalidades?.whatsapp?.costes &&
                (
                <li>
                  <Link to="/whatsapp/costes">
                    {t('Whatsapp pricing')}
                  </Link>
                </li>
                )}
              </ul>
            </li>
            )}
            <li>
              <a
                className="has-arrow"
              >
                <i className="mdi mdi-wifi-cog" />
                <span>{t('Captive portal')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.editorPantallas?.pantallasConexion &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/pantallaslogin`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Main page')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.editorPantallas?.pantallaPromocional &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/pantallassuccess`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Promo page')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.editorPantallas?.reglasVisualizacion &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/programacionPantallas`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('View rules')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.editorPantallas?.pantallaFormulario &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/editarCamposFormulario`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Form page')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
              </ul>
            </li>

            <li>
              <a
                className="has-arrow"
              >
                <i className="bx bx-cog" />
                <span>{t('Settings')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.configuracion?.perfilEstablecimiento &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/editarSelected`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Business profile')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.configuracion?.idiomas &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/editarIdiomas`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Languages')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.configuracion?.hotspot &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/configurarConfHotspot`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Hotspot')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.configuracion?.social &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/configuracionSocial`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Social')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.configuracion?.usuarios &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/listaCredenciales`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Users')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.configuracion?.codigoAcceso &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/listaCodigosAcceso`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Access codes')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.configuracion?.codigoEvento &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/locales/listaCodigosEvento`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Events')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.configuracion?.alertas &&
                (
                <li>
                  <a
                    className="has-arrow"
                  >
                    <span>{t('Reports')}</span>
                  </a>
                  <ul className="sub-menu">
                    {!funcionalidades?.feedback?.general &&
                    (
                    <li>
                      <Link to="/alertas/feedback">
                        {t('Feedback reports')}
                      </Link>
                    </li>
                    )}
                    <li>
                      <Link to="/alertas/sistemas">
                        {t('Systems reports')}
                      </Link>
                    </li>
                    {!funcionalidades?.chatzone?.chatzone &&
                    (
                    <li>
                      <Link to="/alertas/messagingcenter">
                        {t('Messaging Center reports')}
                      </Link>
                    </li>
                    )}
                    {!funcionalidades?.clientes?.checkin &&
                    (
                    <li>
                      <Link to="/alertas/checkin">
                        {t('Checkin reports')}
                      </Link>
                    </li>
                    )}
                  </ul>
                </li>
                )}
                {!funcionalidades?.configuracion?.controlDispositivos &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/whitelist`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Devices management')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
              </ul>
            </li>
            {!funcionalidades?.chatzone?.chatzone &&
            (
            <li>
              <a
                className="has-arrow"
              >
                <i className="bx bx-chat" />
                <span>{t('Messaging Center')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.chatzone?.chatzone && (
                <li>
                  <Link to="/messagingcenter/inbox" className="">
                    {t('Inbox')}
                  </Link>
                </li>
                )}
                {!funcionalidades?.chatzone?.reglas && (
                <li>
                  <Link to="/messagingcenter/reglas" className="">
                    {t('Assignment rules')}
                  </Link>
                </li>
                )}
                {!funcionalidades?.chatzone?.tareas && (
                <li>
                  <Link to="/messagingcenter/tareas" className="">
                    {t('Tasks')}
                  </Link>
                </li>
                )}
                {!funcionalidades?.chatzone?.pipeline && (
                <li>
                  <Link to="/messagingcenter/pipeline" className="">
                    {t('Pipeline')}
                  </Link>
                </li>
                )}
                {!funcionalidades?.chatzone?.estadisticas && (
                <li>
                  <Link to="/messagingcenter/estadisticas" className="">
                    {t('Statistics')}
                  </Link>
                </li>
                )}
                {!funcionalidades?.chatzone?.chatzone && (
                <li>
                  <Link to="/messagingcenter/respuestasRapidas" className="">
                    {t('Quick replies')}
                  </Link>
                </li>
                )}
              </ul>
            </li>
            )}

            <li>
              <a
                className="has-arrow"
              >
                <i className="mdi mdi-eye" />
                <span>{t('Monitoring')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.monitorizacion?.general &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/diagnostico/general`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('General')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.monitorizacion?.portalTester &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/diagnostico/portaltester`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Portal tester')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.monitorizacion?.pms &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/diagnostico/PMS`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('PMS sync')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
              </ul>
            </li>

            <li>
              <a
                className="has-arrow"
              >
                <i className="bx bx-import" />
                <span>{t('Integrations')}</span>
              </a>
              <ul className="sub-menu">
                {!funcionalidades?.integraciones?.mailchimp &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/integraciones/mailchimp`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('Mailchimp')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
                {!funcionalidades?.integraciones?.importarCSV &&
                (
                <li>
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/integraciones/importarCSV`}
                    target="_self"
                    className=""
                  >
                    {/* <Link to="/ecommerce-products"> */}
                    {t('CSV import')}
                    {/* </Link> */}
                  </a>
                </li>
                )}
              </ul>
            </li>

            {mostrarAdm &&
                (
                <li>
                  <a
                    className="has-arrow"
                  >
                    <i className="bx bx-shield-quarter" />
                    <span>{t('Management')}</span>
                  </a>
                  <ul className="sub-menu">
                    <li>
                      <a
                        href={`${process.env.REACT_APP_HOST_PANEL}/administracion/showRoles`}
                        target="_self"
                        className=""
                      >
                        {/* <Link to="/ecommerce-products"> */}
                        {t('Roles')}
                        {/* </Link> */}
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_HOST_PANEL}/administracion/editarFuncionalidades`}
                        target="_self"
                        className=""
                      >
                        {/* <Link to="/ecommerce-products"> */}
                        {t('Functionalities')}
                        {/* </Link> */}
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_HOST_PANEL}/administracion/configuracionEmail`}
                        target="_self"
                        className=""
                      >
                        {/* <Link to="/ecommerce-products"> */}
                        {t('Domain setup')}
                        {/* </Link> */}
                      </a>
                    </li>
                    <li>
                      <Link to="/administracion/miembros">
                        {t('Team members')}
                      </Link>
                    </li>
                  </ul>
                </li>
                )}
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};

SidebarContent.propTypes = {
  // location: PropTypes.object,
  t: PropTypes.any
};

export default withTranslation()(SidebarContent);
