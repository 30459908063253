import PropTypes from 'prop-types';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ColumnChart = (props) => {
  const { series, categories, toolbar } = props;
  const options = {
    chart: {
      toolbar: {
        show: toolbar
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '45%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: false,
      width: 2,
      colors: ['transparent']
    },

    colors: ['#25D366', '#556ee6', '#f46a6a'],
    xaxis: {
      categories
    },
    /* yaxis: {
      title: {
        text: '$ (thousands)'
      }
    }, */
    grid: {
      borderColor: '#f1f1f1'
    },
    fill: {
      opacity: 1
    },
    /* tooltip: {
      y: {
        formatter: val => {
          return `$ ${val} thousands`;
        }
      }
    } */
  };

  return <ReactApexChart options={options} series={series} type="bar" height={350} />;
};

ColumnChart.propTypes = {
  categories: PropTypes.array,
  series: PropTypes.array,
  toolbar: PropTypes.bool
};

export default ColumnChart;
