import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import { withTranslation } from 'react-i18next';

class Pie extends Component {
  getOption = () => {
    const { data, title, completo, toolbar } = this.props;

    const datos = [];
    const colors = [];
    const legend = [];
    data.forEach(element => {
      if (element.value > 0) {
        datos.push({ name: element.name, value: element.value });
        colors.push(element.color);
        legend.push(element.name);
      } else if (completo) {
        datos.push({ name: element.name });
        colors.push(element.color);
        legend.push(element.name);
      }
    });

    return {
      toolbox: {
        // itemSize: 30,
        feature: {
          myCSV: {
            show: false,
            title: 'Download CSV',
            icon: 'M864.25,507.64168c-13.9125,0 -16.91667,-11.725 -16.91667,-25.9v-5.48333c0,-14.9625 3.70417,-25.9 17.15,-25.9c10.73333,0 15.575,5.22083 16.21667,15.28333h17.15c-2.0125,-18.05417 -13.2125,-29.28334 -33.36667,-29.28334c-5.22083,0 -9.94583,0.93333 -14.175,2.8c-12.83333,5.65833 -20.24167,18.725 -20.24167,37.21667v5.36667c0,6.15417 0.7875,11.69583 2.39167,16.625c4.34583,13.44583 14.32083,23.275 31.79167,23.275c5.13333,0 9.7125,-0.72917 13.70833,-2.15833c11.17083,-4.025 18.75417,-12.74583 19.775,-26.65834h-17.15c-0.6125,9.975 -5.6875,14.81667 -16.33333,14.81667zM938.91667,508.10835c-9.94583,0 -16.33333,-3.79167 -16.33333,-13.76667h-17.15c0,3.12083 0.4375,5.95 1.34167,8.51667c4.17083,11.92917 16.15833,18.78333 32.14167,18.78333c4.57917,0 8.72083,-0.525 12.425,-1.575c9.8,-2.8 17.675,-9.21667 17.675,-21.525c0,-3.4125 -0.58333,-6.44583 -1.75,-9.1c-4.2,-9.8 -14.7,-14.29167 -25.78333,-17.73333c-5.36667,-1.63333 -9.45,-3.35417 -12.25,-5.19167c-2.8,-1.8375 -4.2,-4.1125 -4.2,-6.825c0,-6.97083 6.00833,-9.68333 13.18333,-9.68333c8.25417,0 13.76667,3.90833 13.76667,12.01667h17.03333c0,-3.73333 -0.72917,-7.14583 -2.15833,-10.26667c-4.40417,-9.50833 -14.37917,-15.4 -28.29167,-15.4c-4.52083,0 -8.63333,0.55417 -12.36667,1.69167c-9.59583,2.8875 -18.2,9.39167 -18.2,21.525c0,3.5875 0.72917,6.7375 2.15833,9.45c4.725,8.95417 14.99167,13.27083 25.49167,16.68333c3.35417,1.02083 6.09583,2.0125 8.225,2.975c4.49167,2.04167 7.99167,3.99583 7.99167,9.975c0,6.94167 -5.80417,9.45 -12.95,9.45zM1011.13333,499.94168l-18.66667,-62.41667h-19.01667l28.81667,82.95001h17.85l28.81667,-82.95001h-19.01667z',
            onclick: function generateCSV() {
              console.log(datos);
              let doc = `${title},Value`;
              datos.forEach(dato => {
                doc += `\n${dato.name},${dato.value ? dato.value : 0}`;
              });
              const csvContent = `data:text/csv;charset=utf-8,${doc}`;
              const encodedURI = encodeURI(csvContent);
              window.open(encodedURI);
            }
          },
          saveAsImage: {
            show: toolbar,
            // eslint-disable-next-line max-len
            // icon: 'M845,493.30833h15.28333c4.9,0 9.275,-0.6125 13.125,-1.86667c10.4125,-3.35417 18.025,-10.99583 18.025,-24.5c0,-3.87917 -0.7,-7.525 -2.1,-10.90833c-4.25833,-10.325 -14.4375,-16.50833 -29.05,-16.50833h-32.43333v82.95h17.15zM874.16667,467.05833c0,8.34167 -5.39583,12.36667 -13.88333,12.36667h-15.28333v-26.01667h15.28333c8.6625,0 13.88333,5.1625 13.88333,13.65zM970.65,522.475v-82.95h-17.03333v54.71667l-33.36667,-54.71667h-17.15v82.95h17.15v-54.6l33.25,54.6zM1020.35,509.875c-14.29167,0 -19.48333,-10.96667 -19.48333,-26.36667v-5.01667c0,-14.81667 4.375,-26.36667 17.85,-26.36667c9.85833,0 14.49583,5.22083 15.75,13.76667h16.68333c-2.04167,-17.7625 -12.89167,-27.53333 -32.9,-27.53333c-5.27917,0 -10.0625,0.90417 -14.35,2.68333c-13.27083,5.54167 -20.41667,18.66667 -20.41667,37.56667v4.9c0,6.44583 0.84583,12.1625 2.56667,17.09167c4.69583,13.53333 15.4875,23.04167 33.36667,23.04167c14.35,0 25.2875,-4.22917 31.73333,-11.66667v-33.13333h-32.66667v12.6h15.51667v14.35c-2.47917,2.975 -7.99167,4.08333 -13.65,4.08333z',
            title: 'Download PNG'
          },
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        data: legend,
        textStyle: {
          color: ['#74788d']
        }
      },
      color: colors,
      series: [
        {
          name: title,
          type: 'pie',
          radius: '55%',
          center: ['50%', '60%'],
          data: datos,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
  };

  render() {
    return (
      <>
        <ReactEcharts style={{ height: '365px' }} option={this.getOption()} />
      </>
    );
  }
}

Pie.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  completo: PropTypes.bool,
  toolbar: PropTypes.bool
};

export default withTranslation()(Pie);
