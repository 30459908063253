import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Offcanvas,
  Label,
  Form,
  Button
  /* CardText */
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import * as Yup from 'yup';
import { useFormik } from 'formik';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { utils, writeFileXLSX } from 'xlsx';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import es from 'react-phone-input-2/lang/es.json';
import pt from 'react-phone-input-2/lang/pt.json';
import ReactSelect, { components } from 'react-select';
import { GithubPicker } from 'react-color';
import '@vtaits/react-color-picker/dist/index.css';
import { map, findKey } from 'lodash';

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import axiosApi from '../../helpers/apiResquests';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import 'assets/scss/estilos.scss';
import languageList from '../../common/languageList.json';

// iconos-redes-sociales
import accesoDirecto from '../../assets/images/iconos-redes-sociales/icono-acceso-directo.png';
import iconoMail from '../../assets/images/iconos-redes-sociales/icono-mail.png';
import iconoAuto from '../../assets/images/iconos-redes-sociales/icono-auto.png';
import iconoCHN from '../../assets/images/iconos-redes-sociales/icono-chn.png';
import iconoCSV from '../../assets/images/iconos-redes-sociales/icono-csv.png';
import iconoFB from '../../assets/images/iconos-redes-sociales/icono-facebook.png';
import iconoGPlus from '../../assets/images/iconos-redes-sociales/icono-gplus.png';
import iconoTwitter from '../../assets/images/iconos-redes-sociales/icono-twitter.png';
import iconoHuesped from '../../assets/images/iconos-redes-sociales/icono-huesped.svg';
import iconoInstagram from '../../assets/images/iconos-redes-sociales/icono-instagram.png';
import iconoLinkedin from '../../assets/images/iconos-redes-sociales/icono-linkedin.png';
import iconoMC from '../../assets/images/iconos-redes-sociales/icono-mc.png';
import iconoPMS from '../../assets/images/iconos-redes-sociales/icono-pms.png';
import iconoTlf from '../../assets/images/iconos-redes-sociales/icono-telefono.svg';
import iconoVK from '../../assets/images/iconos-redes-sociales/icono-vk.png';
import iconoWhatsapp from '../../assets/images/iconos-redes-sociales/icono-whatsapp.png';
import iconoUserPass from '../../assets/images/iconos-redes-sociales/icono-user-lock.svg';
import iconoManual from '../../assets/images/iconos-redes-sociales/icono-manual.png';
import iconoEvento from '../../assets/images/iconos-redes-sociales/icono-event.svg';
import iconoAcceso from '../../assets/images/iconos-redes-sociales/icono-acceso.svg';
import CabeceraListas from '../../components/Common/CabeceraListas';
import listaTraducida from '../../common/traducirListaIdiomas';

const store = require('store');
const eventPlugin = require('store/plugins/events');

store.addPlugin(eventPlugin);

const ListadoClientes = props => {
  const { t } = props;

  const [visitantes, setVisitantes] = useState([]);
  const [visitantesVisibles, setVisitantesVisibles] = useState([]);
  const [start, setStart] = useState(0);
  const [len, setLen] = useState(50);
  const [size, setSize] = useState(10);
  const [totalAcciones, setTotalAcciones] = useState(60);
  const [pagina, setPagina] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [filtros, setFiltros] = useState({});
  const [filtroActivo, setFiltroActivo] = useState('');
  const [busquedaManual, setBusquedaManual] = useState('');
  const [cargaInicial, setCargaInicial] = useState(true);
  const [modalInstantaneo, setModalInstantaneo] = useState(false);
  const [telefonoSinPrefijo, setTelefonoSinPrefijo] = useState();
  const [nombrePlantilla, setNombrePlantilla] = useState({});
  const [idiomaPlantilla, setIdiomaPlantilla] = useState({});
  const [camposEnvioUnico, setCamposEnvioUnico] = useState([]);
  const [expandirEnvio, setExpandirEnvio] = useState(false);
  const [camposEnviar, setCamposEnviar] = useState([]);
  const [buscaReserva, setBuscaReserva] = useState([]);
  const [idReservaInvalida, setIdReservaInvalida] = useState(false);
  const [campoRequerido, setCampoRequerido] = useState([]);
  const [idUsuarioEnviar, setIdUsuarioEnvia] = useState();
  const [idiomaTlf, setIdiomaTlf] = useState();
  const [modalErrorInstantaneo, setModalErrorInstantaneo] = useState(false);
  const [plantillas, setPlantillas] = useState({});
  const [componentesPlantilla, setComponentesPlantilla] = useState();
  const [dicNombreIdiomasPlantilla, setDicNombreIdiomasPlantilla] = useState({});
  const [opcionesPlantillas, setOpcionesPlantillas] = useState({});
  const [opcionesIdiomas, setOpcionesIdiomas] = useState({});
  const [funcionalidades, setFuncionalidades] = useState(store.get('funcionalidades'));
  const [tlfEnviar, setTlfEnviar] = useState('');
  const [seleccionados, setSeleccionados] = useState(new Set([]));
  const [modalEliminar, setModalEliminar] = useState(false);
  const [idEliminar, setIdEliminar] = useState(-1);
  const [modalTags, setModalTags] = useState(false);
  const [idTags, setIdTags] = useState(-1);
  const [etiquetas, setEtiquetas] = useState([]);
  const [etiquetasSeleccionado, setEtiquetasSeleccionado] = useState([]);
  const [etiquetaEditar, setEtiquetaEditar] = useState(null);
  const [etiquetaNueva, setEtiquetaNueva] = useState(false);
  const [modalVieja, setModalVieja] = useState(false);
  const [etiquetasQuitar, setEtiquetasQuitar] = useState([]);
  const [etiquetasPreviaPag, setEtiquetasPreviaPag] = useState(-1);
  const [modalCreacionUsuarios, setModalCreacionUsuarios] = useState(false);
  const [perteneceDivision, setPerteneceDivision] = useState(false);

  store.watch('funcionalidades', () => {
    setFuncionalidades(store.get('funcionalidades'));
  });

  // function generaColorTexto(colorFondo) {
  //   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorFondo);
  //   const r = parseInt(result[1], 16);
  //   const g = parseInt(result[2], 16);
  //   const b = parseInt(result[3], 16);
  //   const brightness = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
  //   const textColour = (brightness > 125) ? 'black' : 'white';
  //   return textColour;
  // }

  function htmlEntities(str) {
    return String(str).replaceAll('&ntilde;', 'ñ')
      .replaceAll('&Ntilde;', 'Ñ')
      .replaceAll('&amp;', '&')
      .replaceAll('&ntilde;', 'ñ')
      .replaceAll('&Agrave;', 'À')
      .replaceAll('&Aacute;', 'Á')
      .replaceAll('&Acirc;', 'Â')
      .replaceAll('&Atilde;', 'Ã')
      .replaceAll('&Auml;', 'Ä')
      .replaceAll('&Aring;', 'Å')
      .replaceAll('&AElig;', 'Æ')
      .replaceAll('&Ccedil;', 'Ç')
      .replaceAll('&Egrave;', 'È')
      .replaceAll('&Eacute;', 'É')
      .replaceAll('&Ecirc;', 'Ê')
      .replaceAll('&Euml;', 'Ë')
      .replaceAll('&Igrave;', 'Ì')
      .replaceAll('&Iacute;', 'Í')
      .replaceAll('&Icirc;', 'Î')
      .replaceAll('&Iuml;', 'Ï')
      .replaceAll('&ETH;', 'Ð')
      .replaceAll('&Ograve;', 'Ò')
      .replaceAll('&Oacute;', 'Ó')
      .replaceAll('&Ocirc;', 'Ô')
      .replaceAll('&Otilde;', 'Õ')
      .replaceAll('&Ouml;', 'Ö')
      .replaceAll('&Oslash;', 'Ø')
      .replaceAll('&Ugrave;', 'Ù')
      .replaceAll('&Uacute;', 'Ú')
      .replaceAll('&Ucirc;', 'Û')
      .replaceAll('&Uuml;', 'Ü')
      .replaceAll('&Yacute;', 'Ý')
      .replaceAll('&THORN;', 'Þ')
      .replaceAll('&szlig;', 'ß')
      .replaceAll('&agrave;', 'à')
      .replaceAll('&aacute;', 'á')
      .replaceAll('&acirc;', 'â')
      .replaceAll('&atilde;', 'ã')
      .replaceAll('&auml;', 'ä')
      .replaceAll('&aring;', 'å')
      .replaceAll('&aelig;', 'æ')
      .replaceAll('&ccedil;', 'ç')
      .replaceAll('&egrave;', 'è')
      .replaceAll('&eacute;', 'é')
      .replaceAll('&ecirc;', 'ê')
      .replaceAll('&euml;', 'ë')
      .replaceAll('&igrave;', 'ì')
      .replaceAll('&iacute;', 'í')
      .replaceAll('&icirc;', 'î')
      .replaceAll('&iuml;', 'ï')
      .replaceAll('&eth;', 'ð')
      .replaceAll('&ograve;', 'ò')
      .replaceAll('&oacute;', 'ó')
      .replaceAll('&ocirc;', 'ô')
      .replaceAll('&otilde;', 'õ')
      .replaceAll('&ouml;', 'ö')
      .replaceAll('&oslash;', 'ø')
      .replaceAll('&ugrave;', 'ù')
      .replaceAll('&uacute;', 'ú')
      .replaceAll('&ucirc;', 'û')
      .replaceAll('&uuml;', 'ü')
      .replaceAll('&yacute;', 'ý')
      .replaceAll('&thorn;', 'þ')
      .replaceAll('&yuml;', 'ÿ');
  }

  const cargaFiltros = async () => {
    try {
      const response = await axiosApi.get('/api/locales/filtrosclientes');
      setFiltros(response.data.filtros);
    } catch (e) {
      // console.log(e);
    }
  };

  const determinaAmbito = async () => {
    try {
      const response = await axiosApi.get('/api/locales/perteneceDivion');
      setPerteneceDivision(response.data.pertecene);
    } catch (e) {
      // console.log(e);
    }
  };

  function showToast(title, message, toastType) {
    const ele = document.getElementsByName('toastType');
    const position = document.getElementsByName('positions');

    // Close Button
    const closeButton = true;

    // Debug
    const debug = false;

    // Progressbar
    const progressBar = false;

    // Duplicates
    const preventDuplicates = true;

    // Newest on Top
    const newestOnTop = true;

    // position class
    let positionClass = 'toast-top-right';

    // Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) {
        positionClass = position[p].value;
      }
    }

    // Show Easing
    const showEasingAux = 'swing';

    // Hide Easing
    const hideEasingAux = 'linear';

    // show method
    const showMethodAux = 'fadeIn';

    // Hide method
    const hideMethodAux = 'fadeOut';

    // show duration
    const showDurationAux = 1500;

    // Hide duration
    const hideDurationAux = 0;

    // timeout
    const timeOutAux = 0;

    // extended timeout
    const extendedTimeOutAux = 0;

    // Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        toastType = ele[i].value;
      }
    }

    toastr.options = {
      positionClass,
      timeOutAux,
      extendedTimeOutAux,
      closeButton,
      debug,
      progressBar,
      preventDuplicates,
      newestOnTop,
      showEasingAux,
      hideEasingAux,
      showMethodAux,
      hideMethodAux,
      showDurationAux,
      hideDurationAux
    };

    // Toaster Types
    if (toastType === 'info') {
      toastr.info(message, title);
    } else if (toastType === 'warning') {
      toastr.warning(message, title);
    } else if (toastType === 'error') {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function togInstantaneo() {
    setModalInstantaneo(!modalInstantaneo);
    removeBodyCss();
  }

  const cargaPlaceholderCamp = async (camp, idioma) => {
    try {
      const datos = await axiosApi.post('/api/campwhatsapp/getPlaceholderCamp', { camp, idioma });
      const variablesPlantillas = JSON.parse(
        datos.data.datos[0].estructura_placeholder_template_whatsapp
      );
      const campos = Object.keys(variablesPlantillas).map(v => {
        if (v.includes('[[neocheckin_userlang]]')) return 'enlace_checkin';
        return v;
      });
      if (variablesPlantillas.id_pms_show) {
        setBuscaReserva(true);
      } else setBuscaReserva(false);
      setTelefonoSinPrefijo(tlfEnviar);
      setCamposEnviar(Array(Object.keys(variablesPlantillas).length).fill(''));
      setCamposEnvioUnico(campos);
      setCampoRequerido(Array(campos.length).fill(false));
      setModalInstantaneo(true);
    } catch (e) {
      // console.log(e);
      setModalErrorInstantaneo(true);
      setNombrePlantilla({});
      setIdiomaPlantilla({});
    }
  };

  const enviarTemplate = async (name, componentes, language) => {
    const datos = {
      name,
      componentes,
      language,
      phone: telefonoSinPrefijo,
      parametros: camposEnviar,
      id_usuarios: idUsuarioEnviar || ''
    };
    try {
      const response = await axiosApi.post('/api/whatsapp/unico', datos);
      const { data } = response;
      const { enviado } = data;
      if (enviado) {
        showToast(t('Whatsapp unique'), t('The unique message has been sent successfully'), 'success');
      } else {
        showToast(t('Whatsapp unique'), t('The unique message has not been sent'), 'error');
      }
      setIdUsuarioEnvia();
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  const buscarDatosCliente = async () => {
    let idReserva = false;
    try {
      const telefono = telefonoSinPrefijo || '';
      const index = camposEnvioUnico.findIndex(i => i === 'id_pms_show');
      let res = '';
      if (index !== -1) {
        res = camposEnviar[index];
        idReserva = true;
      }
      const response = await axiosApi.post('/api/campwhatsapp/getDatosPlaceholder', { telefono, res });
      const datos = response.data.datos[0];
      const copiaCampos = [...camposEnviar];
      camposEnvioUnico.forEach((c, i) => {
        let valor = '';
        switch (c) {
          case 'first_name':
          case 'username':
            valor = datos.nombre;
            break;
          case 'fecha_inicio':
          case 'checkin_date':
            valor = datos.fecha_inicio;
            break;
          case 'fecha_fin':
          case 'checkout_date':
            valor = datos.fecha_fin;
            break;
          case 'adultos':
          case 'adults':
            valor = datos.adultos;
            break;
          case 'menores':
          case 'children':
            valor = datos.menores;
            break;
          case 'room_type':
          case 'tipo':
            break;
          case 'payment_link':
            valor = `${process.env.REACT_APP_WEBCHAT_DOMAIN}/nuevo/pago?pay=${datos.enlace}&bookin=${datos.id_reservas}`;
            break;
          case 'checkin_link':
            valor = datos.enlace_checkin;
            break;
          default:
            valor = copiaCampos[i];
            break;
        }
        copiaCampos[i] = valor;
      });
      if (datos.telefono) {
        setTelefonoSinPrefijo(datos.telefono);
      }
      setIdUsuarioEnvia(datos.id_usuarios);
      setCamposEnviar(copiaCampos);
      setIdReservaInvalida(false);
    } catch (e) {
      // console.log(e);
      setIdReservaInvalida(true);
      idReserva = false;
    } finally {
      if (!buscaReserva || idReserva) setExpandirEnvio(true);
    }
  };

  useEffect(() => {
    if (nombrePlantilla.value !== '' && idiomaPlantilla.value !== '' && nombrePlantilla.value && idiomaPlantilla.value) {
      cargaPlaceholderCamp(nombrePlantilla.value, idiomaPlantilla.value);
      const p = plantillas.filter(
        temp => temp.name === nombrePlantilla.value && temp.language === idiomaPlantilla.value
      )[0];
      setComponentesPlantilla(p.components);
    } else if (nombrePlantilla.value && nombrePlantilla.value !== '') {
      const nuevosIdiomas = dicNombreIdiomasPlantilla[nombrePlantilla.value].split(',').map(i => {
        return { label: i, value: i };
      });
      setOpcionesIdiomas(nuevosIdiomas);
    }
  }, [nombrePlantilla, idiomaPlantilla]);

  const cargaPlantillas = async () => {
    try {
      const response = await axiosApi.get('/api/whatsapp/plantillas');
      let { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      if (data) {
        data = data.filter(e => {
          return !(e.name.startsWith('sample_') || e.name === 'hello_world');
        });
        const nombrePlantillas = {};
        data.forEach(template => {
          if (nombrePlantillas[template.name]) nombrePlantillas[template.name] += `,${template.language}`;
          else nombrePlantillas[template.name] = template.language;
        });
        setDicNombreIdiomasPlantilla(nombrePlantillas);
        const opSelPlant = Object.keys(nombrePlantillas).map(template => {
          return { value: template, label: template };
        });
        setOpcionesPlantillas(opSelPlant);
      }
      setPlantillas(data);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
    }
  };

  function iconoVia(viaVisita, id) {
    let tipoLoginArchivoIcono = '';
    let extra = ('');
    if (viaVisita) {
      if (viaVisita === 'Facebook') {
        tipoLoginArchivoIcono = iconoFB;
        extra = 'Facebook';
      } else if (viaVisita === 'Google') {
        tipoLoginArchivoIcono = iconoGPlus;
        extra = 'Google';
      } else if (viaVisita === 'Twitter') {
        tipoLoginArchivoIcono = iconoTwitter;
        extra = 'Twitter/X';
      } else if (viaVisita === 'Linkedin') {
        tipoLoginArchivoIcono = iconoLinkedin;
        extra = 'Linkedin';
      } else if (viaVisita === 'VK') {
        tipoLoginArchivoIcono = iconoVK;
        extra = 'VK';
      } else if (viaVisita === 'Instagram') {
        tipoLoginArchivoIcono = iconoInstagram;
        extra = 'VK';
      } else if (viaVisita === 'Boton-Directo') {
        tipoLoginArchivoIcono = accesoDirecto;
        extra = t('Direct access');
      } else if (viaVisita === 'Formulario') {
        tipoLoginArchivoIcono = iconoMail;
        extra = t('Form');
      } else if (viaVisita === 'Telefono') {
        tipoLoginArchivoIcono = iconoTlf;
        extra = t('Phone');
      } else if (viaVisita.indexOf('CSV') > -1) {
        tipoLoginArchivoIcono = iconoCSV;
        extra = 'CSV';
      } else if (viaVisita.indexOf('PMS') > -1) {
        tipoLoginArchivoIcono = iconoPMS;
        extra = 'PMS';
      } else if (viaVisita.indexOf('CHECKIN') > -1) {
        tipoLoginArchivoIcono = iconoCHN;
        extra = 'Checkin';
      } else if (viaVisita.indexOf('MC') > -1) {
        tipoLoginArchivoIcono = iconoMC;
        extra = 'MC';
      } else if (viaVisita.indexOf('Whatsapp') > -1) {
        tipoLoginArchivoIcono = iconoWhatsapp;
        extra = 'Whatsapp';
      } else if (viaVisita === 'Auto') {
        tipoLoginArchivoIcono = iconoAuto;
        extra = t('Automatic');
      } else if (viaVisita.indexOf('AUTO') > -1) {
        tipoLoginArchivoIcono = iconoAuto;
        extra = t('Automatic');
      } else if (viaVisita === 'HUESPED-PMS') {
        tipoLoginArchivoIcono = iconoHuesped;
        extra = t('Checkin');
      } else if (viaVisita.indexOf('USUARIO') > -1) {
        tipoLoginArchivoIcono = iconoUserPass;
        extra = `${t('User and password')}\n${viaVisita.split(':')[1]}`;
      } else if (viaVisita.indexOf('MANUAL') > -1) {
        tipoLoginArchivoIcono = iconoManual;
      } else if (viaVisita.indexOf('evento') > -1) {
        tipoLoginArchivoIcono = iconoEvento;
        extra = t('Event code');
      } else if (viaVisita.indexOf('ACCESO') > -1) {
        tipoLoginArchivoIcono = iconoAcceso;
        extra = t('Access code');
      } else {
        return '-';
      }
    } else {
      return '-';
    }

    return (
      <>
        <img className="rounded" alt="" style={{ width: '30px', height: '30px' }} src={tipoLoginArchivoIcono} id={`viaVisita${id}`} />
        {extra && (
        <UncontrolledTooltip placement="top" target={`viaVisita${id}`}>
          {extra}
        </UncontrolledTooltip>
        )}
      </>
    );
  }
  const cargaDatos = async (inicio, longitud, minIndex = 0, busqueda = '') => {
    let nuevasEtiquetas = [];
    if (cargaInicial) {
      try {
        const result = await axiosApi.get('/api/locales/clientes/etiquetas');
        const tags = result.data.etiquetas;
        nuevasEtiquetas = tags.map(tag => {
          return {
            label: tag.nombre_etiqueta,
            value: tag.id_etiquetas,
            color: tag.color,
            descripcion: tag.descripcion
          };
        });
        setEtiquetas(nuevasEtiquetas);
      } catch (e) {
        // console.log(e);
      }
    } else {
      nuevasEtiquetas = etiquetas;
    }
    try {
      const response = await axiosApi.post('/api/locales/listadoClientes', { inicio, longitud, filtroActivo, busqueda });
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      const datos = data.data.map(camp => {
        if (camp.length === 18) {
          camp = camp.filter((e, i) => i !== 8);
        }
        const fecha = camp[1] ? camp[1].split(' ') : '';
        const emailTlf = camp[4] ? camp[4].split('<br>') : ['', ''];
        let tags = [];
        if (typeof camp[14] === typeof '') {
          const idUEt = camp[14].split(',');
          tags = nuevasEtiquetas.filter(e => idUEt.indexOf(`${e.value}`) > -1);
        }
        const entry = {
          id: camp[13],
          timestamp: fecha ? `${fecha[0]} ${fecha[fecha.length - 1]}` : '',
          nombre: htmlEntities(camp[10]),
          genero: camp[5],
          origen: camp[11],
          mail: emailTlf[0] ? htmlEntities(emailTlf[0]) : '',
          edad: camp[6],
          idioma: htmlEntities(camp[7]),
          ultima: camp[8],
          tlf: emailTlf[1] ? emailTlf[1] : '',
          ficha: camp[12],
          tags,
          nombreP: camp[15],
          apellidos: camp[16]
        };
        return entry;
      });
      setVisitantes(datos);
      const copyDatos = datos.slice(0);
      setVisitantesVisibles(copyDatos.slice(minIndex, minIndex + longitud / 5));
      setIsLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  const cargaCantidadTotal = async (busqueda = '') => {
    try {
      const response = await axiosApi.post('/api/locales/totalListadoClientes', { filtroActivo, busqueda });
      const { data } = response;
      const { error } = data;
      if (error) {
        showToast(t('Error getting data'), t('The data can\'t be fetched'), 'error');
        return;
      }
      setTotalAcciones(data.total);
    } catch (error) {
      // error
    }
  };

  const handleExportar = async (destino) => {
    try {
      const result = await axiosApi.post('/api/locales/listadoClientes', { inicio: 0, longitud: 1000000, filtroActivo, busqueda: busquedaManual });
      let campos = [t('Name'), t('Origin'), t('Email'), t('Phone'), t('Age'), t('Language'), t('Gender'), t('Last night'), t('Registration')];
      if (result.data.data[0].length === 18) {
        campos = campos.filter((e, i) => i === 7);
      }
      const datos = result.data.data.map((camp) => {
        const fecha = camp[1] ? camp[1].split(' ') : '';
        const emailTlf = camp[4] ? camp[4].split('<br>') : ['', ''];
        if (camp.length === 18) {
          const entry = [
            camp[10],
            camp[11],
            emailTlf[0] ? emailTlf[0] : '',
            emailTlf[1] ? emailTlf[1] : '',
            camp[6],
            camp[7],
            camp[5],
            fecha ? `${fecha[0]} ${fecha[fecha.length - 1]}` : '',
          ];
          return entry;
        }
        const entry = [
          camp[10],
          camp[11],
          emailTlf[0] ? emailTlf[0] : '',
          emailTlf[1] ? emailTlf[1] : '',
          camp[6],
          camp[7],
          camp[5],
          camp[8],
          fecha ? `${fecha[0]} ${fecha[fecha.length - 1]}` : '',
        ];
        return entry;
      });
      const fichero = [campos, ...datos];
      if (destino === 'csv') {
        let f = fichero.map(r => {
          r = r.map(v => {
            if (!v) return '-';
            return v;
          });
          return r.join(',');
        });
        f = f.join('\n');
        const blob = new Blob(['\ufeff', f], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${t('Customers')}.csv`;
        document.body.appendChild(a);
        a.click();

        // Limpia el enlace y la URL del blob.
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        const libro = utils.book_new();
        const worksheet = utils.aoa_to_sheet(fichero);
        utils.book_append_sheet(libro, worksheet);
        writeFileXLSX(libro, `${t('Reservations')}.xlsx`);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    determinaAmbito();
    cargaFiltros();
    cargaCantidadTotal();
    const indiceArray = Math.floor(((pagina - 1) % 5) * size);
    cargaDatos(start, len, indiceArray, busquedaManual);
    setCargaInicial(false);
    cargaPlantillas();
    switch (localStorage.getItem('I18N_LANGUAGE')) {
      case 'es':
        setIdiomaTlf(es);
        break;
      case 'pt':
        setIdiomaTlf(pt);
        break;
      default:
        setIdiomaTlf('');
    }
  }, []);

  const handleChangeRowSelected = (id) => {
    if (seleccionados.has(id)) {
      const nuevosSel = new Set(seleccionados);
      nuevosSel.delete(id);
      setSeleccionados(nuevosSel);
      let nuevasEtiquetas = [];
      if (nuevosSel.size > 0) {
        visitantes.filter(v => v.id in nuevosSel).forEach((v, index) => {
          if (index === 0) {
            nuevasEtiquetas = v.tags;
          }
          if (nuevasEtiquetas.length > 0) {
            nuevasEtiquetas = nuevasEtiquetas.filter(e => v.tags.some(ta => ta.value === e.value));
          }
        });
        if (etiquetasPreviaPag !== -1) {
          nuevasEtiquetas = nuevasEtiquetas.filter(e => etiquetasPreviaPag.some(
            ta => ta.value === e.value
          ));
        }
      }
      setEtiquetasSeleccionado(nuevasEtiquetas);
    } else {
      const nuevosSel = new Set(seleccionados);
      nuevosSel.add(id);
      setSeleccionados(nuevosSel);
      if (nuevosSel.size === 1) {
        setEtiquetasSeleccionado(visitantesVisibles.filter(v => v.id === id)[0].tags);
      } else if (etiquetasSeleccionado.length > 0) {
        let nuevasEtiquetas = visitantesVisibles.filter(v => v.id === id)[0].tags;
        nuevasEtiquetas = nuevasEtiquetas.filter(
          e => etiquetasSeleccionado.some(ta => ta.value === e.value)
        );
        setEtiquetasSeleccionado(nuevasEtiquetas);
      }
    }
  };

  const handleChangeRowSelectAll = () => {
    const nuevosSel = new Set();
    let etiquetasComunes = [];
    visitantesVisibles.forEach(v => {
      nuevosSel.add(v.id);
      if (nuevosSel.size === 1) {
        etiquetasComunes = v.tags;
      } else if (etiquetasComunes.length > 0) {
        etiquetasComunes = etiquetasComunes.filter(e => v.tags.some(ta => ta.value === e.value));
      }
    });
    const a1 = [...nuevosSel];
    const a2 = new Set([...seleccionados]);
    if (a1.every(e => seleccionados.has(e))) {
      a1.every(e => a2.delete(e));
      setSeleccionados(a2);
    } else {
      const nuevosSeleccionados = [...nuevosSel, ...seleccionados].sort();
      setSeleccionados(new Set(nuevosSeleccionados));
    }
    setEtiquetasSeleccionado(etiquetasComunes);
  };

  const todosVisiblesSeleccionados = () => {
    const visibles = new Set();
    visitantesVisibles.forEach(v => {
      visibles.add(v.id);
    });
    return [...visibles].every(e => seleccionados.has(e));
  };

  const eliminarUsuario = async () => {
    let eliminar = [];
    if (idEliminar !== -1) eliminar = [idEliminar];
    else eliminar = Array.from(seleccionados);
    try {
      const r = await axiosApi.post('/api/locales/clientes/eliminar', { ids: eliminar });
      if (eliminar.length > 1) {
        if (r.data.valido > 0) {
          showToast(t('Customer deleted'), t('The customers have been eliminated'), 'success');
        } else {
          showToast(t('Error deleting'), t('The customers haven\'t been eliminated'), 'error');
        }
      } else if (r.data.valido > 0) {
        showToast(t('Customer deleted'), t('The customer has been eliminated'), 'success');
      } else {
        showToast(t('Error deleting'), t('The customer hasn\'t been eliminated'), 'error');
      }
      cargaCantidadTotal();
      const indiceArray = Math.floor(((pagina - 1) % 5) * size);
      cargaDatos(start, len, indiceArray, busquedaManual);
    } catch (e) {
      if (eliminar.length > 1) {
        showToast(t('Error deleting'), t('The customers haven\'t been eliminated'), 'error');
      } else {
        showToast(t('Error deleting'), t('The customers hasn\'t been eliminated'), 'error');
      }
      // console.log(e);
    }
    setIdEliminar(-1);
    setSeleccionados(new Set([]));
  };

  useEffect(() => {
    if (!cargaInicial) {
      setIsLoading(true);
      cargaCantidadTotal(busquedaManual);
    }
  }, [filtroActivo]);

  const validationUserData = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: '',
      nombre: '',
      apellidos: '',
      email: '',
      telefono: '',
      idioma: 'es'
    },
    validationSchema: Yup.object().shape({
      nombre: Yup.string().required(t('VALUE_REQUIRED')),
      idioma: Yup.string().required(t('VALUE_REQUIRED')),
      email: Yup.string().test('checkEmail', t('EMAIL_INVALIDO'), async (value) => {
        if (!value || value.length === 0) return true;
        const result = await axiosApi.post('/api/compruebaEmailValido', { email: value });
        return result.data.valido;
      })
    }),
    onSubmit: async () => {
      const { id, nombre, apellidos, email, telefono, idioma } =
        validationUserData.values;
      const datosUsuario = {
        id,
        nombre,
        apellidos,
        email,
        telefono,
        idioma
      };
      try {
        const response = await axiosApi.post('/api/cliente/add', datosUsuario);
        const { data } = response;
        const { error, errorCode } = data;
        if (error) {
          if (errorCode) {
            const title = t('Error saving customer');
            const message = t(errorCode);
            showToast(title, message, 'error');
            return;
          }
          throw Error('Error');
        }
        const title = t('Customer saved');
        const message = t('The customer has been added');
        showToast(title, message, 'success');
        if (data.user?.id_usuarios) {
          const idUsuarios = data.user.id_usuarios.toString();
          const index = visitantes.findIndex(
            c => c.id.toString() === idUsuarios
          );
          if (index < 0) {
            setPagina(1);
            setIsLoading(true);
            cargaCantidadTotal();
            setStart(0);
            cargaDatos(0, len, 0, busquedaManual);
          } else {
            const nuevosClientes = [...visitantes];
            const nuevoCliente = { ...nuevosClientes[index] };
            nuevoCliente.nombre = `${nombre} ${apellidos}`;
            nuevoCliente.nombreP = nombre;
            nuevoCliente.apellidos = apellidos;
            nuevoCliente.tlf = telefono;
            nuevoCliente.mail = email;
            nuevoCliente.idioma = listaTraducida[idioma];
            nuevosClientes[index] = nuevoCliente;
            setVisitantes(nuevosClientes);
            const nuevosVis = [...visitantesVisibles];
            const index2 = nuevosVis.findIndex(c => c.id.toString() === idUsuarios);
            nuevosVis[index2] = nuevoCliente;
            setVisitantesVisibles(nuevosVis);
          }
        }
        setModalCreacionUsuarios(false);
        validationUserData.resetForm();
      } catch (error) {
        // console.log(error);
        const title = t('Error saving customer');
        const message = t('The customer has not been modified');
        showToast(title, message, 'error');
      }
    }
  });

  const editarUsuario = async (idUsuario) => {
    if (idUsuario !== null) {
      const cliente = visitantesVisibles.filter(v => v.id === idUsuario)[0];
      validationUserData.setFieldValue('id', cliente.id);
      if (cliente.nombreP) {
        validationUserData.setFieldValue('nombre', cliente.nombreP);
      }
      if (cliente.apellidos) {
        validationUserData.setFieldValue('apellidos', cliente.apellidos);
      }
      if (cliente.mail) {
        validationUserData.setFieldValue('email', cliente.mail);
      }
      if (cliente.tlf) {
        validationUserData.setFieldValue('telefono', cliente.tlf);
      }
      if (cliente.idioma) {
        const idioma = findKey(listaTraducida, valor => valor === cliente.idioma);
        validationUserData.setFieldValue('idioma', idioma);
      }
    }
    setModalCreacionUsuarios(true);
  };

  const columns = [
    {
      dataField: 'id',
      text: '',
      sort: false,
      style: {
        width: '2%'
      },
      formatExtraData: () => seleccionados,
      headerFormatter: () => {
        return (
          <Input
            type="checkbox"
            onClick={() => handleChangeRowSelectAll()}
            checked={todosVisiblesSeleccionados()}
          />
        );
      },
      formatter: (value, r, i, sel) => {
        return (
          <Input type="checkbox" checked={sel().has(value)} onChange={() => handleChangeRowSelected(value)} />
        );
      }
    },
    {
      dataField: 'nombre',
      text: t('Name'),
      sort: true,
      formatExtraData: () => isLoading,
      formatter: (value, row, i, loading) => {
        if (loading()) return '';
        return <a href={`${process.env.REACT_APP_HOST_PANEL}/listado/ficha_visitante/${row.ficha}`} target="_blank" rel="noreferrer">{value}</a>;
      },
      style: {
        width: '23%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line'
      },
    },
    {
      dataField: 'mail',
      text: `${t('Email')}/${t('Phone')}`,
      sort: true,
      formatter: (value, row) => {
        return (
          <>
            {value}
            <br />
            {row.tlf}
          </>
        );
      },
      style: {
        width: '23%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line'
      },
    },
    {
      dataField: 'idioma',
      text: t('Language'),
      sort: true,
      style: {
        width: '6%'
      },
    },
    {
      dataField: 'tags',
      text: t('Tags'),
      sort: true,
      style: {
        width: '22%',
        whiteSpace: 'pre-line'
      },
      formatExtraData: () => isLoading,
      formatter: (v, r, i, extra) => {
        if (extra()) return '';
        return (
          <>
            {v && v.map(e => (
              <div
                key={e.value}
                style={{
                  display: 'inline-block',
                  padding: '0.35em 0.65em',
                  fontSize: '0.75em',
                  fontWeight: '500',
                  lineWeight: '1',
                  color: 'white',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  verticalAlign: 'baseline',
                  borderRadius: '0.25rem',
                  cursor: 'pointer',
                  backgroundColor: e.color,
                  margin: '2px'
                }}
                onClick={() => {
                  setModalTags(true);
                  if (!perteneceDivision) {
                    setIdTags(r.id);
                    setEtiquetasSeleccionado(v);
                    setSeleccionados(new Set([r.id]));
                  }
                }}
              >
                <div style={{ width: '100%', textAlign: 'center' }} id={`tag${e.value}${r.id}`}>
                  {e.label}
                </div>
                {e.descripcion !== '' && (
                  <UncontrolledTooltip target={`tag${e.value}${r.id}`}>
                    {e.descripcion}
                  </UncontrolledTooltip>
                )}
              </div>
            ))}
          </>
        );
      }
    },
    {
      dataField: 'timestamp',
      text: t('Registration'),
      sort: true,
      style: {
        width: '12%'
      },
    },
    {
      dataField: 'origen',
      text: t('Origin'),
      sort: true,
      formatExtraData: () => isLoading,
      formatter: (value, row, i, loading) => {
        if (loading()) return '';
        return iconoVia(value, row.id);
      },
      style: {
        width: '6%'
      },
    },
    {
      dataField: '',
      text: '',
      formatExtraData: () => [isLoading, funcionalidades],
      formatter: (value, r, i, loading) => {
        const func = loading()[1];
        if (loading()[0]) return '';
        return (
          <UncontrolledDropdown
            direction="left"
          >
            <DropdownToggle tag="a" caret>
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu
              className="lista-opciones"
            >
              <DropdownItem>
                {!func?.chatzone?.chatzone && (
                  <a
                    href={`${process.env.REACT_APP_HOST_PANEL}/nuevo/messagingcenter/inbox?abrir=${r.id}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: 'initial', display: 'flex', width: '100%' }}
                  >
                    {t('Contactar')}
                  </a>
                )}
                {func?.chatzone?.chatzone && (
                  <>
                    <span
                      style={{ color: '#74788d', backgroundColor: 'transparent', cursor: 'default' }}
                      id="dropdownContactar"
                    >
                      {t('Contactar')}
                    </span>
                    <UncontrolledTooltip target="dropdownContactar">
                      {t('Funcionalidad deshabilitada tooltip')}
                    </UncontrolledTooltip>
                  </>
                )}
              </DropdownItem>
              <DropdownItem
                id="dropdownPlantillaWhatsapp"
                onClick={() => {
                  if (!func?.whatsapp?.plantillas) {
                    setModalInstantaneo(true);
                    setTlfEnviar(r.tlf);
                  }
                }}
                style={func?.whatsapp?.plantillas ? { color: '#74788d', backgroundColor: 'transparent', cursor: 'default' } : {}}
              >
                {t('Send whatsapp template')}
              </DropdownItem>
              {func?.whatsapp?.plantillas && (
                <UncontrolledTooltip target="dropdownPlantillaWhatsapp">
                  {t('Funcionalidad deshabilitada tooltip')}
                </UncontrolledTooltip>
              )}
              <DropdownItem
                id={`dropdownTag${r.id}`}
                onClick={() => {
                  setModalTags(true);
                  if (!perteneceDivision) {
                    setIdTags(r.id);
                    if (r.tags) setEtiquetasSeleccionado(r.tags);
                    setSeleccionados(new Set([r.id]));
                  }
                }}
              >
                {t('Edit tags')}
              </DropdownItem>
              <DropdownItem
                id="dropdownEditarCliente"
                onClick={() => {
                  editarUsuario(r.id);
                }}
              >
                {t('Edit customer')}
              </DropdownItem>
              <DropdownItem
                id="dropdownPlantillaWhatsapp"
                onClick={() => {
                  setModalEliminar(true);
                  setIdEliminar(r.id);
                }}
              >
                {t('Delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
      style: {
        width: '6%'
      },
    }
  ];

  const handleCrearEtiqueta = async (etiqueta) => {
    const nueva = { label: etiqueta, color: 'rgba(85,110,230,1)', descripcion: '' };
    try {
      const result = await axiosApi.post('/api/locales/clientes/anadirEtiqueta', { etiqueta: nueva });
      const nuevaEtiqueta = {};
      nuevaEtiqueta.value = result.data.etiqueta.id_etiquetas;
      nuevaEtiqueta.label = result.data.etiqueta.nombre_etiqueta;
      nuevaEtiqueta.color = result.data.etiqueta.color;
      nuevaEtiqueta.descripcion = result.data.etiqueta.descripcion;
      const nuevasEtiquetas = [...etiquetas, nuevaEtiqueta];
      setEtiquetas(nuevasEtiquetas);
      let nuevasSeleccionadas = [];
      if (etiquetasSeleccionado !== null) {
        nuevasSeleccionadas = [...etiquetasSeleccionado, nuevaEtiqueta];
      } else {
        nuevasSeleccionadas = [nuevaEtiqueta];
      }
      setEtiquetaEditar(nuevaEtiqueta);
      setEtiquetasSeleccionado(nuevasSeleccionadas);
      setEtiquetaNueva(true);
      setEtiquetasQuitar(etiquetasQuitar.filter(v => v !== nuevaEtiqueta.value));
    } catch (e) {
      // console.log(e);
    }
  };

  const removeTag = (etiqueta) => {
    // console.log(etiqueta);
    const nuevasEtiquetas = [...etiquetasSeleccionado].filter(e => e.value !== etiqueta);
    setEtiquetasQuitar([...etiquetasQuitar, etiqueta]);
    setEtiquetasSeleccionado(nuevasEtiquetas);
  };

  const guardarEtiquetasUsuarios = async () => {
    let idAfecta = [];
    let dicBBDD = [];
    const visitantesNuevos = [...visitantes];
    let url = '';
    if (idTags === -1) {
      url = '/api/locales/clientes/editarEtiquetasUsuariosMasivo';
      idAfecta = Array.from(seleccionados);
      dicBBDD = {
        ids: idAfecta,
        anadir: etiquetasSeleccionado.map(e => e.value),
        quitar: etiquetasQuitar
      };
      visitantesNuevos.forEach(v => {
        if (idAfecta.indexOf(v.id) > -1) {
          let nuevosTagsUsu = [...v.tags].concat([...etiquetasSeleccionado]);
          nuevosTagsUsu = nuevosTagsUsu.filter(
            (obj, index, s) => index === s.findIndex((o) => (o.value === obj.value))
          );
          nuevosTagsUsu = nuevosTagsUsu.filter(ob => !etiquetasQuitar.some(el => el === ob.value));
          v.tags = nuevosTagsUsu.sort((a, b) => a.value - b.value);
        }
      });
    } else {
      url = '/api/locales/clientes/editarEtiquetasUsuarios';
      idAfecta = [idTags];
      visitantesNuevos.forEach(v => {
        if (idAfecta.indexOf(v.id) > -1) {
          v.tags = [...etiquetasSeleccionado];
          dicBBDD.push({
            usuario: v.id,
            idEt: Array.from(new Set(
              [...v.tags.map(e => e.value), ...etiquetasSeleccionado.map(e => e.value)]
            ))
          });
        }
      });
    }
    try {
      await axiosApi.post(url, dicBBDD);
      setVisitantes(visitantesNuevos);
      setVisitantesVisibles(visitantesNuevos.slice(((pagina - 1) % 5) * size, (pagina % 5) * size));
      setModalTags(false);
      setEtiquetasSeleccionado([]);
      setIdTags(-1);
      setEtiquetasQuitar([]);
    } catch (e) {
      // console.log(e);
    }
  };

  const editaEtiqueta = async () => {
    try {
      await axiosApi.post('/api/locales/clientes/anadirEtiqueta', { etiqueta: etiquetaEditar, id_etiqueta: etiquetaEditar.value });
    } catch (e) {
      // console.log(e);
    }
    const nuevasEtiquetas = etiquetas.filter(e => e.value !== etiquetaEditar.value);
    setEtiquetas([...nuevasEtiquetas, etiquetaEditar]);
    const nuevasEtiquetasSeleccionado = etiquetasSeleccionado.filter(
      e => e.value !== etiquetaEditar.value
    );
    setEtiquetasSeleccionado([...nuevasEtiquetasSeleccionado, etiquetaEditar]);
    setModalVieja(false);
    setEtiquetaNueva(false);
    const nuevosVis = [...visitantes];
    nuevosVis.forEach(v => {
      if (v.tags.length > 0) {
        v.tags = v.tags.map(tag => {
          if (tag.value === etiquetaEditar.value) {
            return etiquetaEditar;
          }
          return tag;
        });
      }
    });
    setVisitantes(nuevosVis);
    setEtiquetaEditar(null);
    const minIndex = ((pagina - 1) % 5) * size;
    setVisitantesVisibles(nuevosVis.slice(minIndex, minIndex + size));
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <UncontrolledDropdown direction="left">
      <DropdownToggle outline color="primary">
        {currSizePerPage}
        <i className="bx bx-caret-down" />
      </DropdownToggle>
      <DropdownMenu className="lista-opciones">
        {options.map(option => (
          <DropdownItem
            key={option.page}
            onClick={e => {
              setIsLoading(true);
              onSizePerPageChange(option.page);
              if (e) e.preventDefault();
              setLen(5 * option.page);
              setSize(option.page);
              cargaDatos(0, 5 * option.page, 0, busquedaManual);
              setPagina(1);
            }}
          >
            {option.page}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const handlePageChange = (page) => {
    if (Math.floor((page - 1) / 5) * len !== start) {
      setIsLoading(true);
      const minIndex = Math.floor((page - 1) / 5) * len;
      const indiceArray = Math.floor(((page - 1) % 5) * size);
      cargaDatos(minIndex, len, indiceArray, busquedaManual);
      setStart(minIndex);
      if (seleccionados.size > 0) setEtiquetasPreviaPag(etiquetasSeleccionado);
    }
    setPagina(page);
  };

  const pageOptions = {
    sizePerPage: size,
    page: pagina,
    totalSize: totalAcciones,
    sizePerPageRenderer,
    custom: true,
    showTotal: true,
    onPageChange: (page) => handlePageChange(page)
  };

  const rowStyle = (row) => {
    if (isLoading) {
      return { display: 'table-row' };
    }
    if (seleccionados.has(row.id)) {
      return { backgroundColor: '#dcdcdc' };
    }
    return {};
  };

  const handleTableChange = (type, { page }) => {
    if (type === 'pagination') {
      const datos = visitantes.slice(0);
      const minIndex = ((page - 1) % 5) * size;
      setVisitantesVisibles(datos.slice(minIndex, minIndex + size));
    }
  };

  const NoDataIndication = () => (
    <div className="spinner">
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
    </div>
  );

  const handleCambioBusqueda = (value) => {
    setBusquedaManual(value);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!cargaInicial) {
      const delayed = setTimeout(() => {
        setIsLoading(true);
        cargaCantidadTotal(busquedaManual);
      }, 1000);
      return () => clearTimeout(delayed);
    }
  }, [busquedaManual]);

  document.title = `${t('Visits list')} - ${t('Journeytok dashboard')}`;

  const customMultiValueContainer = () => {
    return (
      <div
        onKeyDown={(e) => {
          if (e.key === 'Backspace') e.preventDefault();
        }}
      />
    );
  };

  const customInput = (p) => {
    const v = p.value;
    return (
      <components.Input
        {...p}
        placeholder={t('Select or add tag')}
        onKeyDown={(e) => {
          if (v.length === 45 && e.key !== 'Backspace') e.preventDefault();
          if (e.key === 'Backspace' && v === '') e.preventDefault();
        }}
      />
    );
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={`${t('Customers list')}`} breadcrumbItems={[{ title: t('Customers') }, { title: t('Customers list') }]} />
          <Row>
            <Col className="col-12">
              <Row className="mb-2">
                <Col sm={{ size: 'auto' }} style={{ marginLeft: 'auto' }} />
              </Row>
              <Card>
                <CardBody>
                  <CabeceraListas
                    filtros={filtros}
                    modificarFiltroActivo={setFiltroActivo}
                    textoMostrarTodos="All customers"
                    total={totalAcciones}
                    vista="Customers"
                    handleChange={handleCambioBusqueda}
                    funcionExportar={handleExportar}
                    isLoading={isLoading}
                    nSelected={seleccionados.size}
                    handleEliminar={setModalEliminar}
                    handleTags={perteneceDivision ? false : setModalTags}
                    textoNuevo="New customer"
                    handleNuevo={editarUsuario}
                  />
                  <hr style={{ borderColor: '#495057', borderTop: '2px solid', marginLeft: '-20px', marginRight: '-20px' }} />
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={visitantesVisibles}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={visitantesVisibles}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive tabla-datos">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    remote={{ pagination: true }}
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    onTableChange={handleTableChange}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={() => <NoDataIndication />}
                                    rowClasses={isLoading ? 'placeholder-dinamic' : ''}
                                    rowStyle={rowStyle}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card body className="puntos">
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <Row style={{ margin: '5px', marginTop: '20px' }}>
                  <p><strong>{t('Visits list')}</strong></p>
                  <p>{t('TextoPieVisitantes')}</p>
                </Row>
              </div>
            </Card>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={modalInstantaneo}
        toggle={() => {
          togInstantaneo();
          setExpandirEnvio(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t('Unique Whatsapp send')}
          </h5>
          <button
            type="button"
            onClick={() => {
              togInstantaneo();
              setIdiomaPlantilla('');
              setTelefonoSinPrefijo('');
              setCamposEnviar([]);
              setCamposEnvioUnico([]);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row style={{ marginBottom: '15px' }}>
            <Col sm={3} xs={3}>
              {t('Template')}
            </Col>
            <Col>
              <ReactSelect
                onChange={setNombrePlantilla}
                options={opcionesPlantillas}
                placeholder={t('Select')}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col sm={3} xs={3}>
              {t('Language')}
            </Col>
            <Col>
              <ReactSelect
                onChange={setIdiomaPlantilla}
                options={opcionesIdiomas}
                placeholder={t('Select')}
              />
            </Col>
          </Row>
          {!buscaReserva && (
            <Row style={{ marginBottom: '15px' }}>
              <Col
                sm={3}
                xs={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '5vh'
                }}
              >
                {t('Send to')}
              </Col>

              <Col
                sm={6}
                xs={6}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '5vh'
                }}
              >
                <PhoneInput
                  onChange={setTelefonoSinPrefijo}
                  value={telefonoSinPrefijo}
                  enableSearch
                  country={localStorage.getItem('I18N_LANGUAGE')}
                  localization={idiomaTlf}
                  defaultValue={tlfEnviar}
                />
              </Col>
            </Row>
          )}
          {buscaReserva && (
            <Row style={{ marginBottom: '15px' }}>
              <Col
                sm={3}
                xs={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('Booking ID')}
              </Col>
              <Col>
                <Input
                  onChange={(e) => {
                    const index = camposEnvioUnico.indexOf('id_pms_show');
                    setIdReservaInvalida(false);
                    setExpandirEnvio(false);
                    let copiaCampos = [...camposEnviar];
                    copiaCampos = copiaCampos.map((v, i) => {
                      if (i !== index) return '';
                      return e.target.value;
                    });
                    setCamposEnviar(copiaCampos);
                  }}
                  id="idReserva"
                  invalid={idReservaInvalida}
                />
                {idReservaInvalida && (
                  <FormFeedback
                    target="idReserva"
                  >
                    {t('Invalid reservation')}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          )}
          {expandirEnvio && buscaReserva && (
            <>
              <Row style={{ marginBottom: '15px' }}>
                <Col
                  sm={3}
                  xs={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '5vh'
                  }}
                >
                  {t('Send to')}
                </Col>
                <Col
                  sm={6}
                  xs={6}
                  style={{
                    height: '5vh'
                  }}
                >
                  <PhoneInput
                    onChange={setTelefonoSinPrefijo}
                    value={telefonoSinPrefijo}
                    enableSearch
                    country={localStorage.getItem('I18N_LANGUAGE')}
                  />
                </Col>
              </Row>
            </>
          )}
          {expandirEnvio && (
            <Row style={{ marginBottom: '15px' }}>
              <strong>
                {t('Las variables de la plantilla serán sustituidas con esta información:')}
              </strong>
            </Row>
          )}
          {expandirEnvio && camposEnvioUnico.map((c, i) => {
            if (c !== 'id_pms_show') {
              return (
                <Row key={i} style={{ marginBottom: '15px' }}>
                  <Col sm={3} xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                    {t(c)}
                  </Col>
                  <Col>
                    <Input
                      value={camposEnviar[i]}
                      onChange={(e) => {
                        const temp = [...camposEnviar];
                        temp[i] = e.target.value;
                        setCamposEnviar(temp);
                        const nuevosRequeridos = [...campoRequerido];
                        nuevosRequeridos[i] = false;
                        setCampoRequerido(nuevosRequeridos);
                      }}
                      invalid={campoRequerido[i]}
                    />
                    {campoRequerido[i] && (
                      <FormFeedback>
                        {t('This value is required')}
                      </FormFeedback>
                    )}
                  </Col>
                </Row>
              );
            }
            return '';
          })}
        </div>
        <div className="modal-footer">
          {!expandirEnvio && (
            <button
              type="button"
              onClick={() => {
                togInstantaneo();
                setExpandirEnvio(false);
                setIdiomaPlantilla('');
                setTelefonoSinPrefijo('');
                setCamposEnviar([]);
                setCamposEnvioUnico([]);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              {t('Close')}
            </button>
          )}
          {expandirEnvio && (
            <button
              type="button"
              onClick={() => {
                setExpandirEnvio(false);
                let temp = [...camposEnviar];
                const index = camposEnvioUnico.indexOf('id_pms_show');
                if (index !== -1) setTelefonoSinPrefijo('34');
                temp = temp.map((v, i) => {
                  if (i !== index) return '';
                  return v;
                });
                setCamposEnviar(temp);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              {t('Return')}
            </button>
          )}
          <button
            onClick={() => {
              if (nombrePlantilla.value !== '' && camposEnviar.filter(c => c === '').length === 0 && telefonoSinPrefijo) {
                enviarTemplate(
                  nombrePlantilla.value,
                  componentesPlantilla,
                  idiomaPlantilla.value
                );
                setNombrePlantilla({});
                setIdiomaPlantilla({});
                setExpandirEnvio(false);
                togInstantaneo();
                setIdiomaPlantilla('');
                setTelefonoSinPrefijo('');
                setCamposEnviar([]);
                setCamposEnvioUnico([]);
              } else if (!expandirEnvio) {
                buscarDatosCliente();
              } else {
                const nuevosRequeridos = camposEnviar.map(v => {
                  return !v;
                });
                setCampoRequerido(nuevosRequeridos);
              }
            }}
            type="button"
            className="btn btn-primary"
          >
            {camposEnviar.filter(c => c === '').length === 0 ? t('Send') : t('Next')}
          </button>
        </div>
      </Modal>
      <Modal
        title={t('Are you sure?')}
        confirmBtnBsStyle="danger"
        isOpen={modalEliminar}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t('Delete customer')}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalEliminar(false);
              setIdEliminar(-1);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{t('Delete user warning')}</p>
          <p>{t('Accion no reversible')}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="link"
            onClick={() => {
              setModalEliminar(false);
              setIdEliminar(-1);
            }}
            data-dismiss="modal"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              eliminarUsuario();
              setModalEliminar(false);
            }}
            style={{ backgroundColor: '#dc3545', borderColor: '#dc3545' }}
          >
            {t('Delete')}
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={modalErrorInstantaneo}
        toggle={() => setModalErrorInstantaneo(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t('Error')}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalErrorInstantaneo(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{t('Error loading template')}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="success"
            onClick={() => {
              setModalErrorInstantaneo(false);
            }}
            data-dismiss="modal"
          >
            {t('Ok').toUpperCase()}
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={modalTags && perteneceDivision}
        info
        toggle={() => setModalTags(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t('Info')}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalTags(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{t('To assign tags go to your division')}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="success"
            onClick={() => {
              setModalTags(false);
            }}
            data-dismiss="modal"
          >
            {t('Ok').toUpperCase()}
          </Button>
        </div>
      </Modal>
      <Offcanvas
        isOpen={modalTags && !perteneceDivision}
        direction="end"
        style={{ width: '30%', padding: '2%' }}
      >
        <div className="modal-header" style={{ marginBottom: '2%' }}>
          <h5 className="modal-title mt-0" id="myModalLabel">
            {t('Edit tags')}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalTags(false);
              setIdTags(-1);
              setEtiquetasSeleccionado([]);
              setSeleccionados(new Set([]));
              setEtiquetasQuitar([]);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div style={{ height: '25vh' }}>
            {(etiquetasSeleccionado === null || etiquetasSeleccionado.length === 0) &&
            (idTags !== -1 || seleccionados.size === 1) && (
              <div>{t('No tag selected')}</div>
            )}
            {(idTags === -1 && seleccionados.size > 1 && (
              <div>{t('Only common tags will be displayed')}</div>
            ))}
            {etiquetasSeleccionado !== null && etiquetasSeleccionado.map(e => {
              return (
                <div
                  key={e.value}
                  style={{
                    display: 'inline-flex',
                    padding: '0.35em 0.65em',
                    fontSize: '0.75em',
                    fontWeight: '700',
                    lineWeight: '1',
                    color: 'white',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'baseline',
                    borderRadius: '0.25rem',
                    backgroundColor: e.color,
                    margin: '2px'
                  }}
                >
                  <div
                    style={{ cursor: '' }}
                    onClick={() => {
                      setEtiquetaEditar(e);
                      setEtiquetaNueva(false);
                    }}
                  >
                    {e.label}
                  </div>
                  <div style={{ float: 'right', marginLeft: 'auto', cursor: 'pointer' }} onClick={() => removeTag(e.value)}>
                    <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg">
                      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
                    </svg>
                  </div>
                </div>
              );
            })}
          </div>
          <CreatableSelect
            isMulti
            options={etiquetas}
            onChange={(e) => {
              setEtiquetasQuitar(etiquetasQuitar.filter(v => !e.some(o => o.value === v)));
              setEtiquetasSeleccionado(e.sort((a, b) => a.value - b.value));
            }}
            onCreateOption={(value) => handleCrearEtiqueta(value)}
            value={etiquetasSeleccionado}
            onKeyDown={(e) => e}
            components={{
              MultiValueContainer: customMultiValueContainer,
              ClearIndicator: customMultiValueContainer,
              Input: customInput
            }}
            placeholder={t('Select or add tag')}
          />
          {etiquetaEditar !== null && (
            <div
              style={{
                border: '1px solid #dcdcdc',
                margin: '5% 15px',
                padding: '5px 10px',
                borderRadius: '10px'
              }}
            >
              <h5>{t('Edit tag')}</h5>
              <Row style={{ marginBottom: '10px' }}>
                <Label>{t('Name')}</Label>
                <Input
                  style={{ width: '80%', marginLeft: '5%' }}
                  type="text"
                  defaultValue={etiquetaEditar.label}
                  onChange={(e) => {
                    const nEti = { ...etiquetaEditar };
                    nEti.label = e.target.value;
                    setEtiquetaEditar(nEti);
                  }}
                />
              </Row>
              <Row style={{ marginBottom: '10px' }}>
                <Label>{t('Description')}</Label>
                <Input
                  style={{ width: '80%', marginLeft: '5%' }}
                  type="text"
                  defaultValue={etiquetaEditar.descripcion}
                  value={etiquetaEditar.descripcion}
                  onChange={(e) => {
                    if (e.target.value.length < 200) {
                      const nEti = { ...etiquetaEditar };
                      nEti.descripcion = e.target.value;
                      setEtiquetaEditar(nEti);
                    }
                  }}
                />
              </Row>
              <Row>
                <Label>{t('Color')}</Label>
                <Input
                  type="text"
                  maxLength="200"
                  className="colorpicker-default"
                  value={etiquetaEditar.color}
                  style={{ width: '80%', marginLeft: '5%' }}
                  readOnly
                />
                <span
                  style={{
                    height: '36px',
                    width: '36px',
                    position: 'relative',
                    top: '0',
                    right: '8%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderLeft: '1px solid #ced4da',
                    backgroundColor: '#ced4da'
                  }}
                >
                  <span
                    style={{
                      minHeight: '16px',
                      minWidth: '16px',
                      border: 'initial',
                      color: etiquetaEditar.color,
                      backgroundColor: etiquetaEditar.color
                    }}
                  />
                </span>
                <div style={{ marginLeft: '2.5%', width: 'fit-content' }}>
                  <GithubPicker
                    value={etiquetaEditar.color}
                    onChangeComplete={(v) => {
                      const nEti = { ...etiquetaEditar };
                      const color = `rgba(${v.rgb.r},${v.rgb.g},${v.rgb.b},${v.rgb.a})`;
                      nEti.color = color;
                      setEtiquetaEditar(nEti);
                    }}
                    colors={[
                      'rgba(85,110,230,1)', 'rgba(116,120,141,1)', 'rgba(52,195,143,1)', 'rgba(80,165,24,1)', 'rgba(241,180,76,1)', 'rgba(244,106,106,1)', 'rgba(232,62,140,1)', 'rgba(52,58,64,1)',
                      'rgba(85,110,230,0.5)', 'rgba(116,120,141,0.5)', 'rgba(52,195,143,0.5)', 'rgba(80,165,24,0.5)', 'rgba(241,180,76,0.5)', 'rgba(244,106,106,0.5)', 'rgba(232,62,140,0.5)', 'rgba(52,58,64,0.5)'
                    ]}
                    width="220px"
                    triangle="hide"
                  />
                </div>
              </Row>
              <div className="modal-footer" style={{ justifyContent: 'flex-start', marginTop: '5%' }}>
                <button
                  type="button"
                  onClick={() => {
                    setEtiquetaEditar(null);
                  }}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {t('Close')}
                </button>
                <button
                  onClick={() => {
                    if (etiquetaNueva) editaEtiqueta();
                    else setModalVieja(true);
                  }}
                  type="button"
                  className="btn btn-primary"
                  style={{ marginLeft: '2%' }}
                >
                  {t('Save')}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer" style={{ justifyContent: 'flex-start' }}>
          <button
            type="button"
            onClick={() => {
              setEtiquetasSeleccionado([]);
              setModalTags(false);
              setIdTags(-1);
              setSeleccionados(new Set([]));
              setEtiquetaEditar(null);
              setEtiquetasQuitar([]);
            }}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {t('Close')}
          </button>
          <button
            onClick={() => {
              guardarEtiquetasUsuarios();
              setSeleccionados(new Set([]));
              setEtiquetaEditar(null);
            }}
            type="button"
            className="btn btn-primary"
            style={{ marginLeft: '2%' }}
          >
            {t('Save')}
          </button>
        </div>
      </Offcanvas>
      <Modal
        isOpen={modalVieja}
        toggle={() => setModalVieja(false)}
      >
        <div className="modal-header">
          <h5>{t('Are you sure?')}</h5>
          <button
            type="button"
            onClick={() => {
              setModalVieja(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {t('This action will affect all user with this tag')}
        </div>
        <div className="modal-footer" style={{ justifyContent: 'flex-start', marginTop: '5%' }}>
          <button
            type="button"
            onClick={() => {
              setModalVieja(false);
            }}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {t('Cancel')}
          </button>
          <button
            onClick={() => {
              editaEtiqueta();
            }}
            type="button"
            className="btn btn-primary"
            style={{ marginLeft: '2%' }}
          >
            {t('Save')}
          </button>
        </div>
      </Modal>
      <Offcanvas
        isOpen={modalCreacionUsuarios}
        direction="end"
        style={{ width: '30%', padding: '2%' }}
        toggle={() => {
          setModalCreacionUsuarios(false);
          validationUserData.resetForm();
        }}
      >
        <div className="modal-header">
          {validationUserData.values.id !== '' ? (
            <h5 className="modal-title" id="newContactModal">
              {t('Edit customer')}
            </h5>
          ) : (
            <h5 className="modal-title" id="newContactModal">
              {t('New customer')}
            </h5>
          )}
          <button
            type="button"
            onClick={() => {
              setModalCreacionUsuarios(false);
              validationUserData.resetForm();
            }}
            className="btn-close"
          />
        </div>
        <div className="modal-body">
          <Form
            id="form-add-user"
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div className="mb-3">
              <Row>
                <label htmlFor="new-contact-name" className="col-form-label">
                  {t('First name')}
                  :
                </label>
              </Row>
              <Input
                type="text"
                name="nombre"
                className="form-control"
                id="horizontal-nombre-Input"
                onChange={event => {
                  validationUserData.setFieldValue(
                    'nombre',
                    event.target.value
                  );
                }}
                onBlur={validationUserData.handleBlur}
                value={validationUserData.values.nombre || ''}
                invalid={
                  !!(
                    validationUserData.touched.nombre &&
                    validationUserData.errors.nombre
                  )
                }
              />
              {validationUserData.touched.nombre &&
              validationUserData.errors.nombre ? (
                <FormFeedback type="invalid">
                  {validationUserData.errors.nombre}
                </FormFeedback>
                ) : null}
              <label htmlFor="new-contact-lastname" className="col-form-label">
                {t('Last name')}
                :
              </label>
              <input
                type="text"
                className="form-control"
                id="new-contact-lastname"
                value={validationUserData.values.apellidos || ''}
                onChange={event => {
                  validationUserData.setFieldValue(
                    'apellidos',
                    event.target.value
                  );
                }}
              />
              <label htmlFor="new-contact-email" className="col-form-label">
                {t('Email')}
                :
              </label>
              <Input
                type="text"
                name="email"
                className="form-control"
                id="new-contact-email"
                onChange={event => {
                  validationUserData.setFieldValue(
                    'email',
                    event.target.value
                  );
                }}
                onBlur={validationUserData.handleBlur}
                value={validationUserData.values.email || ''}
                invalid={
                  !!(
                    validationUserData.touched.email &&
                    validationUserData.errors.email
                  )
                }
              />
              {validationUserData.touched.email &&
              validationUserData.errors.email ? (
                <FormFeedback type="invalid">
                  {validationUserData.errors.email}
                </FormFeedback>
                ) : null}
              <label htmlFor="new-contact-phone" className="col-form-label">
                {t('Phone')}
                :
              </label>
              <Row>
                <PhoneInput
                  onChange={(e) => validationUserData.setFieldValue('telefono', e)}
                  value={validationUserData.values.telefono}
                  enableSearch
                  country={localStorage.getItem('I18N_LANGUAGE')}
                  localization={idiomaTlf}
                  defaultValue={tlfEnviar}
                />
              </Row>
              <Row>
                <Col sm={{ size: 7, offset: 5 }}>
                  <Input
                    type="hidden"
                    invalid={
                        !!(
                          validationUserData.touched.telefono &&
                          validationUserData.errors.telefono
                        )
                      }
                  />
                  {validationUserData.touched.telefono &&
                  validationUserData.errors.telefono ? (
                    <FormFeedback type="invalid">
                      {validationUserData.errors.telefono}
                    </FormFeedback>
                    ) : null}
                </Col>
              </Row>
              <label htmlFor="new-contact-language" className="col-form-label">
                {t('Language')}
                :
              </label>
              <select
                className="form-select"
                id="horizontal-language-Input"
                onChange={event => {
                  validationUserData.setFieldValue(
                    'idioma',
                    event.target.value
                  );
                }}
                value={validationUserData.values.idioma || ''}
              >
                {map(languageList, (language, indexLanguage) => (
                  <option key={indexLanguage} value={indexLanguage}>
                    {indexLanguage.toUpperCase()}
                    {' '}
                    {t(language)}
                  </option>
                ))}
              </select>
              {validationUserData.touched.idioma &&
              validationUserData.errors.idioma ? (
                <FormFeedback type="invalid">
                  {validationUserData.errors.idioma}
                </FormFeedback>
                ) : null}
            </div>
          </Form>
        </div>
        <div className="modal-footer" style={{ justifyContent: 'flex-start' }}>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setModalCreacionUsuarios(false);
              validationUserData.resetForm();
            }}
          >
            {t('Cancel')}
          </button>
          <button
            className="btn btn-primary"
            type="submit"
            form="form-add-user"
            style={{ marginLeft: '2%' }}
            onClick={() => validationUserData.handleSubmit()}
          >
            {t('Save')}
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

ListadoClientes.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(ListadoClientes);
